import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { TGenreNames } from './types';
import { useRequestHandler } from './useRequestHandler';

export const useGenreNamesByMentalState = () => {
  const mentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );
  const getGenreNames = useRequestHandler();

  return useQuery<TGenreNames>(
    Queries.getGenreNamesByMentalState(mentalStateId!),
    () => getGenreNames(mentalStateId!),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: err => {
        Logger.error(new Error('Error getting Genre Names by Mental State Id'), {
          mentalStateId,
          err,
        });
      },
      enabled: Boolean(mentalStateId),
    },
  );
};
