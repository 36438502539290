import { useRef, useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';

import { useStreaks } from '../../../../hooks';
import * as S from './Streaks.styles';

export const Streaks = () => {
  const {
    streaks: {
      data: { weekly },
    },
  } = useStreaks();
  const prevRef = useRef(null);
  const currentRef = useRef(null);
  const [showPrev, setShowPrev] = useState(true);
  const [showCurrent, setShowCurrent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowPrev(false);
    }, 1000);
  }, [setShowPrev]);

  return (
    <S.Wrapper data-testid="streaksChangeSplash">
      <Transition
        in={showPrev}
        mountOnEnter
        nodeRef={prevRef}
        timeout={300}
        unmountOnExit
        onExited={() => setShowCurrent(true)}
      >
        {state => (
          <S.PrevValueContainer ref={prevRef} animationState={state}>
            <S.Value>{weekly.currentCount - 1}</S.Value>
          </S.PrevValueContainer>
        )}
      </Transition>
      <Transition in={showCurrent} mountOnEnter nodeRef={currentRef} timeout={300} unmountOnExit>
        {state => (
          <S.CurrentValueContainer ref={currentRef} animationState={state}>
            <S.Value>{weekly.currentCount}</S.Value>
          </S.CurrentValueContainer>
        )}
      </Transition>
      <S.Separator />
      <S.Description>WEEK STREAK</S.Description>
      <S.Comment>{weekly.comment}</S.Comment>
    </S.Wrapper>
  );
};
