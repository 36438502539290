import { useIsEnabledDynamicActivities } from '../../../../../../hooks/useIsEnabledDynamicActivities';
import { TracksByGenresDynamic } from './TracksByGenresDynamic';
import { TracksByGenresLegacy } from './TracksByGenresLegacy';

export const TracksByGenres = () => {
  const isDynamicActivitiesActive = useIsEnabledDynamicActivities();
  if (isDynamicActivitiesActive) {
    return <TracksByGenresDynamic />;
  }
  return <TracksByGenresLegacy />;
};
