import { InputField } from '@Cortex';
import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const CurrentPaymentMethod = styled.div`
  color: #d4d2ea;
  font-size: 0.95rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const CardInformation = styled.span`
  color: white;
`;

export const CardInputContainer = styled.div`
  backdrop-filter: blur(34px);
  background: rgba(25, 23, 54, 0.75);
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
`;

export const SubmitButton = styled.div`
  margin-bottom: 1.5rem;
`;

export const NameInputContainer = styled.div`
  border-radius: 0.75rem;
  margin-bottom: 0.5rem;
  position: relative;
`;

export const NameInputLabel = styled.label<{ isError: boolean }>`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.75rem;
  color: ${({ isError }) => (isError ? 'red' : '#fff')};
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const NameInput = styled(InputField)`
  border: none;
  background: none;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
  margin: 0;
  background: rgba(25, 23, 54, 0.75);

  &:focus {
    border: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    font-size: 1rem;
  }
`;
