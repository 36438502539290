import { OnboardingCardTypes, OnboardingSpecification } from './types';

export const ONBOARDING_MENTAL_STATE_ID_KEY = 'onboardingMentalStateId';

export const DEFAULT_ONBOARDING: OnboardingSpecification = {
  id: 'web_fallback_neurotype_quiz_v3_andy',
  cards: [
    {
      body: 'the constant battle for attention can make it hard to focus on important tasks.',
      heading: 'HEY %%FIRST_NAME%%',
      title: 'the world is distracting by design',
      type: OnboardingCardTypes.InfoTwo,
      video: 'https://cdn.brain.fm/videos/onboardingUnfocused.mp4',
    },
    {
      body: 'especially for those struggling with concentration and procrastination',
      title: 'brain.fm boosts focus up to 10x and makes you less distractible',
      type: OnboardingCardTypes.InfoTwo,
      video: 'https://cdn.brain.fm/videos/onboardingFocused.mp4',
    },
    {
      body: 'brain.fm is proven to work by large scale behavioral studies, extensive research, and in-depth brain-scans.',
      image: 'https://cdn.brain.fm/images/onboarding/brainScan.png',
      title: 'our science is sound',
      type: OnboardingCardTypes.InfoTwo,
    },
    {
      body: 'answer a few questions to personalize brain.fm for our unique neurotype.',
      image: 'https://cdn.brain.fm/images/onboarding/coffeeShop.png',
      isImageFullCoverage: true,
      isSkippable: true,
      title: 'ready to do more with less effort?',
      type: OnboardingCardTypes.InfoTwo,
    },
  ],
  quizSpecification: {
    id: 'march2023test',
    neurotypeMatrix: [
      [
        {
          id: 'low_nel_nature',
          preferredFocusGenreNames: [
            'Beach',
            'Chimes & Bowls',
            'Forest',
            'Nightsounds',
            'Rain',
            'Rainforest',
            'River',
            'Thunder',
            'Underwater',
            'Wind',
          ],
          preferredFocusNeuralEffectLevels: ['Low'],
          resultCards: [
            {
              body: 'you don’t have issues with attention and are sensitive to distractions, so we’ve set brain.fm’s technology to add only the gentlest level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'nature soundscapes with gentle stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
        {
          id: 'low_nel_unplugged',
          preferredFocusGenreNames: ['Acoustic', 'Piano', 'Classical'],
          preferredFocusNeuralEffectLevels: ['Low'],
          resultCards: [
            {
              body: 'you don’t have issues with attention and are sensitive to distractions, so we’ve set brain.fm’s technology to add only the gentlest level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'unplugged with gentle stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
        {
          id: 'low_nel_epic_ambient',
          preferredFocusGenreNames: ['Atmospheric', 'Drone', 'Post-Rock', 'Cinematic'],
          preferredFocusNeuralEffectLevels: ['Low'],
          resultCards: [
            {
              body: 'you don’t have issues with attention and are sensitive to distractions, so we’ve set brain.fm’s technology to add only the gentlest level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'epic, ambient, & spacey with gentle stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
        {
          id: 'low_nel_modern_beats',
          preferredFocusGenreNames: ['Electronic', 'Grooves', 'Lofi'],
          preferredFocusNeuralEffectLevels: ['Low'],
          resultCards: [
            {
              body: 'you don’t have issues with attention and are sensitive to distractions, so we’ve set brain.fm’s technology to add only the gentlest level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'modern beats with gentle stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
      ],
      [
        {
          id: 'low_med_nel_nature',
          preferredFocusGenreNames: [
            'Beach',
            'Chimes & Bowls',
            'Forest',
            'Nightsounds',
            'Rain',
            'Rainforest',
            'River',
            'Thunder',
            'Underwater',
            'Wind',
          ],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [
            {
              body: 'you need an energizing environment but don’t have issues with attention, so we’ve set brain.fm’s technology to add just a moderate level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'nature soundscapes with moderate stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
        {
          id: 'low_med_nel_unplugged',
          preferredFocusGenreNames: ['Acoustic', 'Piano', 'Classical'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [
            {
              body: 'you need an energizing environment but don’t have issues with attention, so we’ve set brain.fm’s technology to add just a moderate level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'unplugged with moderate stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
        {
          id: 'low_med_nel_epic_ambient',
          preferredFocusGenreNames: ['Atmospheric', 'Drone', 'Post-Rock', 'Cinematic'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [
            {
              body: 'you need an energizing environment but don’t have issues with attention, so we’ve set brain.fm’s technology to add just a moderate level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'epic, ambient, & spacey with moderate stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
        {
          id: 'low_med_nel_modern_beats',
          preferredFocusGenreNames: ['Electronic', 'Grooves', 'Lofi'],
          preferredFocusNeuralEffectLevels: ['Low', 'Medium'],
          resultCards: [
            {
              body: 'you need an energizing environment but don’t have issues with attention, so we’ve set brain.fm’s technology to add just a moderate level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'modern beats with moderate stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
      ],
      [
        {
          id: 'med_high_nel_nature',
          preferredFocusGenreNames: [
            'Beach',
            'Chimes & Bowls',
            'Forest',
            'Nightsounds',
            'Rain',
            'Rainforest',
            'River',
            'Thunder',
            'Underwater',
            'Wind',
          ],
          preferredFocusNeuralEffectLevels: ['Medium', 'High'],
          resultCards: [
            {
              body: 'you have attentional challenges but are sensitive to distractions, so we’ve set brain.fm’s technology to add just a moderate level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'nature soundscapes with moderate stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
        {
          id: 'med_high_nel_unplugged',
          preferredFocusGenreNames: ['Acoustic', 'Piano', 'Classical'],
          preferredFocusNeuralEffectLevels: ['Medium', 'High'],
          resultCards: [
            {
              body: 'you have attentional challenges but are sensitive to distractions, so we’ve set brain.fm’s technology to add just a moderate level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'unplugged with moderate stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
        {
          id: 'med_high_nel_epic_ambient',
          preferredFocusGenreNames: ['Atmospheric', 'Drone', 'Post-Rock', 'Cinematic'],
          preferredFocusNeuralEffectLevels: ['Medium', 'High'],
          resultCards: [
            {
              body: 'you have attentional challenges but are sensitive to distractions, so we’ve set brain.fm’s technology to add just a moderate level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'epic, ambient, & spacey with moderate stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
        {
          id: 'med_high_nel_modern_beats',
          preferredFocusGenreNames: ['Electronic', 'Grooves', 'Lofi'],
          preferredFocusNeuralEffectLevels: ['Medium', 'High'],
          resultCards: [
            {
              body: 'you have attentional challenges but are sensitive to distractions, so we’ve set brain.fm’s technology to add just a moderate level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'modern beats with moderate stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
      ],
      [
        {
          id: 'high_nel_nature',
          preferredFocusGenreNames: [
            'Beach',
            'Chimes & Bowls',
            'Forest',
            'Nightsounds',
            'Rain',
            'Rainforest',
            'River',
            'Thunder',
            'Underwater',
            'Wind',
          ],
          preferredFocusNeuralEffectLevels: ['High'],
          resultCards: [
            {
              body: 'you need an energizing environment and have attentional challenges, so we’ve set brain.fm’s technology to add the maximum level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'nature soundscapes with maximum stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
        {
          id: 'high_nel_unplugged',
          preferredFocusGenreNames: ['Acoustic', 'Piano', 'Classical'],
          preferredFocusNeuralEffectLevels: ['High'],
          resultCards: [
            {
              body: 'you need an energizing environment and have attentional challenges, so we’ve set brain.fm’s technology to add the maximum level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'unplugged with maximum stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
        {
          id: 'high_nel_epic_ambient',
          preferredFocusGenreNames: ['Atmospheric', 'Drone', 'Post-Rock', 'Cinematic'],
          preferredFocusNeuralEffectLevels: ['High'],
          resultCards: [
            {
              body: 'you need an energizing environment and have attentional challenges, so we’ve set brain.fm’s technology to add the maximum level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'epic, ambient, & spacey with maximum stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
        {
          id: 'high_nel_modern_beats',
          preferredFocusGenreNames: ['Electronic', 'Grooves', 'Lofi'],
          preferredFocusNeuralEffectLevels: ['High'],
          resultCards: [
            {
              body: 'you need an energizing environment and have attentional challenges, so we’ve set brain.fm’s technology to add the maximum level of stimulation to your music.',
              heading: 'YOUR FOCUS SOLUTION',
              image: 'https://cdn.brain.fm/images/onboarding/focus_at_desk.png',
              title: 'modern beats with maximum stimulation',
              type: OnboardingCardTypes.InfoTwo,
            },
          ],
        },
      ],
    ],
    questions: [
      {
        answers: [
          {
            description: 'I have ADHD or difficulty working.',
            title: 'serious.',
            value: 4,
          },
          {
            description: 'I can usually focus when I need to.',
            title: 'normal.',
            value: 0,
          },
        ],
        description: '',
        image: 'https://cdn.brain.fm/images/onboarding/stressed.png',
        scoreId: 'nel',
        title: 'how hard is it to focus?',
      },
      {
        answers: [
          {
            description: 'I thrive in high energy places!',
            title: 'coffee shop',
            value: 2,
          },
          {
            description: "I can't think unless it's quiet.",
            title: 'library.',
            value: 0,
          },
        ],
        description: '',
        image: 'https://cdn.brain.fm/images/onboarding/focused.png',
        scoreId: 'nel',
        title: 'where do you work best?',
      },
      {
        answers: [
          {
            description: 'electronic, grooves, lofi.',
            title: 'modern beats.',
            value: 6,
          },
          {
            description: 'atmospheric, drone, post-rock, cinematic.',
            title: 'epic, ambient, & spacey',
            value: 4,
          },
          {
            description: 'acoustic, piano, classical.',
            title: 'unplugged.',
            value: 2,
          },
          {
            description: 'rain, beach, thunder, forest, and more.',
            title: 'nature soundscapes.',
            value: 0,
          },
        ],
        description: '',
        image: 'https://cdn.brain.fm/images/onboarding/sound.png',
        scoreId: 'genres',
        title: 'your style of focus music?',
      },
    ],
    scores: [
      {
        breakpoints: [1, 3, 5],
        id: 'nel',
        name: 'nel score',
      },
      {
        breakpoints: [1, 3, 5],
        id: 'genres',
        name: 'genres score',
      },
    ],
  },
};
