import { Text, TextBold } from '@Cortex';
import styled from 'styled-components';

export const SignInForm = styled.div`
  align-items: center;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  padding: 0 1rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    overflow-y: inherit;
  }
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 10vh;
  padding-top: 3rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    margin-bottom: 2vh;
    padding-top: 2vh;
  }
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
`;

export const Button = styled.div`
  width: 48%;
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const FormWrapper = styled.div`
  max-width: 545px;
  width: 100%;
`;

export const Form = styled.div`
  margin-bottom: 1.5rem;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Link = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const LinkLast = styled(Link)`
  margin-bottom: 0;
`;

export const SignInWithWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1.5rem;
`;

export const HR = styled.hr`
  color: rgba(55, 62, 91, 0.7);
  width: 100%;
`;

export const SignInWithText = styled(Text)`
  flex-shrink: 0;
  padding: 0 1rem;
  text-align: center;
  text-transform: lowercase;
`;

export const HeaderText = styled(TextBold)`
  margin-bottom: 1rem;
  text-transform: lowercase;
`;

export const CallToActionText = styled(TextBold)`
  margin-bottom: 1rem;
  text-align: center;
`;

export const LegacyLink = styled.div`
  margin-top: 1rem;
`;
