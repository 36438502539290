import styled from 'styled-components';

export const CallToActionWidget = styled.div`
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  display: flex;
  font-family: TTNormsPro-Regular;
  font-size: 1rem;
  font-weight: 500;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    justify-content: space-between;
    width: 100%;
  }
`;

export const Body = styled.div`
  padding: 1rem;
`;
