import { useSelector } from 'react-redux';
import { RootReducerType } from 'src/reducers';

import { PaymentStates } from '../reducer';

export function useIsPaymentProcessing() {
  const paymentState = useSelector((state: RootReducerType) => state.paymentsV2.state);
  const isPaymentProcessing =
    paymentState === PaymentStates.UpgradeProcessing ||
    paymentState === PaymentStates.CancellationProcessing;

  return isPaymentProcessing;
}
