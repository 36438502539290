import styled from 'styled-components';

export const Logo = styled.img`
  height: 3.5rem;
  width: 3.5rem;
`;

export const LogoPlaceholder = styled(Logo)`
  opacity: 0;
  display: block;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    display: none;
  }
`;

export const OnboardingCardVideoColumn = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const OnboardingCardImageColumn = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: auto;
    height: 100%;
  }
`;

export const BelowBodyImage = styled.img`
  max-width: 300px;
  margin-top: 2rem;
`;

export const Image = styled.img`
  max-height: 500px;
  object-fit: contain;
  width: 100%;
`;

export const Video = styled.video`
  max-height: 500px;
  object-fit: contain;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2rem 3rem 0px 3rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  max-width: 79rem;
  gap: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    margin-bottom: 0.5rem;
    width: 100%;
    overflow-y: auto;
  }
`;

export const OnboardingCardTextColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    padding: 1rem;
    text-align: center;
  }
`;

export const Subtitle = styled.div`
  color: #d4d2ea;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  letter-spacing: 0.2em;
  margin-bottom: 0.75rem;
  text-align: center;
  white-space: pre-line;
`;

export const Title = styled.div<{ isCentered: boolean }>`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 2rem;
  white-space: pre-line;
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 32px;
    line-height: auto;
    margin-bottom: 1.5rem;
    text-align: center;
  }
`;

export const Body = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 2rem;
  white-space: pre-wrap;
  line-height: 3rem;
  text-align: left;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 20px;
    line-height: 2rem;
    margin-bottom: 1rem;
    opacity: 1;
    text-align: center;
  }
`;

export const BodyTextBold = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    padding: 1rem;
    align-items: center;
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    background-color: #000000;
    padding: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  width: 20.75rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    width: 100%;
  }
`;

export const SkipButton = styled(ButtonContainer)`
  margin-right: 1rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

export const StepSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  gap: 0.5rem;
  justify-self: center;
`;
export const StepText = styled.div`
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.75);
`;

export const StepPills = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StepPill = styled.div<{
  isActive: boolean;
  isCompleted: boolean;
}>`
  background: ${({ theme, isActive, isCompleted }) =>
    isActive ? `transparent` : isCompleted ? theme.colors.white : `rgba(255, 255, 255, 0.2)`};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, isActive }) => (isActive ? theme.colors.white : `transparent`)};
  border-radius: 0.5rem;
  height: 0.5rem;
  margin-right: 0.25rem;
  width: 4rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex: 1;
    height: 8px;
    margin: 0px 3px;
    max-width: 64px;
  }
`;
