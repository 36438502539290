import { TextBold } from '@Cortex';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RootReducerType } from '../../reducers';
import { mentalStates } from '../../utils/mentalStates';
import { QUOTES } from './lib/quotes';

const QUOTE_UPDATE_INTERVAL = 30 * 1000; // thirty seconds;

const S = {
  Quote: styled.div<{ isVisible: boolean }>`
    opacity: ${props => (props.isVisible ? 0.7 : 0)};
    text-align: center;
    transition: opacity 1s ease-in-out;
    width: 100%;
  `,
};

export function Quotes() {
  const [visibleQuote, setVisibleQuote] = useState<(typeof QUOTES)[0] | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const quote = useGetRandomQuoteForMentalStatePeriodically();

  useEffect(() => {
    if (!quote) return;

    function fadeOut() {
      setIsVisible(false);
    }

    function fadeIn() {
      setVisibleQuote(quote);
      setIsVisible(true);
    }

    fadeOut();
    const timeout = window.setTimeout(fadeIn, 2000);

    return function clearTimeout() {
      window.clearTimeout(timeout);
    };
  }, [quote]);

  return (
    <S.Quote data-testid="visibleQuote" isVisible={isVisible}>
      {visibleQuote?.lines.map((line, index) => (
        <TextBold key={index} size={24}>
          {line}
        </TextBold>
      ))}
    </S.Quote>
  );
}

function useGetRandomQuoteForMentalStatePeriodically(): (typeof QUOTES)[0] | null {
  const getRandomQuoteForMentalState = useGetRandomQuoteForMentalState();
  const [quote, setQuote] = useState<(typeof QUOTES)[0] | null>(null);

  useEffect(() => {
    let timeout: number | null = null;

    function updateQuotePeriodically() {
      setQuote(getRandomQuoteForMentalState());
      timeout = window.setTimeout(updateQuotePeriodically, QUOTE_UPDATE_INTERVAL);
    }

    updateQuotePeriodically();
    return function clearTimeout() {
      if (timeout) window.clearTimeout(timeout);
    };
  }, [getRandomQuoteForMentalState]);

  return quote;
}

function useGetRandomQuoteForMentalState(): () => (typeof QUOTES)[0] | null {
  const quotesForMentalState = useQuotesForMentalState();

  return useCallback(
    function getRandomQuoteForMentalState(): (typeof QUOTES)[0] | null {
      if (!quotesForMentalState) return null;
      return quotesForMentalState[Math.floor(Math.random() * quotesForMentalState.length)];
    },
    [quotesForMentalState],
  );
}

function useQuotesForMentalState(): typeof QUOTES | null {
  const sessionMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );
  const [allMentalStateQuotes, setAllMentalStateQuotes] = useState<typeof QUOTES | null>(null);

  useEffect(() => {
    const mentalState = mentalStates.byId[sessionMentalStateId || '']?.display as
      | 'Focus'
      | 'Relax'
      | 'Sleep';
    if (!mentalState) return;
    setAllMentalStateQuotes(QUOTES.filter(x => x.mentalState === mentalState));
  }, [sessionMentalStateId]);

  return allMentalStateQuotes;
}
