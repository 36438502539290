import { useQuery } from '@tanstack/react-query';
import { useFeatureFlag, FeatureFlags, useIsFeatureFlagsReceived } from '@Utils';
import { Queries } from '../api/common/queries';

export const useIsEnabledDynamicActivities = (): boolean => {
  const isFeatureFlagsReceived = useIsFeatureFlagsReceived();
  const isDynamicActivitiesActive = useFeatureFlag<boolean>(FeatureFlags.DynamicActivities, {
    setAnalyticsUserProperty: true,
  });

  // using useQuery to cache flag's value until page refresh
  const { data } = useQuery(['isEnabledDynamicActivities'], () => isDynamicActivitiesActive, {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: Boolean(isFeatureFlagsReceived),
  });
  return Boolean(data);
};
