import { CloseButton } from '@Cortex';
import { ToastOptions, Zoom, toast } from 'react-toastify';

export function showToast(message: string, options?: ToastOptions) {
  toast(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    transition: Zoom,
    className: 'custom-toast',
    closeButton: ({ closeToast }) => <CloseButton onClick={closeToast} />,
    ...options,
  });
}
