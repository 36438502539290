import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
  background: #2c2a49;
  border-radius: 1.25rem;
`;

export const ImageContainer = styled.div`
  width: 3.875rem;
  height: 3.875rem;
  background: linear-gradient(60.85deg, #4915f8 -89.64%, #ff496b 126.05%);
  border-radius: 100%;
  padding: 1rem;
`;

export const Image = styled.img`
  object-fit: contain;
`;

export const TextWrapper = styled.div`
  margin-bottom: 0.5rem;
  text-transform: lowercase;
`;

export const HeadingContainer = styled.div`
  text-align: center;
  margin-top: 1.25rem;
`;
