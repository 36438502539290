const LEGACY_QUOTES = [
  {
    group_id: 1,
    text: [`"Everyone shines, given the right lighting."`, `~ Susan Cain`],
  },
  {
    group_id: 1,
    text: [`“Learn to be silent. `, `Let your quiet mind listen and absorb.”`, `~ Pythagoras`],
  },
  {
    group_id: 1,
    text: [
      `“All of humanity's problems stem from man's inability to sit quietly in a room alone.” `,
      `~ Blaise Pascal, Pensées`,
    ],
  },
  {
    group_id: 1,
    text: [`"You can be the lead in your own life."`, `~ Kerry Washington`],
  },
  {
    group_id: 1,
    text: [
      `"Don’t compromise yourself. You are all you’ve got. There is no yesterday, no tomorrow, it’s all the same day."`,
      `–Janis Joplin`,
    ],
  },
  {
    group_id: 1,
    text: [`Surrender and allow your heart to guide you. `, `You will be amazed by what follows.`],
  },
  {
    group_id: 1,
    text: [`True lasting peace comes from the internal world, not external.`],
  },
  {
    group_id: 1,
    text: [
      `"The challenge is not to be perfect...it’s to be whole.",
      - Jane Fonda`,
    ],
  },
  {
    group_id: 1,
    text: [
      `Happiness doesn't come from status or a paycheck, `,
      `it comes from following the path you feel truly pulled to.`,
    ],
  },
  {
    group_id: 1,
    text: [
      `"It’s not the absence of fear, it’s overcoming it. Sometimes you’ve got to blast through and have faith."`,
      `~ Emma Watson`,
    ],
  },
  {
    group_id: 1,
    text: [
      `"You are the one that possesses the keys to your being. You carry the passport to your own happiness." ,- Diane von Furstenberg`,
    ],
  },
  { group_id: 2, text: [`“What you seek is seeking you.” `, `~ Rumi`] },
  {
    group_id: 2,
    text: [`Exhilaration of the journey is more important than the destination.`],
  },
  {
    group_id: 2,
    text: [`Don't sweat the small stuff. Everything is small stuff.`],
  },
  {
    group_id: 2,
    text: [`Be kind and compassionate and the whole world will be your friend.`],
  },
  {
    group_id: 2,
    text: [`“The harder the conflict the more glorious the triumph.” `, `~ Thomas Paine`],
  },
  {
    group_id: 2,
    text: [
      `“Instead of focusing on how much you can accomplish, `,
      `focus on how much you can absolutely love what you’re doing.”`,
      `~ Leo Babauta`,
    ],
  },
  {
    group_id: 2,
    text: [
      `“We cannot be more sensitive to pleasure without being more sensitive to pain.” `,
      `~ Alan Watts`,
    ],
  },
  {
    group_id: 3,
    text: [
      `“Men are disturbed not by the things that happen, `,
      `but by their opinion of the things that happen.”`,
      `~ Epictetus`,
    ],
  },
  {
    group_id: 3,
    text: [`“Even if you fall on your face, you're still moving forward.”`, `~ Victor Kiam Read`],
  },
  { group_id: 3, text: [`“After a storm comes a calm.” `, `~ Matthew Henry`] },
  {
    group_id: 3,
    text: [
      `“My focus is to forget the pain of life. Forget the pain, mock the pain, reduce it. And laugh.” `,
      `~ Jim Carrey`,
    ],
  },
  {
    group_id: 4,
    text: [`“Yesterday is but today's memory, and tomorrow is today's dream.” ~ Khalil Gibran`],
  },
  {
    group_id: 4,
    text: [`“God gave us memory so that we might have roses in December.” `, `~ J.M. Barrie`],
  },
  {
    group_id: 4,
    text: [`“Nothing is ever really lost to us as long as we remember it.” `, `~ L.M. Montgomery`],
  },
  {
    group_id: 4,
    text: [`“Memory is the diary we all carry about with us.” `, `~ Oscar Wilde`],
  },
  {
    group_id: 4,
    text: [
      `“Memories establish the past; Senses perceive the present; `,
      `Imaginations shape the future.”`,
      `~ Toba Beta`,
    ],
  },
  {
    group_id: 5,
    text: [`“You are never too old to set another goal or to dream a new dream.” ~ C. S. Lewis`],
  },
  {
    group_id: 5,
    text: [
      `“The will to succeed is important, `,
      `but what's more important is the will to prepare.”`,
      `~ Bobby Knight`,
    ],
  },
  {
    group_id: 5,
    text: [`And the trouble is, if you don't risk anything, you risk even more.`],
  },
  {
    group_id: 5,
    text: [`“Concentration and mental toughness are the margins of victory.” `, `~ Bill Russell`],
  },
  {
    group_id: 5,
    text: [
      `“Be brutally honest about your strengths and weaknesses. Then, get to work.”`,
      `~ Sam Yagan`,
    ],
  },
  {
    group_id: 5,
    text: [
      `Life is denied by lack of attention, `,
      `whether it be to cleaning windows or trying to write a masterpiece.`,
    ],
  },
  {
    group_id: 5,
    text: [
      `Life challenges are not supposed to paralyze us. `,
      `They're supposed to help discover who we are.`,
    ],
  },
  {
    group_id: 5,
    text: [`You must have the mindset that you will succeed on your quest.`],
  },
  {
    group_id: 6,
    text: [
      `“Do not dwell in the past, do not dream of the future, `,
      `concentrate the mind on the present moment.”`,
      `~ Buddha`,
    ],
  },
  { group_id: 6, text: [`You must do the thing you think you cannot do.`] },
  {
    group_id: 6,
    text: [
      `When you are impressed by someone or something - reflect and write down why you are impressed.`,
      `You will learn more about yourself in the process.`,
    ],
  },
  { group_id: 6, text: [`Healthy thoughts leads to better work.`] },
  {
    group_id: 14,
    text: [
      `“I never could have done what I have done `,
      `without the habit to concentrate myself on one subject at a time.”`,
      `~ Charles Dickens`,
    ],
  },
  {
    group_id: 14,
    text: [`“One finds limits by pushing them.”,  ~ Herbert Simon`],
  },
  {
    group_id: 14,
    text: [`“The secret of getting ahead is getting started.”  `, `~ Mark Twain`],
  },
  { group_id: 14, text: [`“Focusing is about saying No.” `, `~ Steve Jobs`] },
  {
    group_id: 14,
    text: [`“When you fully focus your mind, you move forward.“`],
  },
  {
    group_id: 14,
    text: [
      `“Concentrate all your thoughts upon the work at hand. `,
      `The sun's rays do not burn until brought to a focus.”`,
      `~ Alexander Graham Bell`,
    ],
  },
  {
    group_id: 14,
    text: [
      `“Discipline and concentration are a matter of being interested. `,
      `Find pleasure in what you are doing.”`,
      `~ Tom Kite`,
    ],
  },
  { group_id: 14, text: [`Rule your mind or it will rule you.`] },
  {
    group_id: 14,
    text: [`After love, the sense of productivity is the most fulfilling emotion`],
  },
  {
    group_id: 15,
    text: [
      `“Life isn't about finding yourself, it's about creating yourself” `,
      `~ George Bernard Shaw`,
    ],
  },
  {
    group_id: 15,
    text: [
      `“Happiness is when what you think, what you say, and what you do are in harmony.”  `,
      `~Gandhi `,
    ],
  },
  {
    group_id: 15,
    text: [`“The fears we don’t face become our limits”  `, `~ Robin Sharma`],
  },
  {
    group_id: 15,
    text: [
      `“There are no limits. There are only plateaus, and you must not stay there, `,
      `you must go beyond them.”`,
      `~Bruce Lee `,
    ],
  },
  {
    group_id: 15,
    text: [`“What we see depends mainly on what we look for”  ~ John Lubbock`],
  },
  {
    group_id: 15,
    text: [
      `“To see the world in a grain of sand, and to see heaven in a wild flower, `,
      `hold infinity in the palm of your hands, and eternity in an hour.”`,
      `~William Blake`,
    ],
  },
  {
    group_id: 14,
    text: [`In the province of the mind, there are no limits.`, `~ John C. Lilly`],
  },
  {
    group_id: 14,
    text: [`If you want to change the world, start with yourself. `, `~ Gandhi`],
  },
  {
    group_id: 14,
    text: [`Your worst enemy cannot harm you as much as your own unguarded thoughts. `, `~ Buddha`],
  },
  {
    group_id: 14,
    text: [
      `You don't learn to walk by following rules. `,
      `You learn by doing, and by falling over.`,
      `~ Richard Branson`,
    ],
  },
  {
    group_id: 14,
    text: [
      `If you don't see yourself as a winner`,
      `then you cannot perform as a winner`,
      `~ Zig Ziglar`,
    ],
  },
  {
    group_id: 14,
    text: [
      `Life is pure adventure, and the sooner we realize that, the quicker we will be able to treat life as art`,
      `~ Maya Angelou`,
    ],
  },
  {
    group_id: 14,
    text: [`If you can dream it, you can do it.`, `~ Walt Disney`],
  },
  {
    group_id: 14,
    text: [
      `If you don't have a plan, you become part of somebody else's plan.`,
      `~ Terence McKenna`,
    ],
  },
  {
    group_id: 14,
    text: [
      `Just when the caterpillar thought the world was ending, `,
      `it turned into a butterfly.`,
      `~ Proverb`,
    ],
  },
  {
    group_id: 14,
    text: [
      `I start each day by telling myself what a positive influence I am on this world. `,
      `~ Peter Daisyme`,
    ],
  },
  {
    group_id: 14,
    text: [`Every morning we are born again. What we do today is what matters most `, `~ Buddha`],
  },
  {
    group_id: 14,
    text: [`A person who never made a mistake never tried anything new. `, `~ Albert Einstein`],
  },
  {
    group_id: 14,
    text: [`It does not matter how slowly you go as long as you do not stop. `, `~ Confucius`],
  },
  {
    group_id: 14,
    text: [`Doubt everything. Find your own light. `, `~ Buddha`],
  },
  {
    group_id: 14,
    text: [
      `Risk more than others think is safe. Dream more than others think is practical. `,
      `~ Howard Schultz`,
    ],
  },
  {
    group_id: 14,
    text: [`The way to get started is to quit talking and begin doing. `, `~ Walt Disney`],
  },
  {
    group_id: 14,
    text: [`Always do what you are afraid to do `, `~ Ralph Waldo Emerson`],
  },
  {
    group_id: 14,
    text: [
      `One way to remember who you are is to remember who your heroes are `,
      `~ "Walter Isaacson`,
    ],
  },
  {
    group_id: 14,
    text: [
      `Don’t let the noise of others’ opinions drown out your own inner voice `,
      `~ Steve Jobs`,
    ],
  },
  {
    group_id: 14,
    text: [
      `A ship is always safe at the shore - but that is NOT what it is built for. `,
      `~ Albert Einstein`,
    ],
  },
  {
    group_id: 14,
    text: [`Your mind is your prison when you focus on your fear. `, `~ Tim Fargo`],
  },
  {
    group_id: 14,
    text: [
      `Everything will be okay in the end. `,
      `If it's not okay, it's not the end.`,
      `~ John Lennon`,
    ],
  },
  {
    group_id: 14,
    text: [
      `You can't cross the sea merely by standing and staring at the water. `,
      `~ Rabindranath Tagore`,
    ],
  },
  {
    group_id: 14,
    text: [`The mind creates the abyss, the heart crosses it.`, `~ Nisargadatta Maharaj`],
  },
  {
    group_id: 14,
    text: [
      `Do not go where the path may lead, `,
      `go instead where there is no path and leave a trail.`,
      `~ Ralph Waldo Emerson`,
    ],
  },
  {
    group_id: 14,
    text: [
      `Care about what other people think and you will always be their prisoner. `,
      `~ Lao Tzu`,
    ],
  },
  {
    group_id: 1,
    text: [
      `"When the whole world is silent, even one voice becomes powerful."`,
      `~ Malala Yousafzai`,
    ],
  },
  {
    group_id: 14,
    text: [`I’m not afraid of dying, I’m afraid of not trying.`, `~ Jay Z`],
  },
  {
    group_id: 14,
    text: [
      `Never confuse movement with progress. Because you can run in place and not get anywhere. `,
      `~ Denzel Washington`,
    ],
  },
  {
    group_id: 14,
    text: [`As you start to walk out on the way, the way appears.`, `~ Rumi`],
  },
  {
    group_id: 14,
    text: [`Ask for what you want and be prepared to get it! `, `~ Maya Angelou`],
  },
  {
    group_id: 14,
    text: [
      `Climb the mountain so you can see the world, not so the world can see you.`,
      `~ David McCullough Jr.`,
    ],
  },
  {
    group_id: 14,
    text: [
      `Great spirits have always faced violent opposition from mediocre minds. `,
      `~ Einstein`,
    ],
  },
  {
    group_id: 14,
    text: [`Worrying will never change the outcome. `, `~ Unknown`],
  },
  {
    group_id: 14,
    text: [`"I have stood on a mountain of no’s for one yes."`, `~ B. Smith`],
  },
  {
    group_id: 14,
    text: [
      `The happiness of your life depends on the quality of your thoughts. `,
      `~ Marcus Aurelius`,
    ],
  },
  {
    group_id: 14,
    text: [
      `"I will not follow where the path may lead, but I will go where there is no path, and I will leave a trail."`,
      `~ Muriel Strode`,
    ],
  },
  { group_id: 14, text: [`Rule your mind or it will rule you `, `~ Horace`] },
  {
    group_id: 14,
    text: [`Whether You Think You Can Or Think You Can’t, You’re Right. `, `~ Henry Ford`],
  },
  {
    group_id: 14,
    text: [
      `Security Is Mostly A Superstition. Life Is Either A Daring Adventure Or Nothing. `,
      `~ Helen Keller`,
    ],
  },
  {
    group_id: 14,
    text: [
      `Conflict exists strictly as an opportunity to raise our consciousness. `,
      `~ Carl Jung`,
    ],
  },
  {
    group_id: 14,
    text: [
      `“You are never too old to set another goal or to dream a new dream.” `,
      `~ C. S. Lewis`,
    ],
  },
  {
    group_id: 14,
    text: [
      `Pearls don’t lie on the seashore. If you want one, you must dive for it.`,
      `~ Chinese Proverb`,
    ],
  },
  {
    group_id: 14,
    text: [`You have to expect things of yourself before you can do them. `, `~ Michael Jordan`],
  },
  {
    group_id: 14,
    text: [
      `If you look at what you have in life, you’ll always have more. `,
      `If you look at what you don’t have in life, you’ll never have enough.`,
      `~ Oprah Winfrey`,
    ],
  },
  {
    group_id: 14,
    text: [
      `I am not a product of my circumstances. I am a product of my decisions. `,
      `~ Stephen Covey`,
    ],
  },
  {
    group_id: 14,
    text: [`It is during our darkest moments that we must focus to see the light. `, `~ Aristotle`],
  },
  {
    group_id: 14,
    text: [`Love is the strongest medicine.  `, `~ Neem Karoli Baba`],
  },
  {
    group_id: 14,
    text: [
      `Build your own dreams, or someone else will hire you to build theirs.`,
      `~ Farrah Gray`,
    ],
  },
  {
    group_id: 14,
    text: [
      `Remember that not getting what you want is sometimes a wonderful stroke of luck. `,
      `~ Dalai Lama`,
    ],
  },
  {
    group_id: 14,
    text: [`You can’t use up creativity. The more you use, the more you have.`, `~ Maya Angelou`],
  },
  {
    group_id: 14,
    text: [`I would rather die of passion than of boredom. `, `~ Vincent van Gogh`],
  },
  {
    group_id: 14,
    text: [
      `"What you do makes a difference, and you have to decide what kind of difference you want to make."`,
      `~ Jane Goodall`,
    ],
  },
  {
    group_id: 14,
    text: [`If you want to lift yourself up, lift up someone else.`, `~ Booker T. Washington`],
  },
  {
    group_id: 14,
    text: [
      `Certain things catch your eye, but pursue only those that capture the heart.`,
      `~ Indian Proverb`,
    ],
  },
  {
    group_id: 14,
    text: [
      `How wonderful it is that nobody need wait a single moment before starting to improve the world. `,
      `~ Anne Frank`,
    ],
  },
  {
    group_id: 14,
    text: [`Everything you’ve ever wanted is on the other side of fear. `, `~ George Addair`],
  },
  {
    group_id: 14,
    text: [`What we achieve inwardly will change outer reality.`, `~ Plutarch`],
  },
  {
    group_id: 14,
    text: [
      `Don’t judge each day by the harvest you reap but by the seeds that you plant.`,
      `~ Robert Louis Stevenson`,
    ],
  },
  {
    group_id: 14,
    text: [
      `Things work out best for those who make the best of how things work out. `,
      `~ John Wooden`,
    ],
  },
  {
    group_id: 14,
    text: [`"Knowing what must be done does away with fear."`, `~ Rosa Parks`],
  },
  {
    group_id: 14,
    text: [`All progress takes place outside the comfort zone. `, `~ Michael John Bobak`],
  },
  {
    group_id: 14,
    text: [`Courage is resistance to fear, mastery of fear--not absence of fear`, `~ Mark Twain`],
  },
  {
    group_id: 14,
    text: [
      `Too many of us are not living our dreams because we are living our fears. `,
      `~ Les Brown`,
    ],
  },
  {
    group_id: 14,
    text: [
      `The function of leadership is to produce more leaders, not more followers.`,
      `~ Ralph Nader`,
    ],
  },
  {
    group_id: 14,
    text: [`The successful warrior is the average man, with laser-like focus`, `~ Bruce Lee`],
  },
  {
    group_id: 14,
    text: [`People rarely succeed unless they have fun in what they are doing `, `~ Dale Carnegie`],
  },
  {
    group_id: 14,
    text: [`What would you do if you weren't afraid. `, `~ Spencer Johnson`],
  },
  {
    group_id: 14,
    text: [`Motivation is what gets you started. Habit is what keeps you going. `, `~ Jim Ryun`],
  },
  {
    group_id: 14,
    text: [
      `There is no end to the adventures that we can have if only we seek them with our eyes open. `,
      `~ Jawaharlal Nehru`,
    ],
  },
  {
    group_id: 14,
    text: [
      `First they ignore you, then they laugh at you, then they fight you, then you win.`,
      `~ Mahatma Gandhi`,
    ],
  },
  {
    group_id: 14,
    text: [`Man is made by his belief. As he believes, so he is.`, `~ Bhagavad Gita`],
  },
  {
    group_id: 14,
    text: [
      `"I will not follow where the path may lead, but I will go where there is no path, and I will leave a trail."`,
      `~ Muriel Strode`,
    ],
  },
  {
    group_id: 14,
    text: [
      `Our greatest glory is not in never failing, `,
      `but in rising up every time we fail.`,
      `~ Ralph Waldo Emerson`,
    ],
  },
  {
    group_id: 14,
    text: [`Find ecstasy in life; the mere sense of living is joy enough. `, `~ Emily Dickinson`],
  },
  {
    group_id: 14,
    text: [`The best way out is always through.`, `~ Robert Frost`],
  },
  {
    group_id: 14,
    text: [`Listen; there's a hell of a good universe next door: let's go.`, `~ E. E. Cummings`],
  },
  {
    group_id: 14,
    text: [
      `Only those who will risk going too far can possibly find out how far one can go.`,
      `~ T. S. Eliot`,
    ],
  },
  {
    group_id: 14,
    text: [`A ruffled mind makes a restless pillow.`, `~ Charlotte Brontë`],
  },
  {
    group_id: 14,
    text: [`Death is nothing, but to live defeated and inglorious is to die daily.`, `~ Napoléon`],
  },
  {
    group_id: 14,
    text: [`If opportunity doesn't knock, build a door.`, `~ Milton Berle `],
  },
  {
    group_id: 14,
    text: [`It is during our darkest moments that we must focus to see the light `, `~ Aristotle`],
  },
  {
    group_id: 14,
    text: [
      `"If you don’t like the road you’re walking, start paving another one."`,
      `~ Dolly Parton`,
    ],
  },
  {
    group_id: 14,
    text: [
      `Divide each difficulty into as many parts as is feasible and necessary to resolve it.`,
      `~ René Descartes`,
    ],
  },
  {
    group_id: 14,
    text: [`Our true nationality is humankind. `, `~ H. G. Wells`],
  },
  {
    group_id: 14,
    text: [
      `You will either step forward into growth or you will step back into safety. `,
      `~ Abraham Maslow`,
    ],
  },
  {
    group_id: 14,
    text: [`Those who do not want to imitate anything, produce nothing.`, `~ Salvador Dali`],
  },
  {
    group_id: 14,
    text: [`I am not what happened to me, I am what I choose to become. `, `~ Carl Jung`],
  },
  {
    group_id: 14,
    text: [`We save the world by being alive ourselves.  `, `~ Joseph Campbell`],
  },
  {
    group_id: 14,
    text: [
      `What keeps us from happiness is our inability to fully inhabit the present. `,
      `~ Alan Watts`,
    ],
  },
  { group_id: 14, text: [`Work without love is slavery`, `~ Mother Teresa`] },
  {
    group_id: 14,
    text: [
      `Knowing is not enough, we must apply.`,
      `Willing is not enough, we must do.`,
      `~ Bruce Lee`,
    ],
  },
  {
    group_id: 14,
    text: [
      `“Adapt what is useful, reject what is useless`,
      `and add what is specifically your own.”`,
      `~ Bruce Lee`,
    ],
  },
  {
    group_id: 14,
    text: [
      `"You can’t be that kid standing at the top of the waterslide, overthinking it. You have to go down the chute."`,
      `~ Tina Fey`,
    ],
  },
  {
    group_id: 14,
    text: [`Everybody's a teacher if you listen.`, `~ Doris Roberts`],
  },
  {
    group_id: 14,
    text: [
      `"I will not follow where the path may lead, but I will go where there is no path, and I will leave a trail."`,
      `~ Muriel Strode`,
    ],
  },
  {
    group_id: 14,
    text: [
      `Ninety-nine percent of who you are is invisible and untouchable.`,
      `~ Buckminster Fuller`,
    ],
  },
  {
    group_id: 14,
    text: [
      `We are what we pretend to be`,
      `so we must be careful about what we pretend to be.`,
      `~ Kurt Vonnegut`,
    ],
  },
  {
    group_id: 14,
    text: [`The cave you fear to enter`, `holds the treasure you seek.`, `~ Joseph Campbell`],
  },
  {
    group_id: 14,
    text: [`The art of being wise is`, `the art of knowing what to overlook.`, `~ William James`],
  },
  {
    group_id: 14,
    text: [`Love and work`, `are the cornerstones of our humanness.`, `~ Sigmund Freud`],
  },
  {
    group_id: 14,
    text: [`I have no special talents.`, `I am only passionately curious.`, `~ Albert Einstein`],
  },
  {
    group_id: 14,
    text: [`Believe you can`, `and you're halfway there.`, `~ Theodore Roosevelt`],
  },
  {
    group_id: 14,
    text: [`"The beginning is the most`, `important part of the work."`, `~ Plato`],
  },
  {
    group_id: 14,
    text: [
      `"You're only given a little spark of madness.`,
      `You mustn't lose it."`,
      `~ Robin Williams`,
    ],
  },
  {
    group_id: 14,
    text: [`"Either you run the day, `, `or the day runs you."`, `~ Jim Robin`],
  },
  {
    group_id: 14,
    text: [
      `"We have more possibilities available in each moment`,
      `than we realize."`,
      `~ Thich Nhat Hanh`,
    ],
  },
  {
    group_id: 14,
    text: [`"Optimism is the faith that leads to achievement."`, `~ Helen Keller`],
  },
  {
    group_id: 1,
    text: [`"I choose to make the rest of my life the best of my life."`, `~ Louise Hay`],
  },
  {
    group_id: 14,
    text: [
      `"Change your life today. Don’t gamble on the future, act now, without delay."`,
      `~ Simone de Beauvoir`,
    ],
  },
];

interface Quote {
  lines: string[];
  mentalState: 'Focus' | 'Relax' | 'Sleep';
}

export const QUOTES: Quote[] = LEGACY_QUOTES.reduce((quotes, quote) => {
  const groupIdMentalStates: { [key: number]: 'Focus' | 'Relax' | 'Sleep' | undefined } = {
    1: 'Sleep',
    14: 'Focus',
    15: 'Relax',
  };
  const mentalState = groupIdMentalStates[quote.group_id];
  if (!mentalState) return quotes;

  return [
    ...quotes,
    {
      lines: quote.text,
      mentalState,
    },
  ];
}, [] as Quote[]);
