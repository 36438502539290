import { FontColors, Text } from '@Cortex';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';

import ChevronIcon from '../../../../../../assets/images/chevron_up_icon.svg';
import { ListGroup } from '../../../ListGroup';
import * as S from './FilterItem.styles';

export type FilterItemProps = {
  title: string;
  onClick: () => void;
  isExpanded?: boolean;
  items: string[];
  selectedItems: string[];
  onItemClick: (value: any) => void;
};

export function FilterItem({
  title,
  isExpanded = false,
  items,
  selectedItems,
  onClick,
  onItemClick,
}: FilterItemProps) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <S.Container>
      <S.TitleContainer hasBorder={selectedItems.length > 0} onClick={onClick}>
        <Text color={FontColors.WhiteSecondary}>{title}</Text>
        <S.ChevronImage isUp={isExpanded} src={ChevronIcon} />
      </S.TitleContainer>

      <Transition in={isExpanded} mountOnEnter nodeRef={ref} timeout={0} unmountOnExit>
        {animationState => (
          <S.Content ref={ref} animationState={animationState}>
            <ListGroup checkedItems={selectedItems} items={items} onCheck={onItemClick} />
          </S.Content>
        )}
      </Transition>
    </S.Container>
  );
}
