import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import * as analyticsActions from '../../actions/analytics';
// actions
import { extendSesionTimer } from '../../actions/sessionManager';
import { setModal, setSessionFinished } from '../../actions/ui';
// components
import { ModalBackground } from '../../components/shared/modalBackground';
import { useModalCloseOnNavigation } from '../../hooks';
import { RootReducerType } from '../../reducers';
import { Assets } from '../../utils/assets';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const Title = styled.div`
  color: #fff;
  padding: 30px;
  font-size: ${({ theme }) => theme.fontSize.medium};
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  // padding: 0 2rem;
`;
const Button = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4em;
  margin: 2em 2em;
  color: #fff;
  cursor: pointer;
  border-radius: 2rem;
  &:hover {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  }
`;
const Icon = styled.img`
  width: 5rem;
`;
const Text = styled.div`
  padding: 20px 0;
  font-size: ${({ theme }) => theme.fontSize.medium};
`;

interface SelectTimerModeProps {
  onClose(): void;
}

const SelectTimerMode: React.FC<SelectTimerModeProps> = ({ onClose }) => {
  useModalCloseOnNavigation();
  const dispatch = useDispatch();
  const timerLength =
    useSelector((state: RootReducerType) => state.sessionManager?.timerLength) ?? 0;

  useEffect(() => {
    // mount
    dispatch(analyticsActions.playerLogEvent('player_timer_finished_open'));

    // unmount
    return () => {
      dispatch(analyticsActions.playerLogEvent('player_timer_finished_close'));
    };
  }, [dispatch]);

  const handleKeepGoing = () => {
    dispatch(setModal(null));
    dispatch(extendSesionTimer(60 * 15));
  };

  const handleEndSession = () => {
    dispatch(setModal(null));
    dispatch(setSessionFinished(true));
  };

  return (
    <ModalBackground>
      <Wrapper>
        <Title>{timerLength / 60} Minute Timer Completed</Title>

        <ButtonWrapper>
          <Button onClick={() => handleKeepGoing()}>
            <Icon alt={Assets.icons.clockWise.alt} src={Assets.icons.clockWise.url} />
            <Text>Keep Going</Text>
          </Button>
          <Button onClick={() => handleEndSession()}>
            <Icon alt={Assets.icons.checkCircle.alt} src={Assets.icons.checkCircle.url} />
            <Text>End Session</Text>
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </ModalBackground>
  );
};

export default SelectTimerMode;
