import styled from 'styled-components';

export const MenuItem = styled.div<{ isActive: boolean }>`
  align-items: center;
  color: white;
  cursor: ${props => (props.isActive ? 'auto' : 'pointer')};
  display: flex;
  flex-direction: row;
  font-family: TTNormsPro-Regular;
  font-size: 1rem;
  font-weight: 500;
  opacity: ${props => (props.isActive ? '1' : '0.7')};
  transition: opacity 0.15s ease-in-out;
  text-transform: lowercase;
  &:hover {
    opacity: 1;
  }

  & > a {
    color: white;
    text-decoration: none;
  }
`;

export const Icon = styled.div<{ isActive: boolean; isClickable: boolean }>`
  align-items: center;
  cursor: ${props => (props.isClickable ? 'pointer' : 'default')};
  display: flex;
  margin-right: 1rem;
`;
