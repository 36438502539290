import { Button, Text, ButtonVariants, ButtonVariantSizes } from '@Cortex';
import { Genres, NeuralEffectLevelSelection } from '@Music';
import { useSession } from '@Session';
import { useUser } from '@User';
import { useFeatureFlag, useOutsideClickRef, FeatureFlags, showToast } from '@Utils';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  changeSessionActivity,
  replaceTracksWithUpdatedPreferences,
} from '../../../../actions/sessionManager';
import { RootReducerType } from '../../../../reducers';
import { ActivityDataSingle } from '../../../../types';
import { activities } from '../../../../utils/activities';
import { Analytics } from '../../../../utils/analytics';
import { Events } from '../../../../utils/analytics/events';
import { mentalStates } from '../../../../utils/mentalStates';
import {
  trackFilterPreferencesOpened,
  trackFilterPreferencesChanged,
} from '../../../Analytics/coreAnalytics';
import { FilterTypes } from '../../../Analytics/coreAnalytics.types';
import { ActivitySettingsDisplay } from '../ActivitySettings';
import * as S from './Preferences.styles';
import { InfoLink } from './components/InfoLink';
import { NEL_LEARN_MORE_LINK } from './constants';

interface Props {
  onClose: () => void;
}

export function PreferencesLegacy(props: Props) {
  const dispatch = useDispatch();
  const user = useUser();
  const session = useSession();
  const outsideClickRef = useOutsideClickRef({ onOutsideClick: props.onClose });
  const isUsingNewTracks = useFeatureFlag(FeatureFlags.NewMusicLibrary);
  const sessionActivityId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionActivityId,
  );
  const sessionMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );

  const stateSelectedGenres = session
    ? user.mentalStatePreferences[session.mentalState].genreNames
    : [];
  const stateSelectedNELs = session
    ? user.mentalStatePreferences[session.mentalState].neuralEffectLevels
    : [];

  const stateSelectedActivity = sessionActivityId ? activities.byId[sessionActivityId] : null;

  const [localSelectedActivity, setLocalActivity] = useState(stateSelectedActivity);
  const [localSelectedGenres, setLocalGenres] = useState(stateSelectedGenres);
  const [localSelectedNELs, setLocalNELs] = useState(stateSelectedNELs);

  const mentalState = mentalStates.byId[sessionMentalStateId || ''];

  useEffect(() => {
    Analytics.logEventWithProperties(Events.preferences_opened, {});
    if (session?.mentalState) {
      trackFilterPreferencesOpened({
        filterType: FilterTypes.Consolidated,
        mentalState: session.mentalState,
      });
    }
  }, []);

  const mentalStateActivities = mentalState?.activities.map(id => activities.byId[id]) || [];

  const handleSelectActivity = (value: ActivityDataSingle) => {
    setLocalActivity(value);
  };

  const handleAnalytics = (
    newState: {
      localSelectedGenres: string[];
      localSelectedNELs: string[];
      localSelectedActivity: ActivityDataSingle | null;
    },
    oldState: {
      stateSelectedGenres: string[];
      stateSelectedNELs: string[];
      stateSelectedActivity: ActivityDataSingle | null;
    },
  ) => {
    // track genres changes
    let genresChanged = null;
    let nelChanged = null;
    let activityChanged = null;
    if (
      JSON.stringify(newState.localSelectedGenres) !== JSON.stringify(oldState.stateSelectedGenres)
    ) {
      const newGenre = [...newState.localSelectedGenres].sort().join(',');
      const oldGenre = [...oldState.stateSelectedGenres].sort().join(',');
      Analytics.logEventWithProperties(Events.preferences_genre_filters_changed, {
        old_filter: oldGenre,
        new_filter: newGenre,
      } as any);
      genresChanged = {
        newFilterGenre: newGenre,
        oldFilterGenre: oldGenre,
      };
    }

    // track NEL changes
    if (JSON.stringify(newState.localSelectedNELs) !== JSON.stringify(oldState.stateSelectedNELs)) {
      const oldNel = oldState.stateSelectedNELs.slice().sort().join(',');
      const newNel = newState.localSelectedNELs.slice().sort().join(',');
      Analytics.logEventWithProperties(Events.preferences_nel_filters_changed, {
        old_filter: oldNel,
        new_filter: newNel,
      } as any);
      nelChanged = {
        newFilterNel: newNel,
        oldFilterNel: oldNel,
      };
    }

    if (newState.localSelectedActivity?.display !== oldState.stateSelectedActivity?.display) {
      // track activity changes
      Analytics.logEventWithProperties(Events.preferences_activity_filters_changed, {
        old_filter: oldState.stateSelectedActivity?.display,
        new_filter: newState.localSelectedActivity?.display,
      } as any);
      activityChanged = {
        newFilterActivity: newState.localSelectedActivity?.display,
        oldFilterActivity: oldState.stateSelectedActivity?.display,
      };
    }

    if (session && (activityChanged || nelChanged || genresChanged)) {
      trackFilterPreferencesChanged({
        filterType: FilterTypes.Consolidated,
        mentalState: session?.mentalState,
        ...activityChanged,
        ...nelChanged,
        ...genresChanged,
      });
    }
  };

  const onNelReadMoreClick = useCallback(() => {
    Analytics.logEventWithProperties(Events.learn_more_button_clicked, {
      learn_more_button_location: 'nel_filter',
    });
  }, []);

  const handleGenreSelect = (genreName: string) => {
    if (localSelectedGenres.includes(genreName)) {
      setLocalGenres(localSelectedGenres.filter(item => item !== genreName));
    } else {
      setLocalGenres([...localSelectedGenres, genreName]);
    }
  };

  const handleNELSelect = (NEL: string) => {
    if (localSelectedNELs.includes(NEL)) {
      setLocalNELs(localSelectedNELs.filter(item => item !== NEL));
    } else {
      setLocalNELs([...localSelectedNELs, NEL]);
    }
  };

  const handleApplyChanges = () => {
    let payload: {
      genreNames?: string[];
      neuralEffectLevels?: string[];
    } = {};

    if (JSON.stringify(stateSelectedGenres) !== JSON.stringify(localSelectedGenres)) {
      payload.genreNames = localSelectedGenres;
    }
    if (JSON.stringify(stateSelectedNELs) !== JSON.stringify(localSelectedNELs)) {
      payload.neuralEffectLevels = localSelectedNELs;
    }

    if (localSelectedActivity?.display !== stateSelectedActivity?.display) {
      dispatch(
        changeSessionActivity({
          excludeNewTracks: !isUsingNewTracks,
          activityId: localSelectedActivity?._id!,
          ...payload,
        }),
      );
    } else {
      dispatch(replaceTracksWithUpdatedPreferences(payload));
    }

    handleAnalytics(
      {
        localSelectedGenres,
        localSelectedNELs,
        localSelectedActivity,
      },
      {
        stateSelectedActivity,
        stateSelectedGenres,
        stateSelectedNELs,
      },
    );

    props.onClose();
    showToast('Your preferences have been updated!');
  };

  const isAnythingChanged =
    localSelectedActivity?.display !== stateSelectedActivity?.display ||
    JSON.stringify(localSelectedGenres) !== JSON.stringify(stateSelectedGenres) ||
    JSON.stringify(localSelectedNELs) !== JSON.stringify(stateSelectedNELs);

  return (
    <S.Wrapper ref={outsideClickRef}>
      <S.Container>
        <S.Panel>
          <S.HeadingContainer>
            <Text size={15}>Activity</Text>
          </S.HeadingContainer>
          <S.SectionWrapper>
            <ActivitySettingsDisplay
              activities={mentalStateActivities}
              selectedActivity={localSelectedActivity?.display || ''}
              onSelectActivity={handleSelectActivity}
            />
          </S.SectionWrapper>
        </S.Panel>
        <S.Panel hasBorders={true}>
          <S.HeadingContainer>
            <Text size={15}>Genre</Text>
          </S.HeadingContainer>
          <S.SectionWrapper>
            <Genres
              activityId={localSelectedActivity?._id}
              selected={localSelectedGenres}
              onSelect={handleGenreSelect}
            />
          </S.SectionWrapper>
        </S.Panel>
        <S.Panel>
          <S.HeadingContainer>
            <Text size={15}>Neural Effect</Text>
            <InfoLink
              link={NEL_LEARN_MORE_LINK}
              text="read about our science"
              onClick={onNelReadMoreClick}
            />
          </S.HeadingContainer>
          <S.SectionWrapper>
            <NeuralEffectLevelSelection selected={localSelectedNELs} onSelect={handleNELSelect} />
          </S.SectionWrapper>
        </S.Panel>
      </S.Container>
      <S.Bottom>
        <Button
          data-testid="preferences-discard-changes"
          size={ButtonVariantSizes.Small}
          style={{ marginRight: 10 }}
          variant={ButtonVariants.Secondary}
          onClick={props.onClose}
        >
          cancel
        </Button>
        <Button
          data-testid="preferences-apply-changes"
          disabled={!isAnythingChanged}
          size={ButtonVariantSizes.Small}
          variant={ButtonVariants.Primary}
          onClick={handleApplyChanges}
        >
          apply changes
        </Button>
      </S.Bottom>
    </S.Wrapper>
  );
}
