import { useRef, useState, MutableRefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as uiActions from '../../../../actions/ui';
import * as userActions from '../../../../actions/user';
import { put } from '../../../../api/client/client';
import { RootReducerType } from '../../../../reducers';
import { userSliceActions } from '../../../../reducers/user';
import { EditProfileDisplay } from './EditProfile.display';

type UpdateFieldsStatus = 'idle' | 'loading' | 'success' | 'error';

interface Props {
  onClose: () => void;
}

export const EditProfile = ({ onClose }: Props) => {
  const [formStatus, setFormStatus] = useState<UpdateFieldsStatus>('idle');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();
  const inflightNameRequest = useRef(false);
  const inflightEmailRequest = useRef(false);

  const { info: userInfo } = useSelector((state: RootReducerType) => state.user);
  const { token } = useSelector((state: RootReducerType) => state.auth);

  const firstName = userInfo?.firstName ?? '';
  const email = userInfo?.email ?? '';

  const handleAPICall = async (
    callback: () => Promise<any>,
    inflightRequest: MutableRefObject<boolean>,
  ) => {
    if (inflightRequest.current) {
      return;
    }

    try {
      inflightRequest.current = true;
      setFormStatus('loading');

      const { status, result, messages } = await callback();

      if (status === 200 && result) {
        dispatch(userSliceActions.setUserInfo(result));
        dispatch(userActions.getInfo()); // to get fresh verification data
        setFormStatus('success');

        onClose();
        dispatch(
          uiActions.successModalOpen({
            title: 'Success!',
            description: 'Your profile information was updated successfully',
            actions: [{ type: 'primary', text: 'Close', action: uiActions.successModalClose() }],
          }),
        );
      } else {
        setFormStatus('error');
        setErrorMessage(messages.join(' '));
      }
    } catch (error) {
      setFormStatus('error');
    } finally {
      inflightRequest.current = true;

      setTimeout(() => {
        setFormStatus('idle');
      }, 1500);
    }
  };

  const handleNameChange = async (value: string, ref: MutableRefObject<boolean>) => {
    // make API request when the value is changed
    if (firstName !== value) {
      handleAPICall(
        () => put({ path: `/users/${userInfo?.id}`, body: { firstName: value || null }, token }),
        ref,
      );
    }
  };

  const handleEmailChange = async (value: string, ref: MutableRefObject<boolean>) => {
    // make API request when the value is changed
    if (email !== value) {
      handleAPICall(
        () => put({ path: `/users/${userInfo?.id}/update-email`, body: { email: value }, token }),
        ref,
      );
    }
  };

  const handleSubmit = (values: { name: string; email: string }) => {
    handleNameChange(values.name, inflightNameRequest);
    handleEmailChange(values.email, inflightEmailRequest);
  };

  return (
    <EditProfileDisplay
      email={email}
      errorMessage={errorMessage}
      isLoading={formStatus === 'loading'}
      name={firstName}
      onClose={onClose}
      onFormSubmit={handleSubmit}
    />
  );
};
