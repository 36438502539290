import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { useIsExtendedPromoAvailable } from '../../Payment/components/StripePaymentTestContainer/hooks/useIsExtendedPromoAvailable';
import {
  MembershipPromotion,
  MembershipPromotionTypes,
  MembershipState,
  StripeCouponMembershipPromotion,
} from '../reducer';

const BLACK_FRIDAY_PROMOTION: StripeCouponMembershipPromotion = {
  couponId: 'CM2023',
  description: 'CM2023 coupon applied - save 50% off your first year!',
  displayCost: '34.99',
  name: '14 days free, then 50% off your first year',
  targetPlan: 'Yearly',
  type: MembershipPromotionTypes.StripeCoupon,
};

export function getAvailableStripeCouponPromotion(
  state: MembershipState,
): MembershipPromotion | null {
  return state.promotion;
}

export function useGetAvailableStripeCouponPromotion(): StripeCouponMembershipPromotion | null {
  const userState = useSelector((state: RootReducerType) => state.user);
  const membershipState = useSelector((state: RootReducerType) => state.membership);
  const availablePromotion = getAvailableStripeCouponPromotion(membershipState);
  const isExtendedPromoAvailable = useIsExtendedPromoAvailable();

  const expirationDate = userState.membership?.expirationDate;
  const isExpired = expirationDate && new Date(expirationDate) < new Date();

  const shouldShowGlobalPromotion =
    process.env.NODE_ENV !== 'test' &&
    (membershipState.source === 'Trial' || membershipState.source === null || isExpired) &&
    !isExtendedPromoAvailable;
  const globalPromotion = shouldShowGlobalPromotion ? BLACK_FRIDAY_PROMOTION : null;

  if (availablePromotion && availablePromotion.type !== MembershipPromotionTypes.StripeCoupon) {
    return null;
  }

  return availablePromotion || globalPromotion;
}
