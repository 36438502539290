import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
`;

export const Text = styled.div`
  margin-left: 5px;
`;
