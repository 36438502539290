import { ButtonVariantStates, ButtonVariants, Paper } from '@Cortex';

import * as S from './Refer.styles';
import CopyIcon from './assets/copy.svg';
import FriendsIcon from './assets/friends.png';

interface Props {
  buttonState: { text: string; state: ButtonVariantStates };
  headerText: string;
  onButtonClick: () => void;
  subtitleText: string;
  title: string;
}

export const ReferDisplay = ({
  title,
  headerText,
  buttonState,
  subtitleText,
  onButtonClick,
}: Props) => {
  return (
    <S.Container>
      <S.HeaderText>{title}</S.HeaderText>

      <Paper>
        <S.Content>
          <S.Left>
            <S.TitleText>{headerText}</S.TitleText>
            <S.SubtitleText>{subtitleText}</S.SubtitleText>
            <S.ButtonWrapper>
              <S.CopyButton
                data-testid="referLinkCopyButton"
                variant={ButtonVariants.Primary}
                variantState={buttonState.state}
                onClick={onButtonClick}
              >
                {buttonState.state === ButtonVariantStates.Idle ? (
                  <S.ButtonImage src={CopyIcon} />
                ) : null}
                {buttonState.text}
              </S.CopyButton>
            </S.ButtonWrapper>
          </S.Left>

          <S.Right>
            <S.Image src={FriendsIcon} />
          </S.Right>
        </S.Content>
      </Paper>
      <S.Padding />
    </S.Container>
  );
};
