import {
  getTrackComplexity,
  getTrackGenreName,
  getTrackName,
  getTrackNeuralEffectLevel,
  useGetCurrentTrack,
} from '@Music';
import { useSession } from '@Session';
import { useUser, UserMentalStatePreferenceTypes } from '@User';
import { useSelector } from 'react-redux';

import { MentalStates, TimerOrigin } from '../domains/Analytics/coreAnalytics.types';
import { RootReducerType } from '../reducers';
import { activities } from '../utils/activities';
import { mentalStates } from '../utils/mentalStates';
import { useTimerMode } from './useTimerMode';

export const usePlayerAnalyticsParams = () => {
  const session = useSession();
  const currentTrack = useGetCurrentTrack();
  const user = useUser();
  const timerMode = useTimerMode();
  const ui = useSelector((state: RootReducerType) => state.ui);
  const currentPreferences = session ? user.mentalStatePreferences[session.mentalState] : null;
  const sessionMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );
  const sessionActivityId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );
  const { sessionSidebarContentOrigin } = ui;

  return {
    activity: sessionActivityId ? activities.byId[sessionActivityId]?.display : '',
    trackGenre: getTrackGenreName(currentTrack),
    filterGenre: currentPreferences
      ? (currentPreferences[UserMentalStatePreferenceTypes.GenreNames] || []).join(',')
      : '',
    filterNel: currentPreferences
      ? (currentPreferences[UserMentalStatePreferenceTypes.NeuralEffectLevels] || []).join(',')
      : '',
    trackNel: getTrackNeuralEffectLevel(currentTrack),
    mentalState: (sessionMentalStateId
      ? mentalStates.byId[sessionMentalStateId]?.display
      : '') as MentalStates,
    sessionSidebarContentOrigin: sessionSidebarContentOrigin as TimerOrigin,
    timerMode: timerMode,
    complexity: getTrackComplexity(currentTrack),
    trackName: getTrackName(currentTrack),
  };
};
