import styled from 'styled-components';

export const CLOSE_TIMEOUT_MS = 500;

export const baseStyles = {
  content: {
    height: '100%',
    width: '100%',
    inset: 'unset',
    border: 'unset',
    backgroundColor: 'unset',
    padding: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  overlay: {
    backgroundColor: 'unset',
  },
};

export const CloseButton = styled.button<{ isNotFixed?: boolean }>`
  backdrop-filter: blur(34px);
  background: linear-gradient(0deg, rgba(25, 23, 54, 0.5), rgba(25, 23, 54, 0.5));
  border-radius: 100%;
  border: none;
  box-shadow: inset 0px 0px 0px 1px #565487;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 0.875rem;
  height: 3.5rem;
  isolation: isolate;
  outline: none;
  transition: box-shadow 0.2s ease-in-out;
  width: 3.5rem;
  z-index: 99;

  ${props =>
    props.isNotFixed
      ? ''
      : `
    position: fixed;
    right: 1.5rem;
    top: 1.5rem;
  `}

  &:hover,
  &:focus {
    box-shadow: inset 0px 0px 0px 2px #565487;
  }
`;

export const CloseIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
`;
