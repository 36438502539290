import { Button, ButtonVariants } from '@Cortex';

import * as S from './ExpiredMembershipCallToAction.styles';

type Props = {
  callToActionText?: string;
  onClickRenewMembership?: () => void;
};

export function ExpiredMembershipCallToAction(props: Props) {
  return (
    <S.Container>
      <S.HeaderTextSubtitle>
        Oh, no! It looks like your subscription has expired.
      </S.HeaderTextSubtitle>
      <S.HeaderTextTitle>Renew to get access to exclusive features:</S.HeaderTextTitle>
      <S.Benefits>
        <S.Checkmark />
        <S.Container>
          <S.BenefitsTitle>Unlimited Sessions</S.BenefitsTitle>
          <S.BenefitsSubtitle>Access to 1000+ tracks</S.BenefitsSubtitle>
        </S.Container>
      </S.Benefits>
      <S.Benefits>
        <S.Checkmark />
        <S.Container>
          <S.BenefitsTitle>Access all Categories</S.BenefitsTitle>
          <S.BenefitsSubtitle>Discover the perfect music for your brain.</S.BenefitsSubtitle>
        </S.Container>
      </S.Benefits>
      <S.Benefits>
        <S.Checkmark />
        <S.Container>
          <S.BenefitsTitle>Access to our Mobile App</S.BenefitsTitle>
          <S.BenefitsSubtitle>
            Take brain.fm on the go with our mobile application, available for iPhone and Android.
          </S.BenefitsSubtitle>
        </S.Container>
      </S.Benefits>
      <div>
        <Button variant={ButtonVariants.Primary} onClick={props.onClickRenewMembership}>
          {props.callToActionText || 'Renew Subscription'}
        </Button>
      </div>
    </S.Container>
  );
}
