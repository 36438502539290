import { Text, Button, ButtonVariants } from '@Cortex';
import { NOOP } from '@Globals';
import { forwardRef } from 'react';
import { TransitionStatus } from 'react-transition-group';

import { FormContainer } from '../../../../../shared/FormContainer';
import * as S from './Confirmation.styles';
import { Assets } from '../../../../../../utils/assets';

export interface Props {
  onClose?: () => void;
  onContinue?: () => void;
  animationState: TransitionStatus;
}

export const Confirmation = forwardRef<HTMLDivElement, Props>(
  ({ onClose = NOOP, onContinue = NOOP, animationState }, ref) => {
    return (
      <S.Container ref={ref} animationState={animationState}>
        <FormContainer
          keepTextCase
          maxWidth="33rem"
          title="Don’t give up on productivity!"
          onClose={onClose}
        >
          <S.TextWrapper>
            <Text>
              Brain.fm can boost your productivity up to 33%. Listen for 30 min (or more) a day
              while you work and we're confident you'll see similar results in a week!
            </Text>
          </S.TextWrapper>
          <S.Image src={Assets.images.hubSpotReview.url} />
          <S.TextWrapper>
            <Text>
              More than 1 million high performers have unlocked greater focus with Brain.fm . Give
              it another try?
            </Text>
          </S.TextWrapper>
          <S.Footer>
            <S.ButtonWrapper>
              <Button
                data-testid="confirmCancelSubscriptionButton"
                keepTextCase
                variant={ButtonVariants.Base}
                onClick={onContinue}
              >
                Cancel My Subscription
              </Button>
            </S.ButtonWrapper>

            <S.ButtonWrapper>
              <Button keepTextCase variant={ButtonVariants.Primary} onClick={onClose}>
                Try it again
              </Button>
            </S.ButtonWrapper>
          </S.Footer>
        </FormContainer>
      </S.Container>
    );
  },
);
