import { NOOP } from '@Globals';
import { OutsideClickDetector } from '@Utils';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';

import { ProfileButton } from './ProfileButton';
import * as S from './ProfileWidget.styles';

export type MenuItemType = {
  testid?: string;
  urlPath?: string | null;
  label: string;
  onClick?: () => void;
  href?: string;
};

export interface Props {
  isActive?: boolean;
  menuItems?: MenuItemType[];
  onClick?: () => void;
  onOutsideClick?: () => void;
}

export const ProfileWidgetDisplay = ({
  onClick = NOOP,
  onOutsideClick = NOOP,
  isActive = false,
  menuItems = [],
}: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const hoveredIconStyle = {
    border: '1px solid #565487',
    background: '#191736',
  };

  return (
    <S.Container>
      <OutsideClickDetector onOutsideClick={onOutsideClick}>
        <ProfileButton style={isActive ? hoveredIconStyle : undefined} onClick={onClick} />

        <Transition in={isActive} mountOnEnter nodeRef={menuRef} timeout={0} unmountOnExit>
          {state => {
            return (
              <S.MenuContainer ref={menuRef} animationState={state}>
                <S.MenuItemContainer>
                  {menuItems.map(item => (
                    <S.MenuItem
                      key={item.label}
                      data-testid={item.testid}
                      href={item.href}
                      rel="noopener noreferrer"
                      target="_blank"
                      onClick={item.onClick}
                    >
                      {item.label}
                    </S.MenuItem>
                  ))}
                </S.MenuItemContainer>
              </S.MenuContainer>
            );
          }}
        </Transition>
      </OutsideClickDetector>
    </S.Container>
  );
};
