import styled from 'styled-components';

import { ReactComponent as CheckmarkSVG } from './assets/checkmark.svg';

export const Container = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderTextSubtitle = styled.div`
  color: #d4d2ea;
  font-family: TTNormsPro-Regular;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

export const HeaderTextTitle = styled.div`
  font-family: TTNormsPro-Bold;
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
`;

export const Checkmark = styled(CheckmarkSVG)`
  margin-right: 1rem;
`;

export const Benefits = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
`;

export const BenefitsTitle = styled.div`
  font-family: TTNormsPro-Bold;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.25rem;
`;

export const BenefitsSubtitle = styled.div`
  color: #d4d2ea;
  font-family: TTNormsPro-Regular;
  font-weight: 400;
  font-size: 0.875rem;
`;
