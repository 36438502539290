import { Icon, Loading, LoadingText } from '@Cortex';
import React, { useEffect, useState } from 'react';

import BrandLogo from '../../../../assets/images/brand_logo.svg';
import * as S from './MembershipPanel.styles';
import { MembershipPanelAura } from './components/MembershipPanelAura';

export type Props = {
  children?: React.ReactNode;
  iconSrc?: string;
  isLoading?: boolean;
  subtitle: string;
  tagTitle?: string | null;
  title: string;
};

export function MembershipPanel({
  children,
  iconSrc = BrandLogo,
  isLoading = false,
  subtitle,
  tagTitle = null,
  title,
}: Props) {
  return (
    <S.Container>
      <S.Header>
        <S.HeaderIcon>
          {isLoading ? (
            <Loading height="44px" uniqueId="membershipPanelLoading" />
          ) : (
            <Icon size={53} src={iconSrc} />
          )}
          <S.HeaderIconAura>
            <MembershipPanelAura
              innerBackground={tagTitle ? S.redAura.inner : S.normalAura.inner}
              outerBackground={tagTitle ? S.redAura.outer : S.normalAura.outer}
            />
          </S.HeaderIconAura>
        </S.HeaderIcon>
        <S.HeaderText width={isLoading ? '100%' : 'auto'}>
          <S.HeaderTextTitle>{isLoading ? <LoadingText /> : title}</S.HeaderTextTitle>
          <S.HeaderTextSubtitle>
            {isLoading ? <LoadingText width="25%" /> : subtitle}
          </S.HeaderTextSubtitle>
        </S.HeaderText>
        {tagTitle ? (
          <div>
            <S.Tag size="0.75rem">{tagTitle}</S.Tag>
          </div>
        ) : null}
      </S.Header>
      <S.Children>{isLoading ? <LoadingText /> : children}</S.Children>
    </S.Container>
  );
}

export function LifetimeMembershipPanel() {
  return (
    <S.LifetimeContainer>
      <S.Header>
        <S.HeaderIcon>
          <Icon size={53} src={BrandLogo} />
        </S.HeaderIcon>
        <S.LifetimeHeaderText width="auto">
          <S.HeaderTextTitle>brain.fm PRO</S.HeaderTextTitle>
          <S.LifetimeHeaderTextSubtitle>Lifetime plan</S.LifetimeHeaderTextSubtitle>
        </S.LifetimeHeaderText>
      </S.Header>
      <S.Children>Thank you for being a lifetime member!</S.Children>
    </S.LifetimeContainer>
  );
}
