import { Button, ButtonVariants, ButtonVariantStates, FontColors, Text, TextBold } from '@Cortex';
import { Fragment } from 'react';

import { EnhancedMembershipPlanType } from '../../../../../../types';
import { Checkmark } from './components/Checkmark';
import { HeaderWithLogoAndClose } from '../../../../../../domains/shared/HeaderWithLogoAndClose';
import * as S from './Plan.styles';

export interface Props {
  paywallBenefits: string[];
  onClickStartFreeTrial: () => void;
  onClose?: () => void;
  onPlanSelect: (plan: EnhancedMembershipPlanType) => void;
  plans: EnhancedMembershipPlanType[];
  selectedPlan: EnhancedMembershipPlanType | null;
}

export type PaymentStatus = 'idle' | 'loading' | 'success' | 'error';

export const OnboardingStripePlansDisplay: React.FC<Props> = ({
  paywallBenefits,
  onClickStartFreeTrial,
  onPlanSelect,
  plans,
  selectedPlan,
  onClose,
}) => {
  const hasDiscount = Boolean(
    (selectedPlan?.couponId || selectedPlan?.promotionCode) &&
      selectedPlan?.originalCost &&
      selectedPlan?.originalCost !== selectedPlan?.displayCost,
  );

  return (
    <S.Wrapper>
      <HeaderWithLogoAndClose onClose={onClose} />
      <S.BoxContainer>
        <S.Container>
          <S.PlanInfo>
            <S.DescriptionContainer>
              <S.DescriptionHeaderContainer>
                <TextBold size="3.0rem">Boost productivity up to 33%</TextBold>
              </S.DescriptionHeaderContainer>
              <S.Checkmarks>
                {paywallBenefits.map(benefit => (
                  <Checkmark key={benefit}>{benefit}</Checkmark>
                ))}
              </S.Checkmarks>
            </S.DescriptionContainer>
            <S.PlanSelector>
              {plans.map(plan => (
                <Fragment key={plan.title}>
                  <S.Plan
                    data-testid={`plan-${plan.title}`}
                    hasSelected={selectedPlan?.title === plan.title}
                    onClick={() => onPlanSelect(plan)}
                  >
                    <S.PlanDetails>
                      <Text size="1.3rem">
                        {plan.title === 'Yearly' || plan.displayInterval === 'yr'
                          ? '14-Day Free Trial'
                          : '7-Day Free Trial'}
                      </Text>
                      <Text color={FontColors.WhiteSecondary}>
                        {`${
                          plan.title === 'Yearly' || plan.displayInterval === 'yr'
                            ? 'Annual Plan'
                            : 'Monthly Plan'
                        } - ${plan.description
                          .replace(' billed annually', '/year')
                          .replace(' billed monthly', '/mo')} ${
                          (plan.title === 'Yearly' || plan.displayInterval === 'yr') && !hasDiscount
                            ? `($${plan?.displayCost}/mo)`
                            : ''
                        }`}
                      </Text>
                    </S.PlanDetails>
                    {selectedPlan?.title === plan.title ? (
                      <S.PlanCheckmarkSelected />
                    ) : (
                      <S.PlanCheckmarkUnselected />
                    )}
                  </S.Plan>
                </Fragment>
              ))}
            </S.PlanSelector>
          </S.PlanInfo>
          <S.ButtonContainer>
            <Button
              data-testid="goToPayment"
              isFullWidth
              keepTextCase
              type="button"
              variant={ButtonVariants.Secondary}
              variantState={ButtonVariantStates.Idle}
              onClick={onClickStartFreeTrial}
            >
              Start free trial
            </Button>
          </S.ButtonContainer>
        </S.Container>
      </S.BoxContainer>
    </S.Wrapper>
  );
};
