import {
  getTrackActivityId,
  getTrackComplexity,
  getTrackGenreName,
  getTrackId,
  getTrackMentalStateId,
  getTrackName,
  getTrackNeuralEffectLevel,
} from '@Music';
import { showToast } from '@Utils';
import { Action } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import { RootReducerType } from 'src/reducers';

import { RequestMethods } from '../../../api/client/types';
import { requestSaga, SuccessResponseType } from '../../../sagas/httpRequest';
import { activities } from '../../../utils/activities';
import { Analytics } from '../../../utils/analytics';
import { Logger } from '../../../utils/logger';
import { mentalStates } from '../../../utils/mentalStates';
import { trackFavoriteTrackEvent } from '../../Analytics/coreAnalytics';
import { MentalStates } from '../../Analytics/coreAnalytics.types';
import { addToFavorites } from '../actions';
import { getTrackVariationId } from '../lenses';
import { musicActions } from '../reducer';

export function* addToFavoritesSaga(action: Action) {
  if (addToFavorites.match(action)) {
    try {
      yield put(musicActions.addToFavorites(action.payload.track));

      const { info }: RootReducerType['user'] = yield select(
        (state: RootReducerType) => state.user,
      );

      const { favorites }: RootReducerType['music'] = yield select(
        (state: RootReducerType) => state.music,
      );

      if (favorites.length === 0) {
        showToast('Added to your library. \nYou can access your library from the Explore panel.');
      } else {
        showToast('Added to your library');
      }

      const response: SuccessResponseType = yield requestSaga(
        RequestMethods.PUT,
        `/users/${info?.id}/favorite?version=3`,
        {
          variationId: getTrackVariationId(action.payload.track),
        },
      );

      if (response.status === 200) {
        try {
          const id = getTrackId(action.payload.track);
          const genreName = getTrackGenreName(action.payload.track);
          const activityId = getTrackActivityId(action.payload.track);
          const mentalStateId = getTrackMentalStateId(action.payload.track);
          const complexityDisplayValue = getTrackComplexity(action.payload.track);
          const neuralEffectLevelDisplayValue = getTrackNeuralEffectLevel(action.payload.track);
          const name = getTrackName(action.payload.track);

          Analytics.logEventWithProperties('session_track_favorite', {
            id,
            name,
          } as any);

          Analytics.logEventWithProperties('favorite_track', {
            id,
            name,
            genre: genreName || 'error',
            activity: activities.byId[activityId]?.display || 'error',
            mentalState: mentalStates.byId[mentalStateId]?.display || 'error',
            complexity: complexityDisplayValue,
          } as any);
          trackFavoriteTrackEvent({
            activity: activities.byId[activityId]?.display,
            complexity: complexityDisplayValue,
            trackGenre: genreName,
            mentalState: mentalStates.byId[mentalStateId]?.display as MentalStates,
            origin: action.payload.origin,
            trackNel: neuralEffectLevelDisplayValue,
            trackName: name,
          });
        } catch (error) {
          Logger.error(new Error('addToFavoritesSaga(): unable to track favorite'), {
            reason: error,
          });
        }
      } else {
        yield put(musicActions.removeFromFavorites(action.payload.track));
      }
    } catch (error) {
      yield put(musicActions.addToFavorites(action.payload.track));
      Logger.error(error);
    }
  }
}
