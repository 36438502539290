import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  backface-visibility: hidden;
  background: linear-gradient(60.85deg, #4915f8 -89.64%, #ff496b 126.05%);
  border-radius: 100%;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  padding: 0.7rem;
  width: 2.5rem;
`;

export const Image = styled.img`
  width: auto;
  height: 100%;
  object-fit: contain;
`;
