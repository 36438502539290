import styled from 'styled-components';

import { Assets } from '../../../../utils/assets';

export const Overlay = styled.div`
  background: #191736;
  background: linear-gradient(
    30deg,
    rgba(25, 23, 54, 1) 0%,
    rgba(25, 23, 54, 1) 36%,
    rgba(57, 33, 65, 1) 100%
  );
  justify-content: center;
  display: flex;
  min-height: 100%;
  width: 100%;
`;

export const ModalWrapper = styled.div`
  display: flex;
  align-self: center;
`;

export const ModalContent = styled.div`
  background-image: url(${Assets.images.subscriptionSuccess.url});
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  background-position-x: center;
  background-position-y: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 4.5rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 2rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1rem;
`;

export const SubTitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 1.3rem;
  text-align: center;
  color: #ffffff;
  margin-top: 200px;
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    margin-top: 150px;
  }
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  color: #d4d2ea;
  text-align: center;
  width: 90%;
  margin-bottom: 1rem;
`;

export const ListItems = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: flex-start;
  margin-top: 15px;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 10px;
`;

export const ListText = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  text-align: center;
  color: #ffffff;
`;

export const Check = styled.img`
  width: 25px;
  margin-top: 4px;
`;

export const ButtonWrapper = styled.div`
  margin: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;
