import { EffectLevels, Serving, Track } from '@Model';

import { getNelDisplayValue } from '../lib/getNeuralEffectLevelDisplayValue';
import { isServing } from './isServing';

export function getTrackNeuralEffectLevel(trackOrServing?: Track | Serving | null): EffectLevels {
  if (isServing(trackOrServing)) {
    return getNelDisplayValue(trackOrServing.trackVariation.neuralEffectLevel || 0.5);
  }

  return trackOrServing?.neuralEffectLevelDisplayValue || EffectLevels.Medium;
}
