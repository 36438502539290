import { CoreAnalytics } from '@Analytics';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Analytics } from '../../utils/analytics';
import { OnboardingEvents } from '../../utils/analytics/events';
import { OnboardingCards } from './containers/OnboardingCards';
import { QuizCards } from './containers/QuizCards';
import { ResultCards } from './containers/ResultCards';
import { OnboardingMentalStates } from './containers/OnboardingMentalStates';
import { useOnboardingProcessedData } from './hooks/useOnboardingProcessedData';
import { OnboardingQuizAnswer, OnboardingSteps, OnboardingTestimonials } from './types';
import { FunBarsCard } from './components/FunBarsCard';
import { ONBOARDING_MENTAL_STATE_ID_KEY } from './constants';
import { OnboardingOrigins } from '../Analytics/coreAnalytics.types';

export const OnboardingContainer = () => {
  const [step, setStep] = useState<OnboardingSteps>(OnboardingSteps.MentalStates);
  const [onboardingMusicUrl, setOnboardingMusicUrl] = useState<string | null>(null);

  const quizAnswers = useRef<number[]>([]);
  const quizTestimonials = useRef<OnboardingTestimonials[]>([]);
  const quizBenefits = useRef<string[]>([]);
  const { isLoading, onboardingSpecification } = useOnboardingProcessedData();

  useEffect(() => {
    if (onboardingSpecification?.id) {
      CoreAnalytics.trackOnboardingInitiateEvent({
        variation: onboardingSpecification?.id,
        origin: getAnalyticsOriginFromAppOrigin(origin),
      });
      Analytics.logEventWithProperties(OnboardingEvents.onboarding_initiate, {
        onboarding_variation: onboardingSpecification?.id,
        origin: origin || '',
      });
    }
  }, [onboardingSpecification?.id]);

  const handleSetStep = useCallback(
    (step: OnboardingSteps) => {
      setStep(step);
    },
    [setStep],
  );

  const handleSelectAnswer = useCallback(
    (
      questionIndex: number,
      answerIndex: number,
      testimonials?: OnboardingQuizAnswer['testimonials'],
      benefits?: OnboardingQuizAnswer['benefits'],
    ) => {
      quizAnswers.current[questionIndex] = answerIndex;

      if (testimonials) {
        quizTestimonials.current = [...quizTestimonials.current, ...testimonials];
      }

      if (benefits) {
        quizBenefits.current = [...quizBenefits.current, ...benefits];
      }
    },
    [quizAnswers],
  );

  const handleSkipStep = () => {
    setStep(OnboardingSteps.Funbars);
  };

  const onCompleteStep = useCallback(
    (neurotype_id?: string) => {
      switch (step) {
        case OnboardingSteps.Funbars: {
          CoreAnalytics.trackOnboardingCompleteEvent({
            variation: onboardingSpecification.id,
            neurotypeId: neurotype_id || '',
          });
          Analytics.logEventWithProperties(OnboardingEvents.onboarding_complete, {
            onboarding_variation: onboardingSpecification.id,
            neurotype_id: neurotype_id || '',
          });
          break;
        }
        default:
          break;
      }
    },
    [onboardingSpecification, step],
  );

  const handleMentalStateSelect = (mentalStateId: string) => {
    sessionStorage.setItem(ONBOARDING_MENTAL_STATE_ID_KEY, mentalStateId);
    CoreAnalytics.trackOnboardingMentalStateSelectEvent({ mentalState: mentalStateId });

    if (mentalStateId === 'focus') {
      if (onboardingSpecification.cards.length === 0) {
        setStep(OnboardingSteps.Quiz);
      } else {
        setStep(OnboardingSteps.Cards);
      }
    } else {
      handleSkipStep();
    }
  };

  return (
    <>
      {step === OnboardingSteps.MentalStates && (
        <OnboardingMentalStates onSelect={handleMentalStateSelect} />
      )}
      {step === OnboardingSteps.Cards && (
        <OnboardingCards
          isLoading={isLoading}
          onboardingSpecification={onboardingSpecification}
          onCompleteStep={onCompleteStep}
          onSetStep={handleSetStep}
          onSkip={handleSkipStep}
        />
      )}
      {step === OnboardingSteps.Quiz && (
        <QuizCards
          isLoading={isLoading}
          onboardingSpecification={onboardingSpecification}
          onSelectAnswer={handleSelectAnswer}
          onSetStep={handleSetStep}
          onSkip={handleSkipStep}
        />
      )}
      {step === OnboardingSteps.Results && (
        <ResultCards
          onboardingSpecification={onboardingSpecification}
          quizAnswers={quizAnswers.current}
          onMusicUrlReceived={setOnboardingMusicUrl}
          onSetStep={handleSetStep}
        />
      )}
      {step === OnboardingSteps.Funbars && (
        <FunBarsCard
          onboardingMusicUrl={onboardingMusicUrl}
          paywallBenefits={quizBenefits.current.length !== 0 ? quizBenefits.current : undefined}
          paywallTestimonials={
            quizTestimonials.current.length !== 0 ? quizTestimonials.current : undefined
          }
          onCompleteStep={onCompleteStep}
        />
      )}
    </>
  );
};

function getAnalyticsOriginFromAppOrigin(origin: string): OnboardingOrigins {
  if (origin === 'settings') return 'settings';
  if (['emailSignup', 'facebookSignup', 'appleSignup'].includes(origin)) return 'sign_up';
  return 'direct';
}
