import { Paper } from '@Cortex';
import { ExploreMusic } from '@Music';
import { Preferences, SessionProTip, TimeMode } from '@Session';
import { useFeatureFlag, FeatureFlags } from '@Utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import { setSessionSidebarContent, setShouldShowProTipsModal } from '../../actions/ui';
import CrossIcon from '../../assets/images/close_icon.svg';
import { QuizToast } from '../../domains/PersonalizationQuiz';
import { useIsWindowFocused } from '../../hooks';
import { RootReducerType } from '../../reducers';
import { uiSliceActions } from '../../reducers/uiReducer';
import { SessionSidebarContent } from '../../types';
import { SessionType } from '../../types';
import { mentalStates } from '../../utils/mentalStates';
import * as S from './Session.styles';
import { AuraImage } from './components/AuraImage';
import { FocusSectionLegacy } from './components/FocusSectionLegacy';
import { SessionHeader } from './components/SessionHeader';
import { MENTAL_STATE_AURA_GRADIENTS, RING_RADIUS, RING_SIZE_DIFFERENCE } from './constants';
import { SESSION_ANIMATION_DURATION_MS } from './utils/constants';

export type SessionProps = {
  type: SessionType;
};

export const SessionLegacy: React.FC<SessionProps> = ({ type }) => {
  const isWindowFocused = useIsWindowFocused();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isProTipVisible, setProTipVisible] = useState(false);

  const sessionPlayStatus = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionPlayStatus,
  );

  const isUsingDynamicActivities = useFeatureFlag(FeatureFlags.DynamicActivities, {
    setAnalyticsUserProperty: true,
  });
  const sessionMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );
  const dynamicMentalStateDisplayValue = useSelector(
    (state: RootReducerType) =>
      state.sessionManager.sessionDynamicActivity?.mentalState.displayValue,
  );
  const mentalStateDisplayValue = isUsingDynamicActivities
    ? dynamicMentalStateDisplayValue || ''
    : mentalStates.byId[sessionMentalStateId || '']?.display || '';

  const modalType = useSelector((state: RootReducerType) => state.ui.modalType);

  const sessionSidebarContent = useSelector(
    (state: RootReducerType) => state.ui.sessionSidebarContent,
  );
  const shouldShowProTipsModal = useSelector(
    (state: RootReducerType) => state.ui.shouldShowProTipsModal,
  );

  const shouldShowFocusPersonalizationQuiz =
    useSelector((state: RootReducerType) => state.ui.shouldShowFocusPersonalizationQuiz) &&
    type === 'focus';

  const isPlayerAnimationEnabled = useSelector(
    (state: RootReducerType) => state.ui.isPlayerAnimationEnabled,
  );

  useEffect(() => {
    if (location.state && location.state.actions && location.state.actions.length) {
      location.state.actions.forEach((action: any) => {
        dispatch(action);
      });
    }
  }, [dispatch, location.state]);

  useEffect(() => {
    if (!modalType && shouldShowProTipsModal) {
      // timeout is used because the parent component is transitioning in
      const timeoutId = setTimeout(() => {
        setProTipVisible(true);
      }, SESSION_ANIMATION_DURATION_MS);

      return () => clearTimeout(timeoutId);
    }
  }, [shouldShowProTipsModal, modalType]);

  const handleProTipClose = () => {
    dispatch(setShouldShowProTipsModal(false));
    setProTipVisible(false);
  };

  const handleQuizToastClose = () => {
    dispatch(uiSliceActions.setShouldShowFocusPersonalizationQuiz(false));
  };

  const closeSidebarComponent = useCallback(() => {
    dispatch(setSessionSidebarContent({ sessionSidebarContent: null, origin: undefined }));
  }, [dispatch]);

  const setSessionSidebar = (contentType: SessionSidebarContent) => {
    dispatch(setSessionSidebarContent({ sessionSidebarContent: contentType, origin: 'player' }));
  };

  const isAnimating =
    isWindowFocused && sessionPlayStatus === 'PLAYING' && isPlayerAnimationEnabled;
  const backgroundAuraImage = MENTAL_STATE_AURA_GRADIENTS[type];

  return (
    <>
      <S.PageWrapper>
        <Transition in={isProTipVisible} mountOnEnter timeout={0} unmountOnExit>
          <S.SessionProTipWrapper>
            <SessionProTip onClose={handleProTipClose} />
          </S.SessionProTipWrapper>
        </Transition>

        <Transition in={shouldShowFocusPersonalizationQuiz} mountOnEnter timeout={0} unmountOnExit>
          <S.SessionProTipWrapper>
            <QuizToast
              shouldShowButtonToOpenPreferences={
                sessionSidebarContent !== SessionSidebarContent.Preferences
              }
              onClickOpenPreferences={() => setSessionSidebar(SessionSidebarContent.Preferences)}
              onClose={handleQuizToastClose}
            />
          </S.SessionProTipWrapper>
        </Transition>

        <S.BackgroundWrapper>
          <S.SessionWrapper>
            <S.GradientContainer>
              {isWindowFocused ? (
                <AuraImage isAnimating={isAnimating} src={backgroundAuraImage} />
              ) : null}
            </S.GradientContainer>
            {isWindowFocused ? (
              <S.SvgRings height="100%" width="100%">
                <S.CircleOne cx="50%" cy="50%" isAnimating={isAnimating} r={`${RING_RADIUS}em`} />
                <S.CircleTwo
                  cx="50%"
                  cy="50%"
                  isAnimating={isAnimating}
                  r={`${RING_RADIUS + RING_SIZE_DIFFERENCE / 2}em`}
                />
                <S.CircleThree
                  cx="50%"
                  cy="50%"
                  isAnimating={isAnimating}
                  r={`${RING_RADIUS + RING_SIZE_DIFFERENCE}em`}
                />
              </S.SvgRings>
            ) : null}
            <FocusSectionLegacy type={type} />
          </S.SessionWrapper>
        </S.BackgroundWrapper>

        <SessionHeader
          onClickExploreMusic={() => setSessionSidebar(SessionSidebarContent.ExploreMusic)}
          onClickPreferences={() => setSessionSidebar(SessionSidebarContent.Preferences)}
          onClickTimerSettings={() => setSessionSidebar(SessionSidebarContent.TimerSettings)}
        />
        {sessionSidebarContent && (
          <S.SidebarContentContainer
            isCentered={sessionSidebarContent === SessionSidebarContent.Preferences}
          >
            <Paper
              backgroundColor="#211D3F"
              padding={
                sessionSidebarContent === SessionSidebarContent.Preferences ? '0' : undefined
              }
            >
              {sessionSidebarContent !== SessionSidebarContent.Preferences ? (
                <S.CloseButtonContainer>
                  <S.CloseButton
                    data-testid="closeButton"
                    src={CrossIcon}
                    onClick={closeSidebarComponent}
                  />
                </S.CloseButtonContainer>
              ) : null}

              {sessionSidebarContent === SessionSidebarContent.ExploreMusic ? (
                <ExploreMusic
                  mentalState={mentalStateDisplayValue}
                  onClose={closeSidebarComponent}
                />
              ) : null}
              {sessionSidebarContent === SessionSidebarContent.Preferences ? (
                <Preferences onClose={closeSidebarComponent} />
              ) : null}
              {sessionSidebarContent === SessionSidebarContent.TimerSettings ? (
                <TimeMode onClose={closeSidebarComponent} />
              ) : null}
            </Paper>
          </S.SidebarContentContainer>
        )}
      </S.PageWrapper>
      <Outlet />
    </>
  );
};
