import { Button, TextBold } from '@Cortex';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const HeaderText = styled(TextBold)`
  font-size: 3rem;
  margin-bottom: 3rem;
  text-transform: lowercase;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex: 1;
  margin: 1rem 0;
  min-width: 15.5rem;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  min-width: 15.5rem;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  transform: scale(1.1);
`;

export const ButtonWrapper = styled.div`
  margin: 1rem 0;
`;

export const Padding = styled.div`
  height: 200px;
  pointer-events: none;
`;

export const TitleText = styled(TextBold)`
  font-size: 1.75rem;
  margin-bottom: 1rem;
  text-transform: lowercase;
`;

export const SubtitleText = styled(TextBold)`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

export const ButtonImage = styled.img`
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
`;

export const CopyButton = styled(Button)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 80%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    width: 100%;
  }
`;
