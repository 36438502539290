import {
  MenuItem,
  Button,
  ButtonVariants,
  ButtonVariantStates,
  MenuItemIcons,
  Paper,
  Form,
  Icon,
} from '@Cortex';
import { FormikConfig } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import BrandLogo from '../../../../assets/images/brand_logo.svg';
import { FormActionText, SignInFormValues } from '../../../../components/forms';
import { FORM_PADDING } from '../../constants';
import * as S from './SignInForm.styles';

type FormInput<Values> = {
  label: string;
  name: Extract<keyof Values, string>;
  placeholder: string;
  type: string;
};

export type Props<Values> = {
  callToAction?: string;
  formError: string | null;
  formInitialValues: Values;
  formInputs: FormInput<Values>[];
  formValidationSchema: Yup.ObjectSchema<SignInFormValues>;
  isLoading: boolean;
  onClickForgotPassword: () => void;
  onClickForgotPasswordAnalyticsEvent: string;
  onClickSignInWithApple: () => void;
  onClickSignInWithAppleAnalyticsEvent: string;
  onClickSignInWithFacebook: () => void;
  onClickSignInWithFacebookAnalyticsEvent: string;
  onClickSignUp: () => void;
  onClickSignUpAnalyticsEvent: string;
  onFormChange?: () => void;
  onFormSubmit: FormikConfig<Values>['onSubmit'];
  onFormSubmitAnalyticsEvent: string;
};

export function SignInForm<Values extends { [key: string]: string }>(props: Props<Values>) {
  return (
    <S.SignInForm>
      <S.Header>
        <S.IconContainer>
          <Icon size={67} src={BrandLogo} />
        </S.IconContainer>
      </S.Header>
      <S.HeaderText size="3rem">Sign In</S.HeaderText>
      <S.FormWrapper>
        <Paper padding={FORM_PADDING}>
          {props.callToAction ? (
            <S.CallToActionText>{props.callToAction}</S.CallToActionText>
          ) : null}
          <S.Form>
            <Form
              formError={props.formError}
              formInitialValues={props.formInitialValues}
              formInputs={props.formInputs}
              formValidationSchema={props.formValidationSchema}
              isLoading={props.isLoading}
              submitButtonText="Sign In"
              onFormChange={props.onFormChange}
              onFormSubmit={props.onFormSubmit}
              onFormSubmitAnalyticsEvent={props.onFormSubmitAnalyticsEvent}
            >
              <S.SignInWithWrapper>
                <S.HR />
                <S.SignInWithText>Or sign in with</S.SignInWithText>
                <S.HR />
              </S.SignInWithWrapper>
              <S.ButtonWrapper>
                <S.Button>
                  <Button
                    analyticsEvent={props.onClickSignInWithFacebookAnalyticsEvent}
                    isFullWidth={true}
                    variant={ButtonVariants.Tertiary}
                    variantState={ButtonVariantStates.Idle}
                    onClick={props.onClickSignInWithFacebook}
                  >
                    <MenuItem icon={MenuItemIcons.Facebook}>Facebook</MenuItem>
                  </Button>
                </S.Button>
                <S.Button>
                  <Button
                    analyticsEvent={props.onClickSignInWithAppleAnalyticsEvent}
                    isFullWidth={true}
                    variant={ButtonVariants.Tertiary}
                    variantState={ButtonVariantStates.Idle}
                    onClick={props.onClickSignInWithApple}
                  >
                    <MenuItem icon={MenuItemIcons.Apple}>Apple</MenuItem>
                  </Button>
                </S.Button>
                <div id="google-button" />
              </S.ButtonWrapper>
            </Form>
          </S.Form>
          <S.Links>
            <S.Link>
              <FormActionText
                actionText="Sign up"
                analyticsEvent={props.onClickSignUpAnalyticsEvent}
                data-testid="sign-up"
                text="Need an account?"
                onClick={props.onClickSignUp}
              />
            </S.Link>
            <S.LinkLast>
              <FormActionText
                actionText="Click here"
                analyticsEvent={props.onClickForgotPasswordAnalyticsEvent}
                text="Forgot Your Password?"
                onClick={props.onClickForgotPassword}
              />
            </S.LinkLast>
          </S.Links>
        </Paper>
        <S.LegacyLink>
          <Paper padding="1rem">
            <S.Links>
              <S.LinkLast>
                <FormActionText
                  actionText="Click here"
                  analyticsEvent={'sign_in_form_legacy_link_click'}
                  text="looking for the old brain.fm app?"
                  onClick={() => {
                    window.location.href = 'https://v1.brain.fm';
                  }}
                />
              </S.LinkLast>
            </S.Links>
          </Paper>
        </S.LegacyLink>
      </S.FormWrapper>
    </S.SignInForm>
  );
}
