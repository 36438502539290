import styled from 'styled-components';

export const CONTAINER_PADDING = 4;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding: ${CONTAINER_PADDING}px;
  height: 2.5rem;
  background: rgba(25, 23, 54, 0.8);
  border-radius: 0.75rem;
`;

export const Item = styled.button`
  outline: none;
  border: none;
  background: none;

  flex: 1;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  position: relative;
  z-index: 2;
  margin-right: ${CONTAINER_PADDING}px;
  cursor: pointer;
  text-transform: lowercase;

  &:last-child {
    margin-right: 0;
  }
`;

export const Pill = styled.div`
  position: absolute;
  z-index: 1;
  background: #544881;
  border-radius: 8px;
  height: 100%;
  transition:
    transform 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
`;
