import { FontColors, Icon, Text } from '@Cortex';

import { Assets } from '../../../../../../../utils/assets';
import * as S from './Checkmark.styles';

interface Props {
  children: string;
}

export const Checkmark = ({ children }: Props) => {
  return (
    <S.Container>
      <S.IconContainer>
        <S.Icon>
          <Icon isSquare size={18} src={Assets.icons.checkmark.url} />
        </S.Icon>
      </S.IconContainer>
      <S.TextContainer>
        <Text color={FontColors.WhiteSecondary} size="1rem">
          {children}
        </Text>
      </S.TextContainer>
    </S.Container>
  );
};
