import { useActivityFilters, useSetActivityFilters } from '@Music';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../../../reducers';
import { activities } from '../../../../../utils/activities';

export function useActivityFilterHandler() {
  const selectedActivities = useActivityFilters().map(x => x.display);
  const setSelectedActivities = useSetActivityFilters();
  const sessionMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );

  function handleToggleSelectActivity(selectedActivity: string) {
    if (selectedActivities.includes(selectedActivity)) {
      setSelectedActivities(selectedActivities.filter(activity => activity !== selectedActivity));
      return;
    }
    setSelectedActivities([...selectedActivities, selectedActivity]);
  }

  return {
    title: 'Activity',
    selectedItems: selectedActivities,
    items: activities.byArray
      .filter(activity => activity.mentalStateId === sessionMentalStateId)
      .map(activity => activity.display),
    onItemClick: handleToggleSelectActivity,
  };
}
