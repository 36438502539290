import * as S from './SignupBenefits.styles';
import { Checkmark } from './components/Checkmark';

interface Props {
  title: string;
}

export const SignupBenefits = ({ title }: Props) => {
  return (
    <S.Container>
      <S.TextContainer>
        <S.LargeText>Limited Offer:</S.LargeText>
        <S.LargeText>{title}</S.LargeText>
      </S.TextContainer>

      <S.CheckmarksContainer>
        <Checkmark
          description="Access to 1000+ tracks across a wide variety of music genres and nature soundscapes, all specifically designed to help you focus, sleep, relax, and meditate"
          title="Unlimited Sessions"
        />
        <Checkmark
          description="Discover the perfect music for your brain by personalizing science-based features like Neural Effect and Musical Complexity"
          title="Access all Categories"
        />
        <Checkmark
          description="Take brain.fm on the go with our mobile application, available for iPhone and Android."
          title="Access to our Mobile App"
        />
      </S.CheckmarksContainer>
    </S.Container>
  );
};
