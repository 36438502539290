import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const MenuContainer = styled.div<{ animationState: TransitionStatus }>`
  position: absolute;
  z-index: 999;
  right: 0;
  bottom: 1rem;
  padding: 1rem 0;
  transition:
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;

  transform: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return `translateY(0.5rem)`;
      case 'entered':
        return `translateY(0)`;
      case 'exiting':
        return `translateY(0)`;
      case 'exited':
        return `translateY(0.5rem)`;
    }
  }};

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0;
      case 'entered':
        return 1;
      case 'exiting':
        return 0.5;
      case 'exited':
        return 0;
    }
  }};
`;

export const ContentWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 1px solid #565487;
  background: #191736;
  border-radius: 0.75rem;
  min-width: 250px;
  padding: 15px 20px 20px;
`;

export const HeaderWrapper = styled.div`
  align-self: center;
  margin-bottom: 12px;
`;

export const Share = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.8rem;
  margin-bottom: 15px;
  text-align: center;
  cursor: pointer;
`;
export const Shared = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.8rem;
  margin-bottom: 15px;
  text-align: center;
`;
