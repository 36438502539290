import { Icon } from '@Cortex';
import { NOOP } from '@Globals';
import { OutsideClickDetector } from '@Utils';
import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';

import { StreaksType } from '../../../../../../types';
import * as S from './StreaksWidget.styles';
import RocketIcon from './assets/rocket.svg';
import { StreakInfo } from './components/StreakInfo';
import { Streaks } from './components/Streaks';

interface Props {
  isActive?: boolean;
  isCopied: boolean;
  onClick?: () => void;
  onOutsideClick?: () => void;
  onShare: () => void;
  streaks: StreaksType;
}

export const StreaksWidgetDisplay = ({
  onClick = NOOP,
  onOutsideClick = NOOP,
  streaks,
  onShare,
  isActive = false,
  isCopied = false,
}: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const {
    data: { weekly },
  } = streaks;

  return (
    <S.Container data-testid="streaksWidget">
      <OutsideClickDetector onOutsideClick={onOutsideClick}>
        <div style={{ cursor: 'pointer' }} onClick={onClick}>
          <Streaks />
        </div>

        <Transition in={isActive} mountOnEnter nodeRef={menuRef} timeout={0} unmountOnExit>
          {state => {
            return (
              <S.MenuContainer ref={menuRef} animationState={state}>
                <S.ContentWrapper>
                  <S.HeaderWrapper>
                    <Icon size={35} src={RocketIcon} />
                  </S.HeaderWrapper>
                  {isCopied ? (
                    <S.Shared>Copied!</S.Shared>
                  ) : (
                    <S.Share onClick={onShare}>Share your achievements!</S.Share>
                  )}
                  <StreakInfo
                    description="week"
                    subTitle="Consecutive weeks listened."
                    title="current streak"
                    value={weekly.currentCount}
                  />
                  <br />
                  <StreakInfo
                    description="week"
                    subTitle="Your longest listening streak."
                    title="longest streak"
                    value={weekly.longestCount}
                  />
                </S.ContentWrapper>
              </S.MenuContainer>
            );
          }}
        </Transition>
      </OutsideClickDetector>
    </S.Container>
  );
};
