import { TabBar, TextBold } from '@Cortex';
import { musicActions } from '@Music';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import { useRecommendedTrack } from '../../../../api/modules/RecommendedTrack';
import * as S from './ExploreMusic.styles';
import { Favorites } from './components/Favorites';
import { Featured } from './components/Featured';
import { Recent } from './components/Recent';
import { TracksByGenres } from './components/TracksByGenres/TracksByGenres';

export enum ExploreMusicPanes {
  Featured = 'Featured',
  Genres = 'Genres',
  Favorites = 'Favorites',
  Recent = 'Recent',
}

const TAB_ITEMS = Object.values(ExploreMusicPanes);

type Props = {
  mentalState: string;
  onClose: () => void;
};

export function ExploreMusic(props: Props) {
  const location = useLocation();

  const [currentPane, setCurrentPane] = useState<ExploreMusicPanes>(ExploreMusicPanes.Featured);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const feauredRef = useRef<HTMLDivElement>(null);
  const favoritesRef = useRef<HTMLDivElement>(null);
  const recentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const recommendedTrack = useRecommendedTrack();

  useEffect(() => {
    if (location.state && location.state.exploreTab) {
      const activeIndex = TAB_ITEMS.findIndex(tabItem => tabItem === location.state.exploreTab);

      if (activeIndex >= 0) {
        setActiveTabIndex(activeIndex);
      }
      setCurrentPane(location.state.exploreTab);
    }

    return () => {
      dispatch(musicActions.setGenreFilters([]));
      dispatch(musicActions.setActivityFilters([]));
      dispatch(musicActions.setNeuralEffectFilters([]));
    };
  }, []);

  const handleTabItemClick = (pane: ExploreMusicPanes) => {
    const activeIndex = TAB_ITEMS.findIndex(tabItem => tabItem === pane);

    if (activeIndex >= 0) {
      setActiveTabIndex(activeIndex);
    }

    setCurrentPane(pane);
  };

  return (
    <S.Container data-testid="ExploreMusic">
      <S.HeadingContainer>
        <TextBold size={32}>{`Explore ${props.mentalState} Music`}</TextBold>
      </S.HeadingContainer>

      <S.TabBarContainer>
        <TabBar activeTabIndex={activeTabIndex} items={TAB_ITEMS} onSelect={handleTabItemClick} />
      </S.TabBarContainer>

      <Transition
        in={currentPane === ExploreMusicPanes.Featured}
        mountOnEnter
        nodeRef={feauredRef}
        timeout={0}
        unmountOnExit
      >
        {animationState => (
          <S.FadeInWrapper ref={feauredRef} animationState={animationState}>
            <Featured recommendedTrack={recommendedTrack} />
          </S.FadeInWrapper>
        )}
      </Transition>

      <Transition
        in={currentPane === ExploreMusicPanes.Genres}
        mountOnEnter
        timeout={0}
        unmountOnExit
      >
        <TracksByGenres />
      </Transition>

      <Transition
        in={currentPane === ExploreMusicPanes.Favorites}
        mountOnEnter
        nodeRef={favoritesRef}
        timeout={0}
        unmountOnExit
      >
        {animationState => (
          <S.FadeInWrapper ref={favoritesRef} animationState={animationState}>
            <Favorites mentalState={props.mentalState} />
          </S.FadeInWrapper>
        )}
      </Transition>

      <Transition
        in={currentPane === ExploreMusicPanes.Recent}
        mountOnEnter
        nodeRef={recentRef}
        timeout={0}
        unmountOnExit
      >
        {animationState => (
          <S.FadeInWrapper ref={recentRef} animationState={animationState}>
            <Recent />
          </S.FadeInWrapper>
        )}
      </Transition>
    </S.Container>
  );
}
