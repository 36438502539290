import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const CheckmarksContainer = styled.div`
  width: 100%;
`;

export const TextContainer = styled.div`
  margin-bottom: 3rem;
`;

export const SmallTextCaps = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 10px;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  color: #d4d2ea;
  margin: 1rem 0;
`;

export const LargeText = styled.h1`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 3rem;
  color: #ffffff;
  margin: 0.5rem 0;
`;
