import { TextBold } from '@Cortex';
import styled from 'styled-components';

export const ForgotPasswordForm = styled.div`
  align-items: center;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  padding: 0 1rem;
  text-transform: lowercase;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    overflow-y: inherit;
  }
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 10vh;
  padding-top: 3rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    margin-bottom: 2vh;
    padding-top: 2vh;
  }
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const FormWrapper = styled.div`
  max-width: 545px;
  width: 100%;
`;

export const Form = styled.div`
  margin-bottom: 1.5rem;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
`;

const Link = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const LinkLast = styled(Link)`
  margin-bottom: 0;
`;

export const HeaderText = styled(TextBold)`
  margin-bottom: 1rem;
  text-align: center;
`;
