import { Button, ButtonVariants, ButtonVariantStates, FormInput } from '@Cortex';
import { Formik } from 'formik';
import * as yup from 'yup';

import { FormContainer } from '../../../shared/FormContainer';
import * as S from './UpdatePassword.styles';

const validationSchema = yup.object().shape({
  currentPassword: yup.string().required('Your current password is required.'),
  newPassword: yup
    .string()
    .min(6, 'Password must be at least 6 characters long.')
    .required('Type in your new password'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), undefined], 'The value should match New Password field.')
    .required('Confirm your new password.'),
});

export type FormSubmitArgs = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export interface Props {
  errorMessage?: string;
  isLoading?: boolean;
  onClose: () => void;
  onFormSubmit: (values: FormSubmitArgs) => void;
  submitButtonTitle?: string;
}

export const UpdatePasswordDisplay = ({
  errorMessage,
  isLoading = false,
  onClose,
  onFormSubmit,
  submitButtonTitle = 'Save',
}: Props) => {
  return (
    <Formik
      initialValues={{ currentPassword: '', newPassword: '', confirmNewPassword: '' }}
      validationSchema={validationSchema}
      onSubmit={values => onFormSubmit(values)}
    >
      {({ handleChange, handleSubmit, values, touched, errors }) => {
        return (
          <S.Form data-testid="changePasswordForm" onSubmit={handleSubmit}>
            <FormContainer errorMessage={errorMessage} title="Change Password" onClose={onClose}>
              <S.Container>
                <FormInput
                  autoComplete="current-password"
                  errorMessage={
                    touched.currentPassword && errors.currentPassword
                      ? errors.currentPassword
                      : undefined
                  }
                  label="Current Password"
                  name="currentPassword"
                  title={errors.currentPassword}
                  type="password"
                  value={values.currentPassword}
                  onChange={handleChange}
                />

                <FormInput
                  autoComplete="new-password"
                  errorMessage={
                    touched.newPassword && errors.newPassword ? errors.newPassword : undefined
                  }
                  label="New Password"
                  name="newPassword"
                  title={errors.newPassword}
                  type="password"
                  value={values.newPassword}
                  onChange={handleChange}
                />

                <FormInput
                  autoComplete="new-password"
                  errorMessage={
                    touched.confirmNewPassword && errors.confirmNewPassword
                      ? errors.confirmNewPassword
                      : undefined
                  }
                  label="Confirm New Password"
                  name="confirmNewPassword"
                  title={errors.confirmNewPassword}
                  type="password"
                  value={values.confirmNewPassword}
                  onChange={handleChange}
                />

                <S.SubmitButton>
                  <Button
                    data-testid="changePasswordButton"
                    isFullWidth={true}
                    type="submit"
                    variant={ButtonVariants.Primary}
                    variantState={
                      isLoading ? ButtonVariantStates.Loading : ButtonVariantStates.Idle
                    }
                  >
                    {submitButtonTitle}
                  </Button>
                </S.SubmitButton>
                <Button type="button" onClick={onClose}>
                  Cancel
                </Button>
              </S.Container>
            </FormContainer>
          </S.Form>
        );
      }}
    </Formik>
  );
};
