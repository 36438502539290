import { Track } from '@Model';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { useRequestHandler } from './useRequestHandler';

export const useRecommendedTrack = () => {
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);
  const getRecommendedTrack = useRequestHandler();

  return useQuery<Track[], Error>(
    Queries.getRecommendedTrack(userId!),
    () => getRecommendedTrack(userId!),
    {
      onError: () => {
        Logger.error(new Error('Error getting Tracks by Genre'));
      },
      enabled: Boolean(userId),
      cacheTime: 0,
    },
  );
};
