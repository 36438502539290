import { takeEvery, debounce } from 'redux-saga/effects';

import {
  addToDislikes,
  addToFavorites,
  fetchFavoritesAndDislikes,
  removeFromDislikes,
  removeFromFavorites,
  searchTracks,
} from '../actions';
import { addToDislikesSaga } from './addToDislikesSaga';
import { addToFavoritesSaga } from './addToFavoritesSaga';
import { fetchFavoritesAndDislikesSaga } from './fetchFavoritesAndDislikesSaga';
import { removeFromDislikesSaga } from './removeFromDislikesSaga';
import { removeFromFavoritesSaga } from './removeFromFavoritesSaga';
import { searchTracksSaga } from './searchTracks';

export function* musicSagas() {
  yield takeEvery(addToDislikes.type, addToDislikesSaga);
  yield takeEvery(addToFavorites.type, addToFavoritesSaga);
  yield takeEvery(fetchFavoritesAndDislikes.type, fetchFavoritesAndDislikesSaga);
  yield takeEvery(removeFromDislikes.type, removeFromDislikesSaga);
  yield takeEvery(removeFromFavorites.type, removeFromFavoritesSaga);
  yield debounce(300, searchTracks.type, searchTracksSaga);
}
