import { useEffect, useState } from 'react';

import { Assets } from '../../utils/assets';
import { Logger } from '../../utils/logger';
import { PomodoroPhases } from './constants';
import { usePomodoroPhase } from './usePomodoroPhase';

const CHIME = new Audio();
CHIME.src = Assets.sounds.chime.url;
CHIME.volume = 0.2;

const THREE_CHIMES = new Audio();
THREE_CHIMES.src = Assets.sounds.threeChimes.url;
THREE_CHIMES.volume = 0.5;

export function useChimeOnPomodoroPhaseChange() {
  const [hasMounted, setHasMounted] = useState(false);
  const pomodoroPhase = usePomodoroPhase();

  useEffect(() => {
    if (!hasMounted) {
      // don't play sound on mount
      setHasMounted(true);
      return;
    }
    if (!pomodoroPhase) return;

    try {
      if (pomodoroPhase === PomodoroPhases.BreakEnding) {
        THREE_CHIMES.play().catch(e => {
          Logger.info('Custom Timer: three_chimes play() request was interrupted', { reason: e });
        });
      }

      if (pomodoroPhase === PomodoroPhases.Break) {
        CHIME.play().catch(e => {
          Logger.info('Custom Timer: chime play() request was interrupted', { reason: e });
        });
      }
    } catch (error) {
      Logger.error(new Error('useChimeOnPomodoroPhaseChange(): unable to play sound'), {
        reason: error,
      });
    }
  }, [pomodoroPhase]);
}
