import styled from 'styled-components';

export enum FontColors {
  White = '#fff',
  WhiteSecondary = '#D4D2EA',
  WhiteTransparent = 'rgba(255, 255, 255, 0.75)',
}

export enum FontFamilies {
  Bold = 'TTNormsPro-Bold',
  Regular = 'TTNormsPro-Regular',
  SemiBold = 'TTNormsPro-Medium',
}

export type Props = {
  children?: string;
  color?: FontColors;
  family?: FontFamilies;
  size?: string | number;
};

export const Text = styled.div<Props>`
  color: ${props => props.color || FontColors.White};
  font-family: ${props => props.family || FontFamilies.Regular};
  font-size: ${props => getFontSize(props)};
  font-weight: ${props => getFontWeight(props)};
`;

export const TextBold = styled(Text)`
  font-family: ${FontFamilies.Bold};
`;

export const TextSemiBold = styled(Text)`
  font-family: ${FontFamilies.SemiBold};
`;

export const TextSpaced = styled(Text)`
  font-family: ${FontFamilies.Bold};
  font-weight: 700;
  letter-spacing: 0.2rem;
`;

function getFontSize(props: Props) {
  switch (typeof props.size) {
    case 'string':
      return props.size;
    case 'number':
      return getFontSizeInRems(props.size);
    default:
      return '1rem';
  }
}

function getFontSizeInRems(size: number): string {
  return `${size / 16}rem`;
}

function getFontWeight(props: Props): number {
  switch (props.family) {
    case FontFamilies.Bold:
      return 700;
    case FontFamilies.Regular:
    default:
      return 400;
  }
}
