import { EnhancedMembershipPlanType, MembershipPlanType } from '../../../../../types';

/**
 * Modifies given monthly and yearly memebership plans' description and adds
 * a new property called monthlyCost
 * @param plans
 * @returns
 */
export function enhanceMembershipInfo(plans: MembershipPlanType[]): EnhancedMembershipPlanType[] {
  const enhancedPlans: EnhancedMembershipPlanType[] = [];

  plans.forEach(plan => {
    let newPlan: EnhancedMembershipPlanType = { ...plan, displayCost: '0', displayInterval: 'mo' };

    if (plan.title === 'Yearly') {
      const price = plan.price / 100;

      newPlan.price = price;
      newPlan.displayCost = (price / 12).toFixed(2).toLocaleString();
      newPlan.description = `$${price} billed annually.`;
    } else {
      const price = plan.price / 100;

      newPlan.price = price;
      newPlan.displayCost = price.toString();
      newPlan.description = `$${price} billed monthly.`;
    }

    enhancedPlans.push(newPlan);
  });

  return enhancedPlans;
}
