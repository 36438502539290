import { Button, ButtonVariants, Text, TextBold } from '@Cortex';
import { NOOP } from '@Globals';

import * as S from './UpgradeStripeSubscriptionPanel.styles';

export type Props = {
  price?: string;
  onClose?: () => void;
  onSubmit?: () => void;
};

export function UpgradeStripeSubscriptionPanel({
  onClose = NOOP,
  onSubmit = NOOP,
  price = '',
}: Props) {
  return (
    <S.UpgradeStripeSubscriptionPanel>
      <S.Header>
        <TextBold size={36}>Are you sure?</TextBold>
      </S.Header>
      <S.Text>
        <Text>
          {`You will be charged immediately for ${price} minus the cost of any time already paid for in your current billing period.`}
        </Text>
      </S.Text>
      <S.PrimaryButton>
        <Button
          data-testid="confirmUpgradeButton"
          isFullWidth={true}
          variant={ButtonVariants.Primary}
          onClick={onSubmit}
        >
          Upgrade My Subscription
        </Button>
      </S.PrimaryButton>
      <Button onClick={onClose}>Go Back</Button>
    </S.UpgradeStripeSubscriptionPanel>
  );
}
