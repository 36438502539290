import { useGetAvailableStripeCouponPromotion, useApplyRedemptionCode } from '@Memberships';
import { useIsPaymentProcessing } from '@Payment';
import { pluralize } from '@Utils';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setModal } from '../../../../actions/ui';
import BrandLogoExclamation from '../../../../assets/images/brand_logo_exclamation.svg';
import { RootReducerType } from '../../../../reducers';
import { useGetAvailableRedemptionCodePromotion } from '../../lenses/getAvailableRedemptionCodePromotion';
import { useIsExpired } from '../../lenses/isExpired';
import { useIsLifetimeUser } from '../../lenses/isLifetimeUser';
import { useIsTrialUser } from '../../lenses/isTrialUser';
import {
  StripeCouponMembershipPromotion,
  MembershipSources,
  MembershipStates,
} from '../../reducer/reducer';
import { LifetimeMembershipPanel, MembershipPanel } from './MembershipPanel.display';
import { ExpiredMembershipCallToAction } from './components/ExpiredMembershipCallToAction/ExpiredMembershipCallToAction';
import { GracePeriodCallToAction } from './components/GracePeriodCallToAction';
import { SubscriptionInformation } from './components/SubscriptionInformation';
import { TrialCallToAction } from './components/TrialCallToAction';

type Props = {
  onClickUpdatePaymentDetails?: () => void;
};

export function MembershipPanelContainer(props: Props) {
  const openUpgradeStripeSubscriptionModal = useOpenUpgradeStripeSubscriptionModal();
  const isPaymentProcessing = useIsPaymentProcessing();

  const navigate = useNavigate();
  const isTrial = useIsTrialUser();
  const isLifetime = useIsLifetimeUser();
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const availableRedemption = useGetAvailableRedemptionCodePromotion();
  const applyRedemptionCode = useApplyRedemptionCode();
  const isExpired = useIsExpired();

  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);
  const membership = useSelector((state: Pick<RootReducerType, 'membership'>) => state.membership);
  const isLoading = membership.source === MembershipSources.Loading || isPaymentProcessing;

  const expirationDate = new Date(legacyMembership?.expirationDate || '');
  const timeLeftInMilliseconds = expirationDate.getTime() - Date.now();
  const timeLeftInHours = Math.round(timeLeftInMilliseconds / 1000 / 60 / 60);
  const timeLeftInDays = Math.round(timeLeftInHours / 24);

  const gracePeriodExpirationDate = new Date(legacyMembership?.gracePeriodExpirationDate || '');
  const gracePeriodTimeLeftInMilliseconds = gracePeriodExpirationDate.getTime() - Date.now();
  const gracePeriodTimeLeftInMinutes = Math.floor(gracePeriodTimeLeftInMilliseconds / 1000 / 60);
  const gracePeriodTimeLeftInHours = Math.floor(gracePeriodTimeLeftInMinutes / 60);
  const gracePeriodTimeLeftInDays = Math.floor(gracePeriodTimeLeftInHours / 24);

  const isInGracePeriod =
    gracePeriodTimeLeftInMilliseconds >= 0 && legacyMembership?.isInGracePeriod;

  return isLifetime ? (
    <LifetimeMembershipPanel />
  ) : (
    <MembershipPanel
      iconSrc={isExpired ? BrandLogoExclamation : undefined}
      isLoading={isLoading}
      subtitle={
        isTrial
          ? isExpired
            ? 'Your trial has ended'
            : timeLeftInDays
            ? `${pluralize('days', timeLeftInDays)} remaining`
            : `${pluralize('hours', timeLeftInHours)} remaining`
          : `${legacyMembership?.title} plan`
      }
      tagTitle={isExpired ? 'EXPIRED' : undefined}
      title={isTrial ? 'brain.fm Trial' : 'brain.fm PRO'}
    >
      {/* TODO this logic is super confusing, can clean it up but for now taking on as tech debt
          since we are fully test covered. refactoring will be safe! */}
      {isTrial ? (
        <TrialCallToAction
          callToActionText={
            availableRedemption
              ? 'Apply Redemption Code!'
              : getCouponCallToActionText(availablePromotion)
          }
          onCTAClick={
            availableRedemption
              ? applyRedemptionCode
              : () =>
                  navigate(
                    {
                      pathname: '/payment',
                    },
                    {
                      state: { from: 'Membership Panel TrialCallToAction' },
                    },
                  )
          }
        />
      ) : null}
      {!isTrial && isExpired && !isInGracePeriod ? (
        <ExpiredMembershipCallToAction
          callToActionText={getCouponCallToActionText(availablePromotion)}
          onClickRenewMembership={() => {
            navigate('/payment', {
              state: { from: 'Membership Panel Expired' },
            });
          }}
        />
      ) : null}
      {!isTrial && isExpired && isInGracePeriod ? (
        <GracePeriodCallToAction
          daysRemaining={gracePeriodTimeLeftInDays}
          hoursRemaining={gracePeriodTimeLeftInHours % 24}
          minutesRemaining={gracePeriodTimeLeftInMinutes % 60}
          onClickUpdatePaymentDetails={props.onClickUpdatePaymentDetails}
        />
      ) : null}
      {!isLifetime &&
      !isTrial &&
      !isExpired &&
      membership.source &&
      membership.source !== MembershipSources.Unknown ? (
        <SubscriptionInformation
          callToActionText={
            membership.state === MembershipStates.Cancelled
              ? 'Renew Subscription'
              : membership.source === MembershipSources.Stripe &&
                legacyMembership?.title === 'Monthly'
              ? 'Upgrade to yearly'
              : null
          }
          currentPeriodEndDate={membership.currentPeriodEndDate}
          expirationDate={expirationDate}
          isCancelled={membership.state === MembershipStates.Cancelled}
          renewalPrice={membership.renewalPrice}
          subscriptionSource={MembershipSources[membership.source]}
          onClickCallToAction={
            membership.state === MembershipStates.Cancelled
              ? () =>
                  navigate(
                    {
                      pathname: '/payment',
                    },
                    {
                      state: { from: 'Membership Panel SubscriptionInformation' },
                    },
                  )
              : openUpgradeStripeSubscriptionModal
          }
        />
      ) : null}
    </MembershipPanel>
  );
}

function useOpenUpgradeStripeSubscriptionModal() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(setModal('upgradeStripeSubscription'));
  }, [dispatch]);
}

function getCouponCallToActionText(
  promotion?: StripeCouponMembershipPromotion | null,
): string | undefined {
  if (!promotion?.expirationDate) return;
  return `Hurry! Use your ${promotion.name} before ${promotion.expirationDate.displayValue}.`;
}
