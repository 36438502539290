import { NOOP } from '@Globals';
import { HTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import Card from './Card';

const ImageContainer = styled.div`
  display: flex;

  &:hover {
    & img {
      transform: scale(1.05);
    }
  }
`;

const Image = styled.img<{ active: boolean }>`
  position: absolute;
  top: -2.25rem;
  right: -7.5rem;
  width: 88%;
  height: auto;
  transition:
    opacity 0.6s ease-in-out,
    transform 0.6s ease-in-out;
  opacity: ${({ active }) => (active ? 1 : 0)};

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    width: auto;
    height: 100%;
    top: 0;
    left: -9.5rem;
    right: initial;
  }

  ${({ theme }) => theme.mediaQuery.minWidth.lg} {
    width: auto;
    height: 100%;
    right: initial;
    top: 0;
    left: -9.5rem;
  }

  // avoid weird image placement when resizing vertically
  @media (max-height: 660px) and (min-width: 768px) {
    top: initial;
    right: initial;
    width: auto;
    height: 100%;
  }

  @media (max-height: 512px) and (min-width: 768px) {
    top: -2rem;
    left: initial;
    right: -3rem;
    width: 100%;
    height: auto;
  }

  // safari has a bug with overflow:hidden on absolutely positioned children;
  // this is a workaround for it.
  // https://stackoverflow.com/questions/44948735/overflow-hidden-is-not-working-with-absolute-element-in-safari
  transform: translateZ(0);
`;

interface DynamicMentalStateCardProps extends HTMLAttributes<HTMLDivElement> {
  mentalState: string;
  imageUrl: string;
  imageUrlSecondary: string;
  color: string;
  isActive: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const DynamicMentalStateCard = ({
  isActive,
  mentalState,
  imageUrl,
  imageUrlSecondary,
  color,
  onMouseEnter = NOOP,
  onMouseLeave = NOOP,
  ...props
}: DynamicMentalStateCardProps) => {
  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
    onMouseEnter();
  };

  const handleMouseLeave = () => {
    setHovered(false);
    onMouseLeave();
  };

  return (
    <Card
      isActive={isActive}
      text={`${mentalState}.`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      <ImageContainer>
        <picture>
          <source srcSet={imageUrl} type="image/webp" />
          <Image active={!isHovered} src={imageUrl} />
        </picture>
        <picture>
          <source srcSet={imageUrlSecondary} type="image/webp" />
          <Image active={isHovered} src={imageUrlSecondary} />
        </picture>
      </ImageContainer>
    </Card>
  );
};

export default DynamicMentalStateCard;
