import { Serving } from '@Model';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useIsEnabledDynamicActivities } from '../../../hooks/useIsEnabledDynamicActivities';

import { RootReducerType } from '../../../reducers';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { useRequestHandler } from './useRequestHandler';

export const useTracksByGenre = (genreName: string) => {
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();
  const mentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );
  const dynamicMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.mentalState.id,
  );

  const args = isUsingDynamicActivities
    ? {
        dynamicMentalStateId: dynamicMentalStateId as string,
        mentalStateId: undefined,
      }
    : {
        mentalStateId: mentalStateId as string,
        dynamicMentalStateId: undefined,
      };
  const getTracksByGenre = useRequestHandler();

  return useQuery<Serving[], Error>(
    Queries.getTracksByGenre(genreName, (mentalStateId || dynamicMentalStateId)!),
    () => getTracksByGenre(genreName, args),
    {
      onError: () => {
        Logger.error(new Error('Error getting Tracks by Genre'));
      },
      enabled: Boolean(genreName) && (Boolean(mentalStateId) || Boolean(dynamicMentalStateId)),
    },
  );
};
