import { useIsEnabledDynamicActivities } from '../../../../hooks/useIsEnabledDynamicActivities';
import { Genres as GenresDynamic } from './GenresDynamic';
import { Genres as GenresLegacy } from './GenresLegacy';

type Props = {
  selected: string[];
  onSelect: (value: string) => void;
  activityId?: string;
};

export const Genres = (props: Props) => {
  const isDynamicActivitiesActive = useIsEnabledDynamicActivities();
  if (isDynamicActivitiesActive) {
    return <GenresDynamic {...props} />;
  }
  return <GenresLegacy {...props} />;
};
