import { useCallback, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useJoinTeam } from '../../api/modules/JoinTeam';
import { useTeamDataById } from '../../api/modules/TeamDataById';
import { useTeamSubscriptionInfo } from '../../hooks/useHasTeamSubscription';
import { useTeams } from '../../hooks/useTeams';
import { JoinTeamPresentation } from './JoinTeam.presentation';

export const JoinTeam = () => {
  const params = useParams<{ teamId: string }>();
  const requestSent = useRef(false);
  const hasJoined = useRef(false);
  const navigate = useNavigate();
  const { belongsToTeam: doesBelongToAnotherTeam } = useTeamSubscriptionInfo();
  const { list, isFetched: isDataReady } = useTeams();
  const { mutateAsync: handleJoinRequest } = useJoinTeam(params.teamId!);
  const { data: teamInfo, isLoading } = useTeamDataById(params.teamId || '');
  const doesBelongToTheTeam = useMemo(() => {
    if (!params.teamId) return false;
    return (list || []).some(team => team.id === params.teamId);
  }, [list]);

  useEffect(() => {
    if (!isDataReady || requestSent.current) return;
    if (doesBelongToTheTeam) {
      hasJoined.current = true;
      return;
    }
    if (!doesBelongToTheTeam && doesBelongToAnotherTeam) {
      return navigate('/', { replace: true });
    }
    requestSent.current = true;
    handleJoinRequest().then(() => {
      hasJoined.current = true;
    });
  }, [
    isDataReady,
    doesBelongToTheTeam,
    doesBelongToAnotherTeam,
    handleJoinRequest,
    params,
    navigate,
  ]);

  const onCancel = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  const onStartOnboarding = useCallback(() => {
    navigate('/welcome', { replace: true });
  }, [navigate]);

  return (
    <JoinTeamPresentation
      isLoadingData={!hasJoined.current || isLoading || !isDataReady}
      teamInfo={teamInfo?.team}
      onCancel={onCancel}
      onStartOnboarding={onStartOnboarding}
    />
  );
};
