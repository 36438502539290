import { useEffect, useState } from 'react';
import { LoadingIconRotatingLines } from '../../../../components/icons/LoadingIconRotatingLines';

import { CardWrapperTwo } from '../CardWrapperTwo';
import * as S from './FunBarsCard.styles';
import { useNavigate } from 'react-router-dom';
import { usePostOnboardingPath } from '../../hooks/usePostOnboardingPath';

import BrandLogo from '../../../../assets/images/brand_logo.svg';
import { Assets } from '../../../../utils/assets';
import { ONBOARDING_MENTAL_STATE_ID_KEY } from '../../constants';
import { OnboardingTestimonials } from '../../types';
import { CoreAnalytics } from '@Analytics';

export type Props = {
  onboardingMusicUrl: string | null;
  onCompleteStep: (neurotype_id?: string) => void;
  paywallBenefits?: string[];
  paywallTestimonials?: OnboardingTestimonials[];
};

type FunBar = {
  name: string;
  iconUrl: string;
  progress: number;
};

type Review = {
  author: string;
  body: string;
};

const REVIEWS: Review[] = [
  {
    author: 'evoeaer7',
    body: 'i’ve used this for years and will keep using it every day.',
  },
  {
    author: 'e.p.',
    body: "the best tool you'll ever invest in. pure art and science.",
  },
  {
    author: 'd.b.',
    body: "love brain.fm. i'm not sure i could be effective without it.",
  },
  {
    author: 'a.s.',
    body: 'absolutely surprising. totally works. just try it.',
  },
];

const FUN_BARS: FunBar[] = [
  {
    name: 'Neural Effect Level',
    iconUrl: 'https://cdn.brain.fm/images/onboarding/nelIcon.png',
    progress: 0,
  },
  {
    name: 'Music Complexity',
    iconUrl: 'https://cdn.brain.fm/images/onboarding/complexityIcon.png',
    progress: 0,
  },
  {
    name: 'Ideal Genres',
    iconUrl: 'https://cdn.brain.fm/images/onboarding/genresIcon.png',
    progress: 0,
  },
  {
    name: 'Activities',
    iconUrl: 'https://cdn.brain.fm/images/onboarding/activitiesIcon.png',
    progress: 0,
  },
];

export function FunBarsCard({
  onboardingMusicUrl,
  paywallBenefits,
  paywallTestimonials,
  onCompleteStep,
}: Props) {
  const mentalStateText = sessionStorage.getItem(ONBOARDING_MENTAL_STATE_ID_KEY) || 'focus';
  const navigate = useNavigate();
  const postOnboardingPath = usePostOnboardingPath();

  const [currentFunBars, setCurrentFunBars] = useState(FUN_BARS);
  const [currentFunBarIndex, setCurrentFunBarIndex] = useState(0);

  const [currentReviewCardForAnimation, setCurrentReviewCardForAnimation] = useState<
    'blue' | 'green'
  >('blue');
  const [blueReviewCardIndex, setBlueReviewCardIndex] = useState(0);
  const [greenReviewCardIndex, setGreenReviewCardIndex] = useState(-1);

  const [trustedByNumber, setTrustedByNumber] = useState(254043);

  const animationComplete = () => {
    navigate(postOnboardingPath, {
      replace: true,
      state: {
        from: 'Onboarding',
        onboardingMusicUrl,
        paywallBenefits,
        paywallTestimonials,
      },
    });
    onCompleteStep();
    CoreAnalytics.trackOnboardingFunBarCompleteEvent();
  };

  useEffect(() => {
    function animate() {
      const funBars = currentFunBars.map(x => ({ ...x }));
      funBars[currentFunBarIndex].progress = Math.min(
        funBars[currentFunBarIndex].progress + Math.ceil(Math.random() * 10) + 25,
        100,
      );

      if (currentFunBars[currentFunBarIndex].progress === 100) {
        if (currentFunBarIndex + 1 >= currentFunBars.length) {
          setTimeout(() => {
            animationComplete();
          }, 1000);
        } else {
          setCurrentFunBarIndex(currentFunBarIndex + 1);
        }
      }

      setCurrentFunBars(funBars);
    }

    setTimeout(animate, Math.ceil(Math.random() * 600) + 300);
  }, [currentFunBars[currentFunBarIndex].progress]);

  useEffect(() => {
    setTimeout(
      () => {
        setTrustedByNumber(trustedByNumber + 1);
      },
      Math.ceil(Math.random() * 5) + 3000,
    );
    CoreAnalytics.trackOnboardingFunBarImpressionEvent();
  }, []);

  useEffect(() => {
    function animateReviews() {
      if (currentReviewCardForAnimation === 'blue') {
        setGreenReviewCardIndex((greenReviewCardIndex + 2) % REVIEWS.length);
        setCurrentReviewCardForAnimation('green');
      } else {
        setBlueReviewCardIndex((blueReviewCardIndex + 2) % REVIEWS.length);
        setCurrentReviewCardForAnimation('blue');
      }
    }

    setTimeout(animateReviews, 5000);
  }, [currentReviewCardForAnimation]);

  return (
    <S.Container>
      <S.Content>
        <S.HeaderContainer>
          <S.Logo src={BrandLogo} />
        </S.HeaderContainer>
        <CardWrapperTwo height="auto">
          <S.ContentWrapper>
            <S.TitleWrapper>
              <S.Title>{`Personalizing your ${mentalStateText} sounds...`}</S.Title>
            </S.TitleWrapper>
            <S.FunBarsWrapper>
              {currentFunBars.map(funBar => (
                <S.FunBarWrapper key={funBar.name} disabled={funBar.progress === 0}>
                  <S.FunBarLeftWrapper>
                    {funBar.progress === 100 ? (
                      <S.CompletionIcon
                        key={funBar.name}
                        isCompleted
                        src={Assets.icons.taskFilled.url}
                      />
                    ) : (
                      <S.CompletionIcon
                        key={funBar.name}
                        isCompleted={false}
                        src={Assets.icons.taskUnfilled.url}
                      />
                    )}
                  </S.FunBarLeftWrapper>
                  <S.FunBarRightWrapper>
                    <S.FunBarHeadingWrapper>
                      <S.FunBarName>{funBar.name}</S.FunBarName>
                    </S.FunBarHeadingWrapper>
                    <S.FunBarProgressWrapper>
                      <S.FunBarProgressBase />
                      <S.FunBarProgress progress={funBar.progress} />
                    </S.FunBarProgressWrapper>
                  </S.FunBarRightWrapper>
                </S.FunBarWrapper>
              ))}
            </S.FunBarsWrapper>
          </S.ContentWrapper>
          <S.LoaderContainer>
            <LoadingIconRotatingLines />
          </S.LoaderContainer>
        </CardWrapperTwo>
      </S.Content>
    </S.Container>
  );
}
