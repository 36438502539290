import { STRIPE_KEY } from '@Globals';
import { BaseStripeElementsOptions, Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import { Logger } from '../logger';

class StripeClass {
  private instance: Stripe | null = null;
  constructor() {
    loadStripe(STRIPE_KEY)
      .then(instance => {
        this.instance = instance;
      })
      .catch(error => {
        Logger.error(error);
      });
  }
  getInstance() {
    return this.instance;
  }
}
export const ELEMENT_OPTIONS: BaseStripeElementsOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap',
    },
    {
      family: 'TTNormsPro-Regular',
      src: `url('/fonts/TTNormsPro-Regular.ttf') format('truetype')`,
      weight: '400',
    },
    {
      family: 'TTNormsPro-Medium',
      src: `url('/fonts/TTNormsPro-Medium.ttf') format('truetype')`,
      weight: '500',
    },
    {
      family: 'TTNormsPro-Bold',
      src: `url('/fonts/TTNormsPro-Bold.ttf') format('truetype')`,
      weight: '700',
    },
  ],
};

export default new StripeClass();
