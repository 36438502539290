import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2rem 3rem 0rem 3rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-items: center;
  max-width: 50rem;
  width: 100%;

  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Logo = styled.img`
  height: 3.5rem;
  width: 3.5rem;
`;

export const LogoPlaceholder = styled(Logo)`
  opacity: 0;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 1rem;
  text-align: center;
  margin-top: 2.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    line-height: 120%;
    font-size: 32px;
    margin-top: 1.5rem;
  }
`;

export const Body = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  line-height: 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.75);
`;

export const StepSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  gap: 0.5rem;
`;

export const StepText = styled.div`
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.75);

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    margin-top: 0.5rem;
    font-size: 1rem;
  }
`;

export const StepPills = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StepPill = styled.div<{
  isActive: boolean;
  isCompleted: boolean;
}>`
  background: ${({ theme, isActive, isCompleted }) =>
    isActive ? `transparent` : isCompleted ? theme.colors.white : `rgba(255, 255, 255, 0.2)`};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, isActive, isCompleted }) =>
    isActive ? theme.colors.white : `transparent`};
  border-radius: 0.5rem;
  height: 0.5rem;
  margin-right: 0.25rem;
  width: 4rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex: 1;
    height: 8px;
    margin: 0px 3px;
    max-width: 64px;
  }
`;

export const Answers = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 35.75rem;
  width: 100%;
  margin-top: 2rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    margin-top: 1rem;
  }
`;

export const Image = styled.img<{ maxHeight: string; opacity: number }>`
  max-height: ${({ maxHeight }) => maxHeight};
  opacity: ${({ opacity }) => opacity};
  transition: opacity 2s ease-in-out;
`;

export const PrivacyNotice = styled.div`
  color: rgba(255, 255, 255, 0.75);
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  margin-top: 1rem;
`;

export const PrivacyNoticeLinkWrapper = styled.span`
  cursor: pointer;
`;

export const PrivacyNoticeLink = styled.a`
  color: rgba(255, 255, 255, 0.75);
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-self: center;
  margin-top: 1rem;
  width: 20.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    width: 100%;
    background-color: #000000;
    padding: 1rem;
  }
`;
