import { EffectLevels, MentalState } from '@Model';

import { TimerMode } from '../Session/components/TimerSettings/hooks/useTrackTimerModeChange';

export type CoreAnalyticsEvent =
  | ApplicationEnterEvent
  | ApplicationLeaveEvent
  | DeepLinkOpenChromeIosEvent
  | DislikeTrackEvent
  | FavoriteTrackEvent
  | FilterPreferencesChangedEvent
  | FilterPreferencesOpenedEvent
  | LoopTrackEvent
  | MinutesListenedEvent
  | OnboardingCardImpressionEvent
  | OnboardingContinueEvent
  | OnboardingCompleteEvent
  | OnboardingFunbarCompleteEvent
  | OnboardingFunbarImpressionEvent
  | OnboardingInitiateEvent
  | OnboardingMentalStateEvent
  | OnboardingMentalStateSelectEvent
  | OnboardingQuizAnswerEvent
  | OnboardingQuizQuestionImpressionEvent
  | OnboardingSkipEvent
  | PreviousTrackEvent
  | ReferralLinkCopiedEvent
  | SelectTrackEvent
  | ShareTrackEvent
  | SignInAttemptEvent
  | SignInErrorEvent
  | SignInSuccessEvent
  | SignUpAttemptEvent
  | SignUpErrorEvent
  | SignUpSuccessEvent
  | SkipTrackEvent
  | SubscriptionImpressionEvent
  | SubscriptionPlanChangeErrorEvent
  | SubscriptionPlanChangeSuccessEvent
  | SubscriptionPlanLeaveEvent
  | SubscriptionPlanSelectEvent
  | SubscriptionStartAttemptEvent
  | SubscriptionStartErrorEvent
  | SubscriptionStartSuccessEvent
  | TimerSettingsOpenedEvent
  | TimerStartEvent
  | TrackStartEvent;

export enum CoreAnalyticsEventTypes {
  ApplicationEnter = 'poc_application_enter',
  ApplicationLeave = 'poc_application_leave',
  DeepLinkOpenChromeIos = 'core_deep_link_open_chrome_ios',
  DislikeTrack = 'core_dislike_track',
  FavoriteTrack = 'core_favorite_track',
  FilterPreferencesChanged = 'core_filter_preferences_changed',
  FilterPreferencesOpened = 'core_filter_preferences_opened',
  LoopTrack = 'core_loop_track',
  MinutesListened = 'core_minutes_listened',
  OnboardingMentalStateImpression = 'core_onboarding_mental_state_impression',
  OnboardingMentalStateSelect = 'core_onboarding_mental_state_select',
  OnboardingQuizQuestionImpression = 'core_onboarding_quiz_question_impression',
  OnboardingQuizAnswer = 'core_onboarding_quiz_answer',
  OnboardingCardImpression = 'core_onboarding_card_impression',
  OnboardingComplete = 'core_onboarding_complete',
  OnboardingFunbarComplete = 'core_onboarding_funbar_complete',
  OnboardingFunbarImpression = 'core_onboarding_funbar_impression',
  OnboardingInitiate = 'core_onboarding_initiate',
  OnboardingContinue = 'core_onboarding_continue',
  OnboardingSkip = 'core_onboarding_skip',
  OnboardingFunBarImpression = 'core_onboarding_funbar_impression',
  OnboardingFunBarComplete = 'core_onboarding_funbar_complete',
  PreviousTrack = 'core_previous_track',
  ReferralLinkCopied = 'core_referral_link_copied',
  SelectTrack = 'core_select_track',
  ShareTrack = 'core_share_track',
  SignInAttempt = 'core_sign_in_attempt',
  SignInError = 'core_sign_in_error',
  SignInSuccess = 'core_sign_in_success',
  SignUpAttempt = 'core_sign_up_attempt',
  SignUpError = 'core_sign_up_error',
  SignUpSuccess = 'core_sign_up_success',
  SkipTrack = 'core_skip_track',
  SubscriptionImpression = 'core_subscription_impression',
  SubscriptionPlanLeaveEvent = 'core_subscription_plan_leave',
  SubscriptionPlanSelectEvent = 'core_subscription_plan_select',
  SubscriptionPlanChangeError = 'core_subscription_plan_change_error',
  SubscriptionPlanChangeSuccess = 'core_subscription_plan_change_success',
  SubscriptionStartAttempt = 'core_subscription_start_attempt',
  SubscriptionStartError = 'core_subscription_start_error',
  SubscriptionStartSuccess = 'core_subscription_start_success',
  TimerSettingsOpened = 'core_timer_settings_opened',
  TimerStart = 'core_timer_start',
  TrackStart = 'core_track_start',
}

export enum CoreAnalyticsEventProperties {
  Activity = 'core_activity',
  Complexity = 'core_complexity',
  CouponId = 'core_coupon_id',
  ErrorMessage = 'core_error_message',
  FilterGenre = 'core_filter_genre',
  FilterNel = 'core_filter_nel',
  FilterType = 'core_filter_type',
  MentalState = 'core_mental_state',
  NeurotypeId = 'core_neurotype_id',
  NewFilterActivity = 'core_new_filter_activity',
  NewFilterGenre = 'core_new_filter_genre',
  NewFilterNel = 'core_new_filter_nel',
  NewPlanType = 'core_new_plan_type',
  OldFilterActivity = 'core_old_filter_activity',
  OldFilterGenre = 'core_old_filter_genre',
  OldFilterNel = 'core_old_filter_nel',
  OldPlanType = 'core_old_plan_type',
  OnboardingAnswer = 'core_onboarding_answer',
  OnboardingBody = 'core_onboarding_body',
  OnboardingCardIndex = 'core_onboarding_card_index',
  OnboardingCardTitle = 'core_onboarding_card_title',
  OnboardingModule = 'core_onboarding_module',
  OnboardingOrigin = 'core_onboarding_origin',
  OnboardingTitle = 'core_onboarding_title',
  OnboardingStep = 'core_onboarding_step',
  OnboardingVariation = 'core_onboarding_variation',
  Origin = 'core_origin',
  PlanType = 'core_plan_type',
  SignInMethod = 'core_sign_in_method',
  SignUpLocation = 'core_sign_up_location',
  SignUpMethod = 'core_sign_up_method',
  TimerLength = 'core_timer_length',
  TimerMode = 'core_timer_mode',
  TrackGenre = 'core_track_genre',
  TrackName = 'core_track_name',
  TrackNel = 'core_track_nel',
}

export type OnboardingModules = 'info_card' | 'quiz' | 'quiz_results';
export type OnboardingOrigins = 'direct' | 'sign_up' | 'settings';
export type SignUpMethods = 'apple' | 'email' | 'facebook' | 'google';

export enum PlanTypes {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export enum TrackActionOrigins {
  Explore = 'explore',
  Favorites = 'favorites',
  Recents = 'recents',
  NewTracks = 'newly_released_tracks',
  Search = 'search',
  SimilarTracks = 'similar_tracks',
  TrackPlayer = 'track_player',
  Recommended = 'recommended_track',
}

export enum SubscriptionImpressionOrigin {
  MenuSubscriptionPage = 'menu_subscription_page',
  Player = 'player',
  SubscriptionExpiredState = 'subscription_expired_state',
  Direct = 'direct',
}

export enum TimerOrigin {
  ActivitySelectionScreen = 'activity_selection_screen',
  Player = 'player',
  PlayerDisplay = 'player_display',
  Library = 'library',
}

export enum FilterTypes {
  Consolidated = 'consolidated',
  Activity = 'activity',
  Genre = 'genre',
  Nel = 'nel',
}

export enum MentalStates {
  Focus = 'focus',
  Relax = 'relax',
  Sleep = 'sleep',
  Meditate = 'meditate',
}

interface BaseEvent {
  type: CoreAnalyticsEventTypes;
  payload?: {
    [CoreAnalyticsEventProperties.Activity]?: string;
    [CoreAnalyticsEventProperties.Complexity]?: EffectLevels;
    [CoreAnalyticsEventProperties.CouponId]?: string;
    [CoreAnalyticsEventProperties.ErrorMessage]?: string;
    [CoreAnalyticsEventProperties.FilterGenre]?: string;
    [CoreAnalyticsEventProperties.FilterNel]?: string;
    [CoreAnalyticsEventProperties.FilterType]?: FilterTypes;
    [CoreAnalyticsEventProperties.MentalState]?: MentalStates | string;
    [CoreAnalyticsEventProperties.NeurotypeId]?: string;
    [CoreAnalyticsEventProperties.NewFilterActivity]?: string;
    [CoreAnalyticsEventProperties.NewFilterGenre]?: string;
    [CoreAnalyticsEventProperties.NewFilterNel]?: string;
    [CoreAnalyticsEventProperties.NewPlanType]?: PlanTypes;
    [CoreAnalyticsEventProperties.OldFilterActivity]?: string;
    [CoreAnalyticsEventProperties.OldFilterGenre]?: string;
    [CoreAnalyticsEventProperties.OldFilterNel]?: string;
    [CoreAnalyticsEventProperties.OldPlanType]?: PlanTypes;
    [CoreAnalyticsEventProperties.OnboardingAnswer]?: string;
    [CoreAnalyticsEventProperties.OnboardingBody]?: string;
    [CoreAnalyticsEventProperties.OnboardingCardIndex]?: number;
    [CoreAnalyticsEventProperties.OnboardingCardTitle]?: string;
    [CoreAnalyticsEventProperties.OnboardingModule]?: OnboardingModules;
    [CoreAnalyticsEventProperties.OnboardingOrigin]?: OnboardingOrigins;
    [CoreAnalyticsEventProperties.OnboardingTitle]?: string;
    [CoreAnalyticsEventProperties.OnboardingStep]?: string;
    [CoreAnalyticsEventProperties.OnboardingVariation]?: string;
    [CoreAnalyticsEventProperties.Origin]?:
      | TrackActionOrigins
      | SubscriptionImpressionOrigin
      | TimerOrigin;
    [CoreAnalyticsEventProperties.PlanType]?: PlanTypes;
    [CoreAnalyticsEventProperties.SignInMethod]?: SignUpMethods;
    [CoreAnalyticsEventProperties.SignUpMethod]?: SignUpMethods;
    [CoreAnalyticsEventProperties.TimerLength]?: string;
    [CoreAnalyticsEventProperties.TimerMode]?: TimerMode;
    [CoreAnalyticsEventProperties.TrackGenre]?: string;
    [CoreAnalyticsEventProperties.TrackName]?: string;
    [CoreAnalyticsEventProperties.TrackNel]?: string;
  };
}

interface ApplicationEnterEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.ApplicationEnter;
}

interface ApplicationLeaveEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.ApplicationLeave;
}

interface OnboardingCardImpressionEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.OnboardingCardImpression;
  payload: {
    [CoreAnalyticsEventProperties.OnboardingTitle]: string;
    [CoreAnalyticsEventProperties.OnboardingBody]: string;
    [CoreAnalyticsEventProperties.OnboardingStep]: string;
  };
}

interface OnboardingContinueEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.OnboardingContinue;
}

interface OnboardingMentalStateEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.OnboardingMentalStateImpression;
}

interface OnboardingMentalStateSelectEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.OnboardingMentalStateSelect;
  payload: {
    [CoreAnalyticsEventProperties.MentalState]: MentalStates | string;
  };
}

interface OnboardingCompleteEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.OnboardingComplete;
  payload: {
    [CoreAnalyticsEventProperties.NeurotypeId]: string;
    [CoreAnalyticsEventProperties.OnboardingVariation]: string;
  };
}

interface OnboardingFunbarCompleteEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.OnboardingFunBarComplete;
}

interface OnboardingFunbarImpressionEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.OnboardingFunBarImpression;
}

interface OnboardingInitiateEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.OnboardingInitiate;
  payload: {
    [CoreAnalyticsEventProperties.OnboardingOrigin]: OnboardingOrigins;
    [CoreAnalyticsEventProperties.OnboardingVariation]: string;
  };
}
interface OnboardingQuizAnswerEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.OnboardingQuizAnswer;
  payload: {
    [CoreAnalyticsEventProperties.OnboardingAnswer]: string;
  };
}

interface OnboardingQuizQuestionImpressionEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.OnboardingQuizQuestionImpression;
  payload: {
    [CoreAnalyticsEventProperties.OnboardingTitle]: string;
    [CoreAnalyticsEventProperties.OnboardingBody]: string;
    [CoreAnalyticsEventProperties.OnboardingStep]: string;
  };
}

interface OnboardingSkipEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.OnboardingSkip;
  payload: {
    [CoreAnalyticsEventProperties.OnboardingCardTitle]: string;
    [CoreAnalyticsEventProperties.OnboardingVariation]: string;
    [CoreAnalyticsEventProperties.OnboardingCardIndex]: number;
    [CoreAnalyticsEventProperties.OnboardingModule]: OnboardingModules;
  };
}

interface SelectTrackEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SelectTrack;
  payload: {
    [CoreAnalyticsEventProperties.TrackName]: string;
    [CoreAnalyticsEventProperties.Origin]: TrackActionOrigins;
  };
}

interface SignInAttemptEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SignInAttempt;
  payload: {
    [CoreAnalyticsEventProperties.SignInMethod]: SignUpMethods;
  };
}

interface SignInErrorEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SignInError;
  payload: {
    [CoreAnalyticsEventProperties.SignInMethod]: SignUpMethods;
    [CoreAnalyticsEventProperties.ErrorMessage]: string;
  };
}

interface SignInSuccessEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SignInSuccess;
  payload: {
    [CoreAnalyticsEventProperties.SignInMethod]: SignUpMethods;
  };
}

interface SignUpAttemptEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SignUpAttempt;
  payload: {
    [CoreAnalyticsEventProperties.SignUpMethod]: SignUpMethods;
    [CoreAnalyticsEventProperties.SignUpLocation]: 'app';
  };
}

interface SignUpErrorEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SignUpError;
  payload: {
    [CoreAnalyticsEventProperties.ErrorMessage]: string;
    [CoreAnalyticsEventProperties.SignUpLocation]: 'app';
    [CoreAnalyticsEventProperties.SignUpMethod]: SignUpMethods;
  };
}

interface SignUpSuccessEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SignUpSuccess;
  payload: {
    [CoreAnalyticsEventProperties.SignUpMethod]: SignUpMethods;
    [CoreAnalyticsEventProperties.SignUpLocation]: 'app';
  };
}

interface ReferralLinkCopiedEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.ReferralLinkCopied;
}

interface SubscriptionStartAttemptEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SubscriptionStartAttempt;
  payload: {
    [CoreAnalyticsEventProperties.PlanType]: PlanTypes;
    [CoreAnalyticsEventProperties.CouponId]: string;
  };
}

interface SubscriptionStartSuccessEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SubscriptionStartSuccess;
  payload: {
    [CoreAnalyticsEventProperties.PlanType]: PlanTypes;
    [CoreAnalyticsEventProperties.CouponId]: string;
  };
}

interface SubscriptionStartErrorEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SubscriptionStartError;
  payload: {
    [CoreAnalyticsEventProperties.ErrorMessage]: string;
  };
}

interface SubscriptionPlanChangeSuccessEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SubscriptionPlanChangeSuccess;
  payload: {
    [CoreAnalyticsEventProperties.OldPlanType]: PlanTypes;
    [CoreAnalyticsEventProperties.NewPlanType]: PlanTypes;
  };
}

interface SubscriptionPlanChangeErrorEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SubscriptionPlanChangeError;
  payload: {
    [CoreAnalyticsEventProperties.ErrorMessage]: string;
  };
}

interface SubscriptionPlanLeaveEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SubscriptionPlanLeaveEvent;
}

interface SubscriptionPlanSelectEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SubscriptionPlanSelectEvent;
  payload: {
    [CoreAnalyticsEventProperties.PlanType]: PlanTypes;
  };
}

interface SubscriptionImpressionEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SubscriptionImpression;
  payload: {
    [CoreAnalyticsEventProperties.CouponId]?: string;
    [CoreAnalyticsEventProperties.Origin]: SubscriptionImpressionOrigin;
  };
}

interface FilterPreferencesOpenedEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.FilterPreferencesOpened;
  payload: {
    [CoreAnalyticsEventProperties.FilterType]: FilterTypes;
    [CoreAnalyticsEventProperties.MentalState]: MentalStates;
  };
}

interface FilterPreferencesChangedEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.FilterPreferencesChanged;
  payload: {
    [CoreAnalyticsEventProperties.FilterType]: FilterTypes;
    [CoreAnalyticsEventProperties.MentalState]: MentalStates;
    [CoreAnalyticsEventProperties.NewFilterActivity]?: string;
    [CoreAnalyticsEventProperties.OldFilterActivity]?: string;
    [CoreAnalyticsEventProperties.NewFilterGenre]?: string;
    [CoreAnalyticsEventProperties.OldFilterGenre]?: string;
    [CoreAnalyticsEventProperties.NewFilterNel]?: string;
    [CoreAnalyticsEventProperties.OldFilterNel]?: string;
  };
}

interface TimerSettingsOpenedEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.TimerSettingsOpened;
  payload: {
    [CoreAnalyticsEventProperties.Activity]: string;
    [CoreAnalyticsEventProperties.MentalState]: MentalStates;
    [CoreAnalyticsEventProperties.TrackGenre]: string;
    [CoreAnalyticsEventProperties.FilterGenre]: string;
    [CoreAnalyticsEventProperties.TrackNel]: string;
    [CoreAnalyticsEventProperties.FilterNel]: string;
    [CoreAnalyticsEventProperties.Origin]: TimerOrigin;
  };
}

interface TimerStartEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.TimerStart;
  payload: {
    [CoreAnalyticsEventProperties.TimerMode]: TimerMode;
    [CoreAnalyticsEventProperties.Activity]: string;
    [CoreAnalyticsEventProperties.MentalState]: MentalStates;
    [CoreAnalyticsEventProperties.TrackGenre]: string;
    [CoreAnalyticsEventProperties.FilterGenre]: string;
    [CoreAnalyticsEventProperties.TrackNel]: string;
    [CoreAnalyticsEventProperties.FilterNel]: string;
    [CoreAnalyticsEventProperties.TimerLength]: string;
    [CoreAnalyticsEventProperties.Origin]: TimerOrigin;
  };
}

interface ShareTrackEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.ShareTrack;
  payload: {
    [CoreAnalyticsEventProperties.TimerMode]: TimerMode;
    [CoreAnalyticsEventProperties.Activity]: string;
    [CoreAnalyticsEventProperties.MentalState]: MentalStates;
    [CoreAnalyticsEventProperties.TrackGenre]: string;
    [CoreAnalyticsEventProperties.FilterGenre]: string;
    [CoreAnalyticsEventProperties.TrackNel]: string;
    [CoreAnalyticsEventProperties.FilterNel]: string;
    [CoreAnalyticsEventProperties.Complexity]: EffectLevels;
    [CoreAnalyticsEventProperties.TrackName]: string;
  };
}

interface TrackStartEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.TrackStart;
  payload: {
    [CoreAnalyticsEventProperties.TimerMode]: TimerMode;
    [CoreAnalyticsEventProperties.Activity]: string;
    [CoreAnalyticsEventProperties.MentalState]: MentalStates | string; // string for dynamic
    [CoreAnalyticsEventProperties.TrackGenre]: string;
    [CoreAnalyticsEventProperties.FilterGenre]: string;
    [CoreAnalyticsEventProperties.TrackNel]: string;
    [CoreAnalyticsEventProperties.FilterNel]: string;
    [CoreAnalyticsEventProperties.Complexity]: EffectLevels;
    [CoreAnalyticsEventProperties.TrackName]: string;
  };
}

interface PreviousTrackEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.PreviousTrack;
  payload: {
    [CoreAnalyticsEventProperties.TimerMode]: TimerMode;
    [CoreAnalyticsEventProperties.Activity]: string;
    [CoreAnalyticsEventProperties.MentalState]: MentalStates;
    [CoreAnalyticsEventProperties.TrackGenre]: string;
    [CoreAnalyticsEventProperties.FilterGenre]: string;
    [CoreAnalyticsEventProperties.TrackNel]: string;
    [CoreAnalyticsEventProperties.FilterNel]: string;
    [CoreAnalyticsEventProperties.Complexity]: EffectLevels;
    [CoreAnalyticsEventProperties.TrackName]: string;
  };
}

interface SkipTrackEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.SkipTrack;
  payload: {
    [CoreAnalyticsEventProperties.TimerMode]: TimerMode;
    [CoreAnalyticsEventProperties.Activity]: string;
    [CoreAnalyticsEventProperties.MentalState]: MentalStates;
    [CoreAnalyticsEventProperties.TrackGenre]: string;
    [CoreAnalyticsEventProperties.FilterGenre]: string;
    [CoreAnalyticsEventProperties.TrackNel]: string;
    [CoreAnalyticsEventProperties.FilterNel]: string;
    [CoreAnalyticsEventProperties.Complexity]: EffectLevels;
    [CoreAnalyticsEventProperties.TrackName]: string;
  };
}

interface MinutesListenedEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.MinutesListened;
  payload: {
    [CoreAnalyticsEventProperties.TimerMode]: TimerMode;
    [CoreAnalyticsEventProperties.Activity]: string;
    [CoreAnalyticsEventProperties.MentalState]: MentalStates | string; // string for dynamic
    [CoreAnalyticsEventProperties.TrackGenre]: string;
    [CoreAnalyticsEventProperties.FilterGenre]: string;
    [CoreAnalyticsEventProperties.TrackNel]: string;
    [CoreAnalyticsEventProperties.FilterNel]: string;
    [CoreAnalyticsEventProperties.Complexity]: EffectLevels;
    [CoreAnalyticsEventProperties.TrackName]: string;
  };
}

interface FavoriteTrackEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.FavoriteTrack;
  payload: {
    [CoreAnalyticsEventProperties.Activity]: string;
    [CoreAnalyticsEventProperties.Complexity]: EffectLevels;
    [CoreAnalyticsEventProperties.MentalState]: MentalStates;
    [CoreAnalyticsEventProperties.TrackGenre]: string;
    [CoreAnalyticsEventProperties.TrackName]: string;
    [CoreAnalyticsEventProperties.TrackNel]: string;
    [CoreAnalyticsEventProperties.Origin]: TrackActionOrigins;
  };
}

interface DislikeTrackEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.DislikeTrack;
  payload: {
    [CoreAnalyticsEventProperties.Activity]: string;
    [CoreAnalyticsEventProperties.MentalState]: MentalStates;
    [CoreAnalyticsEventProperties.TrackGenre]: string;
    [CoreAnalyticsEventProperties.TrackNel]: string;
    [CoreAnalyticsEventProperties.Complexity]: EffectLevels;
    [CoreAnalyticsEventProperties.TrackName]: string;
  };
}

interface LoopTrackEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.LoopTrack;
  payload: {
    [CoreAnalyticsEventProperties.Activity]: string;
    [CoreAnalyticsEventProperties.MentalState]: MentalStates;
    [CoreAnalyticsEventProperties.TrackGenre]: string;
    [CoreAnalyticsEventProperties.FilterGenre]: string;
    [CoreAnalyticsEventProperties.TrackNel]: string;
    [CoreAnalyticsEventProperties.FilterNel]: string;
    [CoreAnalyticsEventProperties.Complexity]: EffectLevels;
    [CoreAnalyticsEventProperties.TrackName]: string;
  };
}
interface DeepLinkOpenChromeIosEvent extends BaseEvent {
  type: CoreAnalyticsEventTypes.DeepLinkOpenChromeIos;
}
