import { ForgotPasswordFormValues } from '@Authentication';
import { Paper, Form, Icon } from '@Cortex';
import { FormikConfig } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import BrandLogo from '../../../../assets/images/brand_logo.svg';
import { FormActionText } from '../../../../components/forms';
import { FORM_PADDING } from '../../constants';
import * as S from './ForgotPasswordScreen.styles';

type FormInput<Values> = {
  label: string;
  name: Extract<keyof Values, string>;
  placeholder: string;
  type: string;
};

export type Props<Values> = {
  formError: string | null;
  formInitialValues: Values;
  formInputs: FormInput<Values>[];
  formValidationSchema: Yup.ObjectSchema<ForgotPasswordFormValues>;
  isLoading: boolean;
  onClickSignIn: () => void;
  onClickSignInAnalyticsEvent: string;
  onFormChange?: () => void;
  onFormSubmit: FormikConfig<Values>['onSubmit'];
  onFormSubmitAnalyticsEvent: string;
};

export function ForgotPasswordScreenDisplay<Values extends { [key: string]: string }>(
  props: Props<Values>,
) {
  return (
    <S.ForgotPasswordForm>
      <S.Header>
        <S.IconContainer>
          <Icon size={67} src={BrandLogo} />
        </S.IconContainer>
      </S.Header>
      <S.HeaderText size="3rem">Forgot Your Password?</S.HeaderText>
      <S.FormWrapper>
        <Paper padding={FORM_PADDING}>
          <S.Form>
            <Form
              formError={props.formError}
              formInitialValues={props.formInitialValues}
              formInputs={props.formInputs}
              formValidationSchema={props.formValidationSchema}
              isLoading={props.isLoading}
              submitButtonText="Email me"
              onFormChange={props.onFormChange}
              onFormSubmit={props.onFormSubmit}
              onFormSubmitAnalyticsEvent={props.onFormSubmitAnalyticsEvent}
            />
          </S.Form>
          <S.Links>
            <S.LinkLast>
              <FormActionText
                actionText="Click here"
                analyticsEvent={props.onClickSignInAnalyticsEvent}
                text="Ready to sign in?"
                onClick={props.onClickSignIn}
              />
            </S.LinkLast>
          </S.Links>
        </Paper>
      </S.FormWrapper>
    </S.ForgotPasswordForm>
  );
}
