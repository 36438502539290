import styled from 'styled-components';

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    flex-direction: column;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  margin-right: 3.15rem;
  margin-bottom: 1rem;
`;

export const InfoContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const UserPropertyContainer = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const Separator = styled.hr`
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 1.5rem 0;
`;

export const AccountActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DeleteAccount = styled.div`
  opacity: 0.5;
`;
