import styled, { css } from 'styled-components';

export const Container = styled.div<{ isExpanded: boolean }>`
  position: relative;
  height: 2.5rem;
  width: ${({ isExpanded }) => (isExpanded ? '100%' : '40px')};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.6s ease-in-out;
`;

export const Input = styled.input`
  height: 100%;
  width: 100%;
  background: red;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  color: #fff;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  background: rgba(25, 23, 54, 0.5);
  backdrop-filter: blur(34px);
  border-radius: 0.75rem;
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
`;

export const IconButton = styled.button<{ isExpanded: boolean }>`
  border: none;
  background: transparent;
  cursor: pointer;
  width: 16px;
  height: 16px;
  object-fit: contain;
  position: absolute;
  right: 0;
  transform: ${({ isExpanded }) => (isExpanded ? 'translateX(-20px)' : 'translateX(-75%)')};
  transition: transform 0.3s ease-in-out;
`;
