import { SelectHTMLAttributes } from 'react';
import styled from 'styled-components';

const StyledSelect = styled.select`
  color: white;
  background-color: transparent;
  font-size: ${({ theme }) => theme.fontSize.medium};
  border: none;
  outline: none;
  &:hover {
    cursor: pointer;
    outline: none;
  }
`;

interface Option {
  value: any;
  displayName: string;
}

type SelectProps = SelectHTMLAttributes<any> & {
  options: Option[];
};

const Select = (props: SelectProps) => {
  const { options, ...otherProps } = props;

  return (
    <StyledSelect {...otherProps}>
      {options.map((option: Option, i: number) => {
        return (
          <option key={i} value={option.value}>
            {option.displayName}
          </option>
        );
      })}
    </StyledSelect>
  );
};

export default Select;
