import { CoreAnalytics } from '@Analytics';
import { ButtonVariantStates } from '@Cortex';
import { REFER_A_FRIEND_LINK } from '@Globals';
import { useState } from 'react';

import { Analytics } from '../../../utils/analytics';
import { Logger } from '../../../utils/logger';
import { ReferDisplay } from './Refer.display';

// https://github.com/Microsoft/TypeScript/issues/26728#issuecomment-422970152
// workaround for navigator.clipboard not beign available in CI
interface Clipboard {
  writeText(newClipText: string): Promise<void>;
  // Add any other methods you need here.
}

interface NavigatorClipboard {
  // Only available in a secure context.
  readonly clipboard?: Clipboard;
}

interface WorkerNavigator extends NavigatorClipboard {}

export const Refer = () => {
  const [buttonState, setButtonState] = useState({
    text: 'Copy refer link',
    state: ButtonVariantStates.Idle,
  });

  const handleCopyLink = async () => {
    try {
      if (window.navigator?.clipboard) {
        await window.navigator.clipboard.writeText(REFER_A_FRIEND_LINK as string);
        Analytics.logEvent('profile_refer_a_friend_copy_link');
        CoreAnalytics.trackReferralLinkCopied();
        setButtonState({ text: 'Copied!', state: ButtonVariantStates.Success });
      } else {
        Logger.error(new Error('Clipboard API not supported'));
      }
    } catch (error) {
      Logger.error(new Error('Failed to copy refer link to clipboard'), { reason: error });
      setButtonState({ text: 'Error!', state: ButtonVariantStates.Error });
    } finally {
      setTimeout(() => {
        setButtonState({ text: 'Copy refer link', state: ButtonVariantStates.Idle });
      }, 1500);
    }
  };

  return (
    <ReferDisplay
      buttonState={buttonState}
      headerText="Share the power of brain.fm"
      subtitleText="Send this link to anyone and they’ll get their first month of brain.fm for only $1!"
      title="Refer a friend"
      onButtonClick={handleCopyLink}
    />
  );
};
