import { AnalyticsEvents } from '../../types';

type Params = {
  audioElement: Pick<HTMLAudioElement, 'paused'> | null;
  cohort: string;
  getCurrentTrackMetaData?: () => { [key: string]: string | boolean };
  shouldIncludeDebugInformation?: boolean;
  logger: (eventName: AnalyticsEvents, eventData: any) => void;
};

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;

export function createTrackListeningMinutes(params: Params): () => void {
  let timerDebugId = Date.now().toString(36);
  let lastCheck = Date.now();
  let lastPublish = Date.now();
  let timePausedInMS = 0;

  return function trackListeningMinutes() {
    const timeSinceLastCheck = Date.now() - lastCheck;
    const timeSinceLastPublish = Date.now() - lastPublish;
    const timeListeningInMS = timeSinceLastPublish - timePausedInMS;

    const isPaused = Boolean(params.audioElement?.paused);
    const hasListenedForFiveMinutes = timeListeningInMS >= FIVE_MINUTES_IN_MS;

    if (isPaused) {
      timePausedInMS += timeSinceLastCheck;
    } else if (hasListenedForFiveMinutes) {
      params.logger('poc_minutes_listened', {
        cohort: params.cohort,
        minutes: timeListeningInMS / 1000 / 60,
        ...(params.getCurrentTrackMetaData ? params.getCurrentTrackMetaData() : {}),
        ...(params.shouldIncludeDebugInformation
          ? {
              timeListeningInMS,
              timePausedInMS,
              timeSinceLastCheck,
              timeSinceLastPublish,
              timerDebugId,
            }
          : {}),
      });
      lastPublish = Date.now();
      timePausedInMS = 0;
    }

    lastCheck = Date.now();
  };
}
