import { MembershipWidget } from '../../../Memberships';
import * as S from './AudioControlBar.styles';
import { AudioControls } from './components/AudioControls';

export const AudioControlBar = () => {
  return (
    <S.AudioControlWrapper>
      <S.MembershipWidgetWrapper>
        <MembershipWidget />
      </S.MembershipWidgetWrapper>
      <AudioControls />
    </S.AudioControlWrapper>
  );
};
