import styled from 'styled-components';

import { ReactComponent as CloseSvg } from './assets/close.svg';

export const OnboardingCard = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  min-height: 600px;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    min-height: initial;
  }
`;

export const OnboardingCardImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 100%;
  }
`;

export const OnboardingCardTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 100%;
  }
`;

export const StudyImage = styled.img`
  position: absolute;
  top: -10%;
  left: -40%;
  transition: opacity 0.15s ease-in-out;
  width: 150%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    bottom: 40%;
    left: -60%;
    opacity: 0.6;
    position: relative;
    top: auto;
    width: 200%;
  }
`;

export const GraphImage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-right: 2rem;
  height: 100%;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    margin-bottom: 2rem;
  }
`;

export const TimerImage = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-right: 2rem;
  position: relative;
  left: 1rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    margin-bottom: 2rem;
  }
`;

export const Image = styled.img`
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 100%;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 1;
`;

export const Subtitle = styled.div`
  color: #d4d2ea;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  letter-spacing: 0.2em;
  margin-bottom: 0.75rem;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 2rem;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 1rem;
`;

export const Body = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  margin-bottom: 0.75rem;
  line-height: 1.5rem;
`;

export const Buttons = styled.div`
  bottom: 5rem;
  display: flex;
  position: absolute;
  right: 2rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    bottom: auto;
    justify-content: flex-end;
    position: relative;
    right: auto;
    width: 100%;
  }
`;

export const SkipButton = styled.div`
  display: flex;
  margin-right: 1rem;
`;

export const FixedHeight = styled.div`
  height: 30rem;
`;

export const StepPills = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 3.5rem;
  width: 100%;
`;

export const StepPill = styled.div<{ isActive?: boolean }>`
  background: ${({ theme, isActive }) =>
    isActive
      ? 'linear-gradient(60.85deg, #4915F8 -89.64%, #FF496B 126.05%)'
      : theme.colors.white300};
  border-radius: 0.5rem;
  height: 0.5rem;
  margin-right: 0.25rem;
  width: ${({ isActive }) => (isActive ? '2rem' : '1rem')};
`;
