import { Icon } from '@Cortex';
import { HTMLAttributes } from 'react';

import UserIcon from '../../../../assets/images/user_icon.svg';
import * as S from './ProfileButton.styles';

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const ProfileButton = (props: Props) => {
  return (
    <S.Container {...props} data-testid="profile-button">
      <Icon isSquare size={32} src={UserIcon} />
    </S.Container>
  );
};
