import { showToast } from '@Utils';
import { Action } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import { RootReducerType } from 'src/reducers';

import { RequestMethods } from '../../../api/client/types';
import { requestSaga } from '../../../sagas/httpRequest';
import { Logger } from '../../../utils/logger';
import { removeFromDislikes } from '../actions';
import { musicActions } from '../reducer';

type Response = {
  status: number;
  result: { trackId: string }[];
};

export function* removeFromDislikesSaga(action: Action) {
  if (removeFromDislikes.match(action)) {
    const dislikeIds: RootReducerType['music']['dislikedTrackIds'] = yield select(
      (state: RootReducerType) => state.music.dislikedTrackIds,
    );

    try {
      yield put(musicActions.removeFromDislikes(action.payload.trackId));

      const { info }: RootReducerType['user'] = yield select(
        (state: RootReducerType) => state.user,
      );

      showToast("We'll start playing this track again!");

      const response = (yield requestSaga(
        RequestMethods.DELETE,
        `/users/${info?.id}/dislikes?version=3`,
        {
          trackId: action.payload.trackId,
        },
      )) as unknown as Response;

      if (response.status !== 200) {
        yield put(
          musicActions.receiveDislikes({
            dislikedTrackIds: [action.payload.trackId, ...dislikeIds],
          }),
        );
      }
    } catch (error) {
      yield put(
        musicActions.receiveDislikes({
          dislikedTrackIds: [action.payload.trackId, ...dislikeIds],
        }),
      );
      Logger.error(error);
    }
  }
}
