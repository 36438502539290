import React, { useState, useCallback, useEffect } from 'react';

import { useGiftCardInfo } from '../../../../api/modules/GiftCardInfo';
import { GiftCardType } from '../../../../api/modules/GiftCardInfo/types';
import { useRedeemGiftCard } from '../../../../api/modules/RedeemGiftCard';
import { GiftCardPresentation } from './GiftCard.presentation';

export const GiftCard = () => {
  const [cardCode, setCardCode] = useState('');
  const [cardDetails, setCardDetails] = useState<GiftCardType | null>(null);
  const [cardErrorMessage, setCardErrorMessage] = useState('');
  const [redeemErrorMessage, setRedeemErrorMessage] = useState('');
  const {
    refetch: fetchCardData,
    data: cardData,
    isRefetching: isLoadingCardData,
    isInitialLoading: isInitialLoadingCardData,
  } = useGiftCardInfo(cardCode);

  const {
    mutateAsync: handleRedeemCard,
    isLoading: isRedeeming,
    data: redeemData,
  } = useRedeemGiftCard(cardCode);

  useEffect(() => {
    if (cardData?.result) {
      setCardDetails(cardData.result);
      return;
    }
    if (cardData?.messages) {
      setCardErrorMessage(cardData.messages[0]);
      return;
    }
  }, [cardData, setCardDetails, setCardErrorMessage]);

  useEffect(() => {
    if (redeemData?.messages) {
      setRedeemErrorMessage(redeemData?.messages[0] || 'Something went wrong');
    }
  }, [redeemData]);

  const handleFetchCardOnEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter') return;
      fetchCardData();
    },
    [fetchCardData],
  );
  const handleFetchCard = useCallback(() => {
    fetchCardData();
  }, [fetchCardData]);

  const handleChangeCode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newValue = event.currentTarget.value;
      setCardErrorMessage('');
      setRedeemErrorMessage('');
      setCardDetails(null);
      setCardCode(newValue);
    },
    [setCardCode, setCardDetails, setCardErrorMessage],
  );

  return (
    <GiftCardPresentation
      cardCode={cardCode}
      cardDetails={cardDetails}
      cardErrorMessage={cardErrorMessage}
      isLoadingCardData={isLoadingCardData}
      isRedeeming={isInitialLoadingCardData || isRedeeming}
      redeemErrorMessage={redeemErrorMessage}
      onChangeCode={handleChangeCode}
      onFetchCard={handleFetchCard}
      onFetchCardOnEnter={handleFetchCardOnEnter}
      onRedeemCard={handleRedeemCard}
    />
  );
};
