import {
  trackResetTimer,
  useIsPomodoroActive,
  useResetTimer,
  useTurnOnPomodoroTimer,
} from '@Timer';
import { userActions, UserPreferenceDisplayTypes, useUser } from '@User';
import { FeatureFlags, useFeatureFlag } from '@Utils';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as analyticsActions from '../../../../actions/analytics';
import { setSessionTimer, setSessionType } from '../../../../actions/sessionManager';
import { usePlayerAnalyticsParams } from '../../../../hooks/usePlayerAnalyticsParams';
import { RootReducerType } from '../../../../reducers';
import { trackTimerSettingsOpened } from '../../../Analytics/coreAnalytics';
import { TimerOrigin } from '../../../Analytics/coreAnalytics.types';
import { DefaultPomodoroIntervals, PomodoroIntervals } from '../../../Timer/constants';
import { useStorePomodoroIntervals } from '../../../Timer/useStorePomodoroIntervals';
import { useSession } from '../../lenses';
import { TimeModeDisplay } from './TimeMode.display';
import { TimerMode, useTrackTimerModeChange } from './hooks/useTrackTimerModeChange';

type Props = {
  onClose: () => void;
};

export function TimeMode(props: Props) {
  const dispatch = useDispatch();
  const handleTrackTimerModeChange = useTrackTimerModeChange();
  const isCustomPomodoroIntervalsActive = useFeatureFlag(FeatureFlags.CustomPomodoroIntervals);
  const customTimerLength = useSelector(
    (state: RootReducerType) => state.sessionManager.timerLength,
  );
  const isPomodoroActive = useIsPomodoroActive();
  const sessionTimeMode = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionPlayType,
  );
  const [isPomodoroSettingsOpen, setIsPomodoroSettingsOpen] = useState(isPomodoroActive);
  const [isCustomTimerSettingsOpen, setIsCustomTimerSettingsOpen] = useState(
    sessionTimeMode === 'TIMER',
  );
  const handleTogglePomodoroSettings = useCallback(() => {
    setIsPomodoroSettingsOpen(state => !state);
  }, [setIsPomodoroSettingsOpen]);
  const handleToggleIsCustomTimerSettings = useCallback(() => {
    setIsCustomTimerSettingsOpen(state => !state);
  }, [setIsCustomTimerSettingsOpen]);

  const persistedPomodoroIntervals = useSelector(
    (state: RootReducerType) => state.timer.pomodoroSettings.intervals,
  );
  const resetTimer = useResetTimer({ shouldRestartRunningTimers: true });
  const turnOnPomodoroTimer = useTurnOnPomodoroTimer();
  const storePomodoroIntervals = useStorePomodoroIntervals();
  useTrackOpenAndCloseOnMountAndDismount();

  const [customTimedSessionValue, setCustomTimedSessionValue] = useState('90');
  const [customPomodoroIntervals, setCustomPomodoroIntervals] = useState(
    persistedPomodoroIntervals,
  );

  const handleStartInfiniteSession = () => {
    dispatch(setSessionType('NORMAL'));
    dispatch(trackResetTimer());
    resetTimer();
    dispatch(
      userActions.setDefaultDisplayType({
        type: UserPreferenceDisplayTypes.Infinite,
      }),
    );
    handleTrackTimerModeChange({ timerMode: TimerMode.Infinite });
    props.onClose();
  };

  const normalizePomodoroIntervals = (intervals: PomodoroIntervals) => {
    return {
      focusTime: ['', '0'].includes(intervals.focusTime)
        ? persistedPomodoroIntervals.focusTime
        : intervals.focusTime,
      breakTime: ['', '0'].includes(intervals.breakTime)
        ? persistedPomodoroIntervals.breakTime
        : intervals.breakTime,
    };
  };

  const handleStartPomodoroSession = () => {
    dispatch(setSessionType('NORMAL'));
    const pomodoroIntervals = isCustomPomodoroIntervalsActive
      ? normalizePomodoroIntervals(customPomodoroIntervals)
      : DefaultPomodoroIntervals;
    setCustomPomodoroIntervals(pomodoroIntervals);
    storePomodoroIntervals(pomodoroIntervals);
    resetTimer();
    turnOnPomodoroTimer();
    dispatch(
      userActions.setDefaultDisplayType({
        type: UserPreferenceDisplayTypes.Pomodoro,
      }),
    );
    handleTrackTimerModeChange({
      timerMode: TimerMode.Interval,
      customTimerLength: customTimerLength,
      props: { pomodoroIntervals },
    });
    props.onClose();
  };

  const handleStartQuotesSession = () => {
    dispatch(setSessionType('QUOTES'));
    dispatch(
      userActions.setDefaultDisplayType({
        type: UserPreferenceDisplayTypes.Quotes,
      }),
    );
    handleTrackTimerModeChange({ timerMode: TimerMode.Quotes });
    props.onClose();
  };

  const handleStartTimedSession = (value: string) => {
    const timerLength = 60 * parseInt(value, 10);
    dispatch(
      setSessionTimer({
        sessionPlayType: 'TIMER',
        timerLength,
      }),
    );
    handleTrackTimerModeChange({ timerMode: TimerMode.Custom, customTimerLength: timerLength });
    props.onClose();
  };

  const handleSetCustomTimedSessionValue = (value: string) => {
    const isEmpty = value === '';
    const isValidNumber = isFinite(parseInt(value, 10));

    if (isEmpty || isValidNumber) {
      setCustomTimedSessionValue(value);
    }
  };

  const handleSetCustomPomodoroIntervals = ({
    focusTime,
    breakTime,
  }: {
    focusTime?: string;
    breakTime?: string;
  }) => {
    setCustomPomodoroIntervals(state => ({
      focusTime:
        typeof focusTime !== 'undefined' && /^(\s*|\d+)$/.test(focusTime)
          ? focusTime
          : state.focusTime,
      breakTime:
        typeof breakTime !== 'undefined' && /^(\s*|\d+)$/.test(breakTime)
          ? breakTime
          : state.breakTime,
    }));
  };

  // we'll have these confusing vars until we move quotes and pomodoro away from sessions into timer
  const isNormalTimer = sessionTimeMode === 'NORMAL' && !isPomodoroActive;
  const isPomodoroTimer = sessionTimeMode === 'NORMAL' && Boolean(isPomodoroActive);

  return (
    <TimeModeDisplay
      customPomodoroIntervals={customPomodoroIntervals}
      customTimedSessionValue={customTimedSessionValue}
      isCustomPomodoroIntervalsActive={isCustomPomodoroIntervalsActive}
      isCustomTimerSettingsOpen={isCustomTimerSettingsOpen}
      isNormalTimer={isNormalTimer}
      isPomodoroSettingsOpen={isPomodoroSettingsOpen}
      isPomodoroTimer={isPomodoroTimer}
      sessionTimeMode={sessionTimeMode}
      setCustomPomodoroIntervals={handleSetCustomPomodoroIntervals}
      setCustomTimedSessionValue={handleSetCustomTimedSessionValue}
      onStartInfiniteSession={handleStartInfiniteSession}
      onStartPomodoroSession={handleStartPomodoroSession}
      onStartQuotesSession={handleStartQuotesSession}
      onStartTimedSession={handleStartTimedSession}
      onToggleIsCustomTimerSettings={handleToggleIsCustomTimerSettings}
      onTogglePomodoroSettings={handleTogglePomodoroSettings}
    />
  );
}

function useTrackOpenAndCloseOnMountAndDismount() {
  const dispatch = useDispatch();
  const user = useUser();
  const session = useSession();
  const currentPreferences = useRef(
    session ? user.mentalStatePreferences[session.mentalState] : null,
  );
  const {
    activity,
    trackGenre,
    filterGenre,
    filterNel,
    sessionSidebarContentOrigin,
    trackNel,
    mentalState,
  } = usePlayerAnalyticsParams();

  useEffect(() => {
    if (currentPreferences.current) {
      trackTimerSettingsOpened({
        activity,
        trackGenre,
        filterGenre,
        mentalState,
        trackNel,
        filterNel,
        origin: sessionSidebarContentOrigin as TimerOrigin,
      });
    }
    dispatch(analyticsActions.playerLogEvent('player_timer_open'));

    return () => {
      dispatch(analyticsActions.playerLogEvent('player_timer_close'));
    };
  }, []);
}
