import { RootReducerType } from 'src/reducers';
import { getTrackDynamicMentalStateId } from './getTrackDynamicMentalStateId';

export function getDynamicFavorites() {
  return function selectFavorites(state: {
    music: Pick<RootReducerType['music'], 'favorites'>;
    sessionManager: Pick<
      RootReducerType['sessionManager'],
      'sessionDynamicActivity' | 'sessionMentalStateId'
    >;
  }) {
    return state.music.favorites.filter(track => {
      return (
        getTrackDynamicMentalStateId(track) ===
        state.sessionManager.sessionDynamicActivity?.mentalState.id
      );
    });
  };
}
