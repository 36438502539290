import { Button, ButtonVariants, MenuItem, MenuItemIcons } from '@Cortex';

import * as S from './CallToActionWidget.styles';

export type Props = {
  body: string;
  buttonText: string;
  'data-testid'?: string;
  icon?: MenuItemIcons;
  onClickButton: () => void;
  onClickIcon?: () => void;
};

export function CallToActionWidget({ body, buttonText, onClickButton, ...props }: Props) {
  return (
    <S.CallToActionWidget>
      <S.Body>
        <MenuItem
          icon={props.icon || MenuItemIcons.Alert}
          isActive={true}
          onClickIcon={props.onClickIcon}
        >
          {body}
        </MenuItem>
      </S.Body>
      <Button
        data-testid={props['data-testid']}
        variant={ButtonVariants.Secondary}
        onClick={onClickButton}
      >
        {buttonText}
      </Button>
    </S.CallToActionWidget>
  );
}
