import { Navigate, useSearchParams } from 'react-router-dom';

import { createSession } from '../../../actions/sessionManager';
import { setSessionSidebarContent } from '../../../actions/ui';
import { CreateSession, SessionSidebarContent } from '../../../types';
import { ExploreMusicPanes } from './ExploreMusic';

export const DeepLinkRedirector = () => {
  const [searchParams] = useSearchParams();
  const mentalState = searchParams.get('mentalState');

  /**
   * We have to start a session on redirect in order to update the session state and start playing.
   */
  if (mentalState === 'focus' || mentalState === 'relax' || mentalState === 'sleep') {
    let defaultActivity = '';
    let mentalStateId = '';

    if (mentalState === 'focus') {
      defaultActivity = '6042a1bad80ae028b821e953';
      mentalStateId = '6042a1bad80ae028b821e954';
    }
    if (mentalState === 'relax') {
      defaultActivity = '6042a1bad80ae028b821e959';
      mentalStateId = '6042a1bad80ae028b821e958';
    }
    if (mentalState === 'sleep') {
      defaultActivity = '6042a1bad80ae028b821e95b';
      mentalStateId = '6042a1bad80ae028b821e95c';
    }

    return (
      <Navigate
        replace
        state={{
          actions: [
            createSession({
              excludeNewTracks: false,
              sessionType: mentalState as CreateSession['sessionType'],
              sessionMentalStateId: mentalStateId,
              sessionActivityId: defaultActivity,
            }),
            setSessionSidebarContent({
              sessionSidebarContent: SessionSidebarContent.ExploreMusic,
              origin: 'deep_link',
            }),
          ],
          exploreTab: ExploreMusicPanes.Featured,
        }}
        to={`/${mentalState}`}
      />
    );
  }

  return <Navigate replace={true} to={`/`} />;
};
