import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import * as uiActions from '../../../actions/ui';
import * as userActions from '../../../actions/user';
import { getUiErrorMessage } from '../../../utils/getUiErrorMessage';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { TVerifyEmailResponse } from './types';
import { useRequestHandler } from './useRequestHandler';

export const useVerifyEmail = () => {
  const postVerifyEmail = useRequestHandler();
  const dispatch = useDispatch();

  return useMutation<TVerifyEmailResponse, Error, string>(
    Queries.postVerifyEmail,
    (code: string) => postVerifyEmail(code),
    {
      onError: e => {
        Logger.error(new Error(getUiErrorMessage(e, 'Error verifying email address')));
      },
      onSuccess: data => {
        if (data.status !== 200) return;
        dispatch(userActions.getInfo());
        dispatch(
          uiActions.successModalOpen({
            title: 'You have successfully verified your email address',
            actions: [{ type: 'primary', text: 'Close', action: uiActions.successModalClose() }],
          }),
        );
      },
      cacheTime: 0,
    },
  );
};
