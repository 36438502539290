import { Button, ButtonVariants, LoadingText, MenuItem, MenuItemIcons } from '@Cortex';
import React from 'react';

import * as S from './BillingPanel.styles';

export type Props = {
  cardInformation: string;
  isLoading?: boolean;
  onClickCancel?: (() => void) | null;
  onClickUpdatePaymentMethod?: (() => void) | null;
  updatePaymentButtonText?: string;
};

export function BillingPanel({
  cardInformation,
  isLoading = false,
  onClickCancel = null,
  onClickUpdatePaymentMethod = null,
  updatePaymentButtonText = 'Update Payment Method',
}: Props) {
  return (
    <S.Container>
      <S.PaymentMethod>
        <S.Title>
          {isLoading ? <LoadingText>Payment Method</LoadingText> : 'Payment Method'}
        </S.Title>
        <S.Body>
          {isLoading ? <LoadingText>{cardInformation}</LoadingText> : cardInformation}
        </S.Body>
      </S.PaymentMethod>
      {onClickUpdatePaymentMethod || onClickCancel ? (
        <div>
          {onClickUpdatePaymentMethod ? (
            <S.PaymentButton hasBottomMargin={Boolean(onClickCancel)}>
              <Button
                data-testid="updatePaymentButton"
                isFullWidth={true}
                variant={ButtonVariants.Secondary}
                onClick={onClickUpdatePaymentMethod}
              >
                {updatePaymentButtonText}
              </Button>
            </S.PaymentButton>
          ) : null}
          {onClickCancel ? (
            <Button
              data-testid="cancelSubscriptionButton"
              isFullWidth={true}
              variant={ButtonVariants.Base}
              onClick={onClickCancel}
            >
              Cancel Subscription
            </Button>
          ) : null}
        </div>
      ) : null}
    </S.Container>
  );
}
