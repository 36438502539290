import { TextBold } from '@Cortex';
import { Serving, Track } from '@Model';

import { ExploreEvents } from '../../../../../../../../utils/analytics/events';
import { ExpandableTrackCard } from '../../../../../ExpandableTrackCard';
import * as S from './TracksByGenre.styles';
import ArrowLeftIcon from './assets/arrow_left.svg';
import { getTrackId } from '@Music';

interface Props {
  genreName: string;
  onClickGoBack: () => void;
  tracks: ((Track | Serving) & {
    isDisliked: boolean;
    isFavorited: boolean;
    onAddToDislikes: () => void;
    onAddToFavorites: () => void;
    onShareTrack: () => void;
    onClickPlay: () => void;
    onRemoveFromDislikes: () => void;
    onRemoveFromFavorites: () => void;
    onVariationClickPlay: (variationId: string) => void;
  })[];
}

export const TracksByGenreDisplay = (props: Props) => (
  <S.Container>
    <S.Header>
      <S.ImageContainer onClick={props.onClickGoBack}>
        <S.Image src={ArrowLeftIcon} />
      </S.ImageContainer>
      <TextBold size="1.5rem">{props.genreName}</TextBold>
    </S.Header>

    {props.tracks?.map(track => (
      <S.TrackCardWrapper key={getTrackId(track)}>
        <ExpandableTrackCard
          analyticsEvent={ExploreEvents.toggle_track_card_expansion__explore}
          dislikeButtonTestId={
            track.isDisliked
              ? `removeFromDislikes__${getTrackId(track)}`
              : `addToDislikes__${getTrackId(track)}`
          }
          displayType="track"
          expandIconTestId={`expandButton__${getTrackId(track)}`}
          favoritesButtonTestId={`removeFromFavorites__${getTrackId(track)}`}
          isDisliked={track.isDisliked}
          isDislikingAvailable={false}
          isFavorited={track.isFavorited}
          isFavoritingAvailable={false}
          track={track}
          onAddToDislikes={track.onAddToDislikes}
          onAddToFavorites={track.onAddToFavorites}
          onClickPlay={track.onClickPlay}
          onRemoveFromDislikes={track.onRemoveFromDislikes}
          onRemoveFromFavorites={track.onRemoveFromFavorites}
          onShareTrack={track.onShareTrack}
          onVariationClickPlay={track.onVariationClickPlay}
        />
      </S.TrackCardWrapper>
    ))}
  </S.Container>
);
