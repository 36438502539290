import { useIsTrialUser } from '@Memberships';
import { ProfileMenuItemUrlPaths } from '@User';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from '../../../../actions/ui';
import { RootReducerType } from '../../../../reducers';
import { PaywallModalDisplay } from './PaywallModal.display';

export function PaywallModal() {
  const isTrialUser = useIsTrialUser();
  const { modalType } = useSelector((state: RootReducerType) => state.ui);

  return (
    <PaywallModalDisplay
      description={
        isTrialUser
          ? 'But you can keep the music flowing!'
          : "But we'd love to have you back as a member!"
      }
      isOpen={modalType === 'paywall'}
      primaryButtonAction={setModal(null)}
      primaryButtonHref={isTrialUser ? '/payment' : ProfileMenuItemUrlPaths.Account}
      primaryButtonText={isTrialUser ? 'Join brain.fm today' : 'Renew my membership'}
      title={isTrialUser ? 'Your trial has ended...' : 'Your membership has expired...'}
    />
  );
}
