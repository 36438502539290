import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { TextBold } from '@Cortex';
import { Serving } from '@Model';
import { getTrackName, getTrackId } from '@Music';
import { getTrackVariationId } from '@Music';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { trackCardLoad } from '../../../../../../actions/sessionManager';
import { useIsEnabledDynamicActivities } from '../../../../../../hooks/useIsEnabledDynamicActivities';
import { useShareTrack } from '../../../../../../hooks/useShareTrack';
import { RootReducerType } from '../../../../../../reducers';
import { Analytics } from '../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../utils/analytics/events';
import { getDynamicSimilarActivityPlayerPath } from '../../../../../../utils/getDynamicSimilarActivityPlayerPath';
import { removeFromFavorites, removeFromDislikes } from '../../../../actions';
import { ExpandableTrackCard } from '../../../ExpandableTrackCard';
import * as S from './NewTracks.styles';

type TrackDictionary = {
  [key: string]: Serving;
};

type Props = {
  tracks: Serving[];
  error: Error | null;
  isLoading: boolean;
};

export function NewTracks({ tracks, isLoading, error }: Props) {
  const { handleGetShareLink } = useShareTrack();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dislikedTrackIds = useSelector((state: RootReducerType) => state.music.dislikedTrackIds);
  const favorites = useSelector((state: RootReducerType) => state.music.favorites);
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();

  if (isLoading) {
    return <S.Loading />;
  }

  if (error) {
    return <TextBold>{error.message}</TextBold>;
  }

  const newTracks: Serving[] = [...(tracks || [])];

  const newlyReleasedTrackVariationsDictionary = newTracks.reduce((acc, track) => {
    acc[getTrackVariationId(track)] = track;
    return acc;
  }, {} as TrackDictionary);

  function handleClickPlay(track: Serving) {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromNewlyReleasedTracks, {
      trackId: getTrackId(track),
      trackVariationId: getTrackVariationId(track),
      trackName: getTrackName(track),
    } as any);

    if (isUsingDynamicActivities) {
      navigate(getDynamicSimilarActivityPlayerPath(getTrackId(track)));
    } else {
      dispatch(
        trackCardLoad({
          trackId: getTrackId(track),
          trackVariationId: getTrackVariationId(track),
          isUsingDynamicActivities,
        }),
      );
    }

    CoreAnalytics.trackSelectTrackEvent({
      origin: TrackActionOrigins.NewTracks,
      trackName: getTrackName(track),
    });
  }

  function handleClickPlayVariation(track: Serving, trackVariationId: string) {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromNewlyReleasedTracks, {
      trackId: getTrackId(track),
      trackVariationId: trackVariationId,
      trackName: getTrackName(track),
    } as any);

    dispatch(
      trackCardLoad({ trackId: getTrackId(track), trackVariationId, isUsingDynamicActivities }),
    );

    CoreAnalytics.trackSelectTrackEvent({
      origin: TrackActionOrigins.NewTracks,
      trackName: getTrackName(track),
    });
  }

  if (tracks.length === 0) {
    return null;
  }

  return (
    <S.CardsContainer>
      <S.Title size="1.5rem">just added</S.Title>

      {Object.values(newlyReleasedTrackVariationsDictionary).map(track => {
        const isFavorited = favorites.some(favorite => {
          return getTrackVariationId(favorite) === getTrackVariationId(track);
        });

        return (
          <S.CardContainer
            key={getTrackVariationId(track) || getTrackId(track)}
            data-testid="newReleaseTrackItem"
          >
            <ExpandableTrackCard
              analyticsEvent={ExploreEvents.toggle_track_card_expansion__newly_released}
              dislikeButtonTestId={`addToDislikes__${getTrackId(track)}`}
              displayType="track"
              expandIconTestId={`expandButton__${getTrackId(track)}`}
              favoritesButtonTestId={`removeFromFavorites__${getTrackId(track)}`}
              isDisliked={dislikedTrackIds.includes(getTrackId(track))}
              isDislikingAvailable={false}
              isFavorited={isFavorited}
              isFavoritingAvailable={false}
              track={track}
              onClickPlay={() => handleClickPlay(track)}
              onRemoveFromDislikes={() =>
                dispatch(
                  removeFromDislikes({
                    trackId: getTrackId(track),
                  }),
                )
              }
              onRemoveFromFavorites={() =>
                dispatch(
                  removeFromFavorites({
                    track,
                  }),
                )
              }
              onShareTrack={() => handleGetShareLink(track)}
              onVariationClickPlay={variationId => handleClickPlayVariation(track, variationId)}
            />
          </S.CardContainer>
        );
      })}
    </S.CardsContainer>
  );
}
