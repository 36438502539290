import styled from 'styled-components';

export const Container = styled.div<{ isSelected: boolean }>`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  border-radius: 0.75rem;
  background: ${({ isSelected }) => (!isSelected ? 'rgba(25,23,54,0.5)' : '#191736')};
  opacity: ${({ isSelected }) => (!isSelected ? '0.5' : '1')};

  transition:
    background 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  min-height: 6rem;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.78rem;
    width: 100%;
    height: 100%;

    background: linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%);
    opacity: ${({ isSelected }) => (!isSelected ? '0' : '1')};

    z-index: -1;
    transition: transform 0.15s ease-in-out;

    transform: ${({ isSelected }) => (isSelected ? 'scaleX(1.005) scaleY(1.02)' : 'scale(1)')};
  }

  &:hover {
    background: #252340;
    opacity: 1;
  }

  &:active {
    background: #333152;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

export const Title = styled.h6`
  align-items: center;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1rem;
  color: #ffffff;
  display: flex;
  text-transform: lowercase;
`;

export const Description = styled.p`
  margin-top: 0.5rem;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
  color: #d4d2ea;
`;

export const NeuralEffectLevelIcon = styled.div`
  margin-right: 0.5rem;
`;
