import { RootReducerType } from 'src/reducers';

import { getTrackMentalStateId } from './getTrackMentalStateId';

// TODO - dan, did you try and do something clever and then forget to unclever it?
// lets make this not a function that returns a function
export function getFavorites() {
  return function selectFavorites(state: {
    music: Pick<RootReducerType['music'], 'favorites'>;
    sessionManager: Pick<
      RootReducerType['sessionManager'],
      'sessionMentalStateId' | 'sessionDynamicActivity'
    >;
  }) {
    return state.music.favorites.filter(track => {
      return getTrackMentalStateId(track) === state.sessionManager.sessionMentalStateId;
    });
  };
}
