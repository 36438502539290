import { NOOP } from '@Globals';

import * as S from './ListItem.styles';
import CheckMarkSvg from './assets/checkmark.svg';

interface Props {
  item: string;
  isChecked?: boolean;
  onCheck?: () => void;
}

export const ListItem = ({ item, isChecked = false, onCheck = NOOP }: Props) => {
  return (
    <S.Container>
      <S.Label>
        <S.Input type="checkbox" onChange={onCheck} />
        <S.Title>{item}</S.Title>
        <S.Checkbox aria-hidden="true" isChecked={isChecked}>
          {isChecked ? <S.Image src={CheckMarkSvg} /> : null}
        </S.Checkbox>
      </S.Label>
    </S.Container>
  );
};
