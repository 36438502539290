import { useIsEnabledDynamicActivities } from '../../../../hooks/useIsEnabledDynamicActivities';

import { PreferencesDynamic } from './PreferencesDynamic';
import { PreferencesLegacy } from './PreferencesLegacy';

interface Props {
  onClose: () => void;
}

export function Preferences(props: Props) {
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();

  return isUsingDynamicActivities ? (
    <PreferencesDynamic onClose={props.onClose} />
  ) : (
    <PreferencesLegacy onClose={props.onClose} />
  );
}
