import { Button, ButtonVariants, ButtonVariantStates, FormInput } from '@Cortex';
import { Formik } from 'formik';
import * as yup from 'yup';

import { FormContainer } from '../../../shared/FormContainer';
import * as S from './EditProfile.styles';

const validationSchema = yup.object({
  email: yup.string().email().required('Email field is required'),
  name: yup.string(),
});

export interface Props {
  email: string;
  errorMessage?: string;
  isLoading?: boolean;
  name: string;
  onClose: () => void;
  onFormSubmit: (values: { name: string; email: string }) => void;
  submitButtonTitle?: string;
  submitButtonVariantState?: ButtonVariantStates;
}

export const EditProfileDisplay = ({
  email,
  errorMessage,
  isLoading = false,
  name,
  onClose,
  onFormSubmit,
  submitButtonTitle = 'Save',
}: Props) => {
  return (
    <Formik
      initialValues={{ name, email }}
      validationSchema={validationSchema}
      onSubmit={values => onFormSubmit(values)}
    >
      {({ handleChange, handleSubmit, values, touched, errors }) => {
        return (
          <S.Form data-testid="editProfileForm" onSubmit={handleSubmit}>
            <FormContainer errorMessage={errorMessage} title="Edit Profile" onClose={onClose}>
              <S.Container>
                <FormInput
                  autoComplete="name"
                  errorMessage={touched.name && errors.name ? errors.name : ''}
                  label="Name"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                />

                <FormInput
                  autoComplete="email"
                  errorMessage={touched.email && errors.email ? errors.email : ''}
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />

                <S.SubmitButton>
                  <Button
                    data-testid="editProfileSubmitButton"
                    isFullWidth={true}
                    type="submit"
                    variant={ButtonVariants.Primary}
                    variantState={
                      isLoading ? ButtonVariantStates.Loading : ButtonVariantStates.Idle
                    }
                  >
                    {submitButtonTitle}
                  </Button>
                </S.SubmitButton>
                <Button type="button" variant={ButtonVariants.Base} onClick={onClose}>
                  Cancel
                </Button>
              </S.Container>
            </FormContainer>
          </S.Form>
        );
      }}
    </Formik>
  );
};
