import { createSlice } from '@reduxjs/toolkit';

export enum PaymentStates {
  CancellationError,
  CancellationProcessing,
  UpgradeError,
  UpgradeProcessing,
  Idle,
}

export type PaymentState = {
  state: PaymentStates;
};

const initialState: PaymentState = {
  state: PaymentStates.Idle,
};

const { actions: paymentActions, reducer: paymentReducer } = createSlice({
  name: 'Payment_V2',
  initialState,
  reducers: {
    receiveCancellationProcessing: () => {
      return {
        state: PaymentStates.CancellationProcessing,
      };
    },
    receiveCancellationError: () => {
      return {
        state: PaymentStates.CancellationError,
      };
    },
    receiveUpgradeProcessing: () => {
      return {
        state: PaymentStates.UpgradeProcessing,
      };
    },
    receiveUpgradeError: () => {
      return {
        state: PaymentStates.UpgradeError,
      };
    },
    resetState: () => {
      return initialState;
    },
  },
});

export { paymentActions, paymentReducer };
