import styled from 'styled-components';

export const UpgradeStripeSubscriptionPanel = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  font-family: TTNormsPro-Regular;
  max-width: 500px;
  text-align: center;
  width: 100%;
`;

export const Header = styled.div`
  margin-bottom: 1rem;
`;

export const Text = styled.div`
  margin-bottom: 2rem;
  text-align: center;
`;

export const PrimaryButton = styled.div`
  margin-bottom: 1rem;
`;
