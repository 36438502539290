import styled from 'styled-components';

export const Container = styled.button<{ isActive: boolean }>`
  align-items: center;
  background: ${({ isActive }) => (!isActive ? 'rgba(25,23,54,0.5)' : '#191736')};
  border-radius: 0.75rem;
  border: none;
  cursor: pointer;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  margin: 0.25rem 0;
  opacity: ${({ isActive }) => (!isActive ? '0.5' : '1')};
  outline: none;
  padding: 0.25rem 1.25rem;
  position: relative;
  text-align: left;
  text-transform: lowercase;
  transition:
    background 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  width: 100%;

  &::before {
    background: linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%);
    border-radius: 0.78rem;
    content: '';
    height: 100%;
    left: 0;
    opacity: ${({ isActive }) => (!isActive ? '0' : '1')};
    position: absolute;
    top: 0;
    transform: ${({ isActive }) => (isActive ? 'scaleX(1.005) scaleY(1.02)' : 'scale(1)')};
    transition: transform 0.15s ease-in-out;
    width: 100%;
    z-index: -1;
  }

  &:hover {
    background: #252340;
    opacity: 1;
  }

  &:active {
    background: #333152;
  }
`;

export const TextContainer = styled.div`
  margin-right: 5px;
`;
