import styled from 'styled-components';

export const Container = styled.div`
  backdrop-filter: blur(25px);
  background-color: rgba(25, 23, 54, 0.95);
  border-radius: 1.25rem;
  border: 1px solid rgba(55, 62, 91, 0.7);
  display: flex;
  flex-direction: column;
  margin: 1rem;
  max-width: 20rem;
  padding: 1rem 1rem 2rem 1rem;
  width: 100%;
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Text = styled.div`
  margin-bottom: 1rem;
`;
