import { useSearchParams, createSearchParams } from 'react-router-dom';
import { useTeamSubscriptionInfo } from '../../../hooks/useHasTeamSubscription';

export const usePostOnboardingPath = () => {
  const { hasTeamSubscription } = useTeamSubscriptionInfo();
  const [searchParams] = useSearchParams();
  const queryParams = createSearchParams(searchParams).toString();

  return hasTeamSubscription ? '/' : `/payment${queryParams ? `?${queryParams}` : ''}`;
};
