import React, { forwardRef, HTMLAttributes } from 'react';
import { TransitionStatus } from 'react-transition-group';

import * as S from './AnimationContainer.styles';

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  animationState: TransitionStatus;
}

export const AnimationContainer = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, animationState }, ref) => {
    return (
      <S.Wrapper ref={ref} animationState={animationState}>
        <S.Content>{children}</S.Content>
      </S.Wrapper>
    );
  },
);
