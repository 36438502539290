import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  padding: 12px;
  border-radius: 100%;
  border: 1px solid transparent;
  transition:
    border 0.2s ease-in-out,
    background 0.2s ease-in-out;

  &:hover,
  &:focus {
    border: 1px solid #565487;
    background: #191736;
  }
`;
