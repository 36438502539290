import { EffectLevels } from '@Model';
import { NeuralEffectLevelIcon } from '@Music';
import { HTMLAttributes } from 'react';

import * as S from './Activity.styles';

interface Props extends HTMLAttributes<HTMLDivElement> {
  icon?: EffectLevels;
  title: string;
  description: string;
  isSelected?: boolean;
  'data-testid'?: string;
  onClick?: () => void;
}

export const Activity = ({
  icon,
  title,
  description,
  isSelected = false,
  'data-testid': testId,
  onClick,
}: Props) => {
  return (
    <S.Container data-testid={testId} isSelected={isSelected} onClick={onClick}>
      <S.TextContainer>
        <S.Title>
          {icon ? (
            <S.NeuralEffectLevelIcon>
              <NeuralEffectLevelIcon effectLevel={icon} />
            </S.NeuralEffectLevelIcon>
          ) : null}
          {title}
        </S.Title>
        <S.Description>{description}</S.Description>
      </S.TextContainer>
    </S.Container>
  );
};
