import { Button, ButtonVariants, ButtonVariantStates } from '@Cortex';
import { NOOP } from '@Globals';
import { Formik } from 'formik';
import React, { ReactNode } from 'react';
import * as Yup from 'yup';

import { FormContainer } from '../../../shared/FormContainer';
import * as S from './UpdateStripePaymentMethodPanel.styles';

const validationSchema = Yup.object({
  name: Yup.string().required('Please fill out billing name'),
});

export type Props = {
  cardInformation: string;
  errorMessage?: string | null;
  billingName?: string;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit?: (event: string) => void;
  submitButtonTitle?: string;
  submitButtonVariantState?: ButtonVariantStates;
  children: ReactNode | undefined;
};

export const UpdateStripePaymentMethodPanel: React.FC<Props> = ({
  cardInformation,
  children,
  errorMessage = null,
  billingName = '',
  isLoading = false,
  onClose,
  onSubmit = NOOP,
  submitButtonTitle = 'Save',
  submitButtonVariantState = ButtonVariantStates.Idle,
}) => {
  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  return (
    <S.Container>
      <FormContainer
        errorMessage={errorMessage}
        title="Change Payment Method"
        onClose={handleClose}
      >
        <Formik
          initialValues={{ name: billingName }}
          validationSchema={validationSchema}
          onSubmit={evt => onSubmit(evt.name)}
        >
          {({ handleSubmit, handleChange, errors, touched, values }) => {
            const hasErrors = touched.name && errors.name ? true : false;

            return (
              <>
                <S.CurrentPaymentMethod>
                  Current payment method is <S.CardInformation>{cardInformation}</S.CardInformation>
                  .
                </S.CurrentPaymentMethod>

                <S.NameInputContainer>
                  <S.NameInputLabel htmlFor="nameInput" isError={hasErrors}>
                    {hasErrors ? errors.name : 'Billing Name'}
                  </S.NameInputLabel>
                  <S.NameInput
                    id="nameInput"
                    name="name"
                    placeholder="Billing Name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </S.NameInputContainer>

                <S.CardInputContainer data-testid="cardContainer">{children}</S.CardInputContainer>

                <S.SubmitButton>
                  <Button
                    data-testid="confirmUpdatePaymentButton"
                    isFullWidth={true}
                    type="button"
                    variant={ButtonVariants.Primary}
                    variantState={
                      isLoading ? ButtonVariantStates.Loading : submitButtonVariantState
                    }
                    onClick={() => handleSubmit()}
                  >
                    {submitButtonTitle}
                  </Button>
                </S.SubmitButton>
                <Button
                  type="button"
                  variant={ButtonVariants.Base}
                  variantState={isLoading ? ButtonVariantStates.Loading : ButtonVariantStates.Idle}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </>
            );
          }}
        </Formik>
      </FormContainer>
    </S.Container>
  );
};
