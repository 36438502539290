import { FormInput, Button, ButtonVariants } from '@Cortex';
import React from 'react';

import { GiftCardType } from '../../../../api/modules/GiftCardInfo/types';
import { VariantStates } from '../../../Cortex/components/Button/Button';
import * as S from './GiftCard.styles';

type Props = {
  isRedeeming: boolean;
  isLoadingCardData: boolean;
  cardErrorMessage?: string;
  redeemErrorMessage?: string;
  cardCode?: string;
  cardDetails: GiftCardType | null;
  onFetchCard: () => void;
  onChangeCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFetchCardOnEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onRedeemCard: () => void;
};

export const GiftCardPresentation = (props: Props) => {
  const {
    isLoadingCardData,
    isRedeeming,
    cardErrorMessage,
    redeemErrorMessage,
    cardCode,
    cardDetails,
    onChangeCode,
    onFetchCardOnEnter,
    onFetchCard,
    onRedeemCard,
  } = props;
  const isLoading = isLoadingCardData || isRedeeming;
  return (
    <S.Container>
      <S.Form>
        <FormInput
          data-testid="giftCard"
          errorMessage={cardErrorMessage}
          label="Have a gift card?"
          value={cardCode}
          onChange={onChangeCode}
          onKeyDown={onFetchCardOnEnter}
        />
        <S.ButtonWrapper>
          <Button
            variant={ButtonVariants.Outline}
            variantState={isLoading ? VariantStates.Loading : VariantStates.Idle}
            onClick={onFetchCard}
          >
            Apply
          </Button>
        </S.ButtonWrapper>
      </S.Form>
      {cardDetails && (
        <S.CardDetails>
          <S.CardTitle>
            {cardDetails.title} {!cardDetails.canBeRedeemed && ` (inactive)`}
          </S.CardTitle>
          {cardDetails.canBeRedeemed && (
            <Button
              variant={ButtonVariants.Secondary}
              variantState={isLoading ? VariantStates.Loading : VariantStates.Idle}
              onClick={onRedeemCard}
            >
              Redeem
            </Button>
          )}
          {redeemErrorMessage ? <S.ErrorText>{redeemErrorMessage}</S.ErrorText> : null}
        </S.CardDetails>
      )}
    </S.Container>
  );
};
