import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Wrapper = styled.div<{ animationState: TransitionStatus }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  transition: opacity 0.3s ease-in-out;

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0;
      case 'entered':
        return 1;
      case 'exiting':
        return 0;
      case 'exited':
        return 0;
    }
  }};
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
