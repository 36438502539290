import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 35rem;
  min-width: 25rem;
  width: 25vw;
  overflow-y: auto;
  margin-top: 1.25rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    min-width: auto;
    width: 100%;
  }
`;

export const HeadingContainer = styled.div`
  margin-bottom: 1rem;
  text-transform: lowercase;
`;

export const CardContainer = styled.div`
  margin-bottom: 1rem;
  margin-right: 1rem;
`;
