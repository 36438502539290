import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Transition, TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

import * as analyticsActions from '../../../../actions/analytics';
import * as uiActions from '../../../../actions/ui';
import { SuccessModalType } from '../../../../types';
import { Button, ButtonVariants } from '../Button';
import * as S from './SuccessModal.styles';
import Checkmark from './assets/checkmark_icon.svg';

// Temporary implementation of modal within modal
const FloatingWindow = styled.div<{ animationState: TransitionStatus }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  isolation: isolate;
  display: flex;
  padding: 1rem;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out,
    background 0.3s ease-in-out 0.05s;

  ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return css`
          opacity: 0;
          transform: translateY(-0.75rem);
          background: transparent;
        `;
      case 'entered':
        return css`
          opacity: 1;
          transform: translateY(0);
          background: rgba(25, 23, 54, 0.8);
        `;
      case 'exiting':
        return css`
          opacity: 0;
          transform: translateY(-0.75rem);
          background: transparent;
        `;
      case 'exited':
        return css`
          opacity: 0;
          transform: translateY(-2.75rem);
          background: transparent;
        `;
    }
  }};
`;

export interface Props extends SuccessModalType {
  iconSrc?: string | null;
}

export const SuccessModalDisplay = ({
  iconSrc = Checkmark,
  isDismissable,
  isOpen,
  title,
  description,
  actions,
}: Props) => {
  const createOnClick = useCreateOnClick();
  const modalRef = useRef<HTMLDivElement>(null);
  const paragraphs = typeof description === 'string' ? [description] : description;
  const dispatch = useDispatch();

  return (
    <Transition
      in={isOpen}
      mountOnEnter
      nodeRef={modalRef}
      timeout={200}
      unmountOnExit
      onExited={() => {
        dispatch(uiActions.successModalClear());
      }}
    >
      {state => {
        return (
          <FloatingWindow
            ref={modalRef}
            animationState={state}
            onClick={() => {
              if (isDismissable) {
                dispatch(uiActions.successModalClose());
              }
            }}
          >
            <S.Container
              onClick={evt => {
                evt.stopPropagation();
              }}
            >
              {iconSrc ? (
                <S.ImageContainer>
                  <S.Image src={iconSrc} />
                </S.ImageContainer>
              ) : null}

              <S.TextContainer>
                {title && <S.Title>{title}</S.Title>}
                {paragraphs?.map((paragraph, index) => (
                  <S.Description key={index}>{paragraph}</S.Description>
                ))}
              </S.TextContainer>

              {actions && actions.length ? (
                <S.ButtonContainer>
                  {actions.map((action, index) => {
                    if (action.type === 'primary') {
                      return (
                        <S.ButtonWrapper key={index}>
                          <Button
                            data-testid="successButtonPrimary"
                            isFullWidth
                            variant={ButtonVariants.Primary}
                            onClick={() => {
                              if (action.analyticsEvent) {
                                dispatch(
                                  analyticsActions.logEventWithProperties({
                                    event: action.analyticsEvent,
                                  }),
                                );
                              }

                              createOnClick(action)();
                            }}
                          >
                            {action.text}
                          </Button>
                        </S.ButtonWrapper>
                      );
                    } else if (action.type === 'secondary') {
                      return (
                        <S.ButtonWrapper key={index}>
                          <Button
                            data-testid="successButtonSecondary"
                            variant={ButtonVariants.Base}
                            onClick={() => {
                              if (action.analyticsEvent) {
                                dispatch(
                                  analyticsActions.logEventWithProperties({
                                    event: action.analyticsEvent,
                                  }),
                                );
                              }

                              createOnClick(action)();
                            }}
                          >
                            {action.text}
                          </Button>
                        </S.ButtonWrapper>
                      );
                    } else {
                      return null;
                    }
                  })}
                </S.ButtonContainer>
              ) : null}
            </S.Container>
          </FloatingWindow>
        );
      }}
    </Transition>
  );
};

function useCreateOnClick() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function createOnClick(action: NonNullable<SuccessModalType['actions']>[number]): () => void {
    return function onClick() {
      if (action.navigateTo) {
        navigate(action.navigateTo, { state: action.navigateState });
      }
      dispatch(action.action);
    };
  }

  return useCallback(createOnClick, [dispatch, history]);
}
