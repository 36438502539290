import { FeatureFlags, useFeatureFlag } from '@Utils';
import { LegacyVolumeControl } from './LegacyVolumeControl';
import { DynamicVolumeControl } from './DynamicVolumeControl';

export const VolumeControl = () => {
  const isUsingDynamicActivities = useFeatureFlag(FeatureFlags.DynamicActivities, {
    setAnalyticsUserProperty: true,
  });

  return isUsingDynamicActivities ? <DynamicVolumeControl /> : <LegacyVolumeControl />;
};
