import { FormInput, Button, ButtonVariants } from '@Cortex';
import { membershipActions, MembershipPromotion, MembershipPromotionTypes } from '@Memberships';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { get } from '../../../api/client/client';
import { Analytics } from '../../../utils/analytics';

const CouponCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;
const Form = styled.div`
  display: flex;
  gap: 15px;
  align-items: flex-start;
`;
const ButtonWrapper = styled.div`
  margin-top: 26px;
  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    margin-top: 20px;
  }
`;

export function CouponCode() {
  const dispatch = useDispatch();
  const [couponCode, setCouponCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  async function handleFetchCouponPromotion() {
    const { result } = await get<{ status: number; result: MembershipPromotion | null }>({
      path: `/membership/promotions/${couponCode}`,
      token: null,
    });
    if (!result) {
      setErrorMessage('Invalid Coupon!');
      return;
    }

    Analytics.logEventWithProperties('coupon_applied', {
      coupon_code: couponCode,
      ...result,
    });

    dispatch(
      membershipActions.receivePromotion({
        ...result,
        type: MembershipPromotionTypes.StripeCoupon,
      } as MembershipPromotion),
    );
  }

  async function handleFetchCouponPromotionOnEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') return;
    handleFetchCouponPromotion();
  }

  function handleChangeCouponCode(event: React.ChangeEvent<HTMLInputElement>): void {
    const newValue = event.currentTarget.value;
    setErrorMessage('');
    setCouponCode(newValue);
  }

  return (
    <CouponCodeContainer>
      <Form>
        <FormInput
          data-testid="couponCode"
          errorMessage={errorMessage}
          label="Have a coupon code?"
          value={couponCode}
          onChange={handleChangeCouponCode}
          onKeyDown={handleFetchCouponPromotionOnEnter}
        />
        <ButtonWrapper>
          <Button variant={ButtonVariants.Outline} onClick={handleFetchCouponPromotion}>
            Apply
          </Button>
        </ButtonWrapper>
      </Form>
    </CouponCodeContainer>
  );
}
