import { useQuery } from '@tanstack/react-query';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { TGenreNames } from './types';
import { useRequestHandler } from './useRequestHandler';

export const useGenreNamesByDynamicActivity = (activityId?: string) => {
  const getGenreNames = useRequestHandler();

  return useQuery<TGenreNames>(
    Queries.getGenreNamesByActivity(activityId!),
    () => getGenreNames(activityId!),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: err => {
        Logger.error(new Error('Error getting Genre Names by Activity Id'), {
          activityId,
          err,
        });
      },
      enabled: Boolean(activityId),
    },
  );
};
