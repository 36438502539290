import { RootReducerType } from '../../../reducers';
import { getTrackId } from './getTrackId';

type Params = {
  id: string;
};

export function isTrackInFavorites(params: Params) {
  return function selectIsTrackInFavorites(state: Pick<RootReducerType, 'music'>) {
    return state.music.favorites.some(track => getTrackId(track) === params.id);
  };
}
