import { Paper } from '@Cortex';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { baseStyles, CLOSE_TIMEOUT_MS } from '../../../../modals/modalStyles';
import { RootReducerType } from '../../../../reducers';
import { uiSliceActions } from '../../../../reducers/uiReducer';
import { Assets } from '../../../../utils/assets';
import { UpgradeStripeSubscriptionPanel } from '../UpgradeStripeSubscriptionPanel';

ReactModal.setAppElement('#root');

const ModalContainer = styled.div`
  background-image: url(${Assets.images.bowtie.url});
  background-repeat: no-repeat;
  background-size: cover;
  background: #191736;
  justify-content: center;
  display: flex;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    flex-direction: column;
    height: auto;
  }
`;

const ModalContents = styled.div`
  padding-top: 20vh;
`;

export function UpgradeStripeSubscriptionModal() {
  const dispatch = useDispatch();
  const { modalType } = useSelector((state: RootReducerType) => state.ui);

  const handleClose = () => {
    dispatch(uiSliceActions.setModalType('profile'));
  };

  return (
    <ReactModal
      closeTimeoutMS={CLOSE_TIMEOUT_MS}
      isOpen={modalType === 'upgradeStripeSubscription'}
      style={baseStyles}
      onRequestClose={handleClose}
    >
      <ModalContainer>
        <ModalContents>
          <Paper>
            <UpgradeStripeSubscriptionPanel onClose={handleClose} />
          </Paper>
        </ModalContents>
      </ModalContainer>
    </ReactModal>
  );
}
