import { Serving } from '@Model';
import { put, select } from 'redux-saga/effects';
import { RootReducerType } from 'src/reducers';

import { RequestMethods } from '../../../api/client/types';
import { requestSaga } from '../../../sagas/httpRequest';
import { Logger } from '../../../utils/logger';
import { musicActions } from '../reducer';

type FavoritesResponse = {
  status: number;
  result: Serving[];
};

type DislikesResponse = {
  status: number;
  result: { trackId: string }[];
};

export function* fetchFavoritesAndDislikesSaga() {
  try {
    const { info }: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);

    const response = (yield requestSaga(
      RequestMethods.GET,
      `/users/${info?.id}/servings/favorites`,
      {},
      3,
    )) as unknown as FavoritesResponse;
    if (response.status === 200) {
      yield put(musicActions.receiveFavorites(response.result));
    }

    const dislikesResponse = (yield requestSaga(
      RequestMethods.GET,
      `/users/${info?.id}/dislikes?version=3`,
    )) as unknown as DislikesResponse;
    if (dislikesResponse.status === 200) {
      yield put(
        musicActions.receiveDislikes({
          dislikedTrackIds: dislikesResponse.result.map(x => x.trackId),
        }),
      );
    }
  } catch (error) {
    Logger.error(error);
  }
}
