import { useToken } from '@Authentication';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { membershipActions, MembershipPromotionTypes } from '../reducer';

export function RedemptionCodeForwarder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<{ redemptionCode?: string }>();
  const token = useToken();

  useEffect(() => {
    if (params.redemptionCode) {
      dispatch(
        membershipActions.receivePromotion({
          redemptionCode: params.redemptionCode,
          type: MembershipPromotionTypes.RedemptionCode,
        }),
      );
    }

    if (token) {
      // TODO fix account page so back button works without having visited a page previously
      navigate('/');
      navigate('/user/account');
    } else {
      navigate('/signup');
    }
  }, []);

  return null;
}
