import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TitleContainer = styled.button<{ hasBorder?: boolean }>`
  align-items: center;
  background: none;
  border-radius: 0.75rem;
  border: none;
  cursor: pointer;
  display: flex;
  height: 2.5rem;
  justify-content: space-between;
  min-width: 13rem;
  outline: none;
  padding: 0.5rem 1rem;
  position: relative;
  width: 100%;
  isolation: isolate;
  text-transform: lowercase;

  &::after {
    background: #252340;
    border-radius: 0.75rem;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    border: ${({ hasBorder }) => (hasBorder ? 'none' : '1px solid rgba(55, 62, 91, 0.7)')};
  }

  &::before {
    background: ${({ hasBorder }) =>
      hasBorder ? ' linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%)' : 'transparent'};
    border-radius: 0.77rem;
    bottom: -1px;
    content: '';
    left: -1px;
    position: absolute;
    right: -1px;
    top: -1px;
    transition: background 0.2s ease-in-out;
    z-index: -2;
  }
`;

export const ChevronImage = styled.img<{ isUp: boolean }>`
  width: 1rem;
  height: 1rem;
  transform: ${({ isUp }) => (isUp ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 0.2s ease;
`;

export const Content = styled.div<{ animationState: TransitionStatus }>`
  margin-top: 0.5rem;
  width: 100%;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(55, 62, 91, 0.7);
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  max-height: 130px;
  overflow-y: auto;

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0.2;
      case 'entered':
        return 1;
      case 'exiting':
        return 0.2;
      case 'exited':
        return 0;
    }
  }};
`;
