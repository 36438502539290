import { useEffect } from 'react';
import { OnboardingMentalStatesPresentation } from './OnboardingMentalStates.presentation';
import { CoreAnalytics } from '@Analytics';

type Props = {
  onSelect: (mentalStateId: string) => void;
};

export const OnboardingMentalStates = ({ onSelect }: Props) => {
  useEffect(() => {
    CoreAnalytics.trackOnboardingMentalStateImpressionEvent();
  }, []);

  return <OnboardingMentalStatesPresentation onSelect={onSelect} />;
};
