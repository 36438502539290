import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as paymentActions from '../../../../actions/payments';
import { setModal } from '../../../../actions/ui';
import { RootReducerType } from '../../../../reducers';
import { UpgradeStripeSubscriptionPanel } from './UpgradeStripeSubscriptionPanel.display';

type Props = {
  onClose: () => void;
};

export function UpgradeStripeSubscriptionPanelContainer(props: Props) {
  const membershipPlans = useSelector((state: RootReducerType) => state.membershipPlans);
  const yearlySubscriptionId = getYearlySubscriptionIdFromPlans(membershipPlans);
  const yearlyPrice = yearlySubscriptionId
    ? membershipPlans.plans.results[yearlySubscriptionId].price
    : '';
  const dispatch = useDispatch();
  const inFlightRequest = useRef(false);

  async function handleSubmit() {
    if (yearlySubscriptionId === null || inFlightRequest.current) return;

    inFlightRequest.current = true;
    dispatch(paymentActions.updateMembership(yearlySubscriptionId));
    dispatch(setModal(null));
  }

  return (
    <UpgradeStripeSubscriptionPanel
      price={yearlyPrice ? `$${yearlyPrice / 100}` : ''}
      onClose={props.onClose}
      onSubmit={handleSubmit}
    />
  );
}

function getYearlySubscriptionIdFromPlans(
  plans: RootReducerType['membershipPlans'],
): number | null {
  return (
    plans.plans.ids.find(id => {
      return plans.plans.results[id]?.title === 'Yearly';
    }) || null
  );
}
