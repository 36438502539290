import { Button, ButtonVariants, ButtonVariantStates, TextBold, Text } from '@Cortex';
import { NOOP } from '@Globals';
import { Formik } from 'formik';
import { ReactNode, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { EnhancedMembershipPlanType } from '../../../../../../types';
import { Assets } from '../../../../../../utils/assets';
import * as S from './Payment.styles';
import { Checkmark } from './components/Checkmark';
import { HeaderWithLogoAndClose } from '../../../../../../domains/shared/HeaderWithLogoAndClose';
import { OnboardingTestimonials } from '../../../../../../domains/Onboarding/types';
import { Testimonials } from './components/Testimonials';

const validationSchema = Yup.object({
  name: Yup.string().required('Please fill out billing name'),
});

export interface Props {
  paywallBenefits: string[];
  paywallTestimonials: OnboardingTestimonials[];
  billingInterval?: string;
  billingName?: string;
  errorMessage?: string | null;
  onPlanSelect: (plan: EnhancedMembershipPlanType) => void;
  onSubmit?: (value: string) => void;
  plans: EnhancedMembershipPlanType[];
  selectedPlan: EnhancedMembershipPlanType | null;
  transactionStatus?: PaymentStatus;
  children: ReactNode | undefined;
}

export type PaymentStatus = 'idle' | 'loading' | 'success' | 'error';

export const OnboardingStripePaymentDisplay: React.FC<Props> = ({
  paywallBenefits,
  paywallTestimonials,
  billingName = '',
  children,
  errorMessage = null,
  onPlanSelect,
  onSubmit = NOOP,
  plans,
  selectedPlan,
  transactionStatus = 'idle',
}) => {
  let variantState = ButtonVariantStates.Idle;

  if (transactionStatus === 'loading') {
    variantState = ButtonVariantStates.Loading;
  } else if (transactionStatus === 'error') {
    variantState = ButtonVariantStates.Error;
  } else if (transactionStatus === 'success') {
    variantState = ButtonVariantStates.Success;
  }

  const hasDiscount = Boolean(
    (selectedPlan?.couponId || selectedPlan?.promotionCode) &&
      selectedPlan?.originalCost &&
      selectedPlan?.originalCost !== selectedPlan?.displayCost,
  );

  const isYearly = selectedPlan?.title === 'Yearly' || selectedPlan?.displayInterval === 'yr';

  return (
    <S.Container>
      <S.HeaderWrapper>
        <HeaderWithLogoAndClose />
      </S.HeaderWrapper>
      <S.PaymentContainer>
        <S.LeftBoxContainer>
          <S.PlanTitleWrapper>
            <S.YourPlanWrapper>
              <S.YourPlan>{isYearly ? 'ANNUAL' : 'MONTHLY'} PLAN</S.YourPlan>
            </S.YourPlanWrapper>
            {hasDiscount ? null : (
              <S.SwitchPlanWrapper
                onClick={() => {
                  if (selectedPlan?.title === 'Yearly') {
                    onPlanSelect(plans[1]);
                  } else {
                    onPlanSelect(plans[0]);
                  }
                }}
              >
                {isYearly ? 'Switch to monthly' : 'Switch to yearly'}
              </S.SwitchPlanWrapper>
            )}
          </S.PlanTitleWrapper>
          <S.PlanDescription>
            <S.PricingBlock>
              <Text size="1.3rem">Due today:</Text>
              <TextBold size="2.8rem">$0.00</TextBold>
              <S.PlanCost>
                <S.PlanCostText size="1rem">
                  {isYearly ? 'After 14 Days: ' : 'After 7 Days: '}
                </S.PlanCostText>
                {hasDiscount ? (
                  <S.Strikethrough size="1rem">
                    {isYearly ? `$${selectedPlan?.price}` : `$${selectedPlan?.price}`}
                  </S.Strikethrough>
                ) : null}
                <Text size="1rem">
                  {isYearly
                    ? `$${hasDiscount ? selectedPlan?.displayCost : selectedPlan?.price}`
                    : `$${hasDiscount ? selectedPlan?.displayCost : selectedPlan?.price}`}
                </Text>
              </S.PlanCost>
            </S.PricingBlock>
            <S.Checkmarks>
              {paywallBenefits.map(benefit => (
                <Checkmark key={benefit}>{benefit}</Checkmark>
              ))}
            </S.Checkmarks>
            <S.ReviewsLeftBoxWrapper>
              <Testimonials paywallTestimonials={paywallTestimonials} />
            </S.ReviewsLeftBoxWrapper>
          </S.PlanDescription>
        </S.LeftBoxContainer>

        <S.RightBoxContainer>
          <form>
            <Formik
              initialValues={{ name: billingName }}
              validationSchema={validationSchema}
              onSubmit={values => onSubmit(values.name)}
            >
              {({ handleChange, handleSubmit, errors, touched, values }) => {
                const hasErrors = touched.name && errors.name ? true : false;

                return (
                  <>
                    <S.CardBox>
                      <S.NameInputContainer>
                        <S.NameInputLabel htmlFor="billingName" isError={hasErrors}>
                          {hasErrors ? errors.name : 'Billing Name'}
                        </S.NameInputLabel>
                        <S.NameInput
                          data-testid="billingName"
                          id="billingName"
                          name="name"
                          placeholder="Billing Name"
                          value={values.name}
                          onChange={handleChange}
                        />
                      </S.NameInputContainer>

                      <S.NameInputLabel htmlFor="billingName">Card Information</S.NameInputLabel>
                      <S.CardContainer data-testid="cardContainer">{children}</S.CardContainer>
                      <S.BelowCardInfo>
                        <S.PoweredByImage src={Assets.images.poweredByStripe.url} />
                        <Checkmark customIcon={Assets.icons.locker.url}>
                          Guaranteed safe and secure checkout
                        </Checkmark>
                        <Checkmark customIcon={Assets.icons.guarantee.url}>
                          60-Day money back guarantee
                        </Checkmark>
                      </S.BelowCardInfo>

                      <S.ButtonContainer>
                        <Button
                          data-testid="handlePurchase"
                          isFullWidth
                          keepTextCase
                          type="button"
                          variant={ButtonVariants.Secondary}
                          variantState={variantState}
                          onClick={() => handleSubmit()}
                        >
                          Save payment information
                        </Button>
                      </S.ButtonContainer>
                    </S.CardBox>
                    {errorMessage ? (
                      <S.ErrorWrapper>
                        <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
                      </S.ErrorWrapper>
                    ) : null}
                  </>
                );
              }}
            </Formik>
          </form>
          <S.ReviewsRightBoxWrapper>
            <Testimonials paywallTestimonials={paywallTestimonials} />
          </S.ReviewsRightBoxWrapper>

          <S.SubscriptionInfoText>
            {`*${selectedPlan?.description} All prices in USD. Applicable VAT, sales
                      or other taxes may apply.${
                        hasDiscount ? ' Discount applied at trial end.' : ''
                      } Cancel your subscription at anytime by going to your
                      Account Overview and click on the membership button.`}
          </S.SubscriptionInfoText>
        </S.RightBoxContainer>
      </S.PaymentContainer>
    </S.Container>
  );
};
