import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setModal } from '../../../../actions/ui';
import { RootReducerType } from '../../../../reducers';
import { SubscriptionSuccessModalDisplay } from './SubscriptionSuccessModal.display';
import { suggestionsList } from './constants';

export function SubscriptionSuccessModal() {
  const { modalType } = useSelector((state: RootReducerType) => state.ui);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClose = useCallback(() => {
    dispatch(setModal(null));
  }, [dispatch, setModal]);

  const primaryButtonAction = useCallback(() => {
    onClose();
    navigate('/');
  }, [navigate, onClose]);

  return (
    <SubscriptionSuccessModalDisplay
      description="Your experience starts now."
      isOpen={modalType === 'subscriptionSuccess'}
      list={suggestionsList}
      primaryButtonAction={primaryButtonAction}
      primaryButtonText="Let's do it"
      subtitle="For the best results during your first session"
      title="Congratulations!"
      onClose={onClose}
    />
  );
}
