import {
  useGetAvailableRedemptionCodePromotion,
  useGetAvailableStripeCouponPromotion,
} from '@Memberships';
import { FormikConfig } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import BrandLogo from '../../../../assets/images/brand_logo.svg';
import { Disclaimer, FormActionText, SignUpFormValues } from '../../../../components/forms';
import {
  MenuItem,
  Button,
  ButtonVariants,
  ButtonVariantStates,
  MenuItemIcons,
  Paper,
  Form,
} from '../../../Cortex';
import Icon from '../../../Cortex/Icon';
import { SignupBenefits } from '../../../shared/SignupBenefits';
import { FORM_PADDING } from '../../constants';
import * as S from './SignUpForm.styles';

type FormInput<Values> = {
  label: string;
  name: Extract<keyof Values, string>;
  placeholder: string;
  type: string;
};

export type Props<Values> = {
  formError: string | null;
  formInitialValues: Values;
  formInputs: FormInput<Values>[];
  formValidationSchema: Yup.ObjectSchema<SignUpFormValues>;
  isLoading: boolean;
  onClickSignUpWithApple: () => void;
  onClickSignUpWithAppleAnalyticsEvent: string;
  onClickSignUpWithFacebook: () => void;
  onClickSignUpWithFacebookAnalyticsEvent: string;
  onClickSignIn: () => void;
  onClickSignInAnalyticsEvent: string;
  onFormChange?: () => void;
  onFormSubmit: FormikConfig<Values>['onSubmit'];
  onFormSubmitAnalyticsEvent: string;
};

export function SignUpForm<Values extends { [key: string]: string }>(props: Props<Values>) {
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const availableRedemption = useGetAvailableRedemptionCodePromotion();

  return (
    <S.SignUpForm>
      <S.Header>
        <S.IconContainer>
          <Icon size={67} src={BrandLogo} />
        </S.IconContainer>
      </S.Header>
      <S.WidthContainer>
        <Paper padding={FORM_PADDING}>
          <S.Content>
            <S.ContentColumn>
              <SignupBenefits
                title={
                  availablePromotion
                    ? availablePromotion.name
                    : availableRedemption
                    ? 'Sign Up To Redeem Your Extended Trial!'
                    : 'Get Started For Free'
                }
              />
            </S.ContentColumn>
            <S.VerticalSeparator />
            <S.ContentColumn>{renderForm(props)}</S.ContentColumn>
          </S.Content>
        </Paper>
      </S.WidthContainer>
      <S.DisclaimerWrapper>
        <Disclaimer />
      </S.DisclaimerWrapper>
    </S.SignUpForm>
  );
}

function renderForm<Values extends { [key: string]: string }>(props: Props<Values>) {
  return (
    <S.FormWrapper>
      <S.Form>
        <Form
          formError={props.formError}
          formInitialValues={props.formInitialValues}
          formInputs={props.formInputs}
          formValidationSchema={props.formValidationSchema}
          isLoading={props.isLoading}
          submitButtonText="Create Account"
          onFormChange={props.onFormChange}
          onFormSubmit={props.onFormSubmit}
          onFormSubmitAnalyticsEvent={props.onFormSubmitAnalyticsEvent}
        >
          <S.SignUpWithWrapper>
            <S.HR />
            <S.SignUpWithText>Or sign up with</S.SignUpWithText>
            <S.HR />
          </S.SignUpWithWrapper>
          <S.ButtonWrapper>
            <S.Button>
              <Button
                analyticsEvent={props.onClickSignUpWithFacebookAnalyticsEvent}
                isFullWidth={true}
                variant={ButtonVariants.Tertiary}
                variantState={ButtonVariantStates.Idle}
                onClick={props.onClickSignUpWithFacebook}
              >
                <MenuItem icon={MenuItemIcons.Facebook}>Facebook</MenuItem>
              </Button>
            </S.Button>
            <S.Button>
              <Button
                analyticsEvent={props.onClickSignUpWithAppleAnalyticsEvent}
                isFullWidth={true}
                variant={ButtonVariants.Tertiary}
                variantState={ButtonVariantStates.Idle}
                onClick={props.onClickSignUpWithApple}
              >
                <MenuItem icon={MenuItemIcons.Apple}>Apple</MenuItem>
              </Button>
            </S.Button>
            <div id="google-button" />
          </S.ButtonWrapper>
        </Form>
      </S.Form>
      <S.Links>
        <S.LinkLast>
          <FormActionText
            actionText="Sign in"
            analyticsEvent={props.onClickSignInAnalyticsEvent}
            data-testid="sign-in"
            text="Already have an account?"
            onClick={props.onClickSignIn}
          />
        </S.LinkLast>
      </S.Links>
    </S.FormWrapper>
  );
}
