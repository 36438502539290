import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { TextBold } from '@Cortex';
import { NOOP } from '@Globals';
import { getTrackName } from '@Music';
import { getTrackVariationId } from '@Music';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootReducerType } from 'src/reducers';

import { trackCardLoad } from '../../../../../../actions/sessionManager';
import { useIsEnabledDynamicActivities } from '../../../../../../hooks/useIsEnabledDynamicActivities';
import { useShareTrack } from '../../../../../../hooks/useShareTrack';
import { Analytics } from '../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../utils/analytics/events';
import { getDynamicSimilarActivityPlayerPath } from '../../../../../../utils/getDynamicSimilarActivityPlayerPath';
import { addToDislikes, removeFromDislikes, removeFromFavorites } from '../../../../actions';
import { getFavorites, getTrackId } from '../../../../lenses';
import { getDynamicFavorites } from '../../../../lenses/getDynamicFavorites';
import { ExpandableTrackCard } from '../../../ExpandableTrackCard';
import * as S from './Favorites.styles';
import { NoFavorites } from './components/NoFavorites';

type Props = {
  mentalState: string;
};

export function Favorites(props: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();
  const dislikedTrackIds = useSelector((state: RootReducerType) => state.music.dislikedTrackIds);
  const favoritesSelector = isUsingDynamicActivities ? getDynamicFavorites : getFavorites;
  const favorites = useSelector(favoritesSelector());
  const { handleGetShareLink } = useShareTrack();

  return (
    <S.Container>
      <S.HeadingContainer>
        <TextBold size={22}>My Favorites</TextBold>
      </S.HeadingContainer>
      {favorites.length === 0 ? (
        <NoFavorites text={`Looks like you don’t have any ${props.mentalState} favorites!`} />
      ) : (
        favorites.map(track => {
          const trackId = getTrackId(track);
          const trackName = getTrackName(track);
          const trackVariationId = getTrackVariationId(track);

          function handleTrackSelectTrackEvent() {
            CoreAnalytics.trackSelectTrackEvent({
              origin: TrackActionOrigins.Favorites,
              trackName,
            });
          }
          function handleClickRemove() {
            dispatch(removeFromFavorites({ track }));
          }
          function handleClickPlay() {
            Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromFavorites, {
              trackId,
              trackName,
              trackVariationId,
            } as any);

            if (isUsingDynamicActivities) {
              navigate(getDynamicSimilarActivityPlayerPath(getTrackId(track)));
            } else {
              dispatch(
                trackCardLoad({
                  trackId,
                  trackVariationId,
                  isUsingDynamicActivities,
                }),
              );
            }

            handleTrackSelectTrackEvent();
          }
          function handleVariationClickPlay() {
            Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromFavorites, {
              trackId,
              trackVariationId,
              trackName,
            } as any);

            dispatch(trackCardLoad({ trackId, trackVariationId, isUsingDynamicActivities }));

            handleTrackSelectTrackEvent();
          }

          return (
            <S.CardContainer key={getTrackVariationId(track)} data-testid="favoriteTrackItem">
              <ExpandableTrackCard
                analyticsEvent={ExploreEvents.toggle_track_card_expansion__favorites}
                dislikeButtonTestId={
                  dislikedTrackIds.includes(trackId)
                    ? `removeFromDislikes__${trackId}`
                    : `addToDislikes__${trackId}`
                }
                displayType="trackVariation"
                expandIconTestId={`expandButton__${trackId}`}
                favoritesButtonTestId={`removeFromFavorites__${trackId}`}
                isDisliked={dislikedTrackIds.includes(trackId)}
                isDislikingAvailable={true}
                isFavorited={true}
                isFavoritingAvailable={true}
                track={track}
                onAddToDislikes={() => dispatch(addToDislikes({ track }))}
                onAddToFavorites={NOOP}
                onClickPlay={handleClickPlay}
                onRemoveFromDislikes={() =>
                  dispatch(
                    removeFromDislikes({
                      trackId,
                    }),
                  )
                }
                onRemoveFromFavorites={handleClickRemove}
                onShareTrack={() => handleGetShareLink(track)}
                onVariationClickPlay={handleVariationClickPlay}
              />
            </S.CardContainer>
          );
        })
      )}
    </S.Container>
  );
}
