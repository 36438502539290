import { Serving, Track } from '@Model';
import { createAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { TrackActionOrigins } from 'src/domains/Analytics/coreAnalytics.types';

import { musicActions } from './reducer';

export const addToDislikes = createAction<{ track: Track | Serving }>('Music__addToDislikes');
export const addToFavorites = createAction<{ origin: TrackActionOrigins; track: Track | Serving }>(
  'Music__addToFavorites',
);
export const fetchFavoritesAndDislikes = createAction('Music__fetchFavoritesAndDislikes');
export const removeFromDislikes = createAction<{ trackId: string }>('Music__removeFromDislikes');
export const removeFromFavorites = createAction<{ track: Track | Serving }>(
  'Music__removeFromFavorites',
);
export const searchTracks = createAction<{ query: string }>('Music_searchTracks');

export function useSetActivityFilters() {
  const dispatch = useDispatch();

  return function setActivityFilters(activityFilters: string[]): void {
    dispatch(musicActions.setActivityFilters(activityFilters));
  };
}
