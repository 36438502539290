const padZero = (num: number) => (num < 10 ? `0${num}` : num);

/**
 * Formats time to show in timer
 * @param timeLeft number in seconds
 * @returns formatted digital clock time.
 */
export const formatTime = (timeLeft: number) => {
  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = Math.floor(timeLeft % 60);

  const secondsFormatted = padZero(seconds);

  if (hours) {
    const minutesFormatted = padZero(minutes);
    return `${hours}:${minutesFormatted}:${secondsFormatted}`;
  }
  return `${minutes}:${secondsFormatted}`;
};
