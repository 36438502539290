import { ANDROID_DOWNLOAD_LINK, IOS_DOWNLOAD_LINK } from '@Globals';
import { getTrackId } from '@Music';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { trackCardLoad } from '../../actions/sessionManager';
import { useIsEnabledDynamicActivities } from '../../hooks/useIsEnabledDynamicActivities';
import { RootReducerType } from '../../reducers';
import { UNIVERSAL_LINK_ACTIONS } from '../../types/universalLinks';
import { getDeviceType } from '../../utils/getDeviceType';

export function IntentionsPlayTrackHandler() {
  const navigate = useNavigate();
  const { isMobile, isIos } = getDeviceType();
  if (isMobile) {
    window.location.replace(isIos ? IOS_DOWNLOAD_LINK : ANDROID_DOWNLOAD_LINK);
  }
  const [searchParams] = useSearchParams();
  const { intention_action, intention_value, mental_state } = useMemo(
    () => Object.fromEntries(searchParams.entries()),
    [searchParams],
  );
  useEffect(() => {
    if (!mental_state) return;
    navigate(`/${mental_state}`, { replace: true });
  }, [navigate, mental_state]);

  useHandlePlayTrack({ intention_action, intention_value });
  return null;
}

export const useHandlePlayTrack = ({
  intention_action,
  intention_value,
}: {
  intention_action: string;
  intention_value: string;
}) => {
  const [isPerformedOnce, setIsPerformedOnce] = useState(false);

  const { status } = useSelector((state: RootReducerType) => state.app);
  const { currentTrack } = useSelector((state: RootReducerType) => state.music);
  const dispatch = useDispatch();
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();

  return useEffect(() => {
    if (status === 'loading' || status === 'idle' || isPerformedOnce) return;
    setIsPerformedOnce(true);
    if (!intention_value || !intention_action) return;
    switch (intention_action) {
      case UNIVERSAL_LINK_ACTIONS.PlayTrack:
        if (!intention_value) return;
        dispatch(
          trackCardLoad({
            trackId: intention_value,
            loadWithoutPlaying: true,
            isUsingDynamicActivities,
          }),
        );
        return;
    }
  }, [dispatch, status, getTrackId(currentTrack)]);
};
