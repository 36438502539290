import { InputHTMLAttributes } from 'react';

import { Text } from '../Text';
import * as S from './FormInput.styles';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  errorMessage?: string;
  rightSideContent?: string | (() => JSX.Element);
}

export const FormInput = ({ name, label, errorMessage = undefined, ...props }: Props) => {
  const inputStyles = {
    backgroundPosition: errorMessage ? '97%' : '120%',
    borderColor: errorMessage ? '#F34675' : 'initial',
  };

  return (
    <S.InputContainer>
      <S.InputLabel htmlFor={name} style={{ color: errorMessage ? '#F34675' : '#fff' }}>
        {label}
      </S.InputLabel>
      <S.InputField id={name} name={name} style={inputStyles} {...props} />
      {props.rightSideContent ? (
        <S.RightSideContent>
          {typeof props.rightSideContent === 'string' ? (
            <Text style={{ marginRight: '1rem' }}>{props.rightSideContent}</Text>
          ) : (
            props.rightSideContent()
          )}
        </S.RightSideContent>
      ) : null}
      {errorMessage ? <S.ErrorText>{errorMessage}</S.ErrorText> : null}
    </S.InputContainer>
  );
};
