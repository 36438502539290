import { Serving } from '@Model';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useIsEnabledDynamicActivities } from '../../../hooks/useIsEnabledDynamicActivities';

import { RootReducerType } from '../../../reducers';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { useRequestHandler } from './useRequestHandler';

export const useRecentTracks = () => {
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();
  const mentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );
  const dynamicMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.mentalState.id,
  );

  const args = isUsingDynamicActivities
    ? {
        dynamicMentalStateId: dynamicMentalStateId as string,
        mentalStateId: undefined,
      }
    : {
        mentalStateId: mentalStateId as string,
        dynamicMentalStateId: undefined,
      };

  const getRecentTracks = useRequestHandler();

  return useQuery<Serving[], Error>(
    Queries.getRecentTracks(userId!, (mentalStateId || dynamicMentalStateId)!),
    () => getRecentTracks(userId!, args),
    {
      onError: err => {
        Logger.error(new Error('Error getting Recent Tracks'), {
          err,
          userId,
          mentalStateId,
        });
      },
      enabled: Boolean(userId) && (Boolean(mentalStateId) || Boolean(dynamicMentalStateId)),
    },
  );
};
