import { Button, ButtonVariants } from '@Cortex';
import { useEffect, useState } from 'react';

import { OnboardingQuizAnswer } from '../../types';
import { Answer } from '../Answer';
import { CardWrapperTwo } from '../CardWrapperTwo';
import BrandLogo from '../../../../assets/images/brand_logo.svg';
import * as S from './QuestionCard.styles';
import { CoreAnalytics } from '@Analytics';

export type Props = {
  answers: OnboardingQuizAnswer[];
  activeStepIndex: number;
  hasPrivacyNotice?: boolean;
  image?: string;
  numberOfSteps: number;
  questionIndex: number;
  title?: string;
  description?: string;
  secondaryButtonText?: string;
  stepText?: string;
  onClickBack?: () => void;
  onClickContinue: (
    questionIndex: number,
    answerIndex: number,
    responseCards?: OnboardingQuizAnswer['responseCards'],
    testimonials?: OnboardingQuizAnswer['testimonials'],
    benefits?: OnboardingQuizAnswer['benefits'],
  ) => () => void;
};

export const QuestionCard = (props: Props) => {
  const {
    answers,
    activeStepIndex,
    hasPrivacyNotice,
    image,
    questionIndex,
    numberOfSteps,
    title,
    description,
    secondaryButtonText,
    stepText,
    onClickBack,
    onClickContinue,
  } = props;
  const [selectedAnswer, setSelectedAnswer] = useState<OnboardingQuizAnswer | undefined>(undefined);

  useEffect(() => {
    CoreAnalytics.trackOnboardingQuizQuestionImpressionEvent({
      title: title ?? '',
      body: description ?? '',
      step: stepText ?? '',
    });
  }, []);

  return (
    <CardWrapperTwo>
      <S.ContainerRow>
        <S.Logo src={BrandLogo} />
        {activeStepIndex !== null && numberOfSteps !== null && numberOfSteps !== 1 ? (
          <S.StepSection>
            <S.StepText>{stepText || 'Quiz'}</S.StepText>
            <S.StepPills>
              {[...Array(props.numberOfSteps)].map((_, index) => (
                <S.StepPill
                  key={index}
                  isActive={index === props.activeStepIndex}
                  isCompleted={index <= props.activeStepIndex}
                />
              ))}
            </S.StepPills>
          </S.StepSection>
        ) : null}
        <S.LogoPlaceholder src={BrandLogo} />
      </S.ContainerRow>

      <S.Content>
        <S.ContainerColumn>
          {title ? <S.Title>{title}</S.Title> : null}
          {description ? <S.Body>{description}</S.Body> : null}
          <S.Answers>
            {answers.map((a, answerIndex) => (
              <Answer
                key={`${a.title}${a.description}`}
                description={a.description}
                selected={a.title === selectedAnswer?.title}
                title={a.title}
                onClick={() => setSelectedAnswer(answers[answerIndex])}
              />
            ))}
          </S.Answers>
          {hasPrivacyNotice ? (
            <S.PrivacyNotice>
              Any and all data is kept 100% private. See our{' '}
              <S.PrivacyNoticeLinkWrapper>
                <S.PrivacyNoticeLink href="https://brain.fm/privacy" target="_blank">
                  Privacy Policy
                </S.PrivacyNoticeLink>
              </S.PrivacyNoticeLinkWrapper>
            </S.PrivacyNotice>
          ) : null}
        </S.ContainerColumn>
      </S.Content>
      <S.ButtonContainer>
        <Button
          disabled={!selectedAnswer}
          isFullWidth
          keepTextCase
          variant={ButtonVariants.Secondary}
          onClick={onClickContinue(
            questionIndex,
            answers.findIndex(answer => answer === selectedAnswer),
            selectedAnswer?.responseCards,
            selectedAnswer?.testimonials,
            selectedAnswer?.benefits,
          )}
        >
          Continue
        </Button>
      </S.ButtonContainer>
    </CardWrapperTwo>
  );
};
