import { Text, FontColors } from '@Cortex';
import { useRef, useState, useCallback, useEffect } from 'react';

import * as S from './TabBar.styles';

interface Props {
  items: string[];
  activeTabIndex: number;
  onSelect: (value: any) => void;
}

export const TabBar = ({ items, onSelect, activeTabIndex }: Props) => {
  const tabBarRef = useRef<HTMLDivElement>(null);
  const [refs, setRefs] = useState<(HTMLButtonElement | null)[]>([]);
  const [, setRerender] = useState(false);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      setRerender(state => !state);
    });

    if (tabBarRef.current) {
      observer.observe(tabBarRef.current);
    }

    return () => {
      if (tabBarRef.current) {
        observer.unobserve(tabBarRef.current);
      }
    };
  }, [tabBarRef]);

  useEffect(() => {
    setRerender(i => !i);
  }, [refs]);

  const handleAddRef = useCallback(
    (node: HTMLButtonElement | null) => {
      setRefs(currentRefs => [...currentRefs, node]);
    },
    [setRefs],
  );

  const left = refs[activeTabIndex]?.offsetLeft || 0;
  const top = refs[activeTabIndex]?.offsetTop || 0;

  return (
    <S.Container ref={tabBarRef}>
      {refs.length > 0 ? (
        <S.Pill
          style={{
            width: refs[activeTabIndex]?.offsetWidth + 'px',
            height: refs[activeTabIndex]?.offsetHeight + 'px',
            transform: `translate(${left - S.CONTAINER_PADDING}px, ${top - S.CONTAINER_PADDING}px)`,
          }}
        />
      ) : null}
      {items.map((item, i) => (
        <S.Item
          key={i}
          ref={handleAddRef}
          data-testid={item}
          type="button"
          onClick={() => {
            onSelect(item);
          }}
        >
          <Text
            color={activeTabIndex === i ? FontColors.White : FontColors.WhiteSecondary}
            size="0.85rem"
          >
            {item}
          </Text>
        </S.Item>
      ))}
    </S.Container>
  );
};
