import { HTMLAttributes } from 'react';

import * as S from './RadioButton.styles';

interface Props extends HTMLAttributes<HTMLInputElement> {
  text: string;
}

export const RadioButton = ({ text, ...inputProps }: Props) => {
  return (
    <S.Container>
      <S.Radio name="selection" value={text} {...inputProps} />
      <S.RadioButtonLabel htmlFor={inputProps.id}>{text}</S.RadioButtonLabel>
    </S.Container>
  );
};
