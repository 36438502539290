import { Text, Button, ButtonVariants } from '@Cortex';
import { NOOP } from '@Globals';

import { FormContainer } from '../../../../../shared/FormContainer';
import * as S from './Confirmation.styles';

interface Props {
  bodyText?: string;
  isDisabled?: boolean;
  onClose?: () => void;
  onContinue?: () => void;
}

export const Confirmation = ({
  bodyText = '',
  isDisabled = false,
  onClose = NOOP,
  onContinue = NOOP,
}: Props) => {
  return (
    <FormContainer title="Are you sure?" onClose={onClose}>
      <S.TextWrapper>
        <Text>{bodyText}</Text>
      </S.TextWrapper>
      <S.Footer>
        <S.ButtonWrapper>
          <Button
            data-testid="confirmDeleteAccount"
            disabled={isDisabled}
            variant={ButtonVariants.Base}
            onClick={onContinue}
          >
            Delete My Account
          </Button>
        </S.ButtonWrapper>

        <S.ButtonWrapper>
          <Button variant={ButtonVariants.Primary} onClick={onClose}>
            Go Back
          </Button>
        </S.ButtonWrapper>
      </S.Footer>
    </FormContainer>
  );
};
