import { CallToActionWidget } from '@Cortex';
import { useGetAvailableStripeCouponPromotion, MembershipPromotion } from '@Memberships';
import { ProfileMenuItemUrlPaths } from '@User';
import { pluralize } from '@Utils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTeamSubscriptionInfo } from '../../../../hooks/useHasTeamSubscription';
import { RootReducerType } from '../../../../reducers';
import { useIsTrialUser } from '../../lenses/isTrialUser';

type Props = {
  'data-testid'?: string;
};

export function MembershipWidget(props: Props) {
  const navigate = useNavigate();
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const isTrial = useIsTrialUser();
  const { hasTeamSubscription, isDataReady } = useTeamSubscriptionInfo();

  const expirationDate = new Date(legacyMembership?.expirationDate || '');
  const timeLeftInMilliseconds = expirationDate.getTime() - Date.now();
  const timeLeftInHours = Math.round(timeLeftInMilliseconds / 1000 / 60 / 60);
  const timeLeftInDays = Math.round(timeLeftInHours / 24);
  const isExpired = timeLeftInMilliseconds <= 0;

  const gracePeriodExpirationDate = new Date(legacyMembership?.gracePeriodExpirationDate || '');
  const gracePeriodTimeLeftInMilliseconds = gracePeriodExpirationDate.getTime() - Date.now();
  const isInGracePeriod =
    gracePeriodTimeLeftInMilliseconds >= 0 && legacyMembership?.isInGracePeriod;

  if (hasTeamSubscription || !isDataReady) return null;

  // TODO this is an evil spawn of my chaotic brain. it's fully test covered, so do not be
  // afraid to touch it, or clean it up, as i should probably have done in the first place.
  const body = isTrial
    ? isExpired
      ? 'Your trial has ended'
      : timeLeftInDays >= 1
      ? `Your trial ends in ${pluralize('days', timeLeftInDays)}`
      : timeLeftInHours >= 1
      ? `Your trial ends in ${pluralize('hours', timeLeftInHours)}`
      : 'Your trial ends in less than an hour'
    : isExpired
    ? isInGracePeriod
      ? 'Your subscription is about to expire'
      : 'Your subscription has expired'
    : null;

  function goToProfile() {
    navigate(ProfileMenuItemUrlPaths.Account);
  }

  function goToPayment() {
    navigate({ pathname: '/payment' }, { state: { from: 'Membership Widget' } });
  }

  return body ? (
    <CallToActionWidget
      body={body}
      buttonText={
        getCouponCallToAction({ availablePromotion, isInGracePeriod }) ||
        (isTrial ? 'Subscribe' : 'Update Subscription')
      }
      data-testid={props['data-testid']}
      onClickButton={isInGracePeriod ? goToProfile : goToPayment}
    />
  ) : null;
}

function getCouponCallToAction(params: {
  availablePromotion: MembershipPromotion | null;
  isInGracePeriod?: boolean | null;
}): string | undefined {
  if (params.isInGracePeriod || !params.availablePromotion) return;
  return 'Your coupon expires soon!';
}
