import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../reducers';

export type Session = {
  mentalState: string;
} | null;

export function getDynamicSession(state: RootReducerType): Session {
  const mentalState = state.sessionManager.sessionDynamicActivity?.mentalState.id;
  if (!mentalState) return null;

  return { mentalState };
}

export function useDynamicSession() {
  const mentalState = useSelector(
    (state: Partial<RootReducerType>) =>
      state.sessionManager?.sessionDynamicActivity?.mentalState.id,
  );

  if (!mentalState) return null;
  return { mentalState };
}
