import styled from 'styled-components';

export const Link = styled.a`
  display: flex;
  align-self: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.6;
  transition: opacity 0.2s ease;
  font-size: 10px;
  &:hover {
    opacity: 1;
  }
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  display: flex;
`;
