import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 6.25rem;
  display: flex;
  flex-direction: column;
`;

export const ContainerTitle = styled.h2`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 3rem;
  text-align: center;
  color: #ffffff;
`;

export const Header = styled.div`
  margin-bottom: 3rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    flex-direction: column;
  }
`;

export const MenuContent = styled.div`
  width: fit-content;
  margin-right: 2rem;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    width: 100%;
  }
`;

export const QuestionsAndAnswersContent = styled.div`
  flex: 1;
`;

export const MenuItemContainer = styled.div`
  margin-bottom: 2.25rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const QuestionAndAnswerContainer = styled.div`
  padding: 4rem 6.75rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    padding: 3rem;
  }
`;
