import { Track } from '@Model';
import { FeatureFlags, useFeatureFlag } from '@Utils';
import { UseQueryResult } from '@tanstack/react-query';

import { useNewlyReleasedTracks } from '../../../../../../api/modules/NewlyReleasedTracks';
import { RecommendedTrack } from '../../../RecommendedTrack';
import { NewTracks } from '../NewTracks';

type Props = {
  recommendedTrack: UseQueryResult<Track[], Error>;
};

export const Featured = ({ recommendedTrack }: Props) => {
  const newTracks = useNewlyReleasedTracks();

  const shouldShowRecommendedTrack = useFeatureFlag(FeatureFlags.RecommendedTrack, {
    setAnalyticsUserProperty: true,
  });

  const recommendedTracks =
    newTracks.data?.length === 0 ? recommendedTrack.data : recommendedTrack.data?.slice(0, 1);

  return (
    <>
      {shouldShowRecommendedTrack &&
      !recommendedTrack.isRefetching &&
      recommendedTracks &&
      recommendedTracks.length ? (
        <RecommendedTrack tracks={recommendedTracks} />
      ) : null}
      <NewTracks
        error={newTracks.error}
        isLoading={newTracks.isLoading}
        tracks={newTracks.data || []}
      />
    </>
  );
};
