import styled, { keyframes } from 'styled-components';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(120%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  border-radius: 1.25rem;
  border: 1px solid rgba(55, 62, 91, 0.7);
  box-shadow: 0px 20px 80px rgba(25, 23, 54, 0.65);
  width: 100%;
  max-width: 450px;
  background: rgb(54, 50, 95);
  padding: 2rem;
  z-index: 10;
  isolation: isolate;
  animation: ${fadeInUp} 0.4s ease-in-out forwards;

  @supports (backdrop-filter: blur(34px)) {
    backdrop-filter: blur(34px);
    background-color: rgba(54, 50, 95, 0.6);
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    max-width: calc(100% - 3rem);
  }
`;

export const TabBarContainer = styled.div`
  margin-bottom: 1rem;
`;

export const TrackInfoContainer = styled.div`
  margin-bottom: 1rem;
`;

export const CloseButton = styled.button`
  border: none;
  outline: none;
  background: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

export const Image = styled.img`
  width: 1rem;
  height: 1rem;
`;

export const FadeInWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  animation: fadeInDown 0.3s ease-in-out;

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
