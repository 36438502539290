import styled from 'styled-components';

import { RING_SIZE } from '../../constants';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: ${RING_SIZE}rem;
  height: ${RING_SIZE}rem;
  isolation: isolate;
`;
