import { FontColors, Text } from '@Cortex';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-bottom: 0.75rem;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Title = styled(Text)`
  color: ${FontColors.WhiteSecondary};
  transition: color 0.2s ease;
  font-size: 14px;
`;

export const Input = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Checkbox = styled.span<{ isChecked: boolean }>`
  margin-left: 1rem;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 15px;
  justify-content: center;
  /* move left to line up with accordion title */
  right: 2px;
  object-fit: contain;
  padding: 3px;
  position: relative;
  width: 15px;
  transition: border 0.2s ease;

  ${({ isChecked }) =>
    isChecked
      ? css`
          background: linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%);
          box-shadow: 0px 2.5px 11.4px rgba(147, 35, 102, 0.4);
        `
      : css`
          border: 1.5px solid #d4d2ea;
        `}
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover ${Title} {
    color: ${FontColors.White};
  }

  &:hover ${Input}:not(:checked) ~ ${Checkbox} {
    border: 1.5px solid #ffffff;
  }
`;
