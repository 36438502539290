import { Button, ButtonVariants, Text, TextBold } from '@Cortex';

import * as S from './NoFavorites.styles';
import FavoriteTrackIcon from './assets/favorite_track.svg';

interface Props {
  text: string;
}

export const NoFavorites = ({ text }: Props) => {
  return (
    <S.Container>
      <S.ImageContainer>
        <S.Image src={FavoriteTrackIcon} />
      </S.ImageContainer>
      <S.HeadingContainer>
        <S.TextWrapper>
          <TextBold>{text}</TextBold>
        </S.TextWrapper>
        <S.TextWrapper>
          <Text>Check out all of our tracks and start adding your </Text>
        </S.TextWrapper>
        <S.TextWrapper>
          <Text>favorites to easily find them later.</Text>
        </S.TextWrapper>
        {/* TODO: add this button when we have Explore All Music */}
        {/* <S.ButtonWrapper>
          <Button isFullWidth variant={ButtonVariants.Outline}>
            Browse All Tracks
          </Button>
        </S.ButtonWrapper> */}
      </S.HeadingContainer>
    </S.Container>
  );
};
