import { Icon } from '@Cortex';
import { StreakTypes } from '@User';
import React from 'react';

import { useStreaks } from '../../../../../../../../hooks';
import RocketIcon from '../../assets/rocket.svg';
import * as S from './Streaks.styles';

export const Streaks = () => {
  const { streaks, streakType } = useStreaks();
  if (streakType === StreakTypes.Hidden) return null;
  const value = streaks.data.weekly.currentCount;

  return (
    <S.Wrapper data-testid="streaks">
      <Icon size={24} src={RocketIcon} />
      <S.Text>{`${value} week streak`}</S.Text>
    </S.Wrapper>
  );
};
