import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const Form = styled.div`
  display: flex;
  gap: 15px;
  align-items: flex-start;
`;
export const ButtonWrapper = styled.div`
  margin-top: 26px;
  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    margin-top: 20px;
  }
`;

export const CardTitle = styled.div`
  margin-bottom: 10px;
`;
export const CardDetails = styled.div`
  position: relative;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  margin-top: -18px;
  margin-bottom: 20px;
  flex-direction: column;
  display: flex;
  align-items: center;
`;

export const ErrorText = styled.span`
  margin-top: 12px;
  bottom: 0;
  color: #f34675;
  font-size: ${({ theme }) => theme.fontSize.extraSmall};
  text-transform: capitalize;
`;
