import { Paper, Button, ButtonVariants, ButtonVariantSizes } from '@Cortex';
import ReactModal from 'react-modal';

import { baseStyles, CLOSE_TIMEOUT_MS } from '../../../../modals/modalStyles';
import { Assets } from '../../../../utils/assets';
import * as S from './SubscriptionSuccessModal.styles';

ReactModal.setAppElement('#root');

export type Props = {
  description: string;
  isOpen: boolean;
  onClose: () => void;
  primaryButtonAction: () => void;
  primaryButtonText: string;
  title: string;
  subtitle: string;
  list: string[];
};

export function SubscriptionSuccessModalDisplay(props: Props) {
  const { title, description, subtitle, list, primaryButtonText, primaryButtonAction } = props;
  return (
    <ReactModal
      closeTimeoutMS={CLOSE_TIMEOUT_MS}
      isOpen={props.isOpen}
      style={baseStyles}
      onRequestClose={props.onClose}
    >
      <S.Overlay>
        <S.ModalWrapper>
          <Paper padding="2rem 0" style={{ height: '100%', backdropFilter: 'none' }}>
            <S.ModalContent>
              <S.TextContainer>
                {title && <S.Title>{title}</S.Title>}
                {description && <S.Description>{description}</S.Description>}
              </S.TextContainer>
              <S.TextContainer>
                <S.SubTitle>{subtitle}</S.SubTitle>
                <S.ListItems>
                  {list.map(item => (
                    <S.ListItem key={item}>
                      <S.Check
                        alt={Assets.images.gradientCheck.alt}
                        src={Assets.images.gradientCheck.url}
                      />
                      <S.ListText>{item}</S.ListText>
                    </S.ListItem>
                  ))}
                </S.ListItems>
              </S.TextContainer>
              <S.ButtonWrapper>
                <Button
                  data-testid="successButtonPrimary"
                  variant={ButtonVariants.Primary}
                  onClick={primaryButtonAction}
                >
                  {primaryButtonText}
                </Button>
              </S.ButtonWrapper>
            </S.ModalContent>
          </Paper>
        </S.ModalWrapper>
      </S.Overlay>
    </ReactModal>
  );
}
