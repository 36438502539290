import { NOOP } from '@Globals';
import { cancelStripeSubscription } from '@Payment';
import { useRef, useState } from 'react';
import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';

import * as S from './CancelMembership.styles';
import { Cancellation } from './components/Cancellation';
import { Confirmation } from './components/Confirmation';

type CancellationOption = 'confirmation' | 'cancellation';

interface Props {
  onClose?: () => void;
}

export const CancelMembership = forwardRef<HTMLDivElement, Props>(({ onClose = NOOP }, ref) => {
  const confirmationRef = useRef<HTMLDivElement>(null);
  const cancellationRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [state, setState] = useState<CancellationOption>('confirmation');

  return (
    <S.Container ref={ref}>
      <Transition
        in={state === 'confirmation'}
        mountOnEnter
        nodeRef={confirmationRef}
        timeout={0}
        unmountOnExit
      >
        {state => {
          return (
            <Confirmation
              ref={confirmationRef}
              animationState={state}
              onClose={onClose}
              onContinue={() => setState('cancellation')}
            />
          );
        }}
      </Transition>

      <Transition
        in={state === 'cancellation'}
        mountOnEnter
        nodeRef={cancellationRef}
        timeout={0}
        unmountOnExit
      >
        {state => {
          return (
            <Cancellation
              ref={cancellationRef}
              animationState={state}
              onClose={onClose}
              onContinue={reason => {
                dispatch(cancelStripeSubscription({ reason }));
                onClose();
              }}
            />
          );
        }}
      </Transition>
    </S.Container>
  );
});
