export const API_BASE_URL = process.env.BROWSER_API_BASE_URL as string;
export const API_V3_BASE_URL = process.env.BROWSER_API_V3_BASE_URL as string;
export const AMPLITUDE_KEY = process.env.BROWSER_AMPLITUDE_KEY as string;

export const BRAZE_API_ENDPOINT = process.env.BROWSER_BRAZE_API_ENDPOINT as string;
export const BRAZE_API_KEY = process.env.BROWSER_BRAZE_API_KEY as string;
export const BROWSER_ENVIRONMENT = process.env.BROWSER_ENVIRONMENT;
export const BROWSER_TRACK_URL = process.env.BROWSER_TRACK_URL as string;
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;

export const CDN_URL = 'https://cdn.brain.fm';

export const FACEBOOK_APP_ID = process.env.BROWSER_FACEBOOK_APP_ID;

export const FACEBOOK_PIXEL_ID = process.env.BROWSER_FACEBOOK_PIXEL_ID as string;

export const GA_TRACKING_ID = process.env.BROWSER_GA_TRACKING_ID;

export const LAUNCH_DARKLY = process.env.BROWSER_LAUNCH_DARKLY as string;

export const SELF_URL = process.env.BROWSER_SELF_URL;
export const TEAMS_APP_URL = process.env.BROWSER_TEAMS_APP_URL;
export const SENTRY_DSN_KEY = process.env.BROWSER_SENTRY_DSN_KEY as string;
export const STRIPE_KEY = process.env.BROWSER_STRIPE_KEY as string;

export const ANDROID_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=com.brainfm.app&hl=en_US';
export const IOS_DOWNLOAD_LINK =
  'https://itunes.apple.com/us/app/brain-fm-music-for-the-brain/id1110684238?mt=8';

export const NOOP = () => {};

export const REFER_A_FRIEND_LEGACY_PROMO_PRODUCTION = 'promo_1KC6DhDxyvLufNfyZ264p2Za';
export const REFER_A_FRIEND_LEGACY_PROMO_DEVELOPMENT = 'promo_1KC5T2DxyvLufNfyj7UNedUH';
export const REFER_A_FRIEND_PROMO_PRODUCTION = 'promo_1OADzEDxyvLufNfyQFlIxjzX';
export const REFER_A_FRIEND_PROMO_DEVELOPMENT = 'promo_1OADypDxyvLufNfyVKH0sSRQ';
export const REFER_A_FRIEND_LINK = `${window.location.origin}?promotionCode=${
  BROWSER_ENVIRONMENT === 'production'
    ? REFER_A_FRIEND_PROMO_PRODUCTION
    : REFER_A_FRIEND_PROMO_DEVELOPMENT
}&name=Your%20First%20Month%20of%20Brain.fm%20Pro%20for%20$1&displayCost=1.00&description=You%27ve%20been%20referred!%20Get%201%20Month%20of%20Brain.fm%20Pro%20for%20$1&targetPlan=Monthly`;

export const LISTENING_MILESTONES_TO_TRACK = [30, 150, 300];

export const DEEP_LINK_ROUTES = [
  '/intentions/home',
  '/intentions/share',
  '/intentions/pay',
  '/intentions/account',
  '/intentions/justAddedMusic',
];

export const FALLBACK_TRACK_IMAGE = 'https://cdn.brain.fm/images/default_track_image.png';
