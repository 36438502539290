import { useQuery } from '@tanstack/react-query';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { useRequestHandler } from './useRequestHandler';
import { useSelector } from 'react-redux';
import { RootReducerType } from 'src/reducers';
import { DynamicActivity } from '@Model';

export const useDynamicActivitiesByMentalStateId = () => {
  const mentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.mentalState.id,
  );
  const getDynamicActivitiesByMentalStateId = useRequestHandler();

  return useQuery<DynamicActivity[]>(
    Queries.getDynamicActivitiesByMentaStateId(mentalStateId!),
    () => getDynamicActivitiesByMentalStateId(mentalStateId!),
    {
      onError: err => {
        Logger.error(new Error('Error getting dynamic activities'), {
          err,
        });
      },
      enabled: Boolean(mentalStateId),
    },
  );
};
