import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
`;

export const TextContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 1.1rem;
  justify-content: center;
  margin-right: 0.75rem;
  width: 1.1rem;
`;

export const Icon = styled.div`
  position: relative;
  bottom: 0.125rem;
  left: 0.25rem;
`;
