import { NOOP } from '@Globals';

import * as S from './MenuItem.styles';
import { ReactComponent as AccountIcon } from './assets/account.svg';
import { ReactComponent as AlertIcon } from './assets/alert.svg';
import { ReactComponent as AppleIcon } from './assets/apple.svg';
import { ReactComponent as BarChartIcon } from './assets/bar_chart.svg';
import { ReactComponent as BarChartThinIcon } from './assets/bar_chart_thin.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as FacebookIcon } from './assets/facebook.svg';
import { ReactComponent as FemaleUserIcon } from './assets/female_user.svg';
import { ReactComponent as GalaxyIcon } from './assets/galaxy.svg';
import { ReactComponent as GoogleIcon } from './assets/google.svg';
import { ReactComponent as GroupUsersIcon } from './assets/group_users.svg';
import { ReactComponent as HourglassIcon } from './assets/hourglass.svg';
import { ReactComponent as InfiniteIcon } from './assets/infinite.svg';
import { ReactComponent as KeyIcon } from './assets/key.svg';
import { ReactComponent as LogoutIcon } from './assets/logout.svg';
import { ReactComponent as SpeechBubbleIcon } from './assets/message-circle.svg';
import { ReactComponent as ReferIcon } from './assets/refer.svg';
import { ReactComponent as SettingsIcon } from './assets/settings.svg';
import { ReactComponent as NELIcon } from './assets/single_dumbel.svg';
import { ReactComponent as WatchIcon } from './assets/watch.svg';

export enum Icons {
  Account = 'Account',
  Apple = 'Apple',
  Alert = 'Alert',
  Close = 'Close',
  Facebook = 'Facebook',
  Google = 'Google',
  Hourglass = 'Hourglass',
  Infinite = 'Infinite',
  Key = 'Key',
  Logout = 'Logout',
  FemaleUser = 'FemaleUser',
  BarChart = 'BarChart',
  BarChartThin = 'BarChartThin',
  GroupUsers = 'GroupUsers',
  Galaxy = 'Galaxy',
  SingleDumbbell = 'SingleDumbbell',
  Broadcast = 'Broadcast',
  SpeechBubble = 'SpeechBubble',
  Watch = 'Watch',
  Settings = 'Settings',
}

const ICONS: { [key in Icons]: () => React.ReactNode } = {
  [Icons.Account]: () => <AccountIcon />,
  [Icons.Alert]: () => <AlertIcon />,
  [Icons.Apple]: () => <AppleIcon />,
  [Icons.Close]: () => <CloseIcon />,
  [Icons.Facebook]: () => <FacebookIcon />,
  [Icons.Google]: () => <GoogleIcon />,
  [Icons.Hourglass]: () => <HourglassIcon />,
  [Icons.Infinite]: () => <InfiniteIcon />,
  [Icons.Key]: () => <KeyIcon />,
  [Icons.Logout]: () => <LogoutIcon />,
  [Icons.FemaleUser]: () => <FemaleUserIcon />,
  [Icons.BarChart]: () => <BarChartIcon />,
  [Icons.BarChartThin]: () => <BarChartThinIcon />,
  [Icons.GroupUsers]: () => <GroupUsersIcon />,
  [Icons.Galaxy]: () => <GalaxyIcon />,
  [Icons.SingleDumbbell]: () => <NELIcon />,
  [Icons.Broadcast]: () => <ReferIcon />,
  [Icons.SpeechBubble]: () => <SpeechBubbleIcon />,
  [Icons.Watch]: () => <WatchIcon />,
  [Icons.Settings]: () => <SettingsIcon />,
};

export type Props = {
  children?: React.ReactNode;
  icon?: Icons | null;
  isActive?: boolean;
  onClick?: () => void;
  testid?: string;
  onClickIcon?: () => void;
};

export function MenuItem({
  children,
  icon = null,
  isActive = false,
  onClick = NOOP,
  testid,
  onClickIcon,
}: Props) {
  return (
    <S.MenuItem data-testid={testid} isActive={isActive} onClick={isActive ? undefined : onClick}>
      {icon !== null ? (
        <S.Icon isActive={isActive} isClickable={Boolean(onClickIcon)} onClick={onClickIcon}>
          {ICONS[icon]()}
        </S.Icon>
      ) : null}
      {children}
    </S.MenuItem>
  );
}
