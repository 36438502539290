import { Button, ButtonVariants, MenuItem, MenuItemIcons } from '@Cortex';
import { NOOP } from '@Globals';
import React from 'react';

import * as S from './ProfileInfo.styles';
import VerifiedIcon from './assets/verified_icon.svg';
import { AuthenticatedWith } from './components/AuthenticatedWith';
import { UserIcon } from './components/UserIcon';
import { UserProperty } from './components/UserProperty';
import { VerificationForm } from './components/VerificationForm';

export interface Props {
  authType?: string;
  name: string;
  hasTeam?: boolean;
  isVerified?: boolean;
  isVisibleVerificationData?: boolean;
  email: string;
  onClickDeleteAccount?: () => void;
  onPasswordChange?: () => void;
  onEditProfile?: () => void;
}

export const ProfileInfoDisplay = ({
  authType = 'email',
  name,
  email,
  hasTeam,
  isVisibleVerificationData,
  isVerified,
  onClickDeleteAccount = NOOP,
  onEditProfile = NOOP,
  onPasswordChange = NOOP,
}: Props) => {
  return (
    <S.ContentContainer>
      <S.IconContainer>
        <UserIcon />
      </S.IconContainer>
      <S.InfoContainer>
        {authType === 'apple' || authType === 'facebook' ? (
          <AuthenticatedWith platform={authType || ''} />
        ) : null}

        {name ? (
          <S.UserPropertyContainer>
            <UserProperty label="Name" value={name} valueTestId="profileName" />
          </S.UserPropertyContainer>
        ) : null}
        <S.UserPropertyContainer>
          <UserProperty
            icon={isVerified ? VerifiedIcon : undefined}
            iconTitle={isVerified ? 'Verified Email' : 'undefined'}
            label="Email"
            value={email}
            valueTestId="profileEmail"
          />
        </S.UserPropertyContainer>
        {!isVerified && isVisibleVerificationData && <VerificationForm />}
        <Button
          data-testid="editProfileButton"
          variant={ButtonVariants.Secondary}
          onClick={onEditProfile}
        >
          Edit Profile
        </Button>

        <S.Separator />
        <S.AccountActionsContainer>
          {authType === 'email' ? (
            <MenuItem icon={MenuItemIcons.Key} testid="changePassword" onClick={onPasswordChange}>
              Change Password
            </MenuItem>
          ) : null}
          {!hasTeam && (
            <S.DeleteAccount>
              <MenuItem icon={null} testid="deleteAccount" onClick={onClickDeleteAccount}>
                Delete Account
              </MenuItem>
            </S.DeleteAccount>
          )}
        </S.AccountActionsContainer>
      </S.InfoContainer>
    </S.ContentContainer>
  );
};
