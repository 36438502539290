import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

import { Analytics } from '../../utils/analytics';

export enum FeatureFlags {
  AudioHealthCheck = 'audio-health-check',
  CustomPomodoroIntervals = 'custom-pomodoro-intervals',
  DeepLinks = 'deep-links',
  GiftCards = 'gift-cards',
  NewMusicLibrary = 'new-music-library',
  Onboarding = 'onboarding',
  ReferAFriendBanner = 'refer-a-friend-banner',
  RecommendedTrack = 'recommended_track',
  DynamicActivities = 'dynamic_activities',
}

type FFOptions = {
  setAnalyticsUserProperty: boolean;
};

export function useFeatureFlag<T = boolean>(flag: FeatureFlags, options?: FFOptions): T {
  const flags = useFlags();

  useEffect(() => {
    if (options && options.setAnalyticsUserProperty) {
      Analytics.setUserProperties({
        [`web_${flag}`]: flags[flag],
      });
    }
  }, [flag, flags[flag]]);

  return flags[flag];
}
