import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ResetPasswordForm, FormActionText } from '../components/forms';
import { getGAEvent } from '../utils/analytics/ga';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;
`;

const ForgotPasswod: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <ResetPasswordForm />
      <FormActionText
        actionText="Sign in"
        analyticsEvent={getGAEvent('Session', 'reset_password', 'sign-in')}
        text=""
        onClick={() => navigate('/signin', { replace: true })}
      />
    </Container>
  );
};

export default ForgotPasswod;
