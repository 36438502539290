import { StreakTypes } from '@User';
import { useEffect, useState } from 'react';

import { useStreaks } from '../../../hooks';
import { usePrevious } from '../../../hooks/usePrevious';
import { Analytics } from '../../../utils/analytics';
import { Events } from '../../../utils/analytics/events';

const ANIMATION_DURATION = 6000;

export const useStreaksAnimation = () => {
  const [showStreakAnimation, setShowStreakAnimation] = useState(false);
  const [showTimer, setShowTimer] = useState(true);
  const { streaks, streakType } = useStreaks();
  const prevCount = usePrevious(streaks.data.weekly.currentCount);

  useEffect(() => {
    if (!showStreakAnimation) return;
    setTimeout(() => {
      setShowStreakAnimation(!showStreakAnimation);
    }, ANIMATION_DURATION);
  }, [showStreakAnimation]);

  useEffect(() => {
    if (streakType === StreakTypes.Hidden) return; // prevent animation if streaks are turned off
    if (typeof prevCount === 'undefined' || prevCount >= streaks.data.weekly.currentCount) return;
    setShowTimer(false);
    Analytics.logEventWithProperties(Events.core_streak_increase_impression, {
      core_streak_weekly_count_current: streaks.data.weekly.currentCount,
    });
  }, [streaks.data.weekly.currentCount, setShowStreakAnimation, setShowTimer]);

  return {
    showTimer,
    showStreakAnimation,
    setShowTimer,
    setShowStreakAnimation,
  };
};
