import { trackPurchase } from '@Analytics';
import { useToken } from '@Authentication';
import { NOOP } from '@Globals';
import { membershipActions, useGetAvailableStripeCouponPromotion } from '@Memberships';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootReducerType } from 'src/reducers';

import * as analyticsActions from '../../../../actions/analytics';
import * as uiActions from '../../../../actions/ui';
import * as userActions from '../../../../actions/user';
import { post } from '../../../../api/client/client';
import theme from '../../../../styles/theme';
import { PaymentTransactionState, EnhancedMembershipPlanType } from '../../../../types';
import { getErrorMessage } from '../../../../utils/getErrorMessage';
import { Logger } from '../../../../utils/logger';
import {
  trackSubscriptionImpression,
  trackSubscriptionStartAttempt,
  trackSubscriptionStartSuccess,
  trackSubscriptionStartError,
} from '../../../Analytics/coreAnalytics';
import { OnboardingStripePaymentDisplay, PaymentStatus } from './StripePayment.display';
import { getAnalyticsOrigin } from './lib/getAnalyticsOrigin';
import { getPlanTypeFromDisplayInterval } from './lib/getPlanType';
import { useGetMembershipPlans } from './lib/useGetMembershipPlans';

interface Props {
  onPaymentComplete?: () => void;
  onClose?: () => void;
}

interface HistoryState {
  redirect: boolean;
  from?: string;
}

export const StripePayment = ({ onPaymentComplete = NOOP, onClose = NOOP }: Props) => {
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const enhancedPlans = useGetMembershipPlans();
  const location = useLocation();
  const token = useToken();
  const { info } = useSelector((state: RootReducerType) => state.user);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>('idle');
  const [fontSize, setFontSize] = useState(16);
  const [currentPlan, setCurrentPlan] = useState<EnhancedMembershipPlanType | null>(
    enhancedPlans?.[0] || null,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  useEffect(() => {
    trackSubscriptionImpression({
      couponId: availablePromotion?.couponId,
      origin: getAnalyticsOrigin((location.state as HistoryState)?.from),
    });
  }, []);

  const onResize = useCallback(() => {
    if (window.innerWidth < 576 && fontSize !== 12) {
      setFontSize(12);
    } else if (window.innerWidth >= 576 && fontSize !== 16) {
      setFontSize(16);
    }
  }, [fontSize]);

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  const handlePayment = async (billingName: string) => {
    if (!stripe || !elements || !currentPlan) {
      return null;
    }
    trackSubscriptionStartAttempt({
      couponId: availablePromotion?.couponId || '',
      planType: getPlanTypeFromDisplayInterval(currentPlan.displayInterval),
    });

    dispatch(
      analyticsActions.logEventWithProperties({
        event: 'subscription_purchase',
        props: {
          subscribe_screen_from: (location.state as HistoryState)?.from,
          plan_type: currentPlan.title,
        },
      }),
    );

    try {
      setErrorMessage(null);
      setPaymentStatus('loading');

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        return;
      }

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: billingName || undefined,
        },
      });

      if (error) {
        Logger.error('PaymentError: stripe failed to create payment', { reason: error });
        trackSubscriptionStartError({
          errorMessage: error?.message || 'unknown error',
        });
        setErrorMessage(error?.message || null);
        return;
      }

      await createSubscription(paymentMethod!.id);

      dispatch(uiActions.setModal('subscriptionSuccess'));
      trackSubscriptionStartSuccess({
        couponId: availablePromotion?.couponId || '',
        planType: getPlanTypeFromDisplayInterval(currentPlan.displayInterval),
      });
      setPaymentStatus('success');
      dispatch(membershipActions.fetchMembership());

      trackPurchase({ value: currentPlan.price, userId: info?.id, planName: currentPlan.title });
      dispatch(membershipActions.receivePromotion(null));
      onPaymentComplete();
    } catch (error) {
      Logger.error('PaymentError: we failed to confirm payment', { reason: error });
      trackSubscriptionStartError({
        errorMessage: getErrorMessage(error),
      });
      setPaymentStatus('error');
      dispatch(
        analyticsActions.logEventWithProperties({
          event: 'subscription_purchase_error',
          props: {
            subscribe_screen_from: (location.state as HistoryState)?.from,
            plan_type: currentPlan.title,
          },
        }),
      );
    } finally {
      setTimeout(() => {
        setPaymentStatus('idle');
      }, 1000);
    }
  };

  const getCustomerId = async (): Promise<string> => {
    const { result } = await post<{ email?: string }, { result: string }>({
      path: '/payments/create-customer',
      body: { email: info?.email },
      token,
    });

    return result;
  };

  const createSubscription = async (paymentMethodId: string) => {
    if (!currentPlan) return null;
    const customerId = await getCustomerId();

    const { result, status, messages } = await post<
      {
        couponId?: string;
        customerId: string;
        paymentMethodId: string;
        planId: number;
        promotionCode?: string;
        userId?: string;
      },
      { status: number; result: PaymentTransactionState; messages?: string[] }
    >({
      path: '/payments/create-subscription',
      body: {
        couponId: currentPlan.couponId,
        customerId,
        paymentMethodId,
        planId: currentPlan.id,
        promotionCode: currentPlan.promotionCode,
        userId: info?.id,
      },
      token,
    });

    if (status !== 200) {
      const errorMessage = ERROR_DISPLAY_VALUES[status] || messages?.[0] || 'Something went wrong';

      setErrorMessage(errorMessage);
      throw new Error(errorMessage);
    }

    const subscription = result as PaymentTransactionState;

    if (subscription.status === 'requires_action') {
      await confirmCardPayment(paymentMethodId, currentPlan.id, customerId, subscription);
    }

    dispatch(
      analyticsActions.logEventWithProperties({
        event: 'subscription_purchase_success',
        props: {
          coupon_id: availablePromotion?.couponId,
          plan_type: currentPlan.title,
          selected_plan: currentPlan,
          subscribe_screen_from: (location.state as HistoryState)?.from,
        },
      }),
    );
    dispatch(userActions.getInfo());
  };

  const confirmCardPayment = async (
    paymentMethodId: string,
    planId: number,
    customerId: string,
    transaction: PaymentTransactionState,
  ) => {
    if (!stripe) return;

    const { error, paymentIntent } = await stripe.confirmCardPayment(transaction.clientSecret, {
      payment_method: paymentMethodId,
    });

    if (error) {
      setErrorMessage(error?.message || null);
      throw error;
    }

    if (paymentIntent?.status === 'succeeded') {
      return post({
        path: '/payments/confirm-subscription',
        body: {
          subscriptionId: transaction.subscriptionId,
          planId,
          customerId,
          userId: info?.id,
        },
        token,
      });
    }
  };

  return (
    <OnboardingStripePaymentDisplay
      billingName={info?.firstName || undefined}
      errorMessage={errorMessage}
      plans={enhancedPlans}
      selectedPlan={currentPlan}
      transactionStatus={paymentStatus}
      onPlanSelect={setCurrentPlan}
      onSubmit={handlePayment}
    >
      <CardElement
        options={{
          style: {
            base: {
              color: '#fff',
              fontSize: `${fontSize}px`,
              fontFamily: theme.font.family.poppins.regular,
              fontWeight: theme.font.weight.regular,
              ':-webkit-autofill': { color: theme.colors.brandPrimary },
              '::placeholder': {
                color: '#fff',
              },
            },

            invalid: { color: 'red' },
          },
        }}
      />
    </OnboardingStripePaymentDisplay>
  );
};

const ERROR_DISPLAY_VALUES: { [key: number]: string } = {
  409: 'You already have an active subscription.',
};
