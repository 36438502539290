import { FontColors, Paper, Text } from '@Cortex';
import { OutsideClickDetector } from '@Utils';
import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';

import ChevronIcon from '../../../../assets/images/chevron_up_icon.svg';
import { ListGroup } from '../ListGroup';
import * as S from './Sort.styles';

type Props = {
  sortByList: string[];
  isMenuExpanded: boolean;
  selectedSortings: string[];
  setMenuExpanded: (value: boolean) => void;
  onSortingSelect: (value: string) => void;
};

export const SortDisplay = (props: Props) => {
  const dropDownRef = useRef<HTMLDivElement>(null);

  function handleClick(e: React.SyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();
    props.setMenuExpanded(!props.isMenuExpanded);
  }

  return (
    <OutsideClickDetector onOutsideClick={() => props.setMenuExpanded(false)}>
      <S.Container>
        <S.Button hasBorder={Boolean(props.selectedSortings.length)} onClick={handleClick}>
          <S.Header>
            <Text color={FontColors.WhiteSecondary}>Sort by</Text>
          </S.Header>
          <S.ImageIcon isUp={props.isMenuExpanded} src={ChevronIcon} />
        </S.Button>
        <Transition
          in={props.isMenuExpanded}
          mountOnEnter
          nodeRef={dropDownRef}
          timeout={0}
          unmountOnExit
        >
          {animationState => (
            <S.FilterContainer ref={dropDownRef} animationState={animationState}>
              <S.FilterContent>
                <Paper borderRadius="0.75rem" padding="0.75rem">
                  <ListGroup
                    checkedItems={props.selectedSortings}
                    items={props.sortByList}
                    onCheck={props.onSortingSelect}
                  />
                </Paper>
              </S.FilterContent>
            </S.FilterContainer>
          )}
        </Transition>
      </S.Container>
    </OutsideClickDetector>
  );
};
