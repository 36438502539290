import { CDN_URL } from '@Globals';

import { AssetsInterface } from '../types/utils/assets';

export const Assets: AssetsInterface = {
  images: {
    logo: {
      url: `${CDN_URL}/icons/bfm-logo.svg`,
      alt: 'BrainFM logo',
    },
    loadingLogoWithText: {
      url: `${CDN_URL}/images/loading-logo-and-text.gif`,
      alt: 'brain.fm loading animation',
    },
    focusWoman: {
      url: `${CDN_URL}/images/focus-woman.png`,
      alt: 'A woman focusing at her desk.',
    },
    relaxWoman: {
      url: `${CDN_URL}/images/relax-woman.png`,
      alt: 'A woman relaxing at a table.',
    },
    filterMusicWaves: {
      url: `${CDN_URL}/images/amplitude.png`,
      alt: 'Amplitude Waves image',
    },
    waveOne: {
      url: `${CDN_URL}/images/wave-1.svg`,
      alt: 'Amplitude Waves image',
    },
    waveTwo: {
      url: `${CDN_URL}/images/wave-2.svg`,
      alt: 'Amplitude Waves image',
    },
    waveThree: {
      url: `${CDN_URL}/images/wave-3.svg`,
      alt: 'Amplitude Waves image',
    },
    waveFour: {
      url: `${CDN_URL}/images/wave-4.svg`,
      alt: 'Amplitude Waves image',
    },
    waveFive: {
      url: `${CDN_URL}/images/wave-5.svg`,
      alt: 'Amplitude Waves image',
    },
    waveSix: {
      url: `${CDN_URL}/images/wave-6.svg`,
      alt: 'Amplitude Waves image',
    },
    backgroundVector: {
      url: `${CDN_URL}/images/background-vector.png`,
      alt: 'Background Vector',
    },
    focusGirl: {
      url: `${CDN_URL}/images/focus-girl.png`,
      alt: 'focus girl image',
    },
    songPlaceholder: {
      url: `${CDN_URL}/images/song_header_placeholder.svg`,
      alt: 'sunset',
    },
    onboardingCountdown: {
      url: `${CDN_URL}/images/onboarding/onboarding_countdownr.svg`,
      alt: 'A picture of the brain.fm application',
    },
    onboardingGraph: {
      url: `${CDN_URL}/images/onboarding/onboarding_graph.svg`,
      alt: 'A graph of focus and performance',
    },
    bowtie: {
      url: `${CDN_URL}/images/bowtie.svg`,
      alt: 'Bowtie',
    },
    blurBg: {
      url: `${CDN_URL}/images/blur_bg.svg`,
      alt: 'Background Blur',
    },
    subscriptionSuccess: {
      url: `${CDN_URL}/images/subscriptionSuccess.png`,
      alt: 'Subscription Success',
    },
    gradientCheck: {
      url: `${CDN_URL}/images/gradient-check.png`,
      alt: 'Gradient Check',
    },

    focusMentalStateBgPng: {
      url: `${CDN_URL}/images/focus/focus_mental_state_bg_small_aura.png`,
      alt: 'Lady working in focus mode',
    },
    focusMentalStateBgWebp: {
      url: `${CDN_URL}/images/focus/focus_mental_state_bg_small_aura.webp`,
      alt: 'Lady working in focus mode',
    },
    focusMentalStateBgAuraPng: {
      url: `${CDN_URL}/images/focus/focus_mental_state_bg_large_aura.png`,
      alt: 'Lady working in focus mode',
    },
    focusMentalStateBgAuraWebp: {
      url: `${CDN_URL}/images/focus/focus_mental_state_bg_large_aura.webp`,
      alt: 'Lady working in focus mode',
    },

    relaxMentalStateBgPng: {
      url: `${CDN_URL}/images/relax/relax_mental_state_bg_small_aura.png`,
      alt: 'Man relaxing on a chair',
    },
    relaxMentalStateBgWebp: {
      url: `${CDN_URL}/images/relax/relax_mental_state_bg_small_aura.webp`,
      alt: 'Man relaxing on a chair',
    },
    relaxMentalStateBgAuraPng: {
      url: `${CDN_URL}/images/relax/relax_mental_state_bg_large_aura.png`,
      alt: 'Man relaxing on a chair',
    },
    relaxMentalStateBgAuraWebp: {
      url: `${CDN_URL}/images/relax/relax_mental_state_bg_large_aura.webp`,
      alt: 'Man relaxing on a chair',
    },

    sleepMentalStateBgPng: {
      url: `${CDN_URL}/images/sleep/sleep_mental_state_bg_small_aura.png`,
      alt: 'Woman sleeping on a bed',
    },
    sleepMentalStateBgWebp: {
      url: `${CDN_URL}/images/sleep/sleep_mental_state_bg_small_aura.webp`,
      alt: 'Woman sleeping on a bed',
    },
    sleepMentalStateBgAuraPng: {
      url: `${CDN_URL}/images/sleep/sleep_mental_state_bg_large_aura.png`,
      alt: 'Woman sleeping on a bed',
    },
    sleepMentalStateBgAuraWebp: {
      url: `${CDN_URL}/images/sleep/sleep_mental_state_bg_large_aura.webp`,
      alt: 'Woman sleeping on a bed',
    },
    fiveStars: {
      url: `${CDN_URL}/images/onboarding/fiveStarsYellow.png`,
      alt: 'five stars',
    },
    fiveStarsWhite: {
      url: `${CDN_URL}/images/onboarding/fiveStars.png`,
      alt: 'five stars',
    },
    clouds: {
      url: `${CDN_URL}/images/onboarding/clouds.png`,
      alt: 'clouds',
    },
    focusChallenge: {
      url: `${CDN_URL}/images/onboarding/focusChallenge.png`,
      alt: 'woman working at desk',
    },
    poweredByStripe: {
      url: `${CDN_URL}/images/poweredByStripe.svg`,
      alt: 'Stripe',
    },
    hubSpotReview: {
      url: `${CDN_URL}/images/hub_spot_review.png`,
      alt: 'Cancellation',
    },
  },
  videos: {
    session: {
      focus: {
        url: `${CDN_URL}/videos/background_gradient_640x480.mp4`,
        poster: `${CDN_URL}/video_posters/background_gradient_thumbnail_focus.jpg`, // video thumbnail
        type: 'video/mp4',
      },
      relax: {
        url: `${CDN_URL}/videos/relax_background.mov`,
        poster: `${CDN_URL}/video_posters/background_gradient_thumbnail_relax.jpg`,
        type: 'video/mp4',
      },
      sleep: {
        url: `${CDN_URL}/videos/sleep_background.mov`,
        poster: `${CDN_URL}/video_posters/background_gradient_thumbnail_sleep.jpg`,
        type: 'video/mp4',
      },
    },
  },
  icons: {
    focus: {
      url: `${CDN_URL}/icons/focus_icon.svg`,
      alt: 'Focus Icon',
    },
    relax: {
      url: `${CDN_URL}/icons/relax_icon.svg`,
      alt: 'Relax Icon',
    },
    sleep: {
      url: `${CDN_URL}/icons/sleep_icon.svg`,
      alt: 'Sleep Icon',
    },
    android: {
      url: `${CDN_URL}/icons/android_icon.svg`,
      alt: 'Android Icon',
    },
    apple: {
      url: `${CDN_URL}/icons/apple_icon.svg`,
      alt: 'Apple Icon',
    },
    close: {
      url: `${CDN_URL}/icons/close_icon.svg`,
      alt: 'Close icon',
    },
    close_white: {
      url: `${CDN_URL}/icons/close_icon_white.svg`,
      alt: 'Close icon',
    },
    download: {
      url: `${CDN_URL}/icons/download_icon.svg`,
      alt: 'Offline Access',
    },
    facebook: {
      url: `${CDN_URL}/icons/facebook_icon.svg`,
      alt: 'Facebook Icon',
    },
    twitter: {
      url: `${CDN_URL}/icons/twitter_outline.svg`,
      alt: 'Twitter Icon',
    },
    warning: {
      url: `${CDN_URL}/icons/warning_icon.svg`,
      alt: 'Warning Icon',
    },
    settings: {
      url: `${CDN_URL}/icons/settings.svg`,
      alt: 'Settings Icon',
    },
    userAvatar: {
      url: `${CDN_URL}/icons/user_icon.svg`,
      alt: 'User Avatar',
    },
    loading: {
      url: `${CDN_URL}/icons/loading_icon.svg`,
      alt: 'Loading Icon',
    },
    play: {
      url: `${CDN_URL}/icons/play_icon.svg`,
      alt: 'Play Icon',
    },
    playSolid: {
      url: `${CDN_URL}/icons/play_solid.svg`,
      alt: 'Play Icon',
    },
    pause: {
      url: `${CDN_URL}/icons/pause_icon.svg`,
      alt: 'Pause Icon',
    },
    skip: {
      url: `${CDN_URL}/icons/skip_icon.svg`,
      alt: 'Pause Icon',
    },
    heart: {
      url: `${CDN_URL}/icons/heart_icon.svg`,
      alt: 'Heart Icon',
    },
    heartFilled: {
      url: `${CDN_URL}/icons/heart_filled.svg`,
      alt: 'Heart Filled Icon',
    },
    speakerOn: {
      url: `${CDN_URL}/icons/speaker_on.svg`,
      alt: 'Speaker On Icon',
    },
    speakerMuted: {
      url: `${CDN_URL}/icons/speaker_muted.svg`,
      alt: 'Speaker Muted Icon',
    },

    backArrow: {
      url: `${CDN_URL}/icons/arrow_left_icon.svg`,
      alt: 'Go Back',
    },
    arrowLeft: {
      url: `${CDN_URL}/icons/arrow_left_icon.svg`,
      alt: 'Arrow pointing left',
    },

    calendarDay: {
      url: `${CDN_URL}/icons/calendar-day-icon.svg`,
      alt: 'Calendar indicating 1 day',
    },
    calendarWeek: {
      url: `${CDN_URL}/icons/calendar-week-icon.svg`,
      alt: 'Calendar indicating 1 week',
    },
    calendarAll: {
      url: `${CDN_URL}/icons/calendar-infinite-icon.svg`,
      alt: 'Calendar indicating infinite days',
    },
    offlineAccess: {
      url: `${CDN_URL}/icons/offline_access_icon.svg`,
      alt: 'Red globe with magnifier',
    },
    unlimitedListening: {
      url: `${CDN_URL}/icons/unlimited_listening_icon.svg`,
      alt: 'Infinity symbol',
    },
    customExperience: {
      url: `${CDN_URL}/icons/personalized_experience_icon.svg`,
      alt: 'Laser on a grid',
    },
    alarm: {
      url: `${CDN_URL}/icons/alarm_icon.png`,
      alt: 'Alarm',
    },
    clockWise: {
      url: `${CDN_URL}/icons/clockwise_icon.png`,
      alt: 'Clockwise',
    },
    checkCircle: {
      url: `${CDN_URL}/icons/check_circle_icon.png`,
      alt: 'Check Circle',
    },
    power: {
      url: `${CDN_URL}/icons/power_icon.svg`,
      alt: 'Power Level',
    },
    question: {
      url: `${CDN_URL}/icons/question_icon.svg`,
      alt: 'Question',
    },
    shuffle: {
      url: `${CDN_URL}/icons/shuffle_icon.svg`,
      alt: 'Shuffle',
    },
    locker: {
      url: `${CDN_URL}/icons/lock.svg`,
      alt: 'Locker',
    },
    guarantee: {
      url: `${CDN_URL}/icons/guarantee.svg`,
      alt: 'Guarantee',
    },
    checkmark: {
      url: `${CDN_URL}/icons/checkmark.svg`,
      alt: 'Checkmark',
    },
    taskFilled: {
      url: `${CDN_URL}/icons/task_filled_icon.svg`,
      alt: 'TaskFilled',
    },
    taskUnfilled: {
      url: `${CDN_URL}/icons/task_unfilled_icon.svg`,
      alt: 'TaskUnfilled',
    },
  },
  emojis: {
    eWave: {
      url: `${CDN_URL}/emojis/e_wave.png`,
      alt: 'Hande waving',
    },
    monkeyNoPeek: {
      url: `${CDN_URL}/emojis/monkey_no_peek.png`,
      alt: '',
    },
  },
  fonts: {},
  locales: {},
  sounds: {
    threeChimes: {
      url: `${CDN_URL}/sounds/three-chimes.wav`,
    },
    chime: {
      url: `${CDN_URL}/sounds/chime.wav`,
    },
  },
};
