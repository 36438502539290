import { FormikConfig, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import BrandLogo from '../../../../assets/images/brand_logo.svg';
import { Disclaimer, FormActionText, TeamsSignUpFormValues } from '../../../../components/forms';
import LoadingIcon from '../../../../components/icons/LoadingIcon';
import Select from '../../../../components/select';
import { Paper, FormInput, Button, ButtonVariants, ButtonVariantStates } from '../../../Cortex';
import Icon from '../../../Cortex/Icon';
import { TeamsSignupBenefits } from '../../../shared/TeamsSignupBenefits';
import { FORM_PADDING } from '../../constants';
import { FormLoaderWrapper } from './TeamsSignUpForm.styles';
import * as S from './TeamsSignUpForm.styles';

export type Props<Values> = {
  teamDomains: { displayName: string; value: string }[];
  formError: string | null;
  formInitialValues: Values;
  formValidationSchema: Yup.ObjectSchema<TeamsSignUpFormValues>;
  isFetchedTeamData: boolean;
  isLoadingTeamData: boolean;
  isLoading: boolean;
  onClickSignIn: () => void;
  onClickSignInAnalyticsEvent: string;
  onFormChange?: () => void;
  onFormSubmit: FormikConfig<Values>['onSubmit'];
  onFormSubmitAnalyticsEvent: string;
};

export function TeamsSignUpForm<Values extends { [key: string]: string }>(props: Props<Values>) {
  return (
    <S.SignUpForm>
      <S.Header>
        <S.IconContainer>
          <Icon size={67} src={BrandLogo} />
        </S.IconContainer>
      </S.Header>
      <S.WidthContainer>
        <Paper padding={FORM_PADDING}>
          <S.Content>
            <S.ContentColumn>
              <TeamsSignupBenefits />
            </S.ContentColumn>
            <S.VerticalSeparator />
            <S.ContentColumn>
              {props.isFetchedTeamData ? (
                <S.FormWrapper>
                  <S.Form>
                    <Formik<Values>
                      initialValues={props.formInitialValues}
                      validationSchema={props.formValidationSchema}
                      onSubmit={props.onFormSubmit}
                    >
                      {({ touched, handleChange, handleSubmit, handleBlur, values, errors }) => {
                        function handleFormChange(event: any) {
                          props.onFormChange?.();
                          handleChange(event);
                        }
                        return (
                          <S.FormBody onSubmit={handleSubmit}>
                            <FormInput
                              disabled={props.isLoadingTeamData}
                              errorMessage={
                                touched['name'] ? (errors['name'] as string) : undefined
                              }
                              label="Name"
                              name="name"
                              placeholder="Your name"
                              type="text"
                              value={values['name']}
                              onBlur={handleBlur}
                              onChange={handleFormChange}
                            />
                            <S.EmailWrapper>
                              <FormInput
                                disabled={props.isLoadingTeamData}
                                errorMessage={
                                  touched['username'] ? (errors['username'] as string) : undefined
                                }
                                label="Email"
                                name="username"
                                placeholder="Your email"
                                type="text"
                                value={values['username']}
                                onBlur={handleBlur}
                                onChange={handleFormChange}
                              />
                              {!props.isLoadingTeamData && (
                                <Select
                                  disabled={props.isLoadingTeamData}
                                  name="domain"
                                  options={props.teamDomains}
                                  placeholder="Domain"
                                  value={values['domain']}
                                  onBlur={handleBlur}
                                  onChange={handleFormChange}
                                />
                              )}
                            </S.EmailWrapper>
                            <FormInput
                              disabled={props.isLoadingTeamData}
                              errorMessage={
                                touched['password'] ? (errors['password'] as string) : undefined
                              }
                              label="Password"
                              name="password"
                              placeholder="Password"
                              type="password"
                              value={values['password']}
                              onBlur={handleBlur}
                              onChange={handleFormChange}
                            />
                            <Button
                              analyticsEvent={props.onFormSubmitAnalyticsEvent}
                              data-testid="handle-submit-form"
                              disabled={props.isLoadingTeamData}
                              isFullWidth={true}
                              type="submit"
                              variant={ButtonVariants.Primary}
                              variantState={
                                props.formError
                                  ? ButtonVariantStates.Error
                                  : props.isLoading || props.isLoadingTeamData
                                  ? ButtonVariantStates.Loading
                                  : ButtonVariantStates.Idle
                              }
                            >
                              {props.formError || 'Create Account'}
                            </Button>
                          </S.FormBody>
                        );
                      }}
                    </Formik>
                  </S.Form>
                  <S.Links>
                    <S.LinkLast>
                      <FormActionText
                        actionText="Sign in"
                        analyticsEvent={props.onClickSignInAnalyticsEvent}
                        data-testid="sign-in"
                        text="Already have an account?"
                        onClick={props.onClickSignIn}
                      />
                    </S.LinkLast>
                  </S.Links>
                </S.FormWrapper>
              ) : (
                <S.FormLoaderWrapper>
                  <LoadingIcon />
                </S.FormLoaderWrapper>
              )}
            </S.ContentColumn>
          </S.Content>
        </Paper>
      </S.WidthContainer>
      <S.DisclaimerWrapper>
        <Disclaimer />
      </S.DisclaimerWrapper>
    </S.SignUpForm>
  );
}
