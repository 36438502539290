import { StreakTypes } from '@User';
import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useStreaks } from '../../../../../../hooks';
import { RootReducerType } from '../../../../../../reducers';
import { Analytics } from '../../../../../../utils/analytics';
import { Events } from '../../../../../../utils/analytics/events';
import { Logger } from '../../../../../../utils/logger';
import { mentalStates } from '../../../../../../utils/mentalStates';
import { StreaksWidgetDisplay } from './StreaksWidget.display';
import { SHARE_TEXT } from './constants';

export const StreaksWidget = () => {
  const [isActive, setActive] = useState(false);
  const [isCopied, setIsCopies] = useState(false);
  const { streaks, streakType } = useStreaks();
  const sessionMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );
  const mentalState = mentalStates.byId[sessionMentalStateId || '']?.display;
  const handleClickChild = useCallback(() => {
    setActive(!isActive);
  }, [setActive, isActive]);

  const handleClickOutside = useCallback(() => {
    setActive(false);
  }, [setActive]);

  const handleShare = useCallback(async () => {
    try {
      if (window.navigator?.clipboard) {
        await window.navigator.clipboard.writeText(SHARE_TEXT(streaks.data.weekly.currentCount));
        Analytics.logEventWithProperties(Events.core_streak_share, {
          core_streak_weekly_count_current: streaks.data.weekly.currentCount,
          mental_state: mentalState,
        });
        setIsCopies(true);
      } else {
        Logger.error(new Error('Clipboard API not supported'));
      }
    } catch (error) {
      Logger.error(new Error('Failed to copy share text to clipboard'), { reason: error });
    } finally {
      setTimeout(() => {
        setIsCopies(false);
      }, 1500);
    }
  }, [streaks.data.weekly.currentCount, setIsCopies, mentalState]);

  if (streakType === StreakTypes.Hidden) return null;

  return (
    <StreaksWidgetDisplay
      isActive={isActive}
      isCopied={isCopied}
      streaks={streaks}
      onClick={handleClickChild}
      onOutsideClick={handleClickOutside}
      onShare={handleShare}
    />
  );
};
