import { Serving, Track } from '@Model';

import { getTrackName } from '../lenses';

export function sortByTrackName(trackOne: Track | Serving, trackTwo: Track | Serving) {
  const result = getTrackName(trackOne).localeCompare(getTrackName(trackTwo));

  if (result < 0) return -1;
  if (result > 0) return 1;

  return 0;
}
