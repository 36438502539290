import { useSession } from '@Session';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../../reducers';
import { MentalStates } from '../../../../types';
import { SessionProTipDisplay } from './SessionProTip.display';

interface Props {
  onClose?: () => void;
}

export const SessionProTip = (props: Props) => {
  const name = useSelector((state: RootReducerType) => state.user?.info?.firstName);
  const welcomeText = useWelcomeText();
  return <SessionProTipDisplay name={name} welcomeText={welcomeText} onClose={props.onClose} />;
};

function useWelcomeText() {
  const session = useSession();

  switch (session?.mentalState) {
    case MentalStates.Sleep:
      return 'Just close your eyes and allow 5 minutes for the music to take effect.';
    case MentalStates.Relax:
      return 'Just get comfortable and allow 5 minutes for the music to take full effect.';
    case MentalStates.Focus:
    default:
      return 'Just start working normally and allow 5 minutes for the music to take full effect.';
  }
}
