import styled, { keyframes } from 'styled-components';

import { Assets } from '../../utils/assets';
import Button, { ButtonProps } from './ButtonBase';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const PrimaryButtonContainer = styled(Button)`
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.brandSecondary} 30%,
    ${({ theme }) => theme.colors.brandSecondaryDark}
  );
  padding: 0.9rem 2.25rem;
  min-height: 3.5rem;
  min-width: 6rem;
  border-radius: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:disabled {
    cursor: not-allowed;
  }
`;

const LoadingImage = styled.img`
  width: 1rem;
  height: 1rem;
  margin: auto;
  position: absolute;
  left: 1rem;
  animation: ${rotation} 1s infinite linear;
`;
interface PrimaryButtonProps extends ButtonProps {
  loading?: boolean;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  analyticsEvent,
  loading = false,
  ...rest
}) => {
  return (
    <PrimaryButtonContainer data-analytics-event={analyticsEvent} {...rest}>
      {loading && <LoadingImage alt={Assets.icons.loading.alt} src={Assets.icons.loading.url} />}
      {children}
    </PrimaryButtonContainer>
  );
};
