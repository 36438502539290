import { useState, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSessionSidebarContent } from '../../../../actions/ui';
import { RootReducerType } from '../../../../reducers';
import { SessionSidebarContent } from '../../../../types';
import { activities } from '../../../../utils/activities';
import { mentalStates } from '../../../../utils/mentalStates';
import { ActivitySelectorDisplay } from './ActivitySelector.display';

export const ActivitySelectorLegacy = memo(() => {
  const dispatch = useDispatch();
  const sessionActivityId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionActivityId,
  );
  const sessionMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );
  const [selected, setSelected] = useState('');
  const sessionSidebarContent = useSelector(
    (state: RootReducerType) => state.ui.sessionSidebarContent,
  );

  const mentalState = mentalStates.byId[sessionMentalStateId || ''];

  useEffect(() => {
    if (sessionActivityId) {
      setSelected(activities.byId[sessionActivityId].display);
    }
  }, [sessionActivityId]);

  const mentalStateActivities = mentalState?.activities.map(id => activities.byId[id]) || [];

  return mentalStateActivities ? (
    <ActivitySelectorDisplay
      isOpen={sessionSidebarContent === SessionSidebarContent.Preferences}
      selectedActivity={selected}
      onClick={() =>
        dispatch(
          setSessionSidebarContent({
            sessionSidebarContent: SessionSidebarContent.Preferences,
            origin: 'player',
          }),
        )
      }
    />
  ) : null;
});
