import { NOOP } from '@Globals';
import { MembershipSources, MembershipStates } from '@Memberships';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../../reducers';
import { getCardInformationDisplayValue } from '../../lib/getCardInformationDisplayValue';
import { useIsPaymentProcessing } from '../../lib/useIsPaymentProcessing';
import { BillingPanel } from './BillingPanel.display';

export interface Props {
  onClickCancel?: () => void;
  onPaymentMethodChange?: () => void;
}

export function BillingPanelContainer({
  onClickCancel = NOOP,
  onPaymentMethodChange = NOOP,
}: Props) {
  const isPaymentProcessing = useIsPaymentProcessing();
  const membership = useSelector((state: Pick<RootReducerType, 'membership'>) => state.membership);

  if (membership.source === MembershipSources.Loading || isPaymentProcessing)
    return <BillingPanel cardInformation="" isLoading={true} />;

  return membership.source === MembershipSources.Stripe ? (
    <BillingPanel
      cardInformation={getCardInformationDisplayValue(membership)}
      updatePaymentButtonText={membership.cardType ? 'Update Payment Method' : 'Add Payment Method'}
      onClickCancel={membership.state === MembershipStates.Cancelled ? undefined : onClickCancel}
      onClickUpdatePaymentMethod={onPaymentMethodChange}
    />
  ) : (
    <BillingPanel cardInformation={`Your payments are managed by ${membership.source}`} />
  );
}
