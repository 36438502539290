import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setPlayerVolume } from '../../../../../../actions/ui';
import { RootReducerType } from '../../../../../../reducers';
import { Assets } from '../../../../../../utils/assets';
import * as S from './VolumeControl.styles';
import VolumeIcon from './assets/volume.svg';
import VolumeMuteIcon from './assets/volume_mute.svg';
import { mentalStates } from '../../../../../../utils/mentalStates';

/**
 * creates a volume slider
 * Notes:
 * - We use style directly instead of styled components to ensure rerendering is minimized using a dynnamic value
 * - We probably should load both muted and unmuted versions. ****
 * @param selectedVolume - incase there is a user preset let's display it.
 * @returns
 */

const DEFAULT_VOLUME_LEVEL = 0.8;

export const LegacyVolumeControl = () => {
  const dispatch = useDispatch();
  const mentalStateId = useSelector(
    (state: RootReducerType) => state.currentSession.session.mentalStateId,
  );
  const initialVolume =
    useSelector((state: RootReducerType) => state.ui.playerVolume) ?? DEFAULT_VOLUME_LEVEL;
  const [volume, setVolume] = useState(initialVolume);
  const [lastManuallySetVolume, setLastManuallySetVolume] = useState(
    initialVolume || DEFAULT_VOLUME_LEVEL,
  );
  const muted = volume == 0;

  const toggleMute = useCallback(() => {
    const newValue = muted ? Math.max(lastManuallySetVolume, 0.03) : 0;
    dispatch(setPlayerVolume(newValue));
    setVolume(newValue);
  }, [dispatch, setVolume, setPlayerVolume, muted, lastManuallySetVolume]);

  const handleVolumeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value) / 100;
      dispatch(setPlayerVolume(value));
      setVolume(value);
      setLastManuallySetVolume(value);
    },
    [setVolume, dispatch, setPlayerVolume, setLastManuallySetVolume],
  );

  return (
    <S.Wrapper>
      <S.Image
        alt={Assets.icons.speakerOn.alt}
        src={muted ? VolumeMuteIcon : VolumeIcon}
        onClick={toggleMute}
      />
      <S.SlideContainer active={true}>
        <S.SlideBg />
        <S.SlideFill
          style={{ width: `${volume * 100}%`, background: mentalStates.byId[mentalStateId]?.color }}
        />
        <S.Slide
          max="100"
          min="0"
          type="range"
          value={volume * 100}
          onChange={handleVolumeChange}
        />
      </S.SlideContainer>
    </S.Wrapper>
  );
};
