import styled, { keyframes } from 'styled-components';

import { ANIMATION_DURATION_SECONDS } from './constants';

const auraImageAnimation = keyframes`
  from {
    transform: translateY(50vh);
  }
  to {
    top: translateY(0vh);
  }
`;

type Props = {
  isAnimating: boolean;
  animationOffset?: number;
};

export const Image = styled.img.attrs<Props>(({ animationOffset, isAnimating }) => {
  return {
    style: {
      animationDelay: `-${animationOffset}s`,
      animationPlayState: isAnimating ? 'running' : 'paused',
    },
  };
})<Props>`
  height: 150vmax;
  opacity: 0.7;
  position: absolute;
  top: -50vh;
  width: 150vmax;
  z-index: -10;

  animation-name: ${auraImageAnimation};
  animation-timing-function: ease-out;
  animation-duration: ${ANIMATION_DURATION_SECONDS}s;
`;
