import { TextSpaced } from '@Cortex';
import styled from 'styled-components';

export const SessionTypeTextContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 20px;
  padding-bottom: 1rem;
  position: relative;
  text-align: center;
  width: 100%;
`;

export const SessionTypeText = styled(TextSpaced)<{ isVisible: boolean }>`
  margin: 0;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  position: absolute;
  bottom: 0;
  transition: opacity 0.5s ease-in-out;
  width: fit-content;
`;

export const TimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;

  &:hover {
    cursor: pointer;
  }
`;
