import { ImgHTMLAttributes, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../../reducers';
import * as S from './AuraImage.styles';
import { ANIMATION_DURATION_SECONDS } from './constants';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  isAnimating: boolean;
}

export const AuraImage = ({ isAnimating, ...imageProps }: Props) => {
  const [animationOffset, setAnimationOffset] = useState(0);

  const { currentTrackTimeStamp, timerPlayTime, sessionMentalStateId } = useSelector(
    (state: RootReducerType) => state.sessionManager,
  );

  // sets animation progress on the first render.
  // fixes animation resetting when navigating away and coming back.
  useEffect(() => {
    const totalTimerPlayTime = (timerPlayTime || 0) + currentTrackTimeStamp;
    const animationEndValue = Math.min(ANIMATION_DURATION_SECONDS, totalTimerPlayTime);

    setAnimationOffset(animationEndValue);
  }, [sessionMentalStateId]);

  return <S.Image animationOffset={animationOffset} isAnimating={isAnimating} {...imageProps} />;
};
