import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { RootReducerType } from '../../reducers';
import { uiSliceActions } from '../../reducers/uiReducer';
import { baseStyles, CLOSE_TIMEOUT_MS } from '../modalStyles';
import SelectTimer from './main';

ReactModal.setAppElement('#root');

const SelectTimerModal: React.FC = () => {
  const { modalType } = useSelector((state: RootReducerType) => state.ui);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(uiSliceActions.setModalType(null));
  };

  return (
    <ReactModal
      closeTimeoutMS={CLOSE_TIMEOUT_MS}
      isOpen={modalType === 'timerFinished'}
      style={baseStyles}
      // onRequestClose={handleClose}
    >
      <SelectTimer onClose={handleClose} />
    </ReactModal>
  );
};

export default SelectTimerModal;
