import { TNewlyReleasedTracksArgs } from '../modules/NewlyReleasedTracks/types';
import { TRecentTracksArgs } from '../modules/RecentTracks/types';
import { TTracksByGenreArgs } from '../modules/TracksByGenre/types';

export const Endpoints = {
  getDynamicMentalStates: () => '/mentalStates/dynamic',
  getDynamicActivitiesByMentalStateId: (mentalStateId: string) =>
    `/mentalStates/dynamic/${mentalStateId}/activities`,
  getGenreNamesByMentalState: (mentalStateId: string) => `/mentalStates/${mentalStateId}/genres`,
  getGenreNamesByDynamicMentalState: (dynamicMentalStateId: string) =>
    `/mentalStates/dynamic/${dynamicMentalStateId}/genres`,
  getGenreNamesByActivity: (activityId: string) => `/activities/${activityId}/genres`,
  getGenreNamesByDynamicActivity: (activityId: string) =>
    `/activities/dynamic/${activityId}/genres`,
  getSimilarTracks: (trackId: string) => `/tracks/${trackId}/similar?platform=web`,
  getNewlyReleasedTracks: ({ mentalStateId, dynamicMentalStateId }: TNewlyReleasedTracksArgs) =>
    `/servings/new?${
      mentalStateId
        ? `mentalStateId=${mentalStateId}`
        : `dynamicMentalStateId=${dynamicMentalStateId}`
    }`,
  getRecentTracks: (userId: string, { mentalStateId, dynamicMentalStateId }: TRecentTracksArgs) =>
    `/users/${userId}/servings/recent?${
      mentalStateId
        ? `mentalStateId=${mentalStateId}`
        : `dynamicMentalStateId=${dynamicMentalStateId}`
    }&platform=web`,
  getTracksByGenre: (
    genreName: string,
    { mentalStateId, dynamicMentalStateId }: TTracksByGenreArgs,
  ) =>
    `/servings/search?genre=${genreName}&${
      mentalStateId
        ? `mentalStateId=${mentalStateId}`
        : `dynamicMentalStateId=${dynamicMentalStateId}`
    }`,
  getGiftCardInfo: (cardCode: string) => `/membership/gift-card/${cardCode}`,
  getUserTeams: (userId: string) => `/users/${userId}/teams`,
  getRecommendedTrack: (userId: string) => `/users/${userId}/servings/recommended?platform=web`,
  getPublicTeamData: (teamId: string) => `/teams/public/${teamId}`,
  getTeamDataById: (teamId: string) => `/teams/${teamId}`,
  verifyEmail: (userId: string) => `/users/${userId}/verify`,
  resendVerificationEmail: (userId: string) => `/users/${userId}/sendVerificationEmail`,
  postRedeemGiftCard: (cardCode: string) => `/membership/gift-card/${cardCode}/redeem`,
  postJoinTeam: (userId: string, teamId: string) => `/users/${userId}/teams/${teamId}/join`,
};
