import { TextBold } from '@Cortex';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const HeaderText = styled(TextBold)`
  font-size: 3rem;
  margin-bottom: 3rem;
  text-transform: lowercase;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    flex-direction: column;
  }
`;

export const Text = styled.span`
  font-family: ${({ theme }) => theme.font.family.poppins.medium};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.85rem;
  flex: 1;
  text-align: left;
`;

export const ToggleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
