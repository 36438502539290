import { InputField } from '@Cortex';
import styled, { css } from 'styled-components';

export const Modal = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(55, 62, 91, 0.7);
  box-sizing: border-box;
  backdrop-filter: blur(2rem);
  border-radius: 2.5rem;
  padding: 3.5rem;
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    flex-direction: column;
  }
`;

export const Container = styled.div``;

export const PlanSelector = styled.div`
  width: 100%;
  background: rgba(25, 23, 54, 0.8);
  border-radius: 0.75rem;
  margin: 1rem 0px;
  display: flex;
  padding: 0.25rem;
`;

export const PlanDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background: #2a2043;
  border-radius: 20px;
  margin: 16px 0px;
`;

export const CardContainer = styled.div`
  background: rgba(25, 23, 54, 0.75);
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
`;

export const ButtonContainer = styled.div``;

export const Plan = styled.button<{ isActive?: boolean; hasSavings?: boolean }>`
  text-transform: lowercase;
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.825rem;
  color: #ffffff;
  border-radius: 0.5rem;
  background-color: ${({ isActive = false }) => (isActive ? '#544881' : 'transparent')};
  cursor: pointer;
  outline: none;
  border: none;
  transition: background-color 0.3s ease-in-out;

  ${({ isActive, hasSavings }) => {
    return isActive && hasSavings
      ? css`
          &:after {
            font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
            content: 'Save 40%';
            position: absolute;
            top: -30px;
            background: linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%);
            border-radius: 4px;
            font-size: 10px;
            letter-spacing: 0.1rem;
            text-transform: uppercase;
            padding: 0.25rem 0.75rem;
          }
        `
      : null;
  }}
`;

export const Separator = styled.hr`
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 1.5rem 0;
`;

export const PlanDescriptionCost = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 32px;
  align-items: center;
  color: #ffffff;
  margin: 0px 4px;
`;

export const PlanDescriptionOriginalCost = styled(PlanDescriptionCost)``;

export const Strikethrough = styled.span`
  text-decoration: line-through;
`;

export const PlanDescriptionText = styled.p`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  text-align: center;
  color: #d4d2ea;
  margin: 8px 0px;
`;

export const PlanDescriptionCancelText = styled(PlanDescriptionText)`
  text-transform: lowercase;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  color: #ffffff;
  margin: 0;
`;

export const PlanPriceDisclaimerText = styled.p`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.75rem;
  text-align: center;
  color: #d4d2ea;
  margin: 8px 0px;
`;

export const PlanDescriptionCostWrapper = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  align-items: center;
  color: #ffffff;
  margin: 0px 0.25rem;
`;

export const PlanDescriptionOriginalCostWrapper = styled(PlanDescriptionCostWrapper)`
  opacity: 0.4;
`;

export const ErrorMessage = styled.p`
  margin: 0.5rem;
  font-size: 0.75rem;
  color: red;
  text-align: center;
`;

export const NameInputLabel = styled.label<{ isError?: boolean }>`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.75rem;
  color: ${({ isError = false }) => (isError ? 'red' : '#fff')};
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  text-transform: lowercase;
`;

export const NameInput = styled(InputField)`
  border: none;
  background: none;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
  margin: 0;
  background: rgba(25, 23, 54, 0.75);

  &:focus {
    border: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    font-size: 1rem;
  }
`;

export const NameInputContainer = styled.div`
  border-radius: 0.75rem;
  margin-bottom: 0.5rem;
`;

export const BoxContainer = styled.div`
  max-width: 1280px;
  padding: 0.5rem;
  width: 100%;
`;

export const VerticalSeparator = styled.div`
  height: 100%;
  width: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 3.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    width: 100%;
    height: 2px;
    margin: 2rem 0;
  }
`;
