import * as S from './Testimonials.styles';
import { Assets } from '../../../../../../../utils/assets';
import { OnboardingTestimonials } from '../../../../../../../domains/Onboarding/types';
import { useEffect, useState } from 'react';

type Props = {
  paywallTestimonials: OnboardingTestimonials[];
};

export const Testimonials = ({ paywallTestimonials }: Props) => {
  const [currentReviewCardForAnimation, setCurrentReviewCardForAnimation] = useState<
    'blue' | 'green'
  >('blue');
  const [blueReviewCardIndex, setBlueReviewCardIndex] = useState(0);
  const [greenReviewCardIndex, setGreenReviewCardIndex] = useState(-1);

  useEffect(() => {
    function animateReviews() {
      if (currentReviewCardForAnimation === 'blue') {
        setGreenReviewCardIndex((greenReviewCardIndex + 2) % paywallTestimonials.length);
        setCurrentReviewCardForAnimation('green');
      } else {
        setBlueReviewCardIndex((blueReviewCardIndex + 2) % paywallTestimonials.length);
        setCurrentReviewCardForAnimation('blue');
      }
    }

    setTimeout(animateReviews, 5000);
  }, [currentReviewCardForAnimation]);

  return (
    <S.Container>
      <S.BackgroundWrapper>
        <S.ImageWrapper>
          <img alt={Assets.images.fiveStars.alt} src={Assets.images.fiveStars.url} />
        </S.ImageWrapper>
        <S.BlueGreenReviewsWrapper>
          <S.Review opacity={currentReviewCardForAnimation === 'blue' ? 1 : 0}>
            <S.ReviewBody>"{paywallTestimonials[blueReviewCardIndex].text}"</S.ReviewBody>
            <S.ReviewAuthor>- {paywallTestimonials[blueReviewCardIndex].author}</S.ReviewAuthor>
          </S.Review>
          {greenReviewCardIndex === -1 ? null : (
            <S.Review opacity={currentReviewCardForAnimation === 'green' ? 1 : 0}>
              <S.ReviewBody>"{paywallTestimonials[greenReviewCardIndex].text}"</S.ReviewBody>
              <S.ReviewAuthor>- {paywallTestimonials[greenReviewCardIndex].author}</S.ReviewAuthor>
            </S.Review>
          )}
          <S.ReviewSpacer>
            <S.ReviewBody>"{paywallTestimonials[2].text}"</S.ReviewBody>
            <S.ReviewAuthor>- {paywallTestimonials[2].author}</S.ReviewAuthor>
          </S.ReviewSpacer>
        </S.BlueGreenReviewsWrapper>
      </S.BackgroundWrapper>
    </S.Container>
  );
};
