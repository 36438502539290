import { TextBold } from '@Cortex';
import { NOOP } from '@Globals';

import * as S from './TrackGenre.styles';

interface Props {
  icon: HTMLImageElement['src'];
  name: string;
  onClick?: () => void;
}

export const TrackGenre = ({ name, onClick = NOOP, icon }: Props) => {
  return (
    <S.Container onClick={onClick}>
      <S.ImageContainer>
        <S.Image src={icon} />
      </S.ImageContainer>
      <TextBold size="14px">{name}</TextBold>
    </S.Container>
  );
};
