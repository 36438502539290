import { SessionPlayStatus } from '../../types';
import { Logger } from '../../utils/logger';

type Params = {
  audioElement: HTMLAudioElement | null;
  sessionPlayStatus: SessionPlayStatus;
};

export function createRunAudioHealthCheck(params: Params): () => void {
  let updatedAt = Date.now();
  let wasPreviouslyHealthy = true;

  return function runAudioHealthCheck() {
    const isNowHealthy = getIsNowHealthy(params);
    if (wasPreviouslyHealthy === isNowHealthy) return;

    if (isNowHealthy) {
      logAudioCheck({
        elapsedTime: Date.now() - updatedAt,
        message: 'Audio health check resolved.',
        ...params,
      });
      wasPreviouslyHealthy = true;
      updatedAt = Date.now();
      return;
    }

    wasPreviouslyHealthy = false;
    logAudioCheck({
      elapsedTime: Date.now() - updatedAt,
      message: 'Failed audio health check.',
      ...params,
    });
    updatedAt = Date.now();
  };
}

function getIsNowHealthy(params: Params) {
  const isAudioPlaying = !params.audioElement?.paused;
  const shouldAudioBePlaying = params.sessionPlayStatus === 'PLAYING';
  return shouldAudioBePlaying ? isAudioPlaying : !isAudioPlaying;
}

function logAudioCheck(params: {
  elapsedTime: number;
  message: string;
  audioElement: Params['audioElement'];
}): void {
  Logger.info(params.message, {
    bufferedLength: params.audioElement?.buffered.length,
    currentSrc: params.audioElement?.currentSrc,
    currentTime: params.audioElement?.currentTime,
    elapsedTime: params.elapsedTime,
    ended: params.audioElement?.ended,
    error: params.audioElement?.error,
    networkState: params.audioElement?.networkState,
    paused: params.audioElement?.paused,
    playbackRate: params.audioElement?.playbackRate,
    readyState: params.audioElement?.readyState,
  });
}
