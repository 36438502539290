import React, { useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

import ChevronIcon from '../../../../assets/images/chevron_up_icon.svg';
import { Analytics } from '../../../../utils/analytics';
import { ExploreEvents } from '../../../../utils/analytics/events';
import Icon from '../../Icon';
import * as S from './Accordion.styles';

export type Props = {
  analyticsEvent?: ExploreEvents | null;
  isExpandedByDefault?: boolean;
  expandIconTestId?: string;
  renderAnchor: () => React.ReactElement;
  renderDropdown: () => React.ReactElement;
};

export function Accordion(props: Props) {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(props.isExpandedByDefault || false);

  function handleClickExpand() {
    setIsExpanded(!isExpanded);

    if (props.analyticsEvent) {
      Analytics.logEventWithProperties(props.analyticsEvent, {
        type: isExpanded ? 'close' : 'open',
      } as any);
    }
  }

  return (
    <S.Accordion>
      <S.Anchor
        data-testid={props.expandIconTestId}
        isSelected={isExpanded}
        onClick={handleClickExpand}
      >
        {props.renderAnchor()}
        <S.Chevron data-cy="expandIcon" isDown={!isExpanded}>
          <Icon size={20} src={ChevronIcon} />
        </S.Chevron>
      </S.Anchor>
      <Transition in={isExpanded} mountOnEnter nodeRef={dropDownRef} timeout={0} unmountOnExit>
        {animationState => (
          <S.Dropdown ref={dropDownRef} animationState={animationState}>
            {props.renderDropdown()}
          </S.Dropdown>
        )}
      </Transition>
    </S.Accordion>
  );
}
