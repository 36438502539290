import { useSession } from '@Session';
import { UserMentalStatePreferenceTypes, useUser } from '@User';
import { FeatureFlags, useFeatureFlag } from '@Utils';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { logEventWithProperties } from '../../../../../actions/analytics';
import { usePlayerAnalyticsParams } from '../../../../../hooks/usePlayerAnalyticsParams';
import { RootReducerType } from '../../../../../reducers';
import { activities } from '../../../../../utils/activities';
import { TimerEvents } from '../../../../../utils/analytics/events';
import { mentalStates } from '../../../../../utils/mentalStates';
import { trackTimerStartEvent } from '../../../../Analytics/coreAnalytics';
import { TimerOrigin, MentalStates } from '../../../../Analytics/coreAnalytics.types';

export enum TimerMode {
  Infinite = 'infinite',
  Interval = 'interval',
  Custom = 'custom',
  Quotes = 'quotes',
}

export const useTrackTimerModeChange = () => {
  const dispatch = useDispatch();
  const user = useUser();
  const session = useSession();

  const currentPreferences = session ? user.mentalStatePreferences[session.mentalState] : null;
  const customPomodoroIntervals = useFeatureFlag(FeatureFlags.CustomPomodoroIntervals);

  const { sessionMentalStateId, sessionActivityId } = useSelector(
    (state: RootReducerType) => state.sessionManager,
  );

  const {
    activity,
    trackGenre,
    filterGenre,
    filterNel,
    sessionSidebarContentOrigin,
    trackNel,
    mentalState,
  } = usePlayerAnalyticsParams();

  return useCallback(
    ({
      timerMode,
      props,
      customTimerLength,
    }: {
      timerMode: TimerMode;
      props?: { pomodoroIntervals?: { focusTime: string; breakTime: string } };
      customTimerLength?: number;
    }) => {
      trackTimerStartEvent({
        activity,
        trackGenre,
        filterGenre,
        mentalState,
        filterNel,
        trackNel,
        origin: sessionSidebarContentOrigin as TimerOrigin,
        timerLength:
          timerMode === TimerMode.Custom ? ((customTimerLength || 0) / 60).toString() : '',
        timerMode: timerMode,
      });
      dispatch(
        logEventWithProperties({
          event: TimerEvents.PlayerTimerStart,
          props: {
            timer_mode: timerMode,
            is_custom_intervals: customPomodoroIntervals,
            interval_focus_length: props?.pomodoroIntervals?.focusTime || '',
            interval_break_length: props?.pomodoroIntervals?.breakTime || '',
            mentalState: sessionMentalStateId
              ? mentalStates.byId[sessionMentalStateId]?.display
              : '',
            activity: sessionActivityId ? activities.byId[sessionActivityId]?.display : '',
            genre: currentPreferences
              ? currentPreferences[UserMentalStatePreferenceTypes.Genres].join(',')
              : '',
            neuralEffectLevel: currentPreferences
              ? currentPreferences[UserMentalStatePreferenceTypes.NeuralEffectLevels].join(',')
              : '',
          } as any,
        }),
      );
    },
    [dispatch, session, user, customPomodoroIntervals, sessionSidebarContentOrigin],
  );
};
