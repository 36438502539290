import { showToast } from '@Utils';
import { Action } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import { RootReducerType } from 'src/reducers';

import { RequestMethods } from '../../../api/client/types';
import { requestSaga, SuccessResponseType } from '../../../sagas/httpRequest';
import { Logger } from '../../../utils/logger';
import { removeFromFavorites } from '../actions';
import { getTrackVariationId, getTrackVariationUrl } from '../lenses';
import { musicActions } from '../reducer';

export function* removeFromFavoritesSaga(action: Action) {
  if (removeFromFavorites.match(action)) {
    try {
      yield put(musicActions.removeFromFavorites(action.payload.track));

      const { info }: RootReducerType['user'] = yield select(
        (state: RootReducerType) => state.user,
      );

      showToast('Removed from your library');

      const response: SuccessResponseType = yield requestSaga(
        RequestMethods.PUT,
        `/users/${info?.id}/unfavorite?version=3`,
        {
          unfavorite: getTrackVariationUrl(action.payload.track),
          variationId: getTrackVariationId(action.payload.track),
        },
      );

      if (response.status !== 200) {
        yield put(musicActions.addToFavorites(action.payload.track));
      }
    } catch (error) {
      yield put(musicActions.addToFavorites(action.payload.track));
      Logger.error(error);
    }
  }
}
