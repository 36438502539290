import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4.125rem 2.5rem;
  width: 30.625rem;
  margin: auto;
  background: rgb(54, 50, 95);
  border: 1px solid rgba(55, 62, 91, 0.7);
  border-radius: 1.25rem;

  @supports (backdrop-filter: blur(2rem)) {
    backdrop-filter: blur(2rem);
    background: rgba(255, 255, 255, 0.05);
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const Image = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: contain;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 2rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1rem;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  color: #d4d2ea;
  text-align: center;
  width: 90%;
  margin-bottom: 1rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
