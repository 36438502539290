import { trackSignInWithMagicLink } from '@Analytics';
import { useToken } from '@Authentication';
import { API_BASE_URL } from '@Globals';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useQuery } from '../../hooks';
import { authSliceActions } from '../../reducers/authentication';
import { getUiErrorMessage } from '../../utils/getUiErrorMessage';
import { Logger } from '../../utils/logger';

export function IntentionsHandler() {
  const params = useParams<{ token: string }>();
  useConsumeIntentionTokenOnMount(params.token || '');
  return null;
}

export const useConsumeIntentionTokenOnMount = (token: string) => {
  const navigate = useNavigate();
  const query = useQuery();
  const existingUserToken = useToken();
  const isUserAlreadyLoggedIn = Boolean(existingUserToken);
  const dispatch = useDispatch();

  useEffect(() => {
    async function consumeIntentionToken() {
      try {
        const campaignName = query.get('campaignName') || 'null';
        const destination = query.get('destination') || '';

        if (campaignName || destination) {
          query.delete('campaignName');
          navigate(
            {
              search: query.toString(),
            },
            { replace: true },
          );
        }

        const response = await fetch(`${API_BASE_URL}/intentions/${token}/redeem`, {
          method: 'POST',
          headers: isUserAlreadyLoggedIn
            ? {
                Authorization: `Bearer ${existingUserToken}`,
              }
            : undefined,
        });
        const body = await response.json();

        if (body.status === 400) {
          throw new Error('token has expired.');
        }
        if (body.status !== 200) {
          throw new Error('something went wrong.');
        }

        switch (body.result.type) {
          case 'MagicSignIn':
            trackSignInWithMagicLink({ campaignName });

            if (!isUserAlreadyLoggedIn) {
              // magic sign in has side effects like extending trials, so logged in users may still
              // use them. if they aren't logged in, we need to log them in.
              dispatch(
                authSliceActions.loginSuccess({ token: body.result.token, type: 'magic_link' }),
              );
            }

            navigate(`/${destination}`, { replace: true });
            break;
          case 'MagicSignUp':
            dispatch(authSliceActions.loginSuccess({ token: body.result.token, type: 'email' }));
            navigate(`/${destination || 'welcome'}`, { replace: true });
            break;
          case 'MagicSignUpTeamUser':
            dispatch(authSliceActions.loginSuccess({ token: body.result.token, type: 'email' }));
            navigate(`/join/${body.result.teamId}`, { replace: true });
            break;
        }
      } catch (error) {
        const errorMessage = getUiErrorMessage(error);
        if (['token has expired.'].includes(errorMessage)) {
          Logger.info('useConsumeIntentionTokenOnMount(): token has expired.', {
            extra: { reason: error, token: token },
          });
        } else {
          Logger.error(new Error('useConsumeIntentionTokenOnMount(): failed to consume token.'), {
            extra: { reason: error, token: token },
          });
        }

        navigate('/', { replace: true });
      }
    }

    consumeIntentionToken();
  }, []);
};
