import { Icon } from '@Cortex';
import React from 'react';

import InfoIcon from '../../../../../../assets/images/info_icon.svg';
import * as S from './InfoLink.styles';

type Props = {
  link: string;
  text: string;
  onClick: () => void;
};

export const InfoLink = ({ link, text, onClick }: Props) => {
  return (
    <S.Link href={link} target="_blank" onClick={onClick}>
      <S.IconWrapper>
        <Icon size={12} src={InfoIcon} />
      </S.IconWrapper>
      <div>{text}</div>
    </S.Link>
  );
};
