import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

import CheckmarkIcon from './assets/checkmark.svg';

export const Container = styled.div`
  display: flex;
  height: calc(100vh - 9.5rem - 2rem);
  flex-direction: column;
  max-width: 35rem;
  min-width: 25rem;
  overflow: hidden auto;
  width: 25vw;
  position: relative;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    width: 100%;
    height: calc(100vh - 4rem - 2rem); // deduct Paper padding and parent padding
    min-width: initial;
    max-width: initial;
  }
`;

export const HeadingContainer = styled.div`
  margin-bottom: 1.5rem;
  text-transform: lowercase;
`;

export const OptionHeader = styled.div<{ isSelected: boolean }>`
  background: #191736;
  border-radius: 0.75rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 3px 3px 0.5rem 3px;
  padding: 1.125rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.78rem;
    width: 100%;
    height: 100%;
    background: linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%);
    z-index: -1;
    transition: transform 0.15s ease-in-out;
    transform: ${({ isSelected }) => (isSelected ? 'scale(1.005, 1.02)' : 'scale(1)')};
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          &::after {
            content: '';
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
            position: absolute;
            z-index: 1;
            top: 50%;
            right: 1.125rem;

            background-size: 60%, cover;
            object-fit: contain;
            transform: translateY(-50%);
            background:
              url(${CheckmarkIcon}) no-repeat center,
              linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%) no-repeat;
          }
        `
      : null}
`;

export const DropdownContainer = styled.div<{ animationState: TransitionStatus }>`
  background-color: rgba(25, 23, 54, 0.5);
  border-radius: 0.75rem;
  padding: 1rem;

  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;

  transform: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return `translateY(-5px)`;
      case 'entered':
        return `translateY(0)`;
      case 'exiting':
        return `translateY(0)`;
      case 'exited':
        return `translateY(-5px)`;
    }
  }};

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0.2;
      case 'entered':
        return 1;
      case 'exiting':
        return 0.2;
      case 'exited':
        return 0;
    }
  }};
`;

export const TimerInfoContainer = styled.div`
  margin-bottom: 1rem;
`;

export const TimerInfoPresetsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ButtonPresetWrapper = styled.div`
  width: 32%;
`;

export const IconContainer = styled.div<{ isDown?: boolean }>`
  transition: transform 0.3s ease-in-out;
  transform: rotate(${({ isDown = false }) => (isDown ? '180deg' : '0deg')});
`;
