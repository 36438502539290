import { NOOP } from '@Globals';
import { Formik, FormikConfig } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { Button, ButtonVariants, ButtonVariantStates } from '../Button';
import { FormInput } from '../FormInput';
import * as S from './Form.styles';

type Inputs<Values> = {
  label: string;
  name: Extract<keyof Values, string>;
  placeholder: string;
  type: string;
};

export type Props<Values> = {
  children?: React.ReactNode;
  formError: string | null;
  formInitialValues: Values;
  formInputs: Inputs<Values>[];
  formValidationSchema: Yup.Schema<any>;
  isLoading: boolean;
  onFormChange?: () => void;
  onFormSubmit: FormikConfig<Values>['onSubmit'];
  onFormSubmitAnalyticsEvent: string;
  submitButtonText: string;
};

export function Form<Values extends { [key: string]: string }>(props: Props<Values>) {
  return (
    <S.Form>
      <Formik<Values>
        initialValues={props.formInitialValues}
        validationSchema={props.formValidationSchema}
        onSubmit={props.onFormSubmit}
      >
        {createRenderForm<Values>(props)}
      </Formik>
      {props.children}
    </S.Form>
  );
}

function createRenderForm<Values extends { [key: string]: string }>({
  onFormChange = NOOP,
  ...props
}: Props<Values>): FormikConfig<Values>['children'] {
  return function renderForm({ touched, handleChange, handleSubmit, handleBlur, values, errors }) {
    function handleFormChange(event: any) {
      onFormChange();
      handleChange(event);
    }

    return (
      <S.FormBody onSubmit={handleSubmit}>
        {props.formInputs.map(input => {
          return (
            <FormInput
              key={input.name}
              errorMessage={touched[input.name] ? (errors[input.name] as string) : undefined}
              label={input.label}
              name={input.name}
              placeholder={input.placeholder}
              type={input.type}
              value={values[input.name]}
              onBlur={handleBlur}
              onChange={handleFormChange}
            />
          );
        })}
        <Button
          analyticsEvent={props.onFormSubmitAnalyticsEvent}
          data-testid="handle-submit-form"
          isFullWidth={true}
          type="submit"
          variant={ButtonVariants.Primary}
          variantState={
            props.formError
              ? ButtonVariantStates.Error
              : props.isLoading
              ? ButtonVariantStates.Loading
              : ButtonVariantStates.Idle
          }
        >
          {props.formError || props.submitButtonText}
        </Button>
      </S.FormBody>
    );
  };
}
