import { useQuery } from '@tanstack/react-query';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { useRequestHandler } from './useRequestHandler';
import { DynamicMentalState } from '@Model';

export const useDynamicMentalStates = (featureFlag: boolean) => {
  const getDynamicMentalStates = useRequestHandler();

  return useQuery<DynamicMentalState[]>(
    Queries.getDynamicMentalStates,
    () => getDynamicMentalStates(),
    {
      onError: err => {
        Logger.error(new Error('Error getting dynamic mental states'), {
          err,
        });
      },
      enabled: featureFlag,
    },
  );
};
