import { ButtonVariantStates } from '@Cortex';
import { NOOP } from '@Globals';
import { membershipActions } from '@Memberships';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootReducerType } from '../../../../reducers';
import theme from '../../../../styles/theme';
import { Logger } from '../../../../utils/logger';
import { getCardInformationDisplayValue } from '../../lib/getCardInformationDisplayValue';
import { UpdateStripePaymentMethodPanel } from './UpdateStripePaymentMethodPanel.display';
import { handleSubmitUpdatePaymentForm as _handleSubmitUpdatePaymentForm } from './lib/handleSubmitUpdatePaymentForm';

type Props = {
  handleSubmitUpdatePaymentForm?: typeof _handleSubmitUpdatePaymentForm;
  onClose?: () => void;
};

type UpdateFormStatus = 'idle' | 'loading' | 'success' | 'error';

export function UpdateStripePaymentMethodPanelContainer({
  handleSubmitUpdatePaymentForm = _handleSubmitUpdatePaymentForm,
  onClose = NOOP,
}: Props) {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const stripeElements = useElements();
  const inflightRequest = useRef(false);

  const membership = useSelector((state: Pick<RootReducerType, 'membership'>) => state.membership);
  const { id: userId, firstName } = useSelector((state: RootReducerType) => state.user.info!);
  const { token } = useSelector((state: RootReducerType) => state.auth);

  const [formStatus, setFormStatus] = useState<UpdateFormStatus>('idle');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function handleSubmit(billingName: string) {
    if (inflightRequest.current) {
      return;
    }

    try {
      inflightRequest.current = true;
      setFormStatus('loading');
      setErrorMessage(null);

      const result = await handleSubmitUpdatePaymentForm({
        stripe,
        stripeElements,
        userId,
        billingName,
        token: token || '',
      });

      if (!result.result) {
        setFormStatus('error');
        setErrorMessage('Something went wrong.');
        Logger.error(new Error(result.error));
        return;
      }

      setFormStatus('success');
      dispatch(membershipActions.fetchMembership());

      setTimeout(onClose, 1500);
    } catch (error) {
      setFormStatus('error');
    } finally {
      inflightRequest.current = false;
      setTimeout(() => {
        setFormStatus('idle');
      }, 1500);
    }
  }

  let buttonStyle = ButtonVariantStates.Idle;
  let buttonTitle = `Save`;

  if (formStatus === 'success') {
    buttonStyle = ButtonVariantStates.Success;
    buttonTitle = `Success`;
  } else {
    buttonStyle = ButtonVariantStates.Idle;
    buttonTitle = `Save`;
  }

  return (
    <UpdateStripePaymentMethodPanel
      billingName={firstName || undefined}
      cardInformation={getCardInformationDisplayValue(membership)}
      errorMessage={errorMessage}
      isLoading={formStatus === 'loading'}
      submitButtonTitle={buttonTitle}
      submitButtonVariantState={buttonStyle}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <CardElement
        options={{
          disabled: formStatus === 'loading',
          style: {
            base: {
              color: '#fff',
              fontSize: `16px`,
              fontFamily: theme.font.family.poppins.regular,
              fontWeight: theme.font.weight.regular,
              ':-webkit-autofill': { color: theme.colors.brandPrimary },
              '::placeholder': {
                color: '#fff',
              },
            },

            invalid: { color: 'red' },
          },
        }}
      />
    </UpdateStripePaymentMethodPanel>
  );
}
