import { Serving, Track, TrackVariation } from '@Model';

import { isServing } from './isServing';

export const getTrackVariations = (
  trackOrServing: Track | Serving,
): Omit<TrackVariation, 'track'>[] => {
  if (isServing(trackOrServing)) {
    return trackOrServing.track.variations;
  }

  return trackOrServing.serving.track.variations;
};
