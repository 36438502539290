import { ActivityDataSingle } from '../../../../types';
import * as S from './ActivitySettings.styles';
import { Activity } from './components/Activity';

export interface Props {
  activities: ActivityDataSingle[];
  selectedActivity: ActivityDataSingle['display'];
  onSelectActivity: (value: ActivityDataSingle) => void;
}

export const ActivitySettingsDisplay = ({
  selectedActivity,
  onSelectActivity,
  activities,
}: Props) => {
  return (
    <S.ContentWrapper>
      {activities.map(activity => {
        return (
          <Activity
            key={activity.type}
            data-testid={`activity_${activity.type}`}
            description={activity.description}
            isSelected={selectedActivity === activity.display}
            title={activity.display}
            onClick={() => onSelectActivity(activity)}
          />
        );
      })}
    </S.ContentWrapper>
  );
};
