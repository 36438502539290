import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { TextBold } from '@Cortex';
import { Serving, Track } from '@Model';
import { getTrackId, getTrackName } from '@Music';
import { getTrackVariationId } from '@Music';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { trackCardLoad } from '../../../../../../actions/sessionManager';
import { useRecentTracks } from '../../../../../../api/modules/RecentTracks';
import { useIsEnabledDynamicActivities } from '../../../../../../hooks/useIsEnabledDynamicActivities';
import { useShareTrack } from '../../../../../../hooks/useShareTrack';
import { RootReducerType } from '../../../../../../reducers';
import { Analytics } from '../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../utils/analytics/events';
import { getDynamicSimilarActivityPlayerPath } from '../../../../../../utils/getDynamicSimilarActivityPlayerPath';
import {
  removeFromFavorites,
  addToFavorites,
  removeFromDislikes,
  addToDislikes,
} from '../../../../actions';
import { ExpandableTrackCard } from '../../../ExpandableTrackCard';
import * as S from './Recent.styles';

type TrackDictionary = {
  [key: string]: Track | Serving;
};

export function Recent() {
  const { handleGetShareLink } = useShareTrack();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recent = useRecentTracks();
  const dislikedTrackIds = useSelector((state: RootReducerType) => state.music.dislikedTrackIds);
  const favorites = useSelector((state: RootReducerType) => state.music.favorites);
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();

  if (recent.isLoading) {
    return <S.Loading />;
  }

  if (recent.error) {
    return <TextBold>{recent.error.message}</TextBold>;
  }

  const tracks: Serving[] = [...(recent.data || [])];

  const recentTrackVariationsDictionary = tracks.reduce((acc, track: Serving) => {
    acc[track.trackVariation.id] = track;
    return acc;
  }, {} as TrackDictionary);

  function handleVariationClickPlay(track: Track | Serving) {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromRecents, {
      trackId: getTrackId(track),
      trackVariationId: getTrackVariationId(track),
      trackName: getTrackName(track),
    } as any);

    if (isUsingDynamicActivities) {
      navigate(getDynamicSimilarActivityPlayerPath(getTrackId(track)));
    } else {
      dispatch(
        trackCardLoad({
          trackId: getTrackId(track),
          trackVariationId: getTrackVariationId(track),
          isUsingDynamicActivities,
        }),
      );
    }

    CoreAnalytics.trackSelectTrackEvent({
      origin: TrackActionOrigins.Recents,
      trackName: getTrackName(track),
    });
  }

  return (
    <S.CardsContainer>
      {Object.values(recentTrackVariationsDictionary).map(track => {
        const isFavorited = favorites.some(favorite => {
          return getTrackVariationId(favorite) === getTrackVariationId(track);
        });

        const trackId = getTrackId(track);

        return (
          <S.CardContainer
            key={getTrackVariationId(track) || trackId}
            data-testid="recentTrackItem"
          >
            <ExpandableTrackCard
              analyticsEvent={ExploreEvents.toggle_track_card_expansion__recent}
              dislikeButtonTestId={`addToDislikes__${trackId}`}
              displayType="trackVariation"
              expandIconTestId={`expandButton__${trackId}`}
              favoritesButtonTestId={`removeFromFavorites__${trackId}`}
              isDisliked={dislikedTrackIds.includes(trackId)}
              isDislikingAvailable={true}
              isFavorited={isFavorited}
              isFavoritingAvailable={true}
              track={track}
              onAddToDislikes={() => dispatch(addToDislikes({ track }))}
              onAddToFavorites={() =>
                dispatch(addToFavorites({ origin: TrackActionOrigins.Recents, track }))
              }
              onClickPlay={() => handleVariationClickPlay(track)}
              onRemoveFromDislikes={() =>
                dispatch(
                  removeFromDislikes({
                    trackId,
                  }),
                )
              }
              onRemoveFromFavorites={() =>
                dispatch(
                  removeFromFavorites({
                    track,
                  }),
                )
              }
              onShareTrack={() => handleGetShareLink(track)}
              onVariationClickPlay={() => handleVariationClickPlay(track)}
            />
          </S.CardContainer>
        );
      })}
    </S.CardsContainer>
  );
}
