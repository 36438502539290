import { Elements } from '@stripe/react-stripe-js';

import stripe, { ELEMENT_OPTIONS } from '../../../../utils/stripe';
import { StripePaymentFullScreen } from '../StripePaymentFullScreen';
import { StripePaymentFullScreen30DaysTrial } from '../StripePaymentFullScreen30DaysTrial';
import { useIsExtendedPromoAvailable } from './hooks/useIsExtendedPromoAvailable';
import { OnboardingTestimonials } from '../../../../domains/Onboarding/types';

type Props = {
  paywallBenefits?: string[];
  paywallTestimonials?: OnboardingTestimonials[];
  onPaymentComplete: () => void;
  onClose: () => void;
};

export function StripePaymentTestContainer(props: Props) {
  const isExtendedPromoAvailable = useIsExtendedPromoAvailable();

  if (isExtendedPromoAvailable) {
    return (
      <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
        <StripePaymentFullScreen30DaysTrial
          paywallBenefits={props.paywallBenefits}
          paywallTestimonials={props.paywallTestimonials}
          onClose={props.onClose}
          onPaymentComplete={props.onPaymentComplete}
        />
      </Elements>
    );
  }

  return (
    <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
      <StripePaymentFullScreen
        paywallBenefits={props.paywallBenefits}
        paywallTestimonials={props.paywallTestimonials}
        onClose={props.onClose}
        onPaymentComplete={props.onPaymentComplete}
      />
    </Elements>
  );
}
