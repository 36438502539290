import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { TGenreNames } from './types';
import { useRequestHandler } from './useRequestHandler';

export const useGenreNamesByDynamicMentalState = () => {
  const dynamicMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.mentalState.id,
  );
  const getGenreNames = useRequestHandler();

  return useQuery<TGenreNames>(
    Queries.getGenreNamesByDynamicMentalState(dynamicMentalStateId!),
    () => getGenreNames(dynamicMentalStateId!),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: err => {
        Logger.error(new Error('Error getting Genre Names by Dynamic Mental State Id'), {
          dynamicMentalStateId,
          err,
        });
      },
      enabled: Boolean(dynamicMentalStateId),
    },
  );
};
