import styled, { keyframes } from 'styled-components';

import { Assets } from '../../utils/assets';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const LoadingImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  animation: ${rotation} 1s infinite linear;
`;

const LoadingIcon: React.FC = () => {
  return <LoadingImage alt={Assets.icons.loading.alt} src={Assets.icons.loading.url} />;
};

export default LoadingIcon;
