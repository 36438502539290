import { TrackActionOrigins } from '@Analytics';
import { NOOP } from '@Globals';
import {
  addToDislikes,
  addToFavorites,
  CurrentTrackInformationCard,
  getTrackGenreName,
  getTrackId,
  getTrackImageUrl,
  getTrackIsNewlyCreated,
  getTrackName,
  getTrackNeuralEffectLevel,
  isTrackInDislikes,
  isTrackInFavorites,
  removeFromDislikes,
  removeFromFavorites,
} from '@Music';
import { getTrackVariationId } from '@Music';
import { useSession } from '@Session';
import { useUser } from '@User';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useShareTrack } from '../../../../../../hooks/useShareTrack';
import { RootReducerType } from '../../../../../../reducers';
import { isTrackVariationInFavorites } from '../../../../lenses/isTrackVariationInFavorites';
import * as S from './TrackInformation.styles';

interface Props {
  onClick: () => void;
  isVariationBased?: boolean;
}

export const TrackInformation = ({ onClick = NOOP, isVariationBased }: Props) => {
  const dispatch = useDispatch();
  const session = useSession();
  const user = useUser();
  const { handleGetShareLink } = useShareTrack();

  const currentTrack = useSelector((state: RootReducerType) => state.music.currentTrack);
  const currentTrackId = getTrackId(currentTrack);

  const isCurrentTrackInDislikes = useSelector(
    isTrackInDislikes({ id: currentTrack ? currentTrackId : '' }),
  );
  const isCurrentTrackInFavorites = useSelector(
    isTrackInFavorites({ id: currentTrack ? currentTrackId : '' }),
  );

  const isCurrentTrackVariationInFavorites = useSelector(
    isTrackVariationInFavorites({
      trackVariationId: currentTrack ? getTrackVariationId(currentTrack) : '',
    }),
  );

  const handleDislikeClick = () => {
    // disliking is track-based only
    if (!currentTrack) return;

    if (isCurrentTrackInDislikes) {
      dispatch(removeFromDislikes({ trackId: currentTrackId }));
    } else {
      dispatch(addToDislikes({ track: currentTrack }));
      window.setTimeout(() => window.dispatchEvent(new Event('skip')), 1000);
    }
  };

  const handleFavoriteClick = () => {
    if (!currentTrack) return;

    if (
      (isVariationBased && isCurrentTrackVariationInFavorites) ||
      (!isVariationBased && isCurrentTrackInFavorites)
    ) {
      dispatch(
        removeFromFavorites({
          track: currentTrack,
        }),
      );
    } else {
      dispatch(addToFavorites({ origin: TrackActionOrigins.TrackPlayer, track: currentTrack }));
    }
  };

  const onShareTrack = useCallback(() => {
    if (!currentTrack) return;
    handleGetShareLink(currentTrack);
  }, [handleGetShareLink, currentTrack]);

  return (
    <S.PlayerContainer onClick={onClick}>
      {currentTrack ? (
        <CurrentTrackInformationCard
          dislikeButtonTestId={
            isCurrentTrackInDislikes
              ? `removeFromDislikes__${currentTrackId}`
              : `addToDislikes__${currentTrackId}`
          }
          genre={getTrackGenreName(currentTrack)}
          imageUrl={getTrackImageUrl(currentTrack)}
          isDisliked={isCurrentTrackInDislikes}
          isDislikingAvailable={Boolean(isVariationBased)}
          isFavorited={
            isVariationBased ? isCurrentTrackVariationInFavorites : isCurrentTrackInFavorites
          }
          isFavoritingAvailable={Boolean(isVariationBased)}
          isNewlyCreated={getTrackIsNewlyCreated(currentTrack)}
          neuralEffectLevel={getTrackNeuralEffectLevel(currentTrack)}
          title={getTrackName(currentTrack)}
          onAddToDislikes={handleDislikeClick}
          onAddToFavorites={handleFavoriteClick}
          onRemoveFromDislikes={handleDislikeClick}
          onRemoveFromFavorites={handleFavoriteClick}
          onShareTrack={onShareTrack}
        />
      ) : (
        <S.NoTracksText data-testid="noTracksMessage">
          {Boolean(
            session &&
              (user.mentalStatePreferences[session.mentalState].genreNames.length ||
                user.mentalStatePreferences[session.mentalState].neuralEffectLevels.length),
          )
            ? 'No tracks found matching your preferences.'
            : 'No Tracks Loaded'}
        </S.NoTracksText>
      )}
    </S.PlayerContainer>
  );
};
