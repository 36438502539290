import styled from 'styled-components';

export const Wrapper = styled.div`
  color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const Logo = styled.img`
  height: 3.5rem;
  width: 3.5rem;
`;

export const LogoPlaceholder = styled(Logo)`
  opacity: 0;
`;

export const BackgroundImage = styled.div<{ desktopSrc: string; mobileSrc?: string }>`
  background: url('${({ desktopSrc }) => desktopSrc}');
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  pointer-events: none;
  background-size: 95%;
  background-repeat: no-repeat;
  background-position: left bottom;
  ${({ theme, mobileSrc }) => mobileSrc && theme.mediaQuery.maxWidth.sm} {
    background: url('${({ mobileSrc }) => mobileSrc}');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left center;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2rem 3rem 0px 3rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 79rem;
  flex-grow: 1;
  justify-content: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    flex-direction: column;
    margin-bottom: 2rem;
    max-width: 30rem;
  }
`;

export const OnboardingCardTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    padding: 1rem;
  }
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 2rem;
  white-space: pre-line;
  text-align: center;
  max-width: 800px;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    font-size: 2.5rem;
    line-height: auto;
    margin-bottom: 3rem;
  }
`;

export const Body = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 2rem;
  white-space: pre-wrap;
  line-height: 3rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1rem;
    opacity: 1;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    align-items: center;
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  width: 20.75rem;
`;

export const StepSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  gap: 0.5rem;
  justify-self: center;
`;
export const StepText = styled.div`
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.75);
`;

export const StepPills = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StepPill = styled.div<{
  isActive: boolean;
  isCompleted: boolean;
}>`
  background: ${({ theme, isActive, isCompleted }) =>
    isActive ? `transparent` : isCompleted ? theme.colors.white : `rgba(255, 255, 255, 0.2)`};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, isActive }) => (isActive ? theme.colors.white : `transparent`)};
  border-radius: 0.5rem;
  height: 0.5rem;
  margin-right: 0.25rem;
  width: 4rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex: 1;
    height: 8px;
    margin: 0px 3px;
    max-width: 64px;
  }
`;
