import { EffectLevels } from '@Model';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 0.6rem;
`;

const EffectBar = styled.div<{ isActive: boolean }>`
  background: white;
  border-radius: 3px;
  height: 3px;
  margin-bottom: 2px;
  opacity: ${props => (props.isActive ? '1' : '0.4')};
`;

type Props = {
  effectLevel: EffectLevels;
};

export function NeuralEffectLevelIcon({ effectLevel }: Props) {
  return (
    <Container>
      <EffectBar isActive={effectLevel === EffectLevels.High} />
      <EffectBar isActive={effectLevel !== EffectLevels.Low} />
      <EffectBar isActive={true} />
    </Container>
  );
}
