import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { Track } from '@Model';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { trackCardLoad } from '../../../../actions/sessionManager';
import { useIsEnabledDynamicActivities } from '../../../../hooks/useIsEnabledDynamicActivities';
import { Analytics } from '../../../../utils/analytics';
import { ExploreEvents } from '../../../../utils/analytics/events';
import { getDynamicSimilarActivityPlayerPath } from '../../../../utils/getDynamicSimilarActivityPlayerPath';
import { getTrackName, getTrackVariationId, getTrackId } from '../../lenses';
import { RecommendedTrackDisplay } from './RecommendedTrack.display';

type Props = {
  tracks: Track[];
};

export const RecommendedTrack = ({ tracks }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();

  const handleClick = (track: Track) => {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromRecommendedTrack, {
      trackId: getTrackId(track),
      trackVariationId: getTrackVariationId(track),
      trackName: getTrackName(track),
    } as any);

    if (isUsingDynamicActivities) {
      navigate(getDynamicSimilarActivityPlayerPath(getTrackId(track)));
    } else {
      dispatch(
        trackCardLoad({
          trackId: getTrackId(track),
          trackVariationId: getTrackVariationId(track),
          isUsingDynamicActivities,
        }),
      );
    }

    CoreAnalytics.trackSelectTrackEvent({
      origin: TrackActionOrigins.Recommended,
      trackName: getTrackName(track),
    });
  };

  return <RecommendedTrackDisplay tracks={tracks || []} onClick={handleClick} />;
};
