import { HeaderWithLogoAndClose } from '../../../../domains/shared/HeaderWithLogoAndClose';
import { Assets } from '../../../../utils/assets';
import { MentalStateCard } from './MentalStateCard';
import * as S from './OnboardingMentalStates.styles';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';

type Props = {
  onSelect: (mentalStateId: string) => void;
};

export const OnboardingMentalStatesPresentation = ({ onSelect }: Props) => {
  const ref = useRef(null);

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <HeaderWithLogoAndClose />
      </S.HeaderWrapper>
      <S.Container>
        <Transition in mountOnEnter nodeRef={ref} timeout={100} unmountOnExit>
          {state => (
            <S.ContentWrapper ref={ref} animationState={state}>
              <S.Header>Get started</S.Header>
              <S.Subheader>What would you like to try first?</S.Subheader>
              <S.MentanStates>
                <MentalStateCard
                  imageOne={{
                    url: Assets.images.focusMentalStateBgWebp.url,
                    alt: Assets.images.focusMentalStateBgAuraPng.alt,
                  }}
                  imageTwo={{
                    url: Assets.images.focusMentalStateBgAuraWebp.url,
                    alt: Assets.images.focusMentalStateBgAuraPng.alt,
                  }}
                  text="focus"
                  onClick={() => onSelect('focus')}
                />
                <MentalStateCard
                  imageOne={{
                    url: Assets.images.relaxMentalStateBgWebp.url,
                    alt: Assets.images.relaxMentalStateBgAuraPng.alt,
                  }}
                  imageTwo={{
                    url: Assets.images.relaxMentalStateBgAuraWebp.url,
                    alt: Assets.images.relaxMentalStateBgAuraPng.alt,
                  }}
                  text="relax"
                  onClick={() => onSelect('relax')}
                />
                <MentalStateCard
                  imageOne={{
                    url: Assets.images.sleepMentalStateBgWebp.url,
                    alt: Assets.images.sleepMentalStateBgAuraPng.alt,
                  }}
                  imageTwo={{
                    url: Assets.images.sleepMentalStateBgAuraWebp.url,
                    alt: Assets.images.sleepMentalStateBgAuraPng.alt,
                  }}
                  text="sleep"
                  onClick={() => onSelect('sleep')}
                />
              </S.MentanStates>
            </S.ContentWrapper>
          )}
        </Transition>
      </S.Container>
    </S.Wrapper>
  );
};
