import styled, { keyframes, css } from 'styled-components';

import { RING_STROKE_WIDTH_REM, RING_PULSE_DURATION } from './constants';
import { SESSION_ANIMATION_DURATION_MS } from './utils/constants';

export const fadeinAndRise = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to   {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #191736;
`;

export const SessionWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  animation: ${fadeinAndRise} ${SESSION_ANIMATION_DURATION_MS}ms;
  overflow: hidden;
  position: relative;
`;

export const spreadMain = keyframes`
  0% {
    stroke: rgba(255, 255, 255, 0.1);
    transform: scale(1);
  }
  20% {
    stroke: rgba(255, 255, 255, 0.3);
    transform: scale(1.01);
  }
  40%, 100% {
    stroke: rgba(255, 255, 255, 0.1);
    transform: scale(1);
  }
`;

export const spreadOuterOne = keyframes`
  0%, 10% {
    stroke: rgba(255, 255, 255, 0.05);
    transform: scale(1);
  }
  30% {
    stroke: rgba(255, 255, 255, 0.2);
    transform: scale(1.01);
  }
  60%, 100% {
    stroke: rgba(255, 255, 255, 0.05);
    transform: scale(1);
  }
`;

export const spreadOuterTwo = keyframes`
  0%, 20% {
    stroke: rgba(255, 255, 255, 0.02);
    transform: scale(1);
  }
  50% {
    stroke: rgba(255, 255, 255, 0.1);
    transform: scale(1.01);
  }
  70%, 100% {
    stroke: rgba(255, 255, 255, 0.02);
    transform: scale(1);
  }
`;

export const SvgRings = styled.svg`
  position: absolute;
`;

export const CircleBase = styled.circle<{ isAnimating: boolean }>`
  stroke-width: ${RING_STROKE_WIDTH_REM}rem;
  stroke-opacity: 1;
  fill: white;
  fill-opacity: 0;
  transform-origin: 50% 50%;
  animation-duration: ${RING_PULSE_DURATION}s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: ${({ isAnimating }) => (isAnimating ? 'running' : 'paused')};
`;

export const CircleOne = styled(CircleBase)`
  stroke: rgba(255, 255, 255, 0.1);

  @media (prefers-reduced-motion: no-preference) {
    animation-name: ${spreadMain};
  }
`;

export const CircleTwo = styled(CircleBase)`
  stroke: rgba(255, 255, 255, 0.05);

  @media (prefers-reduced-motion: no-preference) {
    animation-name: ${spreadOuterOne};
  }
`;

export const CircleThree = styled(CircleBase)`
  stroke: rgba(255, 255, 255, 0.02);

  @media (prefers-reduced-motion: no-preference) {
    animation-name: ${spreadOuterTwo};
  }
`;

export const GradientContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  will-change: auto;
  isolation: isolate;
  pointer-events: none;
`;

export const BackgroundWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SessionProTipWrapper = styled.div`
  animation: ${({ theme }) => theme.animations.fadeIn} 0.6s ease-in-out;
  display: flex;
  position: absolute;
  z-index: 9999;

  // magic, just line up with player on main screen
  bottom: 8rem;
  right: 1rem;
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    bottom: 12rem;
    right: 0rem;
  }
`;

export const SidebarContentContainer = styled.div<{ isCentered: boolean }>`
  ${props =>
    props.isCentered
      ? css`
          display: flex;
          height: 100vh;
          overflow: hidden;
          padding: 15vh 5vw;
          position: absolute;
          right: 0;
          top: 0;
          width: 100vw;
          z-index: 100;
        `
      : css`
          padding: 1rem;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 100;
        `}

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    padding: 1rem;
    right: 0;
    top: 0;
    z-index: 100;
    ${props => (props.isCentered ? 'height: auto;' : null)}
    width: 100%;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 999;
`;

export const CloseButton = styled.img`
  cursor: pointer;
`;
