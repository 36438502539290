import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';

export function isGiftSubscription(membership: RootReducerType['user']['membership']): boolean {
  return membership?.source === 'GiftUp';
}

export function useIsGiftSubscription() {
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);

  return isGiftSubscription(legacyMembership);
}
