import styled, { css } from 'styled-components';

export const StyledAudioControlBar = styled.div<{ isProfileScreen: boolean }>`
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 2rem;
  border: 1px solid rgba(55, 62, 91, 0.7);
  display: flex;
  flex-direction: column;
  margin: 0 auto 2.25rem auto;
  padding: 1rem;
  width: 100%;
  backdrop-filter: blur(34px);
  background: rgba(255, 255, 255, 0.05);
  transition: background 2s ease-in-out;

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    align-items: baseline;
    background: transparent;
    padding-bottom: 4rem;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    border-radius: 0px;
    flex-direction: row;
    margin: 0;
    width: 100%;
    border: none;

    ${({ isProfileScreen }) =>
      isProfileScreen
        ? css`
            background: #0d0c11;
            backdrop-filter: blur(34px);
            border-top: 1px solid rgba(255, 255, 255, 0.2);
          `
        : css`
            background: transparent;
            backdrop-filter: blur(0px);
          `};
  }
`;

export const ControlLayout = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const TrackInformationColumn = styled.div<{ fade: boolean }>`
  align-items: flex-end;
  display: flex;
  flex: 5;
  opacity: ${props => (props.fade ? 0 : 1)};

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    flex: 10;
  }
`;

export const PlayerControlsColumn = styled.div`
  align-items: center;
  display: flex;
  flex: 2;
`;

export const VolumeColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 5;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    display: none;
  }
`;

export const StreaksWrapper = styled.div`
  margin-bottom: 1.5rem;
`;
