import { TextBold } from '@Cortex';
import styled from 'styled-components';

export const CardsContainer = styled.div`
  margin-top: 1.25rem;
`;

export const CardContainer = styled.div`
  margin-bottom: 1rem;
  margin-right: 0.25rem;
`;

export const Loading = styled.div`
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:after {
    animation: rotation 0.8s linear infinite;
    border-radius: 100%;
    border-right: 3px solid transparent;
    border-top: 3px solid rgba(255, 255, 255, 0.8);
    content: '';
    height: 2rem;
    position: absolute;
    width: 2rem;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const Title = styled(TextBold)`
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
`;
