import { Track } from '@Model';

import { useShareTrack } from '../../../../hooks/useShareTrack';
import { ExploreEvents } from '../../../../utils/analytics/events';
import { ExpandableTrackCard } from '../ExpandableTrackCard';
import * as S from './RecommendedTrack.styles';

interface Props {
  tracks: Track[];
  testId?: string;
  onClick: (value: Track) => void;
}

export const RecommendedTrackDisplay = ({ tracks, onClick }: Props) => {
  const { handleGetShareLink } = useShareTrack();

  return (
    <S.Container data-testid="recommendedTrackCard">
      <S.Title size="1.5rem">recommended for you</S.Title>

      {tracks.map(track => (
        <S.CardContainer key={track.id}>
          <ExpandableTrackCard
            analyticsEvent={ExploreEvents.toggle_track_card_expansion__recommended_track}
            displayType="track"
            expandIconTestId={`expandButton__${track.id}`}
            isDisliked={false}
            isDislikingAvailable={false}
            isFavorited={false}
            isFavoritingAvailable={false}
            track={track}
            onClickPlay={() => onClick(track)}
            onShareTrack={() => handleGetShareLink(track)}
            onVariationClickPlay={() => onClick(track)}
          />
        </S.CardContainer>
      ))}
    </S.Container>
  );
};
