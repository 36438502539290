import { Serving, Track } from '@Model';
import {
  getTrackActivityId,
  getTrackComplexity,
  getTrackGenreName,
  getTrackId,
  getTrackMentalStateId,
  getTrackName,
} from '@Music';
import { useCallback } from 'react';

import { trackShareTrackEvent } from '../domains/Analytics/coreAnalytics';
import { MentalStates } from '../domains/Analytics/coreAnalytics.types';
import { UNIVERSAL_LINK_ACTIONS } from '../types/universalLinks';
import { activities } from '../utils/activities';
import { Analytics } from '../utils/analytics';
import { mentalStates } from '../utils/mentalStates';
import { usePlayerAnalyticsParams } from './usePlayerAnalyticsParams';
import { useTimerMode } from './useTimerMode';

export const useShareTrack = () => {
  const timerMode = useTimerMode();
  const { activity, trackGenre, filterGenre, filterNel, trackNel, complexity, trackName } =
    usePlayerAnalyticsParams();

  const handleGetShareLink = useCallback(
    (track: Track | Serving) => {
      if (!track) return;

      const activityId = getTrackActivityId(track);
      const id = getTrackId(track);
      const genreName = getTrackGenreName(track);
      const complexityDisplayValue = getTrackComplexity(track);
      const mentalStateId = getTrackMentalStateId(track);
      const mentalState = mentalStates.byId[mentalStateId]?.type;
      const link = `${window.location.origin}/intentions/share?intention_action=${UNIVERSAL_LINK_ACTIONS.PlayTrack}&intention_value=${id}&mental_state=${mentalState}`;

      trackShareTrackEvent({
        activity,
        complexity,
        trackNel,
        trackGenre,
        filterGenre,
        filterNel,
        mentalState: mentalState as MentalStates,
        timerMode,
        trackName,
      });

      Analytics.logEventWithProperties('share_track', {
        id,
        name: getTrackName(track),
        genre: genreName || 'error',
        activity: activities.byId[activityId]?.display || 'error',
        mentalState: mentalState || 'error',
        complexity: complexityDisplayValue,
      } as any);
      navigator.clipboard.writeText(link || '').then(
        () => {
          alert('Link copied to the clipboard');
        },
        err => {
          console.error('Async: Could not copy text: ', err);
        },
      );
      return link;
    },
    [timerMode],
  );

  return { handleGetShareLink };
};
