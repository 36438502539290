import styled, { keyframes, css } from 'styled-components';

import { ReactComponent as LevelIcon } from '../../assets/effect_level.svg';

const bottomBar = keyframes`
  0% {
    opacity: 0.4;
  }
  14.28% {
    opacity: 1;
  }
  28.56% {
    opacity: 1;
  }
  42.84% {
    opacity: 1;
  }
  57.12% {
    opacity: 1;
  }
  71.4% {
    opacity: 1;
  }
  85.68% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
`;
const middleBar = keyframes`
  0% {
    opacity: 0.4;
  }
  14.28% {
    opacity: 0.4;
  }
  28.56% {
    opacity: 1;
  }
  42.84% {
    opacity: 1;
  }
  57.12% {
    opacity: 1;
  }
  71.4% {
    opacity: 1;
  }
  85.68% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.4;
  }
`;
const topBar = keyframes`
  0% {
    opacity: 0.4;
  }
  14.28% {
    opacity: 0.4;
  }
  28.56% {
    opacity: 0.4;
  }
  42.84% {
    opacity: 1;
  }
  57.12% {
    opacity: 1;
  }
  71.4% {
    opacity: 0.4;
  }
  85.68% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.4;
  }
`;

const animationDuration = 3;

export const EffectLevelIcon = styled(LevelIcon)`
  width: 8px;
  height: 8px;
  & #high {
    animation: ${topBar} ${animationDuration}s linear infinite;
  }
  & #medium {
    animation: ${middleBar} ${animationDuration}s linear infinite;
  }
  & #low {
    animation: ${bottomBar} ${animationDuration}s linear infinite;
  }
`;
