import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-family: ${({ theme }) => theme.font.family.default.medium};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  text-decoration: none;
  text-align: center;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  margin: 0 0.25rem;
`;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  analyticsEvent: string;
}

export default Button;
