import { useSelector } from 'react-redux';

import { RootReducerType } from '../../reducers';
import { mentalStates } from '../../utils/mentalStates';

export function useUser() {
  const user = useSelector(getUser);
  return user;
}

export function getUser(state: RootReducerType) {
  return state.userV2;
}

export function useDefaultDisplayType() {
  const user = useUser();
  const sessionMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );
  const mentalState = mentalStates.byId[sessionMentalStateId || ''];
  if (!mentalState) return null;
  return user.mentalStatePreferences[mentalState.type].defaultDisplay.type;
}
