import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
  font-weight: 500;
  justify-content: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    margin: 0.5rem 0.5rem 0.5rem 0;
  }
`;

export const Title = styled.div`
  color: #fff;
  margin-bottom: 0.75rem;
`;

export const Body = styled.div`
  color: #d4d2ea;
`;

export const PaymentButton = styled.div<{ hasBottomMargin?: boolean }>`
  ${props => (props.hasBottomMargin ? 'margin-bottom: 1rem;' : null)}
`;
