import { TrackActionOrigins } from '@Analytics';
import { TabBar } from '@Cortex';
import { NOOP } from '@Globals';
import { Serving, Track } from '@Model';
import {
  addToDislikes,
  addToFavorites,
  getTrackActivityId,
  getTrackComplexity,
  getTrackGenreName,
  getTrackId,
  getTrackImageUrl,
  getTrackInstrumentations,
  getTrackIsNewlyCreated,
  getTrackMentalStateId,
  getTrackMoods,
  getTrackName,
  getTrackNeuralEffectLevel,
  getTrackVariationId,
  removeFromDislikes,
  removeFromFavorites,
  TrackInformationCard,
} from '@Music';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsEnabledDynamicActivities } from '../../../../../../../../hooks/useIsEnabledDynamicActivities';

import { useShareTrack } from '../../../../../../../../hooks/useShareTrack';
import { RootReducerType } from '../../../../../../../../reducers';
import { activities } from '../../../../../../../../utils/activities';
import { mentalStates } from '../../../../../../../../utils/mentalStates';
import { getTrackDynamicMentalStateDisplayValue } from '../../../../../../lenses/getTrackDynamicMentalStateDisplayValue';
import { isTrackVariationInFavorites } from '../../../../../../lenses/isTrackVariationInFavorites';
import { TrackDetails } from '../../../../../TrackDetails';
import { SimilarTracks } from '../SimilarTracks';
import * as S from './MoreTrackInfo.styles';
import CloseIcon from './assets/close.svg';

enum TabItems {
  TrackInfo = 'Track Information',
  SimilarTracks = 'Similar Tracks',
}

const TAB_ITEMS = Object.values(TabItems);

interface Props {
  track: Track | Serving;
  fade?: boolean;
  onClose?: () => void;
}

export const MoreTrackInfo = ({ track, onClose = NOOP }: Props) => {
  const dispatch = useDispatch();
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();
  const sessionDynamicActivity = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity,
  );
  const { handleGetShareLink } = useShareTrack();
  const [selectedTab, setSelectedTab] = useState(TabItems.TrackInfo);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const dislikedTrackIds = useSelector((state: RootReducerType) => state.music.dislikedTrackIds);
  const favorites = useSelector((state: RootReducerType) => state.music.favorites);

  // sets up a dictionary for efficient lookup of favorite track
  const favoritesDictionary = favorites.reduce(
    (acc, track) => {
      acc[getTrackId(track)] = track;
      return acc;
    },
    {} as { [key: string]: Track | Serving },
  );

  const handleDislikeClick = () => {
    if (dislikedTrackIds.includes(getTrackId(track))) {
      dispatch(removeFromDislikes({ trackId: getTrackId(track) }));
    } else {
      dispatch(addToDislikes({ track }));
      window.setTimeout(() => window.dispatchEvent(new Event('skip')), 1000);
    }
  };

  const isCurrentTrackVariationInFavorites = useSelector(
    isTrackVariationInFavorites({
      trackVariationId: getTrackVariationId(track),
    }),
  );

  const handleFavoriteClick = () => {
    if (isCurrentTrackVariationInFavorites) {
      dispatch(removeFromFavorites({ track }));
    } else {
      dispatch(addToFavorites({ origin: TrackActionOrigins.TrackPlayer, track }));
    }
  };

  const handleShareTrack = () => {
    handleGetShareLink(track);
  };

  const handleSelectTab = (tab: TabItems) => {
    const activeIndex = TAB_ITEMS.findIndex(tabItem => tabItem === tab);

    if (activeIndex >= 0) {
      setActiveTabIndex(activeIndex);
    }

    setSelectedTab(tab);
  };

  const mentalStateDisplayValue = isUsingDynamicActivities
    ? getTrackDynamicMentalStateDisplayValue(track)
    : mentalStates.byId[getTrackMentalStateId(track)]?.display;

  const activity = isUsingDynamicActivities
    ? sessionDynamicActivity?.displayValue
    : activities.byId[getTrackActivityId(track)]?.display;

  return (
    <S.Container>
      <S.CloseButton data-testid="trackInfoCloseButton" onClick={onClose}>
        <S.Image src={CloseIcon} />
      </S.CloseButton>

      <S.TrackInfoContainer>
        <TrackInformationCard
          addToFavoritesButtonTestId={'trackCardAddToFavoritesButton'}
          imageUrl={getTrackImageUrl(track)}
          isDisliked={dislikedTrackIds.includes(getTrackId(track))}
          isDislikingAvailable={true}
          isFavorited={isCurrentTrackVariationInFavorites}
          isFavoritingAvailable={true}
          isNewlyCreated={getTrackIsNewlyCreated(track)}
          neuralEffectLevel={getTrackNeuralEffectLevel(track)}
          subtitle={`${getTrackGenreName(track)} • ${getTrackNeuralEffectLevel(
            track,
          )} Neural Effect`}
          title={getTrackName(track)}
          onAddToDislikes={handleDislikeClick}
          onAddToFavorites={handleFavoriteClick}
          onRemoveFromDislikes={handleDislikeClick}
          onRemoveFromFavorites={handleFavoriteClick}
          onShareTrack={handleShareTrack}
        />
      </S.TrackInfoContainer>

      <S.TabBarContainer>
        <TabBar activeTabIndex={activeTabIndex} items={TAB_ITEMS} onSelect={handleSelectTab} />
      </S.TabBarContainer>

      {selectedTab === TabItems.TrackInfo ? (
        <S.FadeInWrapper>
          <TrackDetails
            NEL={getTrackNeuralEffectLevel(track) || ''}
            activity={activity || ''}
            complexity={getTrackComplexity(track) || ''}
            instrumentation={
              getTrackInstrumentations(track).length
                ? getTrackInstrumentations(track).join(' • ')
                : ''
            }
            mentalState={mentalStateDisplayValue || ''}
            mood={getTrackMoods(track).length ? getTrackMoods(track).join(' • ') : ''}
          />
        </S.FadeInWrapper>
      ) : null}

      {selectedTab === TabItems.SimilarTracks ? (
        <S.FadeInWrapper>
          <SimilarTracks favoritesDictionary={favoritesDictionary} />
        </S.FadeInWrapper>
      ) : null}
    </S.Container>
  );
};
