import styled from 'styled-components';
import Colors from '../../../../../../styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const PlanSelector = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
`;

export const Plan = styled.div<{ hasSelected?: boolean }>`
  border: 1px solid ${props => (props.hasSelected ? '#d4d2ea' : Colors.white300)};
  border-radius: 0.75rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 0;
  padding: 1rem;
  transition: background 0.2s ease-in-out;
  width: 100%;
  gap: 1rem;
`;

export const ButtonContainer = styled.div`
  margin-left: 1rem;
  margin-top: 2rem;
`;

export const BoxContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  max-width: 700px;
  width: 100%;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DescriptionHeaderContainer = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`;

export const PlanDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;
export const Checkmarks = styled.div`
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.07);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 550px;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    min-width: unset;
  }
`;

export const PlanCheckmarkSelected = styled.div`
  align-items: center;
  align-self: center;
  border: 12px solid white;
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
  height: 2.5rem;
  justify-content: center;
  margin-left: 1rem;
  width: 2.5rem;
`;

export const PlanCheckmarkUnselected = styled.div`
  align-items: center;
  align-self: center;
  border: 1px solid ${Colors.white300};
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
  height: 2.5rem;
  justify-content: center;
  margin-left: 1rem;
  width: 2.5rem;
`;

export const Close = styled.div`
  position: absolute;
  font-size: 20px;
  right: 30px;
  top: 22px;
  cursor: pointer;
  color: #868496;
`;
