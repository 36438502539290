import { useState, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSessionSidebarContent } from '../../../../actions/ui';
import { RootReducerType } from '../../../../reducers';
import { SessionSidebarContent } from '../../../../types';
import { ActivitySelectorDisplay } from './ActivitySelector.display';
import { useDynamicActivitiesByMentalStateId } from '../../../../api/modules/DynamicActivities';

export const ActivitySelectorDynamic = memo(() => {
  const dispatch = useDispatch();
  const sessionDynamicActivity = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity,
  );
  const sessionDynamicMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.mentalState.id,
  );
  const [selected, setSelected] = useState('');
  const sessionSidebarContent = useSelector(
    (state: RootReducerType) => state.ui.sessionSidebarContent,
  );

  const { data: dynamicActivities } = useDynamicActivitiesByMentalStateId();

  useEffect(() => {
    if (dynamicActivities) {
      const displayValue =
        sessionDynamicMentalStateId === sessionDynamicActivity?.mentalState.id
          ? sessionDynamicActivity?.displayValue
          : dynamicActivities[0].displayValue;
      setSelected(displayValue || '');
    }
  }, [dynamicActivities, sessionDynamicActivity, sessionDynamicMentalStateId]);

  return dynamicActivities ? (
    <ActivitySelectorDisplay
      isOpen={sessionSidebarContent === SessionSidebarContent.Preferences}
      selectedActivity={selected}
      onClick={() =>
        dispatch(
          setSessionSidebarContent({
            sessionSidebarContent: SessionSidebarContent.Preferences,
            origin: 'player',
          }),
        )
      }
    />
  ) : null;
});
