import { useSelector } from 'react-redux';

import { RootReducerType } from '../../reducers';
import { PomodoroPhases, TimerDisplayTypes, TimerStatuses } from './constants';

const POMODORO_BREAK_ENDING_TIME_IN_MILLISECONDS = 1000 * 2;

export function useTimerDisplayValue() {
  const timerDisplayType = useSelector<RootReducerType, RootReducerType['timer']['displayType']>(
    state => state.timer.displayType,
  );
  const countUpDisplayValue = useCountUpTimerDisplayValue();
  const pomodoroDisplayValue = usePomodoroDisplayValues().time;

  if (timerDisplayType === TimerDisplayTypes.Pomodoro) {
    return pomodoroDisplayValue;
  }

  return countUpDisplayValue;
}

function useCountUpTimerDisplayValue() {
  const timerState = useSelector<RootReducerType, RootReducerType['timer']>(state => state.timer);
  if (timerState.startTime === null) return '0:00';

  const elapsedTimeInMilliseconds =
    timerState.currentTime - timerState.startTime - (timerState.timeSpentPaused ?? 0);
  return getDisplayValueFromMilliseconds(elapsedTimeInMilliseconds);
}

function getDisplayValueFromMilliseconds(milliseconds: number): string {
  const elapsedSeconds = milliseconds / 1000;

  const hours = Math.floor(elapsedSeconds / 3600);
  const minutes = Math.floor((elapsedSeconds % 3600) / 60);
  const seconds = Math.floor(elapsedSeconds % 60);
  const secondsDisplayValue = addLeftPaddedZeroes(seconds);

  if (hours === 0) return `${minutes}:${secondsDisplayValue}`;
  return `${hours}:${addLeftPaddedZeroes(minutes)}:${secondsDisplayValue}`;
}

function addLeftPaddedZeroes(number: number): string {
  if (number >= 10) return number.toString();
  return `0${number}`;
}

export function usePomodoroDisplayValues(): { time: string; phase: PomodoroPhases } {
  const timerState = useSelector<RootReducerType, RootReducerType['timer']>(state => state.timer);
  const { focusTime, breakTime } = timerState.pomodoroSettings.intervals;

  const focusTimeInMilliseconds = 1000 * 60 * +focusTime;
  const breakTimeInMilliseconds = 1000 * 60 * +breakTime;
  const cycleTimeInMilliseconds = focusTimeInMilliseconds + breakTimeInMilliseconds;

  if (
    timerState.displayType !== TimerDisplayTypes.Pomodoro ||
    timerState.status === TimerStatuses.Initial
  )
    return {
      time: getDisplayValueFromMilliseconds(focusTimeInMilliseconds),
      phase: PomodoroPhases.Work,
    };

  const elapsedTimeInMilliseconds =
    timerState.currentTime - timerState.startTime - (timerState.timeSpentPaused ?? 0);
  const elapsedTimeInCurrentCycleInMilliseconds =
    elapsedTimeInMilliseconds % cycleTimeInMilliseconds;
  const remainingTimeInCurrentCycleInMilliseconds =
    cycleTimeInMilliseconds - elapsedTimeInCurrentCycleInMilliseconds;

  if (remainingTimeInCurrentCycleInMilliseconds > breakTimeInMilliseconds) {
    return {
      time: getDisplayValueFromMilliseconds(
        remainingTimeInCurrentCycleInMilliseconds - breakTimeInMilliseconds,
      ),
      phase: PomodoroPhases.Work,
    };
  }
  return {
    time: getDisplayValueFromMilliseconds(remainingTimeInCurrentCycleInMilliseconds),
    phase:
      remainingTimeInCurrentCycleInMilliseconds > POMODORO_BREAK_ENDING_TIME_IN_MILLISECONDS
        ? PomodoroPhases.Break
        : PomodoroPhases.BreakEnding,
  };
}
