import { TextSpaced } from '@Cortex';
import styled, { css, keyframes } from 'styled-components';

export const redAura = {
  outer: `linear-gradient(313.03deg, #F34675 34.15%, rgba(243, 70, 117, 0) 92.55%)`,
  inner: `linear-gradient(232.01deg, rgba(255, 0, 31, 0.8) -3.6%, rgba(207, 61, 87, 0.8) 68.92%)`,
};

export const normalAura = {
  outer:
    'linear-gradient(187.11deg, rgba(208, 26, 234, 0.8) 19.55%, rgba(44, 168, 234, 0.8) 87.99%)',
  inner:
    'linear-gradient(240.5deg, rgba(208, 26, 234, 0.8) 16.31%, rgba(44, 168, 234, 0.8) 67.25%)',
};

// the z-indexes ensure that the aura effect does not overlap the header texts!

export const Container = styled.div`
  border-radius: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2rem;
  text-transform: lowercase;
  width: 100%;

  // safari has a bug with overflow:hidden on absolutely positioned children;
  // this is a workaround for it.
  // https://stackoverflow.com/questions/44948735/overflow-hidden-is-not-working-with-absolute-element-in-safari
  transform: translateZ(0);
`;

const backgroundAnimation = keyframes`
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const LifetimeContainer = styled.div`
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2rem;
  color: white;
  background: ${normalAura.inner}, ${normalAura.outer};
  background-size: 200% 200%;
  animation: ${backgroundAnimation} 20s ease infinite;
  text-transform: lowercase;

  @media (prefers-reduced-motion) {
    animation: none;
    background-size: 100% 100%;
  }

  /* filter: invert(1); */
  // safari has a bug with overflow:hidden on absolutely positioned children;
  // this is a workaround for it.
  // https://stackoverflow.com/questions/44948735/overflow-hidden-is-not-working-with-absolute-element-in-safari
  transform: translateZ(0);
`;

export const Header = styled.div`
  display: flex;
  font-family: TTNormsPro-Regular;
  margin-bottom: 1.5rem;
`;

export const HeaderIcon = styled.div`
  margin-right: 1rem;
  position: relative;
  z-index: 1; // see note at top
`;

export const HeaderText = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1.25rem;
  width: ${props => props.width};
  z-index: 2; // see note at top
`;

export const LifetimeHeaderText = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1.25rem;
  width: ${props => props.width};
  z-index: 2; // see note at top
`;

export const HeaderTextTitle = styled.div`
  font-family: TTNormsPro-Bold;
  font-weight: 700;
  font-size: 1.25rem;
`;

export const LifetimeHeaderTextSubtitle = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

export const HeaderTextSubtitle = styled.div`
  color: #d4d2ea;
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

export const Tag = styled(TextSpaced)`
  background: #f34675;
  border-radius: 2.75rem;
  padding: 0.25rem 0.5rem;
  text-align: center;
  text-transform: uppercase;
`;

export const HeaderIconAura = styled.div`
  position: absolute;
  top: -100%;
  left: -100%;
  z-index: -1; // see note at top
`;

export const Children = styled.div`
  text-transform: initial;
  z-index: 2; // see note at top
`;
