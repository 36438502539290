import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { MentalStates, SessionType } from '../../../types';

export type UserState = {
  mentalStatePreferences: { [key in MentalStates]: UserMentalStatePreferences };
  preferences: UserPreferencesType;
};

export enum StreakTypes {
  Hidden = 'Hidden',
  Weekly = 'Weekly',
}

export type UserPreferencesType = {
  streakType: StreakTypes | null;
};

export enum UserMentalStatePreferenceTypes {
  /** @deprecated use GenreNames instead */
  Genres = 'genres',
  GenreNames = 'genreNames',
  NeuralEffectLevels = 'neuralEffectLevels',
}

export enum UserPreferenceDisplayTypes {
  Infinite = 'Infinite',
  Quotes = 'Quotes',
  Pomodoro = 'Pomodoro',
}

type UserMentalStatePreferences = {
  defaultDisplay: {
    type: UserPreferenceDisplayTypes;
  };
  [UserMentalStatePreferenceTypes.Genres]: string[];
  [UserMentalStatePreferenceTypes.GenreNames]: string[];
  [UserMentalStatePreferenceTypes.NeuralEffectLevels]: string[];
};

export const initialState: UserState = {
  mentalStatePreferences: {
    [MentalStates.Focus]: {
      defaultDisplay: {
        type: UserPreferenceDisplayTypes.Infinite,
      },
      [UserMentalStatePreferenceTypes.Genres]: [],
      [UserMentalStatePreferenceTypes.GenreNames]: [],
      [UserMentalStatePreferenceTypes.NeuralEffectLevels]: [],
    },
    [MentalStates.Relax]: {
      defaultDisplay: {
        type: UserPreferenceDisplayTypes.Infinite,
      },
      [UserMentalStatePreferenceTypes.Genres]: [],
      [UserMentalStatePreferenceTypes.GenreNames]: [],
      [UserMentalStatePreferenceTypes.NeuralEffectLevels]: [],
    },
    [MentalStates.Sleep]: {
      defaultDisplay: {
        type: UserPreferenceDisplayTypes.Infinite,
      },
      [UserMentalStatePreferenceTypes.Genres]: [],
      [UserMentalStatePreferenceTypes.GenreNames]: [],
      [UserMentalStatePreferenceTypes.NeuralEffectLevels]: [],
    },
  },
  preferences: {
    streakType: null,
  },
};

const { actions: userSliceActions, reducer: userReducer } = createSlice({
  name: 'User_V2',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },

    addMentalStatePreferences(
      state,
      action: PayloadAction<{
        mentalState: SessionType;
        preferenceType: UserMentalStatePreferenceTypes;
        preference: string;
      }>,
    ) {
      state.mentalStatePreferences[action.payload.mentalState][action.payload.preferenceType].push(
        action.payload.preference,
      );

      return state;
    },

    removeMentalStatePreferences(
      state,
      action: PayloadAction<{
        mentalState: SessionType;
        preferenceType: UserMentalStatePreferenceTypes;
        preference: string;
      }>,
    ) {
      state.mentalStatePreferences[action.payload.mentalState][action.payload.preferenceType] =
        state.mentalStatePreferences[action.payload.mentalState][
          action.payload.preferenceType
        ].filter(preference => preference !== action.payload.preference);

      return state;
    },

    /** sets preferences to a singe filter (genres | NEL) */
    setMentalStatePreference(
      state,
      action: PayloadAction<{
        mentalState: SessionType;
        preferenceType: UserMentalStatePreferenceTypes;
        preferences: string[];
      }>,
    ) {
      state.mentalStatePreferences[action.payload.mentalState][action.payload.preferenceType] =
        action.payload.preferences;

      return state;
    },

    setMentalStateDefaultDisplayType(
      state,
      action: PayloadAction<{
        mentalState: SessionType;
        defaultDisplayType: UserPreferenceDisplayTypes;
      }>,
    ) {
      if (
        state.mentalStatePreferences[action.payload.mentalState].defaultDisplay.type ===
        action.payload.defaultDisplayType
      ) {
        return;
      }

      return {
        ...state,
        mentalStatePreferences: {
          ...state.mentalStatePreferences,
          [action.payload.mentalState]: {
            ...state.mentalStatePreferences[action.payload.mentalState],
            defaultDisplay: {
              type: action.payload.defaultDisplayType,
            },
          },
        },
      };
    },

    setMentalStatePreferences(state, action: PayloadAction<UserState['mentalStatePreferences']>) {
      state.mentalStatePreferences = action.payload;
    },

    setUserPreferences(state, action: PayloadAction<UserState['preferences']>) {
      state.preferences = action.payload;
    },
  },
});

const persistedUserReducer = persistReducer(
  {
    key: 'domains/User/reducer',
    storage,
    whitelist: ['mentalStatePreferences', 'preferences'],
  },
  userReducer,
);

export { userSliceActions, persistedUserReducer };
