export type OnboardingSpecification = {
  id: string;
  cards: OnboardingCardType[];
  quizSpecification?: OnboardingQuizSpecification;
};

export type OnboardingCardType =
  | OnboardingInfoCard
  | OnboardingInfoCardTwo
  | OnboardingVideoCard
  | OnboardingFunBarsCard
  | OnboardingHeroCard;

export enum OnboardingCardTypes {
  FunBars = 'funBars',
  Info = 'info',
  InfoTwo = 'infoTwo',
  Hero = 'hero',
  Video = 'video',
}

export interface OnboardingInfoCard {
  type: OnboardingCardTypes.Info;
  heading?: string;
  image?: string;
  title?: string;
  body?: string;
}

export interface OnboardingInfoCardTwo {
  type: OnboardingCardTypes.InfoTwo;
  ctaButtonText?: string;
  body?: string;
  belowBodyImage?: string;
  heading?: string;
  image?: string;
  isImageFullCoverage?: boolean;
  isSkippable?: boolean;
  title?: string;
  video?: string;
  stepText?: string;
}

export interface OnboardingHeroCard {
  type: OnboardingCardTypes.Hero;
  ctaButtonText?: string;
  body?: string;
  desktopBackgroundImage?: string;
  mobileBackgroundImage?: string;
  title?: string;
  stepText?: string;
}

export interface OnboardingVideoCard {
  type: OnboardingCardTypes.Video;
  heading?: string;
  image?: string;
  title?: string;
  youtubeVideoId: string;
}

export interface OnboardingFunBarsCard {
  type: OnboardingCardTypes.FunBars;
  title?: string;
}

export type OnboardingQuizScore = {
  id: string;
  name: string;
  breakpoints: number[];
};

export type OnboardingNeurotypeMatrixItem = {
  id: string;
  preferredFocusGenreNames: string[];
  preferredFocusNeuralEffectLevels: string[];
  resultCards: OnboardingCardType[];
  onboardingMusicUrl?: string;
  paywallBenefits?: string[];
};

export type OnboardingQuizSpecification = {
  id: string;
  questions: OnboardingQuizQuestion[];
  scores: OnboardingQuizScore[];
  neurotypeMatrix: OnboardingNeurotypeMatrixItem[][];
};

export type OnboardingQuizQuestion = {
  answers: OnboardingQuizAnswer[];
  description?: string;
  hasPrivacyNotice?: boolean;
  image?: string;
  scoreId: string;
  title?: string;
  stepText?: string;
};

export type OnboardingTestimonials = {
  text: string;
  author: string;
};

export type OnboardingQuizAnswer = {
  title?: string;
  description?: string;
  value?: number;
  responseCards?: OnboardingCardType[];
  testimonials?: OnboardingTestimonials[];
  benefits?: string[];
};

export enum OnboardingSteps {
  MentalStates = 'mentalStates',
  Cards = 'cards',
  Quiz = 'quiz',
  Results = 'results',
  Funbars = 'funbars',
}
