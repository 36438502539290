import styled from 'styled-components';

import { getGAEvent } from '../../utils/analytics/ga';

const TextContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.extraSmall};
  line-height: 1rem;
  margin: 1rem 0.5rem 0 0.5rem;
  text-align: center;
  text-transform: lowercase;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.brandSecondaryDark};
  font-size: ${({ theme }) => theme.fontSize.extraSmall};
  font-family: ${({ theme }) => theme.font.family.default.medium};
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

export const Disclaimer: React.FC = () => {
  return (
    <TextContainer>
      By creating an account, you agree to brain.fm&apos;s{' '}
      <StyledLink
        data-analytics-event={getGAEvent('Signin', 'terms_and_conditions')}
        href="https://try.brain.fm/terms"
        target="_blank"
      >
        Terms & Conditions
      </StyledLink>{' '}
      and{' '}
      <StyledLink
        data-analytics-event={getGAEvent('Signin', 'privacy_policy')}
        href="https://try.brain.fm/privacy"
        target="_blank"
      >
        Privacy Policy
      </StyledLink>
      .
    </TextContainer>
  );
};
