import { Button, ButtonVariants, FontColors, FormInput, TextSemiBold } from '@Cortex';
import React, { ChangeEvent, useCallback } from 'react';
import { Transition } from 'react-transition-group';

import { PomodoroIntervals } from '../../../Timer/constants';
import * as S from '../TimerSettings/TimeMode.styles';

interface Props {
  isActive: boolean;
  onStart: () => void;
  onSetIntervals: (
    data: Partial<{
      focusTime?: string;
      breakTime?: string;
    }>,
  ) => void;
  intervals: PomodoroIntervals;
}

export const PomodoroSettings = (props: Props) => {
  const {
    isActive,
    intervals: { focusTime, breakTime },
    onStart,
    onSetIntervals,
  } = props;

  const handleChangeInterval = useCallback(
    (type: string) => (e: ChangeEvent<HTMLInputElement>) => {
      onSetIntervals({ [type]: e.currentTarget.value });
    },
    [onSetIntervals],
  );

  return (
    <Transition in={isActive} mountOnEnter timeout={0} unmountOnExit>
      {animationState => (
        <S.DropdownContainer animationState={animationState}>
          <S.TimerInfoContainer>
            <TextSemiBold color={FontColors.WhiteSecondary} size="0.825rem">
              Input your desired focus and break lengths below, then press 'start interval timer'.
            </TextSemiBold>
          </S.TimerInfoContainer>
          <FormInput
            data-testid="focusLength"
            label="Focus Length:"
            rightSideContent="minutes"
            value={focusTime}
            onChange={handleChangeInterval('focusTime')}
          />
          <FormInput
            data-testid="breakLength"
            label="Break Length:"
            rightSideContent="minutes"
            value={breakTime}
            onChange={handleChangeInterval('breakTime')}
          />
          <Button
            data-testid="startIntervalTimer"
            isFullWidth={true}
            type="submit"
            variant={ButtonVariants.Primary}
            onClick={onStart}
          >
            Start Interval Timer
          </Button>
        </S.DropdownContainer>
      )}
    </Transition>
  );
};
