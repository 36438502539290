import styled from 'styled-components';

export const Streak = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white};
`;

export const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const RightPart = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  text-align: center;
`;

export const Description = styled.div`
  font-size: 0.6rem;
`;

export const Title = styled.div`
  font-size: 1.2rem;
`;
