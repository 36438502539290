import { useSession } from '@Session';

import { useGenreNamesByActivity } from '../../../../api/modules/GenreNamesByActivity';
import * as S from './GenresLegacy.styles';
import { GenreButton } from './components';

type Props = {
  selected: string[];
  onSelect: (value: string) => void;
  activityId?: string;
};

export const Genres = ({ activityId, onSelect, selected }: Props) => {
  const { data, isLoading } = useGenreNamesByActivity(activityId);

  const session = useSession();

  const handleClick = (genreName: string) => () => {
    onSelect(genreName);
  };

  if (isLoading) return <S.Loading />;

  return (
    <S.Container>
      <S.Section>
        <S.SectionTitle>Music</S.SectionTitle>

        <S.GenresContainer>
          {!isLoading &&
            data &&
            data.baseGenreNames.map(item => {
              const isActive = Boolean(session && selected.includes(item));

              return (
                <S.GenreContainer key={item}>
                  <GenreButton isActive={isActive} title={item} onClick={handleClick(item)} />
                </S.GenreContainer>
              );
            })}
        </S.GenresContainer>
      </S.Section>

      <S.Section>
        <S.SectionTitle>Nature</S.SectionTitle>
        <S.GenresContainer>
          {!isLoading &&
            data &&
            data.natureGenreNames.map(item => {
              const isActive = Boolean(session && selected.includes(item));

              return (
                <S.GenreContainer key={item}>
                  <GenreButton isActive={isActive} title={item} onClick={handleClick(item)} />
                </S.GenreContainer>
              );
            })}
        </S.GenresContainer>
      </S.Section>
    </S.Container>
  );
};
