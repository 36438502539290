import { TrackVariation } from '@Model';
import { useIsEnabledDynamicActivities } from '../../../../hooks/useIsEnabledDynamicActivities';

import { getNelDisplayValue } from '../../lib/getNeuralEffectLevelDisplayValue';
import * as S from './TrackDetails.styles';
import { MultiNelLevelIcon } from './components/MultiNelLevelIcon';

export interface Props {
  mentalState: string;
  activity?: string;
  complexity: string;
  hasMultipleNELs?: boolean;
  variations?: Omit<TrackVariation, 'track'>[];
  NEL: string;
  mood: string;
  instrumentation: string;
  onVariationClick?: (variationId: string) => void;
}

export const TrackDetails = (props: Props) => {
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();
  const handleVariationClick = (variationId: string) => () => {
    if (!props.onVariationClick) return;
    props.onVariationClick(variationId);
  };

  return (
    <S.TrackInfoBody>
      {props.hasMultipleNELs && !isUsingDynamicActivities && (
        <S.VariationsContainer>
          {(props.variations || []).map(variation => (
            <S.Variation
              key={variation.id}
              data-cy="playVariationButton"
              data-testid={`play${getNelDisplayValue(variation.neuralEffectLevel || 0)}Variation`}
              onClick={handleVariationClick(variation.id)}
            >
              <S.Triangle />
              <S.VariationData>
                <S.EffectLevelIcon $effectLevel={getNelDisplayValue(variation.neuralEffectLevel)} />
                <S.VariationDescription>
                  {getNelDisplayValue(variation.neuralEffectLevel)}
                </S.VariationDescription>
              </S.VariationData>
            </S.Variation>
          ))}
        </S.VariationsContainer>
      )}
      <S.TrackInfoBodySection>
        <S.Row>
          <S.Column>
            <S.SectionTitle>Mental State</S.SectionTitle>
            <S.SectionDescription>{props.mentalState}</S.SectionDescription>
          </S.Column>
          {props.activity && (
            <S.Column>
              <S.SectionTitle>Activity</S.SectionTitle>
              <S.SectionDescription>{props.activity}</S.SectionDescription>
            </S.Column>
          )}
        </S.Row>

        <S.Row>
          <S.Column>
            <S.SectionTitle>Musical Complexity</S.SectionTitle>

            <S.EffectLevelContainer>
              <S.GradientCircle>
                <S.EffectLevelIcon $effectLevel={props.complexity} />
              </S.GradientCircle>

              <S.SectionDescription>{props.complexity}</S.SectionDescription>
            </S.EffectLevelContainer>
          </S.Column>
          <S.Column>
            <S.SectionTitle>Neural Effect Level</S.SectionTitle>
            <S.EffectLevelContainer data-testid="trackDetailsNel">
              <S.GradientCircle>
                {props.hasMultipleNELs ? (
                  <MultiNelLevelIcon />
                ) : (
                  <S.EffectLevelIcon $effectLevel={props.NEL} />
                )}
              </S.GradientCircle>
              {props.hasMultipleNELs ? (
                <S.SectionDescription>multiple</S.SectionDescription>
              ) : (
                <S.SectionDescription>{props.NEL}</S.SectionDescription>
              )}
            </S.EffectLevelContainer>
          </S.Column>
        </S.Row>

        <S.Row>
          <S.Column>
            <S.SectionTitle>Mood</S.SectionTitle>
            <S.SectionDescription>{props.mood}</S.SectionDescription>
          </S.Column>
        </S.Row>

        <S.Row>
          <S.Column>
            <S.SectionTitle>Instrumentation</S.SectionTitle>
            <S.SectionDescription>{props.instrumentation}</S.SectionDescription>
          </S.Column>
        </S.Row>
      </S.TrackInfoBodySection>
    </S.TrackInfoBody>
  );
};
