import { FontColors, Text } from '@Cortex';
import { OutsideClickDetector } from '@Utils';
import React, { useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

import ChevronUp from '../../../../assets/images/chevron_up_icon.svg';
import * as S from './Filter.styles';
import FilterIcon from './assets/filter.svg';
import { FilterItem, FilterItemProps } from './components/FilterItem';

export interface FilterDisplayProps {
  isFilterListOpen: boolean;
  onCloseFilterList: () => void;
  onOpenFilterList: () => void;
  filters: Pick<FilterItemProps, 'items' | 'title' | 'selectedItems' | 'onItemClick'>[];
}

export const FilterDisplay = ({
  isFilterListOpen,
  onCloseFilterList,
  onOpenFilterList,
  filters,
}: FilterDisplayProps) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(['Activity']);

  function handleClick(e: React.SyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (isFilterListOpen) {
      onCloseFilterList();
    } else {
      onOpenFilterList();
    }
  }

  function toggleExpanded(value: string) {
    if (expanded.includes(value)) {
      setExpanded(expanded.filter(x => x !== value));
    } else {
      setExpanded([...expanded, value]);
    }
  }

  const isAnyFilterSelected = filters?.some(filter => filter.selectedItems.length > 0);

  return (
    <OutsideClickDetector onOutsideClick={onCloseFilterList}>
      <S.Container data-testid="FilterBlock">
        <S.Button hasBorder={isAnyFilterSelected} onClick={handleClick}>
          <S.FilterTextContainer>
            <S.ImageIcon src={FilterIcon} />
            <S.Header>
              <Text color={FontColors.WhiteSecondary}>Filter</Text>
            </S.Header>
          </S.FilterTextContainer>
          <S.ChevronIcon isUp={isFilterListOpen} src={ChevronUp} />
        </S.Button>
        <Transition
          in={isFilterListOpen}
          mountOnEnter
          nodeRef={dropDownRef}
          timeout={0}
          unmountOnExit
        >
          {animationState => (
            <S.FilterWrapper ref={dropDownRef} animationState={animationState}>
              <S.FilterContainer>
                {filters?.map((filter, i) => (
                  <FilterItem
                    key={i}
                    isExpanded={expanded.includes(filter.title)}
                    items={filter.items}
                    selectedItems={filter.selectedItems}
                    title={filter.title}
                    onClick={() => toggleExpanded(filter.title)}
                    onItemClick={filter.onItemClick}
                  />
                ))}
              </S.FilterContainer>
            </S.FilterWrapper>
          )}
        </Transition>
      </S.Container>
    </OutsideClickDetector>
  );
};
