import { Button, ButtonVariants } from '@Cortex';

import { CardWrapperTwo } from '../CardWrapperTwo';
import * as S from './InfoCardTwo.styles';
import BrandLogo from '../../../../assets/images/brand_logo.svg';
import { useEffect } from 'react';
import { CoreAnalytics } from '@Analytics';

export type Props = {
  activeStepIndex: number;
  body?: string;
  belowBodyImage?: string;
  ctaButtonText?: string;
  heading?: string;
  image?: string;
  isImageFullCoverage?: boolean;
  isSkippable?: boolean;
  numberOfSteps: number;
  onClickNext?: () => void;
  onClickSkip?: () => void;
  title?: string;
  video?: string;
  stepText?: string;
};

const Body = ({ text }: { text: string }) => {
  const parts = text.split(/\*([^*]+)\*/g);

  return (
    <S.Body>
      {parts.map((part, index) => {
        if (index % 2 === 1) {
          return <S.BodyTextBold key={part + index}>{part}</S.BodyTextBold>;
        } else {
          return part;
        }
      })}
    </S.Body>
  );
};

export function InfoCardTwo(props: Props) {
  useEffect(() => {
    CoreAnalytics.trackOnboardingCardImpressionEvent({
      title: props.title ?? '',
      body: props.body ?? '',
      step: props.stepText ?? '',
    });
  }, []);

  return (
    <CardWrapperTwo>
      <S.Header>
        <S.Logo src={BrandLogo} />
        {props.activeStepIndex !== null &&
        props.numberOfSteps !== null &&
        props.numberOfSteps !== 1 ? (
          <S.StepSection>
            <S.StepText>{props.stepText || 'Onboarding'}</S.StepText>
            <S.StepPills>
              {[...Array(props.numberOfSteps)].map((_, index) => (
                <S.StepPill
                  key={index}
                  isActive={index === props.activeStepIndex}
                  isCompleted={index <= props.activeStepIndex}
                />
              ))}
            </S.StepPills>
          </S.StepSection>
        ) : null}
        <S.LogoPlaceholder src={BrandLogo} />
      </S.Header>

      <S.Content>
        <S.OnboardingCardTextColumn>
          {props.heading ? <S.Subtitle>{props.heading}</S.Subtitle> : null}
          {props.title ? <S.Title isCentered={!props.image}>{props.title}</S.Title> : null}
          {props.body ? <Body text={props.body} /> : null}
          {props.belowBodyImage ? <S.BelowBodyImage src={props.belowBodyImage} /> : null}
        </S.OnboardingCardTextColumn>
        {props.image ? (
          <S.OnboardingCardImageColumn>
            <S.Image src={props.image} />
          </S.OnboardingCardImageColumn>
        ) : null}
        {props.video ? (
          <S.OnboardingCardVideoColumn>
            <S.Video autoPlay loop muted>
              <source src={props.video} type="video/mp4" />
              Your browser does not support the video tag.
            </S.Video>
          </S.OnboardingCardVideoColumn>
        ) : null}
      </S.Content>

      <S.Buttons>
        {props.isSkippable && (
          <S.SkipButton>
            <Button
              isFullWidth
              keepTextCase
              variant={ButtonVariants.Outline}
              onClick={props.onClickSkip}
            >
              Start listening
            </Button>
          </S.SkipButton>
        )}
        {props.onClickNext && (
          <S.ButtonContainer>
            <Button
              isFullWidth
              keepTextCase
              variant={ButtonVariants.Secondary}
              onClick={props.onClickNext}
            >
              {props.ctaButtonText || 'Continue'}
            </Button>
          </S.ButtonContainer>
        )}
      </S.Buttons>
    </CardWrapperTwo>
  );
}
