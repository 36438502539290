import { TextBold } from '@Cortex';

import { Analytics } from '../../../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../../../utils/analytics/events';
import { TrackGenre } from '../TrackGenre';
import * as S from './TrackGenres.styles';
import AcousticIcon from './assets/acoustic.svg';
import AtmosphericIcon from './assets/atmospheric.svg';
import BeachIcon from './assets/beach.svg';
import ChimesAndBowlsIcon from './assets/chimes_and_bowls.svg';
import CinematicIcon from './assets/cinematic.svg';
import ClassicalIcon from './assets/classical.svg';
import DroneIcon from './assets/drone.svg';
import ElectronicIcon from './assets/electronic.svg';
import ForestIcon from './assets/forest.svg';
import GroovesIcon from './assets/grooves.svg';
import LofiIcon from './assets/lofi.svg';
import NightsoundsIcon from './assets/nightsounds.svg';
import PianoIcon from './assets/piano.svg';
import PostRockIcon from './assets/post_rock.svg';
import RainIcon from './assets/rain.svg';
import RainforestIcon from './assets/rainforest.svg';
import RiverIcon from './assets/river.svg';
import ThunderIcon from './assets/thunder.svg';
import UnderwaterIcon from './assets/underwater.svg';
import WindIcon from './assets/wind.svg';

interface Props {
  genres?: {
    baseGenreNames: string[];
    natureGenreNames: string[];
  };
  isLoading: boolean;
  onGenreSelect: (genre: string) => void;
}

export const TrackGenres = ({ genres, onGenreSelect, isLoading }: Props) => {
  const handleGenreSelect = (genre: string) => () => {
    Analytics.logEventWithProperties(ExploreEvents.ExploreGenre, {
      genre,
    } as any);
    onGenreSelect(genre);
  };

  return (
    <S.Container>
      <S.Header>
        <TextBold size="1.5rem">Music Genres</TextBold>
      </S.Header>
      <S.GenresContainer>
        {isLoading && <S.Loading />}
        {genres?.baseGenreNames.map(genre => (
          <S.GenreWrapper key={genre}>
            <TrackGenre
              icon={getGenreIcon(genre)}
              name={genre}
              onClick={handleGenreSelect(genre)}
            />
          </S.GenreWrapper>
        ))}
      </S.GenresContainer>

      <S.Header>
        <TextBold size="1.5rem">Nature Soundscapes</TextBold>
      </S.Header>
      <S.GenresContainer>
        {isLoading && <S.Loading />}
        {genres?.natureGenreNames.map(genre => (
          <S.GenreWrapper key={genre}>
            <TrackGenre
              icon={getGenreIcon(genre)}
              name={genre}
              onClick={handleGenreSelect(genre)}
            />
          </S.GenreWrapper>
        ))}
      </S.GenresContainer>
    </S.Container>
  );
};

enum GenreIcons {
  Acoustic = 'Acoustic',
  Atmospheric = 'Atmospheric',
  Beach = 'Beach',
  ChimesAndBowls = 'Chimes & Bowls',
  Cinematic = 'Cinematic',
  Classical = 'Classical',
  Drone = 'Drone',
  Electronic = 'Electronic',
  Forest = 'Forest',
  Grooves = 'Grooves',
  Lofi = 'Lofi',
  Nightsounds = 'Nightsounds',
  Piano = 'Piano',
  PostRock = 'Post Rock',
  Rain = 'Rain',
  Rainforest = 'Rainforest',
  River = 'River',
  Thunder = 'Thunder',
  Underwater = 'Underwater',
  Wind = 'Wind',
}

const getGenreIcon = (genre: string) => {
  if (genre === GenreIcons.Acoustic) return AcousticIcon;
  if (genre === GenreIcons.Atmospheric) return AtmosphericIcon;
  if (genre === GenreIcons.Beach) return BeachIcon;
  if (genre === GenreIcons.ChimesAndBowls) return ChimesAndBowlsIcon;
  if (genre === GenreIcons.Cinematic) return CinematicIcon;
  if (genre === GenreIcons.Classical) return ClassicalIcon;
  if (genre === GenreIcons.Drone) return DroneIcon;
  if (genre === GenreIcons.Electronic) return ElectronicIcon;
  if (genre === GenreIcons.Forest) return ForestIcon;
  if (genre === GenreIcons.Grooves) return GroovesIcon;
  if (genre === GenreIcons.Lofi) return LofiIcon;
  if (genre === GenreIcons.Nightsounds) return NightsoundsIcon;
  if (genre === GenreIcons.Piano) return PianoIcon;
  if (genre === GenreIcons.PostRock) return PostRockIcon;
  if (genre === GenreIcons.Rain) return RainIcon;
  if (genre === GenreIcons.Rainforest) return RainforestIcon;
  if (genre === GenreIcons.River) return RiverIcon;
  if (genre === GenreIcons.Thunder) return ThunderIcon;
  if (genre === GenreIcons.Underwater) return UnderwaterIcon;
  if (genre === GenreIcons.Wind) return WindIcon;

  return ChimesAndBowlsIcon;
};
