import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.span`
  color: #d4d2ea;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 0.5rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
`;

export const Text = styled.span`
  color: white;
  flex: 1;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  margin-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: lowercase;
  white-space: nowrap;
  width: 100%;
`;

export const IconContainer = styled.div<{ isDown?: boolean }>`
  transition: transform 0.3s ease-in-out;
  transform: rotate(${({ isDown = false }) => (isDown ? '180deg' : '0deg')});
`;

export const TextContainer = styled.div`
  display: flex;
  margin-top: 0.15rem;
`;

export const Selection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1.25rem;
  margin-right: 1rem;
  height: 100%;
  min-width: 8rem;
  background: linear-gradient(0deg, #191736, #191736);
  backdrop-filter: blur(34px);
  border-radius: clamp(1rem, 8vw - 2rem, 1.5rem);
  border: 1px solid #525386;
  cursor: pointer;
`;
