import { useSelector } from 'react-redux';

import { RootReducerType } from '../../reducers';
import { MentalStates } from '../../types';
import { mentalStates } from '../../utils/mentalStates';

export type Session = {
  mentalState: MentalStates;
} | null;

export function getSession(state: RootReducerType): Session {
  const mentalState = getSessionMentalStateFromId(state.sessionManager.sessionMentalStateId);
  if (!mentalState) return null;

  return { mentalState };
}

export function useSession() {
  const sessionMentalStateId = useSelector(
    (state: Partial<RootReducerType>) => state.sessionManager?.sessionMentalStateId,
  );
  const mentalState = getSessionMentalStateFromId(sessionMentalStateId);

  if (!mentalState) return null;
  return { mentalState };
}

export function getSessionMentalStateFromId(mentalStateId?: string | null): MentalStates | null {
  if (mentalStateId === mentalStates.byType.focus._id) return MentalStates.Focus;
  if (mentalStateId === mentalStates.byType.relax._id) return MentalStates.Relax;
  if (mentalStateId === mentalStates.byType.sleep._id) return MentalStates.Sleep;
  return null;
}
