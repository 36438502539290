import { InputField, TextBold, Text, Icon, FontFamilies } from '@Cortex';
import styled from 'styled-components';

export const YourPlanWrapper = styled.div`
  display: flex;
  margin-right: 1rem;
`;

export const YourPlan = styled.div`
  background: linear-gradient(100.23deg, #515fbf -26.81%, #9249ff 134.77%);
  border-radius: 0.5rem;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
  padding: 0.5rem 0.75rem;
  color: white;
  text-transform: uppercase;
`;

export const PlanTitleWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  margin-bottom: 0.8rem;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const SwitchPlanWrapper = styled.div`
  color: #d4d2ea;
  cursor: pointer;
  font-size: 1rem;
  flex-shrink: 0;
  text-decoration: underline;
`;

export const PlanDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0px;
`;
export const PricingBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0px;
`;

export const CardContainer = styled.div`
  background: #0d0c11;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
`;

export const ButtonContainer = styled.div``;
export const BelowCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem 0;
`;

export const PlanCostText = styled(Text)`
  margin-right: 0.2rem;
`;

export const Strikethrough = styled(Text)`
  margin-right: 0.2rem;
  opacity: 0.5;
  text-decoration: line-through;
`;

export const ErrorWrapper = styled.div`
  margin-top: 1rem;
  background-color: rgba(255, 255, 255, 0.07);
  padding: 0.2rem;
  border-radius: 1rem;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    max-width: 750px;
    margin: 0 auto;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: auto;
  }
`;

export const PaymentContainer = styled.div`
  display: flex;
  max-width: 1500px;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
  }
`;

export const ErrorMessage = styled.p`
  margin: 0.5rem;
  font-size: 0.75rem;
  color: red;
  text-align: center;
`;

export const NameInputLabel = styled.label<{ isError?: boolean }>`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.9rem;
  color: ${({ isError = false }) => (isError ? 'red' : '#fff')};
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  display: block;
`;

export const SubscriptionInfoText = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 300;
  font-family: ${FontFamilies.Regular};
  color: rgba(255, 255, 255, 0.5);
`;

export const CardBox = styled.div`
  background-color: #0d0c11;
  border-radius: 1.5rem;
  padding: 2.5rem 1.5rem;
`;

export const NameInput = styled(InputField)`
  background: none;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
  margin: 0;
  height: 3.325rem;
  background: #0d0c11;
  border: 1px solid rgba(255, 255, 255, 0.2);

  &:focus {
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    font-size: 1rem;
  }
`;

export const NameInputContainer = styled.div`
  border-radius: 0.75rem;
  margin-bottom: 1rem;
`;

export const LeftBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: clamp(1rem, 8vw - 3rem, 4rem);
  flex: 1;
`;

export const RightBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: clamp(1rem, 8vw - 3rem, 4rem);
  flex: 1;
`;

export const Checkmarks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const ReviewsLeftBoxWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  display: block;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    display: none;
  }
`;

export const ReviewsRightBoxWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: none;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    display: block;
  }
`;

export const PlanCost = styled.div`
  display: flex;
  margin-top: 0.5rem;
  div {
    color: rgba(255, 255, 255, 0.75);
  }
`;

export const PoweredByImage = styled.img`
  position: absolute;
  width: 120px;
  right: 0;
  top: 0;
  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    position: relative;
    align-self: flex-end;
    margin-bottom: 1rem;
  }
`;
