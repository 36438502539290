export type InitialTimer = {
  currentTime: number;
  displayType: TimerDisplayTypes;
  startTime: null;
  status: TimerStatuses.Initial;
  timeSpentPaused: null;
  pomodoroSettings: {
    intervals: PomodoroIntervals;
  };
};

type ActiveTimer = {
  currentTime: number;
  displayType: TimerDisplayTypes;
  startTime: number;
  status: TimerStatuses.Paused | TimerStatuses.Running;
  timeSpentPaused: number;
  pomodoroSettings: {
    intervals: PomodoroIntervals;
  };
};

export type TimerState = InitialTimer | ActiveTimer;

export enum TimerDisplayTypes {
  Countup = 'Countup',
  Pomodoro = 'Pomodoro',
}

export enum TimerStatuses {
  Initial = 'Initial',
  Paused = 'Paused',
  Running = 'Running',
}

export enum PomodoroPhases {
  Work = 'Work',
  Break = 'Break',
  BreakEnding = 'BreakEnding',
  Inactive = 'Inactive',
}

export type PomodoroIntervals = {
  focusTime: string;
  breakTime: string;
};

export const DefaultPomodoroIntervals: PomodoroIntervals = {
  focusTime: '25',
  breakTime: '5',
};
