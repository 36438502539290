import { FontColors, TextBold, TextSemiBold } from '@Cortex';
import { EffectLevels } from '@Model';
import styled, { css } from 'styled-components';

import { ReactComponent as LevelIcon } from './assets/effect_level.svg';

export const TrackInfoBody = styled.div`
  margin-bottom: 1rem;
  background: rgba(25, 23, 54, 0.5);
  border-radius: 0.75rem;
  padding: 1.5rem;
  text-transform: lowercase;
`;

export const TrackInfoBodySection = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
`;

export const SectionTitle = styled(TextBold)`
  font-size: 0.875rem;
  text-transform: lowercase;
`;

export const SectionDescription = styled(TextSemiBold)`
  color: ${FontColors.WhiteSecondary};
  font-size: 0.875rem;
  margin-top: 0.25rem;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

export const EffectLevelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

export const GradientCircle = styled.div`
  background: linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 0.5rem;
`;

export const EffectLevelIcon = styled(LevelIcon)<{ $effectLevel: string }>`
  width: 8px;
  height: 8px;

  ${({ $effectLevel }) => {
    switch ($effectLevel) {
      case EffectLevels.Low:
        return css`
          & #high,
          & #medium {
            opacity: 0.5;
          }
        `;
      case EffectLevels.Medium:
        return css`
          & #high {
            opacity: 0.5;
          }
        `;
    }
  }}
`;

export const VariationsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
`;

export const Variation = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  flex-basis: 30%;
  background-color: ${({ theme }) => theme.colors.brandPrimary};
  &:hover {
    background-color: ${({ theme }) => theme.colors.brandPrimary500};
  }
`;

export const VariationData = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const VariationDescription = styled(TextSemiBold)`
  color: ${FontColors.WhiteSecondary};
  font-size: 0.6rem;
  margin-left: 6px;
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 12.1px;
  border-color: transparent transparent transparent #ffffff;
`;
