export type ClientParams = {
  path: string;
  token: string | null;
  config: RequestInit;
  options?: { apiVersion?: number };
};

export type GetParams = {
  path: string;
  token: string | null;
  config?: RequestInit;
  options?: { apiVersion?: number };
};

export type UseGetParams = {
  path: string;
  config?: RequestInit;
  options?: { apiVersion?: number };
};

export type PostParams<T> = {
  path: string;
  token: string | null;
  body: T;
  config?: RequestInit;
  options?: { apiVersion?: number };
};

export type UsePostParams<T> = {
  path: string;
  body: T;
  config?: RequestInit;
  options?: { apiVersion?: number };
};

export type PutParams<T> = {
  path: string;
  token: string | null;
  body: T;
  config?: RequestInit;
  options?: { apiVersion?: number };
};

export type UsePutParams<T> = {
  path: string;
  body: T;
  config?: RequestInit;
  options?: { apiVersion?: number };
};

export type DeleteParams<T> = {
  path: string;
  token: string | null;
  body: T;
  config?: RequestInit;
  options?: { apiVersion?: number };
};

export type UseDeleteParams<T> = {
  path: string;
  body: T;
  config?: RequestInit;
  options?: { apiVersion?: number };
};

export enum RequestMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}
