import styled from 'styled-components';

import Button, { ButtonProps } from './ButtonBase';

export const SecondaryButtonContainer = styled(Button)`
  padding: 0.9rem 2.25rem;
`;

export const SecondaryButton: React.FC<ButtonProps> = ({ children, analyticsEvent, ...rest }) => {
  return (
    <SecondaryButtonContainer data-analytics-event={analyticsEvent} {...rest}>
      {children}
    </SecondaryButtonContainer>
  );
};
