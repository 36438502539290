import { Text } from '@Cortex';
import { CouponCode } from '@Payment';
import { useFeatureFlag, FeatureFlags } from '@Utils';
import { createRef, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { GiftCard } from '../../domains/Payment/components/GiftCard';
import { StripePaymentTestContainer } from '../../domains/Payment/components/StripePaymentTestContainer/StripePaymentTestContainer';
import { useIsExtendedPromoAvailable } from '../../domains/Payment/components/StripePaymentTestContainer/hooks/useIsExtendedPromoAvailable';
import { HeaderWithLogoAndClose } from '../../domains/shared/HeaderWithLogoAndClose';
import { useAnalyticsPageView } from '../../hooks';
import useImagesPreloader from '../../hooks/useImagesPreloader';
import { Assets } from '../../utils/assets';
import { CoreAnalytics } from '@Analytics';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem;
`;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  justify-content: center;
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
  }
`;

const CodesContainer = styled.div`
  display: flex;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    align-items: center;
    flex-direction: column;
  }
`;

const TermsContainer = styled.div`
  margin: 1.5rem auto;
  max-width: 764px;
`;

const CouponCodeContainer = styled.div`
  margin: 0 2rem;
  width: 75%;
`;

const CouponToggle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;
`;

const HaveACoupon = styled(Text)`
  cursor: pointer;
`;

const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

interface HistoryState {
  redirect: boolean;
  from?: string;
}

const ImagesToPreload = [Assets.images.subscriptionSuccess.url];

export const OnboardingPayment = () => {
  useImagesPreloader(ImagesToPreload);
  const navigate = useNavigate();
  const location = useLocation();
  const isGiftCardsEnabled = useFeatureFlag(FeatureFlags.GiftCards);
  const audioRef = createRef<HTMLMediaElement>();
  const onboardingMusicUrl = location.state?.onboardingMusicUrl;
  const paywallTestimonials = location.state?.paywallTestimonials;
  const paywallBenefits = location.state?.paywallBenefits;
  const isExtendedPromoAvailable = useIsExtendedPromoAvailable();

  useAnalyticsPageView('subscription_screen_view', {
    subscribe_screen_from: (location.state as HistoryState)?.from,
  });
  const [isCodeInputVisible, setIsCodeInputVisible] = useState(false);

  useEffect(() => {
    if (audioRef.current && onboardingMusicUrl) {
      audioRef.current.volume = 0.4;
      audioRef.current.play();
    }
  }, [audioRef, onboardingMusicUrl]);

  const handleOnClose = useCallback(() => {
    if ((location.state as HistoryState)?.redirect) {
      navigate('/welcome', {
        replace: true,
        state: {
          origin: 'subscription_screen',
        },
      });
    } else {
      navigate('/', { replace: true });
    }

    CoreAnalytics.trackSubscriptionPlanLeaveEvent();
  }, [navigate, location]);

  return (
    <PaymentWrapper>
      <audio ref={audioRef} src={onboardingMusicUrl} />
      <Wrapper>
        <Container>
          <StripePaymentTestContainer
            paywallBenefits={paywallBenefits}
            paywallTestimonials={paywallTestimonials}
            onClose={handleOnClose}
            onPaymentComplete={handleOnClose}
          />
        </Container>
        <TermsContainer>
          {!isExtendedPromoAvailable && (
            <>
              {isCodeInputVisible ? (
                <CodesContainer>
                  <CouponCodeContainer>
                    <CouponCode />
                  </CouponCodeContainer>
                  {isGiftCardsEnabled && (
                    <CouponCodeContainer>
                      <GiftCard />
                    </CouponCodeContainer>
                  )}
                </CodesContainer>
              ) : (
                <CouponToggle>
                  <HaveACoupon
                    data-testid="displayCouponForm"
                    onClick={() => setIsCodeInputVisible(true)}
                  >
                    Gift card or coupon code? Click here.
                  </HaveACoupon>
                </CouponToggle>
              )}
            </>
          )}
        </TermsContainer>
      </Wrapper>
    </PaymentWrapper>
  );
};
