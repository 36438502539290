export const getCountdownTextForMentalState = (mentalState: string) => {
  switch (mentalState) {
    case 'sleep':
      return 'GETTING SLEEPY...';
    case 'relax':
      return 'RELAXING...';
    case 'focus':
    default:
      return 'INCREASING FOCUS...';
  }
};
