import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { TextBold } from '@Cortex';
import { Serving, Track } from '@Model';
import { getTrackId, getTrackName, useApplyFiltersToTracks } from '@Music';
import { useDispatch, useSelector } from 'react-redux';

import { trackCardLoad } from '../../../../../../actions/sessionManager';
import { useIsEnabledDynamicActivities } from '../../../../../../hooks/useIsEnabledDynamicActivities';
import { useShareTrack } from '../../../../../../hooks/useShareTrack';
import { RootReducerType } from '../../../../../../reducers';
import { Analytics } from '../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../utils/analytics/events';
import {
  removeFromFavorites,
  addToFavorites,
  addToDislikes,
  removeFromDislikes,
} from '../../../../actions';
import { ExpandableTrackCard } from '../../../../components/ExpandableTrackCard';
import { NoSearchResults } from '../NoSearchResults/NoSearchResults';
import * as S from './SearchResults.styles';

type TrackDictionary = {
  [key: string]: Track | Serving;
};

export function SearchResults() {
  const dispatch = useDispatch();
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();
  const { handleGetShareLink } = useShareTrack();
  const dislikedTrackIds = useSelector((state: RootReducerType) => state.music.dislikedTrackIds);
  const favorites = useSelector((state: RootReducerType) => state.music.favorites);
  const search = useSelector((state: RootReducerType) => state.music.search);

  const filteredTracks = useApplyFiltersToTracks(search.results || []);

  if (search.state === 'loading') {
    return <S.Loading />;
  }

  if (search.state === 'error' && search.error) {
    return <TextBold>{search.error}</TextBold>;
  }

  // sets up a dictionary for efficient lookup of favorite track
  const favoritesDictionary = favorites.reduce((acc, track) => {
    acc[getTrackId(track)] = track;
    return acc;
  }, {} as TrackDictionary);

  function handleClickPlay(track: Track | Serving) {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromSearch, {
      trackId: getTrackId(track),
      trackName: getTrackName(track),
    } as any);

    dispatch(trackCardLoad({ trackId: getTrackId(track), isUsingDynamicActivities }));

    CoreAnalytics.trackSelectTrackEvent({
      origin: TrackActionOrigins.Search,
      trackName: getTrackName(track),
    });
  }

  const handleVariationClickPlay = (track: Track | Serving) => (trackVariationId: string) => {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromSearch, {
      trackId: getTrackId(track),
      trackVariationId,
      trackName: getTrackName(track),
    } as any);

    dispatch(
      trackCardLoad({ trackId: getTrackId(track), trackVariationId, isUsingDynamicActivities }),
    );
  };

  return (
    <S.CardsContainer>
      {!search.results?.length ? (
        <NoSearchResults title={search.results === null ? 'Start Searching' : 'No Tracks Found'} />
      ) : null}
      {filteredTracks.map(track => {
        return (
          <S.CardContainer key={getTrackId(track)}>
            <ExpandableTrackCard
              analyticsEvent={ExploreEvents.toggle_track_card_expansion__search}
              dislikeButtonTestId={
                dislikedTrackIds.includes(getTrackId(track))
                  ? `removeFromDislikes__${getTrackId(track)}`
                  : `addToDislikes__${getTrackId(track)}`
              }
              displayType="track"
              expandIconTestId={`expandButton__${getTrackId(track)}`}
              favoritesButtonTestId={`searchResultFavoriteButton__${getTrackId(track)}`}
              isDisliked={dislikedTrackIds.includes(getTrackId(track))}
              isDislikingAvailable={false}
              isFavorited={!!favoritesDictionary[getTrackId(track)]}
              isFavoritingAvailable={false}
              track={track}
              onAddToDislikes={() => dispatch(addToDislikes({ track }))}
              onAddToFavorites={() =>
                dispatch(addToFavorites({ origin: TrackActionOrigins.Explore, track }))
              }
              onClickPlay={() => handleClickPlay(track)}
              onRemoveFromDislikes={() =>
                dispatch(
                  removeFromDislikes({
                    trackId: getTrackId(track),
                  }),
                )
              }
              onRemoveFromFavorites={() =>
                dispatch(
                  removeFromFavorites({
                    track,
                  }),
                )
              }
              onShareTrack={() => handleGetShareLink(track)}
              onVariationClickPlay={handleVariationClickPlay(track)}
            />
          </S.CardContainer>
        );
      })}
    </S.CardsContainer>
  );
}
