import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

import CheckmarkIcon from '../../../Session/components/TimerSettings/assets/checkmark.svg';

export const Container = styled.div`
  position: relative;
  isolation: isolate;
  text-transform: lowercase;
  z-index: 10;
`;

export const Header = styled.div`
  text-transform: lowercase;
`;

export const Button = styled.button<{ hasBorder: boolean }>`
  align-items: center;
  background: ${props => (props.hasBorder ? 'rgba(25, 23, 54)' : 'rgba(25, 23, 54, 0.5)')};
  border-radius: 0.75rem;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  ${props => (props.hasBorder ? null : 'transition: background 0.3s ease-in-out;')}
  width: 100%;

  &:hover {
    background: #191736;
  }

  ${({ hasBorder }) =>
    hasBorder
      ? css`
          &::before {
            background: linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%);
            border-radius: 0.8rem;
            bottom: -1px;
            content: '';
            left: -1px;
            position: absolute;
            right: -1px;
            top: -1px;
            z-index: -1;
          }
          &::after {
            content: '';
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
            position: absolute;
            z-index: 1;
            top: 50%;
            right: 1.125rem;

            background-size: 60%, cover;
            object-fit: contain;
            transform: translateY(-50%);
            background:
              url(${CheckmarkIcon}) no-repeat center,
              linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%) no-repeat;
          }
        `
      : null}
`;

export const FilterTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageIcon = styled.img`
  width: 1rem;
  height: 1rem;
  object-fit: contain;
  margin: 0.5rem;
`;

export const ChevronIcon = styled.img<{ isUp: boolean }>`
  width: 1rem;
  height: 1rem;
  object-fit: contain;
  margin-left: 0.5rem;
  transform: ${({ isUp }) => (isUp ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 0.2s ease;
`;

export const FilterContainer = styled.div`
  background: #191736;
  border-radius: 0.75rem;
  padding: 1rem;
`;

export const FilterWrapper = styled.div<{ animationState: TransitionStatus }>`
  left: 0;
  padding-top: 0.5rem;
  position: absolute;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  isolation: isolate;

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0.2;
      case 'entered':
        return 1;
      case 'exiting':
        return 0.2;
      case 'exited':
        return 0;
    }
  }};
  transform: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return `translateY(-5px)`;
      case 'entered':
        return `translateY(0)`;
      case 'exiting':
        return `translateY(0)`;
      case 'exited':
        return `translateY(-5px)`;
    }
  }};
`;
