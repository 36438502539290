import { EffectLevels } from '@Model';
import { NeuralEffectLevelIcon } from '@Music';
import { useFeatureFlag, FeatureFlags } from '@Utils';
import { SyntheticEvent } from 'react';

import { MultiNelIcon } from '../MultiNelIcon';
import * as S from './CurrentTrackInformationCard.styles';
import { HeartIcon } from './components/HeartIcon';
import { ShareIcon } from './components/ShareIcon';
import { ThumbsDownIcon } from './components/ThumbsDownIcon/ThumbsDownIcon';
import { FALLBACK_TRACK_IMAGE } from '@Globals';

export type Props = {
  addToFavoritesButtonTestId?: string;
  shareButtonTestId?: string;
  dislikeButtonTestId?: string;
  imageUrl: string;
  hasMultipleNELs?: boolean;
  isDisliked?: boolean;
  isFavoritingAvailable?: boolean;
  isDislikingAvailable?: boolean;
  isFavorited?: boolean;
  isNewlyCreated?: boolean;
  neuralEffectLevel: EffectLevels;
  onAddToDislikes?: () => void;
  onAddToFavorites?: () => void;
  onClick?: () => void;
  onShareTrack?: () => void;
  onRemoveFromDislikes?: () => void;
  onRemoveFromFavorites?: () => void;
  genre: string;
  title: string;
};

export function CurrentTrackInformationCard(props: Props) {
  const isDeepLinksEnabled = useFeatureFlag(FeatureFlags.DeepLinks);

  return (
    <S.Container data-testid="currentTrackInformationCard">
      <S.Image
        src={props.imageUrl}
        onClick={createHandleClick(props.onClick)}
        onError={evt => {
          evt.currentTarget.src = FALLBACK_TRACK_IMAGE;
        }}
      />
      <S.InformationContainer>
        <S.Title data-testid="currentTrackTitle" onClick={createHandleClick(props.onClick)}>
          {props.title}
          {props.isNewlyCreated && <S.TrackLabel data-testid="newLabel">new</S.TrackLabel>}
        </S.Title>

        <S.NeuralEffectContainer>
          <S.NeuralEffectLevelIcon>
            {props.hasMultipleNELs ? (
              <MultiNelIcon />
            ) : (
              <NeuralEffectLevelIcon effectLevel={props.neuralEffectLevel} />
            )}
          </S.NeuralEffectLevelIcon>
          <S.NeuralEffectText
            data-testid="trackNeuralEffect"
            onClick={createHandleClick(props.onClick)}
          >
            {`${props.neuralEffectLevel} Neural Effect`}
          </S.NeuralEffectText>
        </S.NeuralEffectContainer>
        <S.PillContainer>
          <S.GenrePill>
            <S.PillText data-testid="trackGenre" onClick={createHandleClick(props.onClick)}>
              {props.genre}
            </S.PillText>
          </S.GenrePill>
          <S.DetailPill>
            <S.PillText data-testid="trackDetail" onClick={createHandleClick(props.onClick)}>
              + Details
            </S.PillText>
          </S.DetailPill>
        </S.PillContainer>
        <S.TrackActions>
          {props.isDislikingAvailable && (
            <S.TrackActionButton
              data-cy="dislikeCurrentButton"
              data-testid={props.dislikeButtonTestId ?? 'dislikeButton'}
              onClick={createHandleClick(
                props.isDisliked ? props.onRemoveFromDislikes : props.onAddToDislikes,
              )}
            >
              <ThumbsDownIcon fillOpacity={props.isDisliked ? 1 : 0} />
            </S.TrackActionButton>
          )}
          {props.isFavoritingAvailable && (
            <S.TrackActionButton
              data-cy="addToFavoritesButton"
              data-testid={props.addToFavoritesButtonTestId ?? 'addToFavoritesButton'}
              onClick={createHandleClick(
                props.isFavorited ? props.onRemoveFromFavorites : props.onAddToFavorites,
              )}
            >
              <HeartIcon fillOpacity={props.isFavorited ? 1 : 0} />
            </S.TrackActionButton>
          )}
          {props.onShareTrack && isDeepLinksEnabled && (
            <S.TrackActionButton
              data-testid={props.shareButtonTestId ?? 'shareButton'}
              onClick={createHandleClick(props.onShareTrack)}
            >
              <ShareIcon />
            </S.TrackActionButton>
          )}
        </S.TrackActions>
      </S.InformationContainer>
    </S.Container>
  );
}

function createHandleClick(onClick?: () => void): (event: SyntheticEvent) => void {
  return function handleClick(event: SyntheticEvent): void {
    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }
  };
}
