import { NOOP } from '@Globals';
import { HTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import { Assets } from '../../utils/assets';
import Card from './Card';

const ImageContainer = styled.div`
  display: flex;

  &:hover {
    & img {
      transform: scale(1.05);
    }
  }
`;

const Image = styled.img<{ active: boolean }>`
  position: absolute;
  top: -3rem;
  right: -7rem;
  width: 88%;
  height: auto;
  transition:
    opacity 0.6s ease-in-out,
    transform 0.6s ease-in-out;
  opacity: ${({ active }) => (active ? 1 : 0)};

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    width: auto;
    height: 100%;
    top: 0;
    left: -9.5rem;
    right: initial;
  }

  ${({ theme }) => theme.mediaQuery.minWidth.lg} {
    width: auto;
    height: 100%;
    right: initial;
    top: 0;
    left: -9.5rem;
  }

  // avoid weird image placement when resizing vertically
  @media (max-height: 660px) and (min-width: 768px) {
    top: initial;
    right: initial;
    width: auto;
    height: 100%;
  }

  @media (max-height: 512px) and (min-width: 768px) {
    top: -2rem;
    left: initial;
    right: -3rem;
    width: 100%;
    height: auto;
  }

  // safari has a bug with overflow:hidden on absolutely positioned children;
  // this is a workaround for it.
  // https://stackoverflow.com/questions/44948735/overflow-hidden-is-not-working-with-absolute-element-in-safari
  transform: translateZ(0);
`;

interface RelaxCardProps extends HTMLAttributes<HTMLDivElement> {
  isActive: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const RelaxCard = ({
  isActive,
  onMouseEnter = NOOP,
  onMouseLeave = NOOP,
  ...props
}: RelaxCardProps) => {
  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
    onMouseEnter();
  };

  const handleMouseLeave = () => {
    setHovered(false);
    onMouseLeave();
  };

  return (
    <Card
      isActive={isActive}
      text="Relax."
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      <ImageContainer>
        <picture>
          <source srcSet={Assets.images.relaxMentalStateBgWebp.url} type="image/webp" />
          <Image
            active={!isHovered}
            alt={Assets.images.relaxMentalStateBgPng.alt}
            src={Assets.images.relaxMentalStateBgPng.url}
          />
        </picture>
        <picture>
          <source srcSet={Assets.images.relaxMentalStateBgAuraWebp.url} type="image/webp" />
          <Image
            active={isHovered}
            alt={Assets.images.relaxMentalStateBgAuraPng.alt}
            src={Assets.images.relaxMentalStateBgAuraPng.url}
          />
        </picture>
      </ImageContainer>
    </Card>
  );
};

export default RelaxCard;
