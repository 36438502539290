import { UserPreferenceDisplayTypes, useDefaultDisplayType } from '@User';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSessionSidebarContent } from '../../../../actions/ui';
import { RootReducerType } from '../../../../reducers';
import { SessionSidebarContent, SessionType } from '../../../../types';
import { QuotesContainer } from '../QuotesContainer';
import { Timer } from '../Timer';
import { getAfterCountdownTextForMentalState } from './utils/getAfterCountdownTextForMentalState';
import { getCountdownTextForMentalState } from './utils/getCountdownTextForMentalState';

type Props = {
  type: SessionType;
};

export const TimerContainerLegacy = (props: Props) => {
  const { type } = props;
  const dispatch = useDispatch();
  const defaultDisplayType = useDefaultDisplayType();
  const sessionPlayType = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionPlayType,
  );
  const sessionSidebarContent = useSelector(
    (state: RootReducerType) => state.ui.sessionSidebarContent,
  );
  const setSessionSidebar = (contentType: SessionSidebarContent) => {
    dispatch(
      setSessionSidebarContent({ sessionSidebarContent: contentType, origin: 'player_display' }),
    );
  };
  const closeSidebarComponent = useCallback(() => {
    dispatch(setSessionSidebarContent({ sessionSidebarContent: null, origin: undefined }));
  }, [dispatch]);

  // currently TIMER is not ever counted as a default display type, so we need to only render
  // quotes when thats not the session type.
  const shouldRenderQuotes =
    defaultDisplayType === UserPreferenceDisplayTypes.Quotes && sessionPlayType !== 'TIMER';

  const onClick = useCallback(() => {
    if (sessionSidebarContent) {
      closeSidebarComponent();
      return;
    }
    setSessionSidebar(SessionSidebarContent.TimerSettings);
  }, [sessionSidebarContent, closeSidebarComponent, setSessionSidebar]);

  return shouldRenderQuotes ? (
    <QuotesContainer onClick={onClick} />
  ) : (
    <Timer
      afterCountdownText={getAfterCountdownTextForMentalState(type)}
      countdownText={getCountdownTextForMentalState(type)}
      onClick={onClick}
    />
  );
};
