import styled from 'styled-components';

import { CALL_TO_ACTION_WIDGET_MOBILE_BREAKPOINT } from '../../../Memberships';

export const AudioControlWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0;
  z-index: 99;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    padding: 1rem;
  }
`;

export const MembershipWidgetWrapper = styled.div`
  display: none;

  /* this constant ensures that the other instance of this component pops in and out at the
   same breakpoint as this one! see <SessionHeader /> -danp */
  ${CALL_TO_ACTION_WIDGET_MOBILE_BREAKPOINT} {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
`;
