import { useCallback } from 'react';

import { useGet } from '../../client/client';
import { Endpoints } from '../../common/endpoints';
import { TGenreNamesResponse } from './types';

export const useRequestHandler = () => {
  const get = useGet<TGenreNamesResponse>();

  return useCallback(
    async (activityId: string) => {
      const data = await get({
        path: Endpoints.getGenreNamesByActivity(activityId),
        options: { apiVersion: 3 },
      });
      return data.result;
    },
    [get],
  );
};
