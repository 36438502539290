import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { TextSemiBold } from '@Cortex';
import { Serving, Track } from '@Model';
import {
  addToDislikes,
  addToFavorites,
  getTrackName,
  removeFromDislikes,
  removeFromFavorites,
  TrackInformationCard,
  getTrackId,
  getTrackImageUrl,
  getTrackHasMultipleNELs,
  getTrackIsNewlyCreated,
  getTrackGenreName,
  getTrackNeuralEffectLevel,
} from '@Music';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { trackCardLoad } from '../../../../../../../../actions/sessionManager';
import { useSimilarTracks } from '../../../../../../../../api/modules/SimilarTracks';
import { useIsEnabledDynamicActivities } from '../../../../../../../../hooks/useIsEnabledDynamicActivities';
import { useShareTrack } from '../../../../../../../../hooks/useShareTrack';
import { RootReducerType } from '../../../../../../../../reducers';
import { getDynamicSimilarActivityPlayerPath } from '../../../../../../../../utils/getDynamicSimilarActivityPlayerPath';
import { ExpandableTrackCard } from '../../../../../ExpandableTrackCard';
import * as S from './SimilarTracks.styles';

interface Props {
  favoritesDictionary: { [key: string]: Track | Serving };
}

export const SimilarTracks = ({ favoritesDictionary }: Props) => {
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleGetShareLink } = useShareTrack();
  const dislikedTrackIds = useSelector((state: RootReducerType) => state.music.dislikedTrackIds);
  const { isLoading, data, error } = useSimilarTracks();

  if (isLoading) {
    return (
      <S.Container>
        <S.Loading />
      </S.Container>
    );
  }

  if (error) {
    return (
      <S.Container>
        <TextSemiBold>No Similar tracks were found.</TextSemiBold>
      </S.Container>
    );
  }

  const handleFavoriteClick = (track: Track | Serving) => {
    if (favoritesDictionary[getTrackId(track)]) {
      dispatch(removeFromFavorites({ track }));
    } else {
      dispatch(addToFavorites({ origin: TrackActionOrigins.SimilarTracks, track }));
    }
  };

  const handleTrackSelectTrack = (track: Track | Serving) => {
    CoreAnalytics.trackSelectTrackEvent({
      origin: TrackActionOrigins.SimilarTracks,
      trackName: getTrackName(track),
    });
  };
  const handleAddToDislikes = (track: Track | Serving) => {
    dispatch(addToDislikes({ track }));
  };
  const handleRemoveFromDislikes = (track: Track | Serving) => {
    dispatch(removeFromDislikes({ trackId: getTrackId(track) }));
  };
  const handleVariationClickPlay = (track: Track | Serving) => (trackVariationId: string) => {
    dispatch(
      trackCardLoad({ trackId: getTrackId(track), trackVariationId, isUsingDynamicActivities }),
    );
    handleTrackSelectTrack(track);
  };
  const handleTrackClickPlay = (track: Track | Serving) => {
    dispatch(trackCardLoad({ trackId: getTrackId(track), isUsingDynamicActivities }));
    handleTrackSelectTrack(track);
  };

  const handleTrackCardClick = (serving: Track | Serving) => () => {
    if (isUsingDynamicActivities) {
      navigate(getDynamicSimilarActivityPlayerPath(getTrackId(serving)));
      return;
    }
    handleTrackClickPlay(serving);
  };

  return (
    <S.Container>
      {data?.map(serving => (
        <S.TrackWrapper key={getTrackId(serving)}>
          {getTrackHasMultipleNELs(serving) && !isUsingDynamicActivities ? (
            <ExpandableTrackCard
              displayType="track"
              isDisliked={dislikedTrackIds.includes(getTrackId(serving))}
              isDislikingAvailable={false}
              isFavorited={!!favoritesDictionary[getTrackId(serving)]}
              isFavoritingAvailable={false}
              track={serving}
              onAddToDislikes={() => handleAddToDislikes(serving)}
              onAddToFavorites={() => handleFavoriteClick(serving)}
              onClickPlay={() => handleTrackClickPlay(serving)}
              onRemoveFromDislikes={() => handleRemoveFromDislikes(serving)}
              onRemoveFromFavorites={() => handleFavoriteClick(serving)}
              onShareTrack={() => handleGetShareLink(serving)}
              onVariationClickPlay={handleVariationClickPlay(serving)}
            />
          ) : (
            <TrackInformationCard
              hasMultipleNELs={getTrackHasMultipleNELs(serving)}
              imageUrl={getTrackImageUrl(serving)}
              isDisliked={dislikedTrackIds.includes(getTrackId(serving))}
              isDislikingAvailable={false}
              isFavorited={!!favoritesDictionary[getTrackId(serving)]}
              isFavoritingAvailable={false}
              isNewlyCreated={getTrackIsNewlyCreated(serving)}
              neuralEffectLevel={getTrackNeuralEffectLevel(serving)}
              subtitle={`${getTrackGenreName(serving)} • ${
                getTrackHasMultipleNELs(serving) ? 'Multiple' : getTrackNeuralEffectLevel(serving)
              } Neural Effect`}
              title={getTrackName(serving)}
              onAddToDislikes={() => handleAddToDislikes(serving)}
              onAddToFavorites={() => handleFavoriteClick(serving)}
              onClick={handleTrackCardClick(serving)}
              onRemoveFromDislikes={() => handleRemoveFromDislikes(serving)}
              onRemoveFromFavorites={() => handleFavoriteClick(serving)}
              onShareTrack={() => handleGetShareLink(serving)}
            />
          )}
        </S.TrackWrapper>
      ))}
    </S.Container>
  );
};
