import {
  StripeCouponMembershipPromotion,
  useGetAvailableStripeCouponPromotion,
} from '@Memberships';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../../../reducers';
import { EnhancedMembershipPlanType } from '../../../../../types';
import { enhanceMembershipInfo } from './enhanceMembershipInfo';

export function useGetMembershipPlans(): EnhancedMembershipPlanType[] {
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const membershipPlans = useSelector((state: RootReducerType) => state.membershipPlans.plans);
  const enhancedPlans = enhanceMembershipInfo(Object.values(membershipPlans.results)).sort(
    (a, b) => b.price - a.price,
  );
  return availablePromotion
    ? replacePlansWithPromotions({ availablePromotion, enhancedPlans })
    : enhancedPlans;
}

function replacePlansWithPromotions(params: {
  availablePromotion: StripeCouponMembershipPromotion;
  enhancedPlans: EnhancedMembershipPlanType[];
}): EnhancedMembershipPlanType[] {
  const promotionPlan = params.enhancedPlans.find(plan => {
    return plan.title === params.availablePromotion.targetPlan;
  });

  if (!promotionPlan) return params.enhancedPlans;

  return [
    {
      ...promotionPlan,
      couponId: params.availablePromotion.couponId,
      description: params.availablePromotion.description,
      displayCost: params.availablePromotion.displayCost,
      displayInterval: params.availablePromotion.targetPlan === 'Monthly' ? 'mo' : 'yr',
      originalCost: promotionPlan.price.toString(),
      promotionCode: params.availablePromotion.promotionCode,
      title: params.availablePromotion.name,
    },
  ];
}
