import { MentalStatesDataType } from '../types/mentalStates';

export const mentalStates: MentalStatesDataType = {
  types: ['focus', 'relax', 'sleep'],
  byId: {
    '6042a1bad80ae028b821e954': {
      _id: '6042a1bad80ae028b821e954',
      activities: [
        '6042a1bad80ae028b821e953',
        '6042a1bad80ae028b821e956',
        '6042a1bad80ae028b821e955',
      ],
      type: 'focus',
      display: 'Focus',
      color: '#EE8894',
    },
    '6042a1bad80ae028b821e958': {
      _id: '6042a1bad80ae028b821e958',
      activities: [
        '6042a1bad80ae028b821e959',
        '6042a1bad80ae028b821e957',
        '6042a1bad80ae028b821e95a',
      ],
      type: 'relax',
      display: 'Relax',
      color: '#6ECBFD',
    },
    '6042a1bad80ae028b821e95c': {
      _id: '6042a1bad80ae028b821e95c',
      activities: ['6042a1bad80ae028b821e95b', '6042a1bad80ae028b821e95d'],
      type: 'sleep',
      display: 'Sleep',
      color: '#7F4AFF',
    },
  },
  byType: {
    focus: {
      _id: '6042a1bad80ae028b821e954',
      activities: [
        '6042a1bad80ae028b821e953',
        '6042a1bad80ae028b821e956',
        '6042a1bad80ae028b821e955',
      ],
      type: 'focus',
      display: 'Focus',
      color: '#EE8894',
    },
    relax: {
      _id: '6042a1bad80ae028b821e958',
      activities: [
        '6042a1bad80ae028b821e959',
        '6042a1bad80ae028b821e957',
        '6042a1bad80ae028b821e95a',
      ],
      type: 'relax',
      display: 'Relax',
      color: '#6ECBFD',
    },
    sleep: {
      _id: '6042a1bad80ae028b821e95c',
      activities: ['6042a1bad80ae028b821e95b', '6042a1bad80ae028b821e95d'],
      type: 'sleep',
      display: 'Sleep',
      color: '#7F4AFF',
    },
  },
};
