import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  ${({ theme }) => theme.mediaQuery.maxWidth.xs} {
    flex-direction: column-reverse;
  }
`;

export const TextWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
`;

export const ButtonWrapper = styled.div`
  margin: 0.5rem;
  display: flex;
  justify-content: center;
`;
