import { Accordion } from '@Cortex';
import { Serving, Track } from '@Model';
import {
  getTrackActivityId,
  getTrackComplexity,
  getTrackGenreName,
  getTrackHasMultipleNELs,
  getTrackImageUrl,
  getTrackInstrumentations,
  getTrackIsNewlyCreated,
  getTrackMentalStateId,
  getTrackMoods,
  getTrackName,
  getTrackNeuralEffectLevel,
} from '@Music';
import { useIsEnabledDynamicActivities } from '../../../../hooks/useIsEnabledDynamicActivities';

import { activities } from '../../../../utils/activities';
import { ExploreEvents } from '../../../../utils/analytics/events';
import { mentalStates } from '../../../../utils/mentalStates';
import { getTrackDynamicMentalStateDisplayValue } from '../../lenses/getTrackDynamicMentalStateDisplayValue';
import { getTrackVariations } from '../../lenses/getTrackVariations';
import { TrackDetails } from '../TrackDetails';
import { TrackInformationCard } from '../TrackInformationCard';
import * as S from './ExpandableTrackCard.styles';

export type Props = {
  analyticsEvent?: ExploreEvents | null;
  dislikeButtonTestId?: string;
  displayType: 'track' | 'trackVariation';
  expandIconTestId?: string;
  favoritesButtonTestId?: string;
  isDisliked: boolean;
  isDislikingAvailable?: boolean;
  isFavorited: boolean;
  isFavoritingAvailable?: boolean;
  onAddToDislikes?: () => void;
  onAddToFavorites?: () => void;
  onClickPlay: () => void;
  onRemoveFromDislikes?: () => void;
  onRemoveFromFavorites?: () => void;
  onShareTrack?: () => void;
  onVariationClickPlay?: (variationId: string) => void;
  track: Track | Serving;
};

const DELIMITER = ' • ';

export function ExpandableTrackCard(props: Props) {
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();
  const activity = isUsingDynamicActivities
    ? ''
    : activities.byId[getTrackActivityId(props.track)]?.display || '';
  const mentalStateDisplayValue = isUsingDynamicActivities
    ? getTrackDynamicMentalStateDisplayValue(props.track)
    : mentalStates.byId[getTrackMentalStateId(props.track)]?.display || '';

  const shouldShowMultipleNEL =
    getTrackHasMultipleNELs(props.track) && props.displayType === 'track';

  return (
    <Accordion
      analyticsEvent={props.analyticsEvent}
      expandIconTestId={props.expandIconTestId}
      renderAnchor={() => (
        <TrackInformationCard
          addToFavoritesButtonTestId={props.favoritesButtonTestId}
          dislikeButtonTestId={props.dislikeButtonTestId}
          hasMultipleNELs={shouldShowMultipleNEL}
          imageUrl={getTrackImageUrl(props.track)}
          isDisliked={props.isDisliked}
          isDislikingAvailable={props.isDislikingAvailable}
          isFavorited={props.isFavorited}
          isFavoritingAvailable={props.isFavoritingAvailable}
          isNewlyCreated={getTrackIsNewlyCreated(props.track)}
          neuralEffectLevel={getTrackNeuralEffectLevel(props.track)}
          subtitle={getTrackCardSubtitle(props.track, shouldShowMultipleNEL)}
          title={getTrackName(props.track)}
          onAddToDislikes={props.onAddToDislikes}
          onAddToFavorites={props.onAddToFavorites}
          onClick={props.onClickPlay}
          onRemoveFromDislikes={props.onRemoveFromDislikes}
          onRemoveFromFavorites={props.onRemoveFromFavorites}
          onShareTrack={props.onShareTrack}
        />
      )}
      renderDropdown={() => (
        <S.TrackDetails>
          <TrackDetails
            NEL={getTrackNeuralEffectLevel(props.track)}
            activity={activity}
            complexity={getTrackComplexity(props.track)}
            hasMultipleNELs={shouldShowMultipleNEL}
            instrumentation={getTrackInstrumentations(props.track).join(DELIMITER)}
            mentalState={mentalStateDisplayValue}
            mood={getTrackMoods(props.track).join(DELIMITER)}
            variations={getTrackVariations(props.track)}
            onVariationClick={props.onVariationClickPlay}
          />
        </S.TrackDetails>
      )}
    />
  );
}

function getTrackCardSubtitle(track: Track | Serving, shouldShowMultipleNEL?: boolean): string {
  return [
    getTrackGenreName(track),
    `${shouldShowMultipleNEL ? 'Multiple' : getTrackNeuralEffectLevel(track)} Neural Effect`,
  ]
    .join(DELIMITER)
    .toUpperCase();
}
