import { Icon, RelaxCard, SleepCard, FocusCard } from '@Cortex';
import { useResetTimer, useTurnOnPomodoroTimer } from '@Timer';
import { ProfileWidget, UserPreferenceDisplayTypes, useUser } from '@User';
import { FeatureFlags, useFeatureFlag } from '@Utils';
import React, { useLayoutEffect, useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { homeScreenLogEvent, logEventWithProperties } from '../../actions/analytics';
import { createDynamicSession, createSession } from '../../actions/sessionManager';
import BrandLogo from '../../assets/images/brand_logo.svg';
import { useIsEnabledDynamicActivities } from '../../hooks/useIsEnabledDynamicActivities';
import { RootReducerType } from '../../reducers';
import { SessionType } from '../../types/sessionManager';
import { activities } from '../../utils/activities';
import { mentalStates } from '../../utils/mentalStates';
import { useDynamicMentalStates } from '../../api/modules/DynamicMentalStates';
import DynamicMentalStateCard from '../../domains/Cortex/DynamicMentalStateCard';
import { DynamicMentalState } from '@Model';
import { ONBOARDING_MENTAL_STATE_ID_KEY } from '../../domains/Onboarding/constants';

// this animation timing serves TWO purposes
// to fade out nicely before starting a new session
// AND to hide the fact that the underlying display screen takes a bit to get
// everything right. lower this and you will get some timer flashes. we can fix
// that in the future by straightening out our app state, but for now it is
// what it is.
const ANIMATION_TIMING_MS = 500;
const HEADER_HEIGHT = 3.5;
const HEADER_PADDING = 1.25;

type CardHoverState = 'focus' | 'relax' | 'sleep' | 'none';

interface WrapperProps {
  selectedState: string;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  transition: opacity ${ANIMATION_TIMING_MS}ms;
  // turns the opacity down during transition when a mental state is selected
  opacity: ${({ selectedState }) => (selectedState !== '' ? 0 : 1)};
`;

const Header = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT + HEADER_PADDING * 2}rem;
  display: flex;
  padding: ${HEADER_PADDING}rem;
`;

const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const UserLogoContainer = styled(IconContainer)`
  justify-content: flex-end;
`;

const Body = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.minWidth.md} {
    flex-direction: row;
    padding-bottom: ${HEADER_HEIGHT + HEADER_PADDING * 2}rem;
  }
`;

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const resetTimer = useResetTimer({ shouldRestartRunningTimers: true });
  const turnOnPomodoroTimer = useTurnOnPomodoroTimer();
  const user = useUser();
  const [selectedState, setSelectedState] = useState<string>('');
  const [hoverState, setHoverState] = useState<CardHoverState | string>('none');

  const sessionPlayStatus = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionPlayStatus,
  );
  const sessionMentalStateId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionMentalStateId,
  );
  const lastPlayedActivities = useSelector(
    (state: RootReducerType) => state.user.lastPlayedActivities,
  );
  const lastPlayedDynamicActivities = useSelector(
    (state: RootReducerType) => state.user.lastPlayedDynamicActivities,
  );

  const isUsingNewTracks = useFeatureFlag(FeatureFlags.NewMusicLibrary);
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();

  const { data: dynamicMentalStates } = useDynamicMentalStates(isUsingDynamicActivities);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    // TODO: move to redux or come up with a better solution.
    const onboardingMentalStateId = sessionStorage.getItem(ONBOARDING_MENTAL_STATE_ID_KEY);

    if (onboardingMentalStateId) {
      if (
        onboardingMentalStateId === 'focus' ||
        onboardingMentalStateId === 'relax' ||
        onboardingMentalStateId === 'sleep'
      ) {
        navigateToSessionPlayer(onboardingMentalStateId);
      }

      sessionStorage.removeItem(ONBOARDING_MENTAL_STATE_ID_KEY);
    }
  }, []);

  useEffect(() => {
    dispatch(homeScreenLogEvent('home_screen_view'));
  }, [dispatch]);

  const navigateToSessionPlayer = useCallback(
    (mentalState: SessionType) => {
      const selectedState = mentalStates.byType[mentalState];

      if (selectedState._id !== sessionMentalStateId || sessionPlayStatus !== 'PLAYING') {
        const lastActivity = lastPlayedActivities[selectedState.type];

        const defaultActivity = lastActivity
          ? lastActivity
          : selectedState.activities.find(activity => activities.byId[activity].default);

        const sessionActivityId = defaultActivity ? defaultActivity : selectedState.activities[1];

        resetTimer();

        if (
          user.mentalStatePreferences[mentalState].defaultDisplay.type ===
          UserPreferenceDisplayTypes.Pomodoro
        ) {
          turnOnPomodoroTimer();
        }

        dispatch(
          createSession({
            excludeNewTracks: !isUsingNewTracks,
            sessionType: mentalState,
            sessionMentalStateId: selectedState._id,
            sessionActivityId: sessionActivityId,
          }),
        );
      }

      setSelectedState(mentalState);

      setTimeout(() => navigate(`/${mentalState}`), ANIMATION_TIMING_MS);

      if (mentalState === 'focus') {
        dispatch(homeScreenLogEvent('home_screen_start_focus'));
      } else if (mentalState === 'relax') {
        dispatch(homeScreenLogEvent('home_screen_start_relax'));
      } else if (mentalState === 'sleep') {
        dispatch(homeScreenLogEvent('home_screen_start_sleep'));
      }
    },
    [dispatch, history, lastPlayedActivities, sessionMentalStateId],
  );

  const navigateToDynamicSessionPlayer = useCallback(
    (mentalState: DynamicMentalState) => () => {
      const activity =
        lastPlayedDynamicActivities[mentalState.id] || mentalState.defaultActivity.id;

      setSelectedState(mentalState.id); // just for visual fade out effect
      setTimeout(() => navigate(`/player/${activity}`), ANIMATION_TIMING_MS);
      dispatch(homeScreenLogEvent(`home_screen_start_${mentalState.id}`));
    },
    [navigate, dispatch, lastPlayedDynamicActivities, homeScreenLogEvent],
  );

  const handleOpenProfileWidget = () => {
    dispatch(logEventWithProperties({ event: 'profile_open_menu' }));
  };

  return (
    <Wrapper selectedState={selectedState}>
      <Header>
        <IconContainer />
        <IconContainer>
          <Icon size={67} src={BrandLogo} />
        </IconContainer>
        <UserLogoContainer>
          <ProfileWidget onOpen={handleOpenProfileWidget} />
        </UserLogoContainer>
      </Header>
      {isUsingDynamicActivities && dynamicMentalStates ? (
        <Body>
          {dynamicMentalStates?.map(mentalState => (
            <DynamicMentalStateCard
              key={mentalState.id}
              color={mentalState.primaryColor}
              data-testid={`${mentalState.id}Card`}
              imageUrl={mentalState.imageUrl}
              imageUrlSecondary={mentalState.imageUrlSecondary}
              isActive={hoverState === mentalState.id}
              mentalState={mentalState.displayValue}
              onClick={navigateToDynamicSessionPlayer(mentalState)}
              onMouseEnter={() => setHoverState(mentalState.id)}
              onMouseLeave={() => setHoverState('none')}
            />
          ))}
        </Body>
      ) : (
        <Body>
          <FocusCard
            data-testid="focusCard"
            isActive={hoverState === 'focus'}
            onClick={() => navigateToSessionPlayer('focus')}
            onMouseEnter={() => setHoverState('focus')}
            onMouseLeave={() => setHoverState('none')}
          />
          <RelaxCard
            data-testid="relaxCard"
            isActive={hoverState === 'relax'}
            onClick={() => navigateToSessionPlayer('relax')}
            onMouseEnter={() => setHoverState('relax')}
            onMouseLeave={() => setHoverState('none')}
          />
          <SleepCard
            data-testid="sleepCard"
            isActive={hoverState === 'sleep'}
            onClick={() => navigateToSessionPlayer('sleep')}
            onMouseEnter={() => setHoverState('sleep')}
            onMouseLeave={() => setHoverState('none')}
          />
        </Body>
      )}
    </Wrapper>
  );
};

export default Home;
