import React from 'react';
import { useIsEnabledDynamicActivities } from '../../hooks/useIsEnabledDynamicActivities';
import { SessionType } from '../../types';
import { SessionDynamic } from './SessionDynamic';
import { SessionLegacy } from './SessionLegacy';

export type SessionProps = {
  type?: SessionType;
};

export const Session: React.FC<SessionProps> = ({ type }) => {
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();

  return !isUsingDynamicActivities && type ? <SessionLegacy type={type} /> : <SessionDynamic />;
};
