import {
  UserPreferenceDisplayTypes,
  UserMentalStatePreferenceTypes,
  UserPreferencesType,
  userSliceActions,
  userActions,
} from '@User';
import { APIResponse } from '@Utils';
import { Action } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { put, select } from 'redux-saga/effects';
import { RootReducerType } from 'src/reducers';

import { RequestMethods } from '../../../api/client/types';
import { requestSaga, SuccessResponseType } from '../../../sagas/httpRequest';
import { MentalStates } from '../../../types';
import { Logger } from '../../../utils/logger';
import { mentalStates } from '../../../utils/mentalStates';
import * as userAction from '../actions';

interface SessionPreferences {
  defaultDisplay: {
    type: UserPreferenceDisplayTypes;
  };
  genres: string[];
  genreNames: string[];
  neuralEffectLevels: string[];
}

interface MentalStatePreferencesResponseType {
  focus: SessionPreferences;
  relax: SessionPreferences;
  sleep: SessionPreferences;
}

export function* getSessionPreferencesSaga() {
  try {
    const user: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
    const { result } = (yield requestSaga(
      RequestMethods.GET,
      `/users/${user.info?.id}/session/preferences`,
    )) as APIResponse<MentalStatePreferencesResponseType>;

    yield put(userSliceActions.setMentalStatePreferences(result));
  } catch (error) {
    Logger.error(error);
  }
}

export function* getUserPreferencesSaga() {
  try {
    const user: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
    const { result } = (yield requestSaga(
      RequestMethods.GET,
      `/users/${user.info?.id}/preferences/web`,
      {},
      3,
    )) as APIResponse<UserPreferencesType>;

    yield put(userSliceActions.setUserPreferences(result));
  } catch (error: unknown) {
    yield put(
      userSliceActions.setUserPreferences({
        streakType: null,
      }),
    );

    const isNormal404 = (error as { message?: string })?.message === 'User preferences not found.';
    if (!isNormal404) {
      Logger.error(error);
    }
  }
}

export function* addSessionPreferencesSaga(action: Action) {
  if (userAction.addSessionPreference.match(action)) {
    try {
      const user: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
      yield requestSaga(
        RequestMethods.POST,
        `/users/${user.info?.id}/session/preferences`,
        action.payload,
      );
    } catch (error) {
      Logger.error(error);
    }
  }
}

export function* removeSessionPreferencesSaga(action: Action) {
  if (userAction.removeSessionPreference.match(action)) {
    try {
      const user: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
      yield requestSaga(
        RequestMethods.DELETE,
        `/users/${user.info?.id}/session/preferences`,
        action.payload,
      );
    } catch (error) {
      Logger.error(error);
    }
  }
}

export function* toggleSessionPreferencesSaga(action: Action) {
  if (userAction.toggleSessionPreference.match(action)) {
    const sessionMentalStateId: RootReducerType['sessionManager']['sessionMentalStateId'] =
      yield select((state: RootReducerType) => state.sessionManager.sessionMentalStateId);

    const sessionDynamicMentalStateId: string = yield select(
      (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.mentalState.id,
    );

    const { mentalStatePreferences }: RootReducerType['userV2'] = yield select(
      (state: RootReducerType) => state.userV2,
    );

    if (sessionMentalStateId || sessionDynamicMentalStateId) {
      const mentalStateType = (sessionDynamicMentalStateId ||
        mentalStates.byId[sessionMentalStateId!]?.type) as MentalStates;

      if (mentalStateType) {
        const preference = mentalStatePreferences[mentalStateType];

        if (action.payload.genreNames) {
          const oldGenresToRemove = preference.genreNames.filter(
            item => !action.payload.genreNames?.includes(item),
          );

          yield put(
            userSliceActions.setMentalStatePreference({
              mentalState: mentalStateType,
              preferenceType: UserMentalStatePreferenceTypes.GenreNames,
              preferences: action.payload.genreNames,
            }),
          );

          for (const oldGenreName of oldGenresToRemove) {
            yield put(
              userActions.removeSessionPreference({
                genreName: oldGenreName,
                mentalState: mentalStateType,
              }),
            );
          }

          for (const newGenreName of action.payload.genreNames) {
            yield put(
              userActions.addSessionPreference({
                genreName: newGenreName,
                mentalState: mentalStateType,
              }),
            );
          }
        } else if (action.payload.neuralEffectLevels) {
          const oldNELsToRemove = preference.neuralEffectLevels.filter(
            item => !action.payload.neuralEffectLevels?.includes(item),
          );

          yield put(
            userSliceActions.setMentalStatePreference({
              mentalState: mentalStateType,
              preferenceType: UserMentalStatePreferenceTypes.NeuralEffectLevels,
              preferences: action.payload.neuralEffectLevels,
            }),
          );

          for (const oldNEL of oldNELsToRemove) {
            yield put(
              userActions.removeSessionPreference({
                neuralEffectLevel: oldNEL,
                mentalState: mentalStateType,
              }),
            );
          }

          for (const newNELs of action.payload.neuralEffectLevels) {
            yield put(
              userActions.addSessionPreference({
                neuralEffectLevel: newNELs,
                mentalState: mentalStateType,
              }),
            );
          }
        }
      }
    }
  }
}

export function* setDefaultDisplayTypeSaga(action: Action) {
  if (!userAction.setDefaultDisplayType.match(action)) return;

  const { sessionMentalStateId }: RootReducerType['sessionManager'] = yield select(
    (state: RootReducerType) => state.sessionManager,
  );
  const mentalState = mentalStates.byId[sessionMentalStateId || ''];
  if (!mentalState) return;

  yield put(
    userSliceActions.setMentalStateDefaultDisplayType({
      mentalState: mentalState.type,
      defaultDisplayType: action.payload.type,
    }),
  );

  try {
    const user: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);
    const { result } = (yield requestSaga(
      RequestMethods.POST,
      `/users/${user.info?.id}/session/preferences`,
      {
        mentalState: mentalState.type,
        defaultDisplay: {
          type: action.payload.type,
        },
      },
    )) as APIResponse<MentalStatePreferencesResponseType>;

    yield put(userSliceActions.setMentalStatePreferences(result));
  } catch (error) {
    Logger.error(error);
  }
}

export function* updateUserPreferencesSaga(action: Action) {
  if (userActions.updateUserPreferences.match(action)) {
    try {
      const { info }: RootReducerType['user'] = yield select(
        (state: RootReducerType) => state.user,
      );
      const { preferences }: RootReducerType['userV2'] = yield select(
        (state: RootReducerType) => state.userV2,
      );
      const data: SuccessResponseType<{ result: UserPreferencesType }> = yield requestSaga(
        RequestMethods.PUT,
        `/users/${info?.id}/preferences/web`,
        {
          ...preferences,
          ...action.payload,
        },
        3,
      );

      // filter out all null preferences as we don't use them still
      const updatedPreferences = Object.fromEntries(
        Object.entries(data.result).filter(([_, v]) => v != null),
      ) as UserPreferencesType;

      yield put(userSliceActions.setUserPreferences(updatedPreferences));
    } catch (err) {
      Logger.error(err);
    }
  }
}
