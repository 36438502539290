import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    overflow: hidden auto;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    height: calc(100vh - 4rem - 2rem); // deduct Paper padding and parent padding
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: background 0.3s ease-in-out;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(33, 29, 63, 1) 57%);
`;

export const HeadingContainer = styled.div`
  margin-bottom: 2.75rem;
  letter-spacing: 0.2rem;
  text-align: center;
  text-transform: uppercase;
`;

export const Panel = styled.div<{ hasBorders?: boolean }>`
  ${props => (props.hasBorders ? 'border-left: solid 2px black;' : null)}
  ${props => (props.hasBorders ? 'border-right: solid 2px black;' : null)}
  padding: 4rem;
  overflow: hidden auto;
  width: 50%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    border-left: none;
    border-right: none;
    overflow: unset;
    padding-bottom: 0;
    width: 100%;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
`;
