import {
  Button,
  ButtonVariants,
  FontColors,
  FormInput,
  Icon,
  MenuItem,
  MenuItemIcons,
  TabBar,
  TextBold,
  TextSemiBold,
} from '@Cortex';
import React, { useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

import ChevronIcon from '../../../../assets/images/chevron_up_icon.svg';
import { SessionPlayType } from '../../../../types';
import { PomodoroIntervals } from '../../../Timer/constants';
import { PomodoroSettings } from '../PomodoroSettings';
import * as S from './TimeMode.styles';

type Props = {
  isNormalTimer: boolean;
  isPomodoroTimer: boolean;
  isCustomPomodoroIntervalsActive: boolean;
  isCustomTimerSettingsOpen: boolean;
  isPomodoroSettingsOpen: boolean;
  customTimedSessionValue: string;
  customPomodoroIntervals: PomodoroIntervals;
  sessionTimeMode: SessionPlayType;
  onTogglePomodoroSettings: () => void;
  onToggleIsCustomTimerSettings: () => void;
  onStartInfiniteSession: () => void;
  onStartPomodoroSession: () => void;
  onStartQuotesSession: () => void;
  onStartTimedSession: (value: string) => void;
  setCustomPomodoroIntervals: (data: { focusTime?: string; breakTime?: string }) => void;
  setCustomTimedSessionValue: (time: string) => void;
};

const TIME_UNITS = ['mins', 'hrs'];

export function TimeModeDisplay(props: Props) {
  const {
    isNormalTimer,
    isPomodoroTimer,
    isCustomPomodoroIntervalsActive,
    isCustomTimerSettingsOpen,
    isPomodoroSettingsOpen,
    onTogglePomodoroSettings,
    onToggleIsCustomTimerSettings,
  } = props;

  const dropDownRef = useRef<HTMLDivElement>(null);
  const [timeUnit, setTimeUnit] = useState<'mins' | 'hrs'>('mins');

  return (
    <S.Container>
      <S.HeadingContainer>
        <TextBold size={32}>{`Timer Settings`}</TextBold>
      </S.HeadingContainer>

      <S.OptionHeader
        data-testid="infiniteTimer"
        isSelected={isNormalTimer}
        onClick={props.onStartInfiniteSession}
      >
        <MenuItem icon={MenuItemIcons.Infinite}>
          {isNormalTimer ? 'Infinite (Click To Reset)' : 'Infinite'}
        </MenuItem>
      </S.OptionHeader>
      {isCustomPomodoroIntervalsActive ? (
        <S.OptionHeader
          data-testid="intervalTimer"
          isSelected={isPomodoroTimer}
          onClick={onTogglePomodoroSettings}
        >
          <MenuItem icon={MenuItemIcons.Watch}>Interval Timer</MenuItem>
          <S.IconContainer isDown={!isPomodoroSettingsOpen}>
            <Icon size={20} src={ChevronIcon} />
          </S.IconContainer>
        </S.OptionHeader>
      ) : (
        <S.OptionHeader
          data-testid="pomodoroTimer"
          isSelected={isPomodoroTimer}
          onClick={props.onStartPomodoroSession}
        >
          <MenuItem icon={MenuItemIcons.Watch}>
            {isPomodoroTimer ? 'Pomodoro (Click To Reset)' : 'Pomodoro'}
          </MenuItem>
        </S.OptionHeader>
      )}
      {isCustomPomodoroIntervalsActive && (
        <PomodoroSettings
          intervals={props.customPomodoroIntervals}
          isActive={isPomodoroSettingsOpen}
          onSetIntervals={props.setCustomPomodoroIntervals}
          onStart={props.onStartPomodoroSession}
        />
      )}
      <S.OptionHeader
        data-testid="customTimer"
        isSelected={props.sessionTimeMode === 'TIMER'}
        onClick={onToggleIsCustomTimerSettings}
      >
        <MenuItem icon={MenuItemIcons.Hourglass}>Custom Timer</MenuItem>
        {props.sessionTimeMode !== 'TIMER' ? (
          <S.IconContainer isDown={!isCustomTimerSettingsOpen}>
            <Icon size={20} src={ChevronIcon} />
          </S.IconContainer>
        ) : null}
      </S.OptionHeader>

      <Transition
        in={isCustomTimerSettingsOpen}
        mountOnEnter
        nodeRef={dropDownRef}
        timeout={0}
        unmountOnExit
      >
        {animationState => (
          <S.DropdownContainer ref={dropDownRef} animationState={animationState}>
            <S.TimerInfoContainer>
              <TextSemiBold color={FontColors.WhiteSecondary} size="0.825rem">
                Choose one of our preset session lengths below, or enter your own and press 'Start
                Custom Timer'.
              </TextSemiBold>
            </S.TimerInfoContainer>
            <S.TimerInfoPresetsContainer>
              <S.ButtonPresetWrapper>
                <Button
                  backgroundColor="#191736"
                  data-testid="customTimer-30min"
                  isFullWidth={true}
                  onClick={() => props.onStartTimedSession('30')}
                >
                  30 min
                </Button>
              </S.ButtonPresetWrapper>
              <S.ButtonPresetWrapper>
                <Button
                  backgroundColor="#191736"
                  data-testid="customTimer-60min"
                  isFullWidth={true}
                  onClick={() => props.onStartTimedSession('60')}
                >
                  1 hr
                </Button>
              </S.ButtonPresetWrapper>
              <S.ButtonPresetWrapper>
                <Button
                  backgroundColor="#191736"
                  data-testid="customTimer-120min"
                  isFullWidth={true}
                  onClick={() => props.onStartTimedSession('120')}
                >
                  2 hrs
                </Button>
              </S.ButtonPresetWrapper>
            </S.TimerInfoPresetsContainer>
            <form
              onSubmit={evt => {
                evt.preventDefault();
                let sesssionLength = props.customTimedSessionValue;
                if (timeUnit === 'hrs') {
                  if (props.customTimedSessionValue.includes('.')) {
                    sesssionLength = `${parseFloat(props.customTimedSessionValue) * 60}`;
                  } else {
                    sesssionLength = `${parseInt(props.customTimedSessionValue) * 60}`;
                  }
                }

                props.onStartTimedSession(sesssionLength);
              }}
            >
              <FormInput
                data-testid="timerLengthInput"
                label="Custom Timer:"
                rightSideContent={() => (
                  <div style={{ width: '6.25rem' }}>
                    <TabBar
                      activeTabIndex={TIME_UNITS.findIndex(item => item === timeUnit)}
                      items={['mins', 'hrs']}
                      onSelect={unit => setTimeUnit(unit)}
                    />
                  </div>
                )}
                value={props.customTimedSessionValue}
                onChange={e => props.setCustomTimedSessionValue(e.target.value)}
              />

              <Button
                data-testid="startCustomTimer"
                disabled={!props.customTimedSessionValue}
                isFullWidth={true}
                type="submit"
                variant={ButtonVariants.Primary}
              >
                Start Custom Timer
              </Button>
            </form>
          </S.DropdownContainer>
        )}
      </Transition>

      <S.OptionHeader
        data-testid="quotesTimer"
        isSelected={props.sessionTimeMode === 'QUOTES'}
        onClick={props.onStartQuotesSession}
      >
        <MenuItem icon={MenuItemIcons.SpeechBubble}>Quotes</MenuItem>
      </S.OptionHeader>
    </S.Container>
  );
}
