import { Button, ButtonVariants } from '@Cortex';
import { NOOP } from '@Globals';

import * as S from './SubscriptionInformation.styles';

export type Props = {
  callToActionText?: string | null;
  currentPeriodEndDate: string | null;
  expirationDate?: Date | null;
  isCancelled?: boolean;
  onClickCallToAction?: () => void;
  renewalPrice: string | null;
  subscriptionSource: string;
};

export function SubscriptionInformation({
  callToActionText = null,
  currentPeriodEndDate,
  expirationDate,
  isCancelled = false,
  onClickCallToAction = NOOP,
  renewalPrice,
  subscriptionSource,
}: Props) {
  const isGiftSubscription = subscriptionSource === 'GiftUp';

  if (currentPeriodEndDate && isCancelled) {
    return (
      <>
        <S.PaymentInfo>
          Your membership has been cancelled. You can continue to use brain.fm until{' '}
          <S.PaymentInfoBold>{currentPeriodEndDate}</S.PaymentInfoBold>.
        </S.PaymentInfo>
        {callToActionText ? (
          <Button
            data-testid="subscriptionButton"
            variant={ButtonVariants.Primary}
            onClick={onClickCallToAction}
          >
            {callToActionText}
          </Button>
        ) : null}
      </>
    );
  }

  if (isGiftSubscription && expirationDate) {
    return (
      <S.PaymentInfo>
        Your gift subscription is valid until
        <S.PaymentInfoBold>
          {` ${expirationDate.toLocaleString('en-us', {
            month: 'long',
          })} ${expirationDate.getDate()}, ${expirationDate.getFullYear()}`}
        </S.PaymentInfoBold>
        .
      </S.PaymentInfo>
    );
  }

  if (renewalPrice && currentPeriodEndDate) {
    return (
      <>
        <S.PaymentInfo>
          Your next payment is <S.PaymentInfoBold>{renewalPrice}</S.PaymentInfoBold> which will be
          charged automatically on <S.PaymentInfoBold>{currentPeriodEndDate}</S.PaymentInfoBold>.
        </S.PaymentInfo>
        {callToActionText ? (
          <Button
            data-testid="subscriptionButton"
            variant={ButtonVariants.Primary}
            onClick={onClickCallToAction}
          >
            {callToActionText}
          </Button>
        ) : null}
      </>
    );
  }

  return (
    <>
      <S.PaymentInfo>
        Your subscription is managed by <S.PaymentInfoBold>{subscriptionSource}</S.PaymentInfoBold>.
      </S.PaymentInfo>
      {callToActionText ? (
        <Button
          data-testid="subscriptionButton"
          variant={ButtonVariants.Primary}
          onClick={onClickCallToAction}
        >
          {callToActionText}
        </Button>
      ) : null}
    </>
  );
}
