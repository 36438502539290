import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Accordion = styled.div`
  width: 100%;
`;

export const Anchor = styled.div<{ isSelected: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const Chevron = styled.div<{ isDown?: boolean }>`
  margin-right: 1rem;
  transform: rotate(${({ isDown = false }) => (isDown ? '180deg' : '0deg')});
  transition: transform 0.3s ease-in-out;
`;

export const Dropdown = styled.div<{ animationState: TransitionStatus }>`
  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0.2;
      case 'entered':
        return 1;
      case 'exiting':
        return 0.2;
      case 'exited':
        return 0;
    }
  }};
  transform: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return `translateY(-5px)`;
      case 'entered':
        return `translateY(0)`;
      case 'exiting':
        return `translateY(0)`;
      case 'exited':
        return `translateY(-5px)`;
    }
  }};
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
`;
