import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Image = styled.img<{ isClickable?: boolean }>`
  border-radius: 20px;
  height: 88px;
  margin-right: 24px;
  width: 88px;
  transition: transform 0.15s ease-in-out;
  transform-origin: center;
  cursor: ${props => (props.isClickable ? 'pointer' : 'initial')};

  &:hover {
    transform: ${props => (props.isClickable ? 'scale(1.02)' : 'scale(1)')};
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    border-radius: 1rem;
    height: 64px;
    width: 64px;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    border-radius: 0.75rem;
    margin-right: 1rem;
    height: 40px;
    width: 40px;
  }
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Subtitle = styled.div`
  color: white;
  font-family: TTNormsPro-Regular;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.2em;
  margin-bottom: 0.5rem;
  text-transform: uppercase;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 0.5rem;
    margin-bottom: 0.25rem;
  }
`;

export const TrackLabel = styled.div`
  font-size: 8px;
  letter-spacing: 1px;
  left: 4px;
  color: ${({ theme }) => theme.colors.navy};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  padding: 1px 4px;
  top: -10px;
  position: relative;
  text-transform: uppercase;
`;

export const Title = styled.div<{ isClickable?: boolean }>`
  align-items: center;
  color: white;
  cursor: ${props => (props.isClickable ? 'pointer' : 'initial')};
  display: flex;
  font-family: TTNormsPro-Bold;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-transform: lowercase;
  transform-origin: center;
  transition: transform 0.15s ease-in-out;
  width: fit-content;

  &:hover {
    transform: ${props => (props.isClickable ? 'scale(1.009)' : 'scale(1)')};
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }
`;

export const TrackActions = styled.div`
  display: flex;
`;

export const TrackActionButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  outline: none;
  width: fit-content;
`;

export const NeuralEffectLevelIcon = styled.div`
  margin-right: 0.25rem;
`;
