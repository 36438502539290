import { Serving, Track } from '@Model';

import { mentalStates } from '../../../utils/mentalStates';
import { isServing } from './isServing';

export function getTrackMentalStateId(trackOrServing?: Track | Serving | null): string {
  if (isServing(trackOrServing)) {
    switch (trackOrServing.track.mentalState.id) {
      case 1:
        return mentalStates.byType.focus._id;
      case 2:
      case 4: // meditate goes into relax
        return mentalStates.byType.relax._id;
      case 3:
        return mentalStates.byType.sleep._id;
      default:
        return mentalStates.byType.focus._id;
    }
  }

  return trackOrServing?.mentalStateId || '';
}
