import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  SessionManagerStateType,
  SessionPlayStatus,
  InitSessionPayload,
  FetchMoreTracksState,
  ReplaceTracksPayload,
  InitSessionDynamicPayload,
} from '../types';
import { SESSION_COUNT_TIME } from '../utils/player';

export const initialState: SessionManagerStateType = {
  sessionType: null,
  sessionPlayType: 'NORMAL',
  sessionPlayStatus: 'EMPTY',
  sessionPassed5Min: false,

  isDeepLinkReferrer: false,

  timerLength: 0,
  timerPlayTime: 0,
  timerFinished: false,

  intervalFocus: 0,
  intervalRelax: 0,

  sessionStartTime: null,
  sessionPlayTime: 0,

  currentTrackTimeStamp: 0,

  id: '',
  sessionMentalStateId: null,
  sessionActivityId: null,
  sessionPowerLevel: null,
  fetchTrackStatus: 'idle',
  pauseArray: [],

  sessionDynamicActivity: null,
};

const sessionManagerSlice = createSlice({
  name: 'sessionManager',
  initialState,
  reducers: {
    /**
     * Updates any top level property
     * @param state
     * @param action
     */
    update(state, action: PayloadAction<Partial<SessionManagerStateType>>) {
      return {
        ...state,
        ...action.payload,
      };
    },

    /**
     * Reset the sessionManager to initiate a new session. Note, this does not start the session,
     * it just loads up the tracks and basic session info that's about to start. The audioPlayer
     * will call startSession when it's ready to play.
     * @param {SessionManagerState} state
     * @param {PayloadAction<InitSessionPayload>} action Contains basic info about the type of session
     */
    initSession(state, action: PayloadAction<InitSessionPayload>) {
      state.sessionPlayType = action.payload.sessionPlayType || 'NORMAL';
      state.sessionPlayStatus = 'EMPTY';
      state.sessionPassed5Min = false;

      state.timerLength = 0;
      state.timerPlayTime = 0;
      state.timerFinished = false;

      state.intervalFocus = 0;
      state.intervalRelax = 0;

      state.sessionStartTime = null;
      state.sessionPlayTime = 0;
      state.currentTrackTimeStamp = 0;
      state.fetchTrackStatus = 'idle';

      // state.id = action.payload.id;
      state.sessionType = action.payload.sessionType;
      state.sessionMentalStateId = action.payload.sessionMentalStateId;
      state.sessionActivityId = action.payload.sessionActivityId;
      state.sessionDynamicActivity = null;
      state.pauseArray = [];
      return state;
    },

    /**
     * Reset the sessionManager to initiate a new session. Note, this does not start the session,
     * it just loads up the tracks and basic session info that's about to start. The audioPlayer
     * will call startSession when it's ready to play.
     * @param {SessionManagerState} state
     * @param {PayloadAction<InitSessionPayload>} action Contains basic info about the type of session
     */
    initDynamicSession(state, action: PayloadAction<InitSessionDynamicPayload>) {
      state.sessionPlayType = action.payload.sessionPlayType || 'NORMAL';
      state.sessionPlayStatus = 'EMPTY';
      state.sessionPassed5Min = false;

      state.timerLength = 0;
      state.timerPlayTime = 0;
      state.timerFinished = false;

      state.intervalFocus = 0;
      state.intervalRelax = 0;

      state.sessionStartTime = null;
      state.sessionPlayTime = 0;
      state.currentTrackTimeStamp = 0;
      state.fetchTrackStatus = 'idle';

      // state.id = action.payload.id;
      state.sessionMentalStateId = null;
      state.sessionActivityId = null;
      state.sessionDynamicActivity = action.payload.sessionActivity;
      state.pauseArray = [];
      return state;
    },

    /**
     * This is called when a user changes their activity in the middle of a session. For example
     * when they switch from a Deep Work session to a Learning session. It will update session
     * tracks and activityId but will not stop the current session.
     * @param state
     * @param action
     */
    changeSessionActivity(
      state: SessionManagerStateType,
      action: PayloadAction<Pick<SessionManagerStateType, 'sessionActivityId'>>,
    ) {
      if (state.sessionPlayType === 'TIMER') {
        state.timerPlayTime = 0 + state.currentTrackTimeStamp + (state.timerPlayTime ?? 0);
      }

      state.sessionPlayStatus = 'UPDATING';
      state.sessionActivityId = action.payload.sessionActivityId;
      state.sessionDynamicActivity = null;
      state.sessionPlayTime = (state.sessionPlayTime ?? 0) + state.currentTrackTimeStamp;
      state.currentTrackTimeStamp = 0;
      return state;
    },

    /**
     * This is called when a user changes their activity in the middle of a session. For example
     * when they switch from a Deep Work session to a Learning session. It will update session
     * tracks and activityId but will not stop the current session.
     * @param state
     * @param action
     */
    changeSessionDynamicActivity(
      state: SessionManagerStateType,
      action: PayloadAction<Pick<SessionManagerStateType, 'sessionDynamicActivity'>>,
    ) {
      if (state.sessionPlayType === 'TIMER') {
        state.timerPlayTime = 0 + state.currentTrackTimeStamp + (state.timerPlayTime ?? 0);
      }

      state.sessionPlayStatus = 'UPDATING';
      state.sessionDynamicActivity = action.payload.sessionDynamicActivity;
      state.sessionActivityId = null;
      state.sessionPlayTime = (state.sessionPlayTime ?? 0) + state.currentTrackTimeStamp;
      state.currentTrackTimeStamp = 0;
      return state;
    },

    /**
     * Change the status of the sessionManager. For example, when pausing the player.
     * @param {SessionManagerState} state
     * @param {PayloadAction<PlayStatus} action The status you want to update to
     */
    setSessionManagerPlayStatus(state, action: PayloadAction<SessionPlayStatus>) {
      state.sessionPlayStatus = action.payload;
      return state;
    },

    /**
     * Change the value of the deepLinkReferrer after initial track load
     */
    setDeepLinkReferrer(state, action: PayloadAction<boolean>) {
      state.isDeepLinkReferrer = action.payload;
      return state;
    },

    /**
     * Append new tracks for the sessionManager to play
     * @param {SessionManagerState} state
     * @param {PayloadAction<Track[]>} action An array of tracks to add onto the tracks queue.
     */
    appendTracks(state) {
      state.fetchTrackStatus = 'idle';
      return state;
    },

    /**
     * Replaces tracks for the sessionManager to play
     */
    replaceTracks(state, action: PayloadAction<ReplaceTracksPayload>) {
      if (state.sessionPlayType === 'TIMER') {
        state.timerPlayTime = 0 + state.currentTrackTimeStamp + (state.timerPlayTime ?? 0);
      }
      state.isDeepLinkReferrer = Boolean(action?.payload.isDeepLinkReferrer);
      state.sessionPlayTime = (state.sessionPlayTime ?? 0) + state.currentTrackTimeStamp;
      state.currentTrackTimeStamp = 0;
      state.sessionPlayStatus = 'UPDATING';
      state.fetchTrackStatus = 'idle';
      return state;
    },

    /**
     * Sets loading status while fetching for more tracks
     * @param state
     */
    setTrackStatus(state, action: PayloadAction<FetchMoreTracksState>) {
      state.fetchTrackStatus = action.payload;
      return state;
    },

    /**
     * Used when skipping or finishing a track. Removes the current track from tracks[] and
     * adds it to the sessionPlayHistory
     * @param {SessionManagerState} state
     */
    nextTrack(state) {
      const totalTime = (state.sessionPlayTime || 0) + state.currentTrackTimeStamp;

      if (state.sessionPlayType === 'TIMER') {
        state.timerPlayTime = 0 + state.currentTrackTimeStamp + (state.timerPlayTime || 0);
      }

      state.sessionPlayTime = totalTime;
      state.currentTrackTimeStamp = 0;

      if (totalTime >= SESSION_COUNT_TIME && !state.sessionPassed5Min) {
        state.sessionPassed5Min = true;
      }

      return state;
    },

    /**
     * Resume playing after a pause event. Adds a timestamp to the last pausedObject
     * to keep track of when playing resumed.
     * @param {SessionManagerState} state
     * @param {PayloadAction<number>} action unixTimestamp of when resume started
     */
    resume(state, action: PayloadAction<number>) {
      state.sessionPlayStatus = 'PLAYING';

      if (state.pauseArray.length) {
        state.pauseArray[state.pauseArray.length - 1].resumedAt = action.payload;
      }
      return state;
    },

    /**
     * Pause playing. Appends a new pauseObject to the pauseArray with the time the pause
     * event occurred.
     * @param {SessionManagerState} state
     * @param {PayloadAction<number>} action unixTimestamp of when the pause event occurred
     */
    pause(state, action: PayloadAction<number>) {
      state.sessionPlayStatus = 'PAUSED';
      state.pauseArray.push({ pausedAt: action.payload, resumedAt: null });
      return state;
    },

    /**
     * This is called after initSession has been called and the tracks have been loaded and
     * the player is ready to play music. It updates the sessionManager to playing status and
     * sets the sessionStartTime.
     * @param {SessionManagerState} state
     * @param {PayloadAction<number>} action unixTimestamp of when the session started playing music
     */
    startSession(state, action: PayloadAction<number>) {
      state.sessionPlayStatus = 'PLAYING';
      state.sessionStartTime = action.payload;
      return state;
    },

    /**
     * Resets properties that are used to initialize a new session.
     */
    endSession() {
      return initialState;
    },

    /**
     * set's the current track time stamp in session manager used for refresh
     */
    setTrackTimestamp(state, action: PayloadAction<number>) {
      state.currentTrackTimeStamp = action.payload;

      return state;
    },

    /**
     * Sets true or false if session passed the 5 min countdown;
     * @param state
     * @param action
     */
    setPassedFiveMins: (state, action: PayloadAction<boolean>) => {
      state.sessionPassed5Min = action.payload;

      return state;
    },

    /**
     * Sets true or false if timer is up;
     * @param state
     * @param action
     */
    setTimerFinished: (state, action: PayloadAction<boolean>) => {
      state.timerFinished = action.payload;

      return state;
    },

    /**
     * Increments track offset
     * @param state
     */
    incrementTrackOffset(state) {
      // state.trackOffset += 1;
      return state;
    },

    /**
     * Sets Session Type
     * @param state
     */
    setSessionType(state, action) {
      return { ...state, sessionPlayType: action.payload };
    },
    /**
     * Sets Timer Length
     * @param state
     */
    setTimerLength(state, action: PayloadAction<number>) {
      return {
        ...state,
        timerPlayTime: 0 - (state.currentTrackTimeStamp || 0),
        timerLength: action.payload,
        timerFinished: false,
      };
    },

    /**
     * Extend Timer Length
     * @param state
     */
    extendTimerLength(state, action: PayloadAction<number>) {
      return {
        ...state,
        timerPlayTime: 0 - (state.currentTrackTimeStamp || 0),
        timerLength: action.payload,
        timerFinished: false,
        sessionPlayStatus: 'RESUMING',
      };
    },

    extendTimerInfinity(state) {
      return {
        ...state,
        sessionPlayType: 'NORMAL',
        timerFinished: false,
        sessionPlayStatus: 'RESUMING',
      };
    },

    /**
     * Resets the reducer
     */
    clearState() {
      return initialState;
    },
  },
});

const sessionManagerPersistConfig = {
  key: 'sessionManager',
  storage: storage,
  whitelist: ['sessionPlayType', 'sessionMentalStateId'],
};

export const sessionManagerSliceActions = sessionManagerSlice.actions;
export default persistReducer(sessionManagerPersistConfig, sessionManagerSlice.reducer);
