import { TextBold, Switch } from '@Cortex';
import { HTMLAttributes } from 'react';

import * as S from './GenreButton.styles';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  title: string;
  isActive?: boolean;
  onClick: () => void;
}

export const GenreButton = ({ title, onClick, isActive = false }: Props) => {
  return (
    <S.Container data-testid={`genre_${title.toLowerCase()}`} isActive={isActive} onClick={onClick}>
      <S.TextContainer>
        <TextBold size="0.875rem">{title}</TextBold>
      </S.TextContainer>
      <Switch isActive={isActive} />
    </S.Container>
  );
};
