import { CALL_TO_ACTION_WIDGET_MOBILE_BREAKPOINT } from '@Memberships';
import styled from 'styled-components';

import { HEADER_HEIGHT, HEADER_PADDING } from './constants';

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${HEADER_HEIGHT + HEADER_PADDING * 2}rem;
  display: flex;
  padding: ${HEADER_PADDING}rem;
`;

export const LeftContainer = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const RightContainer = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const LogoContainer = styled.div`
  display: block;
  cursor: pointer;
  transition: transform 0.25s ease-in-out;
  margin-right: 1.5rem;

  &:hover {
    transform: scale(1.05);
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    display: none;
  }
`;

export const MobileLogoContainer = styled(LogoContainer)`
  display: none;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    display: block;
  }
`;

export const CallToActionWidgetContainer = styled.div`
  margin-right: 2rem;

  /* this constant ensures that the other instance of this component pops in and out at the
     same breakpoint as this one! see <Session /> -danp */
  ${CALL_TO_ACTION_WIDGET_MOBILE_BREAKPOINT} {
    display: none;
  }
`;

export const HeaderIconLabel = styled.span`
  position: absolute;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  bottom: -14px;
  opacity: 0;
  transform: scale(0.4);
  transition: all 0.3s ease-in-out;
`;

export const HeaderIconContainer = styled.div`
  position: relative;
  cursor: pointer;
  padding: 12px;
  border-radius: 100%;
  border: 1px solid transparent;
  transition:
    border 0.2s ease-in-out,
    background 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    border: 1px solid #565487;
    background: #191736;
  }

  &:hover > ${HeaderIconLabel} {
    bottom: -20px;
    opacity: 1;
    transform: scale(1);
  }
`;
