import styled from 'styled-components';

export const Container = styled.div``;

export const QuestionContainer = styled.div`
  color: #fff;
  margin-bottom: 4rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
`;

export const Question = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1.5rem;
  color: #ffffff;
`;

export const Answer = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  transition:
    max-height 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  height: auto;
  max-height: ${({ isOpen }) => (isOpen ? '20rem' : 0)};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  color: #d4d2ea;
`;

export const Separator = styled.hr`
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 1.5rem 0;
`;

export const IconContainer = styled.div<{ isUp?: boolean }>`
  transition: transform 0.3s ease-in-out;
  transform: rotate(${({ isUp = false }) => (isUp ? '0deg' : '180deg')});
`;
