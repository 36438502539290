import { NOOP } from '@Globals';
import { useMenuItems } from '@User';
import { useState } from 'react';

import { ProfileWidgetDisplay } from './ProfileWidget.display';

interface Props {
  onOpen?: () => void;
}

export const ProfileWidget = ({ onOpen = NOOP }: Props) => {
  const [isActive, setActive] = useState(false);
  const menuItems = useMenuItems();

  const handleProfileClick = () => {
    setActive(!isActive);
    onOpen();
  };

  return (
    <ProfileWidgetDisplay
      isActive={isActive}
      menuItems={menuItems}
      onClick={handleProfileClick}
      onOutsideClick={() => setActive(false)}
    />
  );
};
