import { Button, Text, ButtonVariants, ButtonVariantSizes } from '@Cortex';
import { Genres, NeuralEffectLevelSelection } from '@Music';
import { useUser } from '@User';
import { useFeatureFlag, useOutsideClickRef, FeatureFlags, showToast } from '@Utils';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  changeSessionDynamicActivity,
  dynamicReplaceTracksWithUpdatedPreferences,
} from '../../../../actions/sessionManager';
import { RootReducerType } from '../../../../reducers';
import { MentalStates } from '../../../../types';
import { Analytics } from '../../../../utils/analytics';
import { Events } from '../../../../utils/analytics/events';
import {
  trackFilterPreferencesOpened,
  trackFilterPreferencesChanged,
} from '../../../Analytics/coreAnalytics';
import { FilterTypes } from '../../../Analytics/coreAnalytics.types';
import { useDynamicSession } from '../../lenses/useDynamicSession';
import * as S from './Preferences.styles';
import { InfoLink } from './components/InfoLink';
import { NEL_LEARN_MORE_LINK } from './constants';
import { useDynamicActivitiesByMentalStateId } from '../../../../../src/api/modules/DynamicActivities';
import { ActivitySettingsDynamicDisplay } from '../ActivitySettings/ActivitySettingsDynamic.display';
import { DynamicActivity } from '@Model';

interface Props {
  onClose: () => void;
}

export function PreferencesDynamic(props: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useUser();
  const session = useDynamicSession();
  const outsideClickRef = useOutsideClickRef({ onOutsideClick: props.onClose });
  const isUsingNewTracks = useFeatureFlag(FeatureFlags.NewMusicLibrary);
  const sessionDynamicActivity = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity,
  );

  const stateSelectedGenres = session
    ? user.mentalStatePreferences[session.mentalState as MentalStates].genreNames
    : [];
  const stateSelectedNELs = session
    ? user.mentalStatePreferences[session.mentalState as MentalStates].neuralEffectLevels
    : [];

  const { data: dynamicActivities } = useDynamicActivitiesByMentalStateId();

  const [localSelectedGenres, setLocalGenres] = useState(stateSelectedGenres);
  const [localSelectedNELs, setLocalNELs] = useState(stateSelectedNELs);
  const [localDynamicSelectedActivity, setLocalDynamicActivity] = useState(
    sessionDynamicActivity || dynamicActivities?.[0],
  );

  useEffect(() => {
    Analytics.logEventWithProperties(Events.preferences_opened, {});
    if (session?.mentalState) {
      trackFilterPreferencesOpened({
        filterType: FilterTypes.Consolidated,
        mentalState: session.mentalState as MentalStates,
      });
    }
  }, []);

  const handleSelectDynamicActivity = (activity: DynamicActivity) => {
    setLocalDynamicActivity(activity);
  };

  const handleAnalytics = (
    newState: {
      localSelectedGenres: string[];
      localSelectedNELs: string[];
      localSelectedActivity: Omit<DynamicActivity, 'mentalState'>;
    },
    oldState: {
      stateSelectedGenres: string[];
      stateSelectedNELs: string[];
      stateSelectedActivity: Omit<DynamicActivity, 'mentalState'>;
    },
  ) => {
    // track genres changes
    let genresChanged = null;
    let nelChanged = null;
    let activityChanged = null;
    if (
      JSON.stringify(newState.localSelectedGenres) !== JSON.stringify(oldState.stateSelectedGenres)
    ) {
      const newGenre = [...newState.localSelectedGenres].sort().join(',');
      const oldGenre = [...oldState.stateSelectedGenres].sort().join(',');
      Analytics.logEventWithProperties(Events.preferences_genre_filters_changed, {
        old_filter: oldGenre,
        new_filter: newGenre,
      } as any);
      genresChanged = {
        newFilterGenre: newGenre,
        oldFilterGenre: oldGenre,
      };
    }

    // track NEL changes
    if (JSON.stringify(newState.localSelectedNELs) !== JSON.stringify(oldState.stateSelectedNELs)) {
      const oldNel = oldState.stateSelectedNELs.slice().sort().join(',');
      const newNel = newState.localSelectedNELs.slice().sort().join(',');
      Analytics.logEventWithProperties(Events.preferences_nel_filters_changed, {
        old_filter: oldNel,
        new_filter: newNel,
      } as any);
      nelChanged = {
        newFilterNel: newNel,
        oldFilterNel: oldNel,
      };
    }

    if (
      newState.localSelectedActivity?.displayValue !== oldState.stateSelectedActivity?.displayValue
    ) {
      // track activity changes
      Analytics.logEventWithProperties(Events.preferences_activity_filters_changed, {
        old_filter: oldState.stateSelectedActivity?.displayValue,
        new_filter: newState.localSelectedActivity?.displayValue,
      } as any);
      activityChanged = {
        newFilterActivity: newState.localSelectedActivity?.displayValue,
        oldFilterActivity: oldState.stateSelectedActivity?.displayValue,
      };
    }

    if (session && (activityChanged || nelChanged || genresChanged)) {
      trackFilterPreferencesChanged({
        filterType: FilterTypes.Consolidated,
        mentalState: session?.mentalState as MentalStates,
        ...activityChanged,
        ...nelChanged,
        ...genresChanged,
      });
    }
  };

  const onNelReadMoreClick = useCallback(() => {
    Analytics.logEventWithProperties(Events.learn_more_button_clicked, {
      learn_more_button_location: 'nel_filter',
    });
  }, []);

  const handleGenreSelect = (genreName: string) => {
    if (localSelectedGenres.includes(genreName)) {
      setLocalGenres(localSelectedGenres.filter(item => item !== genreName));
    } else {
      setLocalGenres([...localSelectedGenres, genreName]);
    }
  };

  const handleNELSelect = (NEL: string) => {
    if (localSelectedNELs.includes(NEL)) {
      setLocalNELs(localSelectedNELs.filter(item => item !== NEL));
    } else {
      setLocalNELs([...localSelectedNELs, NEL]);
    }
  };

  const handleApplyChanges = () => {
    let payload: {
      genreNames?: string[];
      neuralEffectLevels?: string[];
    } = {};

    if (JSON.stringify(stateSelectedGenres) !== JSON.stringify(localSelectedGenres)) {
      payload.genreNames = localSelectedGenres;
    }
    if (JSON.stringify(stateSelectedNELs) !== JSON.stringify(localSelectedNELs)) {
      payload.neuralEffectLevels = localSelectedNELs;
    }
    if (localDynamicSelectedActivity?.id !== sessionDynamicActivity?.id) {
      dispatch(
        changeSessionDynamicActivity({
          navigate,
          excludeNewTracks: !isUsingNewTracks,
          activity: localDynamicSelectedActivity!,
          ...payload,
        }),
      );
    } else {
      dispatch(dynamicReplaceTracksWithUpdatedPreferences(payload));
    }

    handleAnalytics(
      {
        localSelectedGenres,
        localSelectedNELs,
        localSelectedActivity: localDynamicSelectedActivity!,
      },
      {
        stateSelectedActivity: localDynamicSelectedActivity!,
        stateSelectedGenres,
        stateSelectedNELs,
      },
    );

    props.onClose();
    showToast('Your preferences have been updated!');
  };

  const isAnythingChanged =
    localDynamicSelectedActivity?.displayValue !== sessionDynamicActivity?.displayValue ||
    JSON.stringify(localSelectedGenres) !== JSON.stringify(stateSelectedGenres) ||
    JSON.stringify(localSelectedNELs) !== JSON.stringify(stateSelectedNELs);

  return (
    <S.Wrapper ref={outsideClickRef}>
      <S.Container>
        <S.Panel>
          <S.HeadingContainer>
            <Text size={15}>Activity</Text>
          </S.HeadingContainer>
          <S.SectionWrapper>
            <ActivitySettingsDynamicDisplay
              activities={dynamicActivities ?? []}
              selectedActivityId={localDynamicSelectedActivity?.id || ''}
              onSelectActivity={handleSelectDynamicActivity}
            />
          </S.SectionWrapper>
        </S.Panel>
        <S.Panel hasBorders={true}>
          <S.HeadingContainer>
            <Text size={15}>Genre</Text>
          </S.HeadingContainer>
          <S.SectionWrapper>
            <Genres
              activityId={localDynamicSelectedActivity?.id}
              selected={localSelectedGenres}
              onSelect={handleGenreSelect}
            />
          </S.SectionWrapper>
        </S.Panel>
        <S.Panel>
          <S.HeadingContainer>
            <Text size={15}>Neural Effect</Text>
            <InfoLink
              link={NEL_LEARN_MORE_LINK}
              text="read about our science"
              onClick={onNelReadMoreClick}
            />
          </S.HeadingContainer>
          <S.SectionWrapper>
            <NeuralEffectLevelSelection selected={localSelectedNELs} onSelect={handleNELSelect} />
          </S.SectionWrapper>
        </S.Panel>
      </S.Container>
      <S.Bottom>
        <Button
          data-testid="preferences-discard-changes"
          size={ButtonVariantSizes.Small}
          style={{ marginRight: 10 }}
          variant={ButtonVariants.Secondary}
          onClick={props.onClose}
        >
          cancel
        </Button>
        <Button
          data-testid="preferences-apply-changes"
          disabled={!isAnythingChanged}
          size={ButtonVariantSizes.Small}
          variant={ButtonVariants.Primary}
          onClick={handleApplyChanges}
        >
          apply changes
        </Button>
      </S.Bottom>
    </S.Wrapper>
  );
}
