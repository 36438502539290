import { Icon } from '@Cortex';
import { MembershipWidget } from '@Memberships';
import { ActivitySelector } from '@Session';
import { ProfileWidget } from '@User';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowLeftIcon from '../../../../assets/images/arrow_left_icon.svg';
import BrandLogo from '../../../../assets/images/brand_logo.svg';
import FiltersIcon from '../../../../assets/images/filters_icon.svg';
import HourglassIcon from '../../../../assets/images/hourglass_icon.svg';
import MusicIcon from '../../../../assets/images/music_icon.svg';
import { ReferAFriendWidget } from '../../../../domains/Memberships/components/ReferAFriendWidget/ReferAFriendWidget.container';
import * as S from './SessionHeader.styles';

type Props = {
  onClickExploreMusic?: () => void;
  onClickPreferences?: () => void;
  onClickTimerSettings?: () => void;
};

export const SessionHeader = (props: Props) => {
  const navigate = useNavigate();

  const membershipWidget = MembershipWidget({
    ['data-testid']: 'membershipWidgetDesktop',
  });
  const referAFriendWidget = ReferAFriendWidget();

  return (
    <S.Header>
      <S.LeftContainer>
        <S.LogoContainer>
          <Icon size={56} src={BrandLogo} onClick={() => navigate('/', { replace: true })} />
        </S.LogoContainer>
        <S.MobileLogoContainer>
          <Icon size={24} src={ArrowLeftIcon} onClick={() => navigate('/', { replace: true })} />
        </S.MobileLogoContainer>

        <ActivitySelector />
      </S.LeftContainer>

      <S.RightContainer>
        <S.CallToActionWidgetContainer>
          {membershipWidget || referAFriendWidget || null}
        </S.CallToActionWidgetContainer>
        <S.HeaderIconContainer data-testid="exploreMusicButton" onClick={props.onClickExploreMusic}>
          <Icon isSquare size={24} src={MusicIcon} />
          <S.HeaderIconLabel>explore</S.HeaderIconLabel>
        </S.HeaderIconContainer>
        <S.HeaderIconContainer data-testid="preferencesButton" onClick={props.onClickPreferences}>
          <Icon isSquare size={24} src={FiltersIcon} />
          <S.HeaderIconLabel>preferences</S.HeaderIconLabel>
        </S.HeaderIconContainer>
        <S.HeaderIconContainer
          data-testid="timerSettingsButton"
          onClick={props.onClickTimerSettings}
        >
          <Icon isSquare size={24} src={HourglassIcon} />
          <S.HeaderIconLabel>timer</S.HeaderIconLabel>
        </S.HeaderIconContainer>
        <ProfileWidget />
      </S.RightContainer>
    </S.Header>
  );
};
