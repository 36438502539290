import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { Text } from '@Cortex';
import { NOOP } from '@Globals';
import { Serving, Track } from '@Model';
import {
  addToDislikes,
  addToFavorites,
  getTrackId,
  getTrackName,
  removeFromDislikes,
  removeFromFavorites,
  useApplyFiltersToTracks,
  getTrackVariationId,
} from '@Music';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { trackCardLoad } from '../../../../../../../../actions/sessionManager';
import { useTracksByGenre } from '../../../../../../../../api/modules/TracksByGenre';
import { useIsEnabledDynamicActivities } from '../../../../../../../../hooks/useIsEnabledDynamicActivities';
import { useShareTrack } from '../../../../../../../../hooks/useShareTrack';
import { RootReducerType } from '../../../../../../../../reducers';
import { Analytics } from '../../../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../../../utils/analytics/events';
import { getDynamicSimilarActivityPlayerPath } from '../../../../../../../../utils/getDynamicSimilarActivityPlayerPath';
import { TracksByGenreDisplay } from './TracksByGenre.display';
import * as S from './TracksByGenre.styles';

interface Props {
  genre: string;
  onGoBack?: () => void;
}

export const TracksByGenre = ({ genre, onGoBack = NOOP }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleGetShareLink } = useShareTrack();
  const dislikedTrackIds = useSelector((state: RootReducerType) => state.music.dislikedTrackIds);
  const favorites = useSelector((state: RootReducerType) => state.music.favorites);
  const { isLoading, error, data } = useTracksByGenre(genre);
  const filteredData = useApplyFiltersToTracks(data);
  const isUsingDynamicActivities = useIsEnabledDynamicActivities();

  if (isLoading) {
    return <S.Loading />;
  }

  if (error) {
    return <Text>{error.message}</Text>;
  }

  // sets up a dictionary for efficient lookup of favorite track
  const favoritesDictionary = favorites.reduce(
    (acc, track) => {
      acc[getTrackId(track)] = track;
      return acc;
    },
    {} as { [key: string]: Track | Serving },
  );

  function handleClickPlay(track: Track | Serving) {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromExploreByGenre, {
      trackId: getTrackId(track),
      trackName: getTrackName(track),
    } as any);

    if (isUsingDynamicActivities) {
      navigate(getDynamicSimilarActivityPlayerPath(getTrackId(track)));
    } else {
      dispatch(
        trackCardLoad({
          trackId: getTrackId(track),
          isUsingDynamicActivities,
        }),
      );
    }

    CoreAnalytics.trackSelectTrackEvent({
      origin: TrackActionOrigins.Explore,
      trackName: getTrackName(track),
    });
  }

  const handleVariationClickPlay = (track: Track | Serving) => (trackVariationId: string) => {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromExploreByGenre, {
      trackId: getTrackId(track),
      trackVariationId,
      trackName: getTrackName(track),
    } as any);

    dispatch(
      trackCardLoad({ trackId: getTrackId(track), trackVariationId, isUsingDynamicActivities }),
    );
  };

  return (
    <TracksByGenreDisplay
      genreName={genre}
      tracks={filteredData.map(data => ({
        ...data,
        isDisliked: dislikedTrackIds.includes(getTrackId(data)),
        isFavorited: Boolean(favoritesDictionary[getTrackId(data)]),
        onAddToDislikes: () => dispatch(addToDislikes({ track: data })),
        onAddToFavorites: () =>
          dispatch(addToFavorites({ origin: TrackActionOrigins.Explore, track: data })),
        onClickPlay: () => handleClickPlay(data),
        onShareTrack: () => handleGetShareLink(data),
        onVariationClickPlay: handleVariationClickPlay(data),
        onRemoveFromDislikes: () => dispatch(removeFromDislikes({ trackId: getTrackId(data) })),
        onRemoveFromFavorites: () => dispatch(removeFromFavorites({ track: data })),
      }))}
      onClickGoBack={onGoBack}
    />
  );
};
