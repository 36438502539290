import { Button, ButtonVariants, ButtonVariantStates } from '@Cortex';
import { NOOP } from '@Globals';
import { Formik } from 'formik';
import { ReactNode } from 'react';
import * as Yup from 'yup';

import { EnhancedMembershipPlanType } from '../../../../types';
import { SignupBenefits } from '../../../shared/SignupBenefits';
import * as S from './StripePayment.styles';

const validationSchema = Yup.object({
  name: Yup.string().required('Please fill out billing name'),
});

export interface Props {
  billingInterval?: string;
  billingName?: string;
  errorMessage?: string | null;
  onPlanSelect: (plan: EnhancedMembershipPlanType) => void;
  onSubmit?: (value: string) => void;
  plans: EnhancedMembershipPlanType[];
  selectedPlan: EnhancedMembershipPlanType | null;
  transactionStatus?: PaymentStatus;
  children: ReactNode | undefined;
}

export type PaymentStatus = 'idle' | 'loading' | 'success' | 'error';

export const OnboardingStripePaymentDisplay: React.FC<Props> = ({
  billingName = '',
  children,
  errorMessage = null,
  onPlanSelect,
  onSubmit = NOOP,
  plans,
  selectedPlan,
  transactionStatus = 'idle',
}) => {
  let variantState = ButtonVariantStates.Idle;

  if (transactionStatus === 'loading') {
    variantState = ButtonVariantStates.Loading;
  } else if (transactionStatus === 'error') {
    variantState = ButtonVariantStates.Error;
  } else if (transactionStatus === 'success') {
    variantState = ButtonVariantStates.Success;
  }

  const hasDiscount = Boolean(
    (selectedPlan?.couponId || selectedPlan?.promotionCode) &&
      selectedPlan?.originalCost &&
      selectedPlan?.originalCost !== selectedPlan?.displayCost,
  );

  return (
    <S.Modal>
      <SignupBenefits title={'Get brain.fm PRO'} />
      <S.VerticalSeparator />
      <S.BoxContainer>
        <S.Container>
          <S.PlanSelector>
            {plans.map(plan => (
              <S.Plan
                key={plan.title}
                data-testid={plan.title}
                hasSavings={plan.title === 'Yearly'}
                isActive={selectedPlan?.title === plan.title}
                onClick={() => onPlanSelect(plan)}
              >
                {plan.title}
              </S.Plan>
            ))}
          </S.PlanSelector>

          <S.PlanDescription>
            {hasDiscount ? (
              <S.PlanDescriptionOriginalCostWrapper data-testid="discountInfo">
                <S.PlanDescriptionOriginalCost>
                  <S.Strikethrough>${selectedPlan?.originalCost}</S.Strikethrough>
                </S.PlanDescriptionOriginalCost>{' '}
                <S.Strikethrough>/ {selectedPlan?.displayInterval}</S.Strikethrough>
              </S.PlanDescriptionOriginalCostWrapper>
            ) : null}
            <S.PlanDescriptionCostWrapper>
              <S.PlanDescriptionCost>${selectedPlan?.displayCost}</S.PlanDescriptionCost> /{' '}
              {selectedPlan?.displayInterval}
            </S.PlanDescriptionCostWrapper>
            <S.PlanDescriptionText>{selectedPlan?.description}*</S.PlanDescriptionText>
            <S.PlanDescriptionCancelText>Cancel anytime</S.PlanDescriptionCancelText>
            <S.PlanPriceDisclaimerText>
              *All prices in USD. Subject to currency exchange rates.
            </S.PlanPriceDisclaimerText>
          </S.PlanDescription>
          <S.Separator />

          <form>
            <Formik
              initialValues={{ name: billingName }}
              validationSchema={validationSchema}
              onSubmit={values => onSubmit(values.name)}
            >
              {({ handleChange, handleSubmit, errors, touched, values }) => {
                const hasErrors = touched.name && errors.name ? true : false;

                return (
                  <>
                    <S.NameInputContainer>
                      <S.NameInputLabel htmlFor="billingName" isError={hasErrors}>
                        {hasErrors ? errors.name : 'Billing Name'}
                      </S.NameInputLabel>
                      <S.NameInput
                        data-testid="billingName"
                        id="billingName"
                        name="name"
                        placeholder="Billing Name"
                        value={values.name}
                        onChange={handleChange}
                      />
                    </S.NameInputContainer>

                    <S.CardContainer data-testid="cardContainer">{children}</S.CardContainer>

                    <S.ButtonContainer>
                      <Button
                        data-testid="handlePurchase"
                        isFullWidth
                        type="button"
                        variant={ButtonVariants.Primary}
                        variantState={variantState}
                        onClick={() => handleSubmit()}
                      >
                        {selectedPlan?.couponId || selectedPlan?.promotionCode
                          ? 'Purchase'
                          : `Purchase ${selectedPlan?.title}`}
                      </Button>
                    </S.ButtonContainer>
                  </>
                );
              }}
            </Formik>
          </form>
          {errorMessage ? <S.ErrorMessage>{errorMessage}</S.ErrorMessage> : null}
        </S.Container>
      </S.BoxContainer>
    </S.Modal>
  );
};
