import { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';

const APPLE_URL =
  'https://itunes.apple.com/vn/app/brain.fm-music-for-focus-meditation/id1110684238?mt=8';
const GOOGLE_URL =
  'https://play.google.com/store/apps/details?id=com.brainfm.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';

const StyledLink = styled.a`
  text-decoration: underline;
  color: #fff;
  cursor: pointer;
`;

interface ExternalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  analyticsEvent: any;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ children, analyticsEvent, ...rest }) => (
  <StyledLink
    data-analytics-event={JSON.stringify(analyticsEvent)}
    rel="noopener noreferrer"
    target="_blank"
    {...rest}
  >
    {children}
  </StyledLink>
);

export enum FAQTopics {
  ScienceAndTech = 'ScienceAndTech',
  AccessAndAccounts = 'AccessAndAccounts',
  GettingBestResults = 'GettingBestResults',
  ProjectAndTeam = 'ProjectAndTeam',
}

export const data = {
  [FAQTopics.ScienceAndTech]: {
    title: 'Science & Tech',

    questions: [
      {
        question: 'What is brain.fm?',
        answer: (
          <div>
            <p>
              brain.fm makes music to help you do what you need to do, better. The company uses a
              patented A.I. engine to create music backed by scientific research to help listeners
              focus, relax, and sleep. While other music is primarily made to sound good and evoke
              feelings, brain.fm works with teams of scientists and composers to engineer music
              specifically designed to help you enter particular mental states within minutes of
              use.
            </p>
            <p>
              A pioneer in auditory neuroscience, brain.fm has recently been recognized by the
              National Science Foundation with a grant to support its work in the field.
            </p>
          </div>
        ),
      },
      {
        question: 'How does brain.fm make music?',
        answer: (
          <div>
            <p>
              Humans compose the musical content (the art: melodies, harmonies, chord progressions,
              sound design, instrument choices, etc.) Then, a patented algorithmic system (A.I.)
              arranges these motifs over long timescales, and adds the acoustic features which
              constitute our core technology (the science: modulation to alter brain activity, 3D
              spatialization, salience reduction, etc.). Finally, compositions are assessed by human
              listeners in-house and tweaked or discarded if necessary, and are tested via
              large-scale experiments to ensure they have the properties required to help listeners
              attain and sustain desired mental states over long periods of time.
            </p>
          </div>
        ),
      },
      {
        question: 'What’s the science behind brain.fm?',
        answer: (
          <div>
            <p>
              The music is designed to have effects on neurophysiology via unique acoustic features
              woven into the music (brain.fm holds patents for key aspects of this process).
              Examples include modulations optimized to evoke entrainment of neural oscillations,
              filtering to exclude distracting sound events, or smooth movement in virtual space to
              direct attention or avoid habituation.
            </p>
            <p>
              brain.fm is funded by a grant from the National Science Foundation (NSF-STTR#1720698).
            </p>
            <p>
              Research conducted by brain.fm and other literature can be found here:{' '}
              <ExternalLink
                analyticsEvent={{ category: 'FAQ Page', action: 'About Us Link Click' }}
                href="https://try.brain.fm/science"
              >
                https://www.brain.fm/science
              </ExternalLink>
            </p>
          </div>
        ),
      },
      {
        question: 'How long does brain.fm take to work?',
        answer: (
          <div>
            <p>
              brain.fm sessions are designed to work within 10 - 15 minutes of use, and the effects
              while listening are designed to be sustained over long periods of time. Users often
              listen to brain.fm in sessions lasting 30 minutes at a minimum, with runs of several
              continuous hours not uncommon.
            </p>
          </div>
        ),
      },
      {
        question: 'Is brain.fm using binaural beats?',
        answer: (
          <div>
            <p>
              No, and brain.fm is very different from binaural beats. Binaural beats use stereo
              separation to create phantom modulation in the brainstem, which is not what brain.fm
              is doing. Although both use acoustic modulation, brain.fm also includes many other
              sound features designed to help you reach particular mental states. Just to give a few
              examples, our sleep music uses 3D spatialization to produce relaxing auditory motion
              (‘rocking’), and our focus music filters out high frequencies that can otherwise be
              distracting.
            </p>
          </div>
        ),
      },
    ],
  },
  [FAQTopics.AccessAndAccounts]: {
    title: 'Access and Accounts',

    questions: [
      {
        question: 'What devices can I use to listen to brain.fm?',
        answer: (
          <div>
            <p>
              If your device can run Chrome, Firefox or Safari, you can use it to listen. Visiting
              brain.fm in any modern web browser lets you access the full app.
            </p>
            <p>
              We also have mobile apps for iOS{' '}
              <ExternalLink
                analyticsEvent={{ category: 'FAQ Page', action: 'Apple App Store Link Click' }}
                href={APPLE_URL}
              >
                (available on iTunes)
              </ExternalLink>
              and for Android{' '}
              <ExternalLink
                analyticsEvent={{ category: 'FAQ Page', action: 'Google Play Store Link Click' }}
                href={GOOGLE_URL}
              >
                (available on Google Play)
              </ExternalLink>
              .
            </p>
          </div>
        ),
      },
      {
        question: 'How do I purchase?',
        answer: (
          <div>
            <p>
              In the free trial, you’ll spot an “Upgrade” button in the top right corner. There
              you’ll find all the subscription details, and the option to purchase using any major
              international credit card (Need an account? Sign up{' '}
              <ExternalLink
                analyticsEvent={{ category: 'FAQ Page', action: 'Sign up Link Click' }}
                href="https://my.brain.fm/signup"
              >
                here
              </ExternalLink>
              ).
            </p>
          </div>
        ),
      },
      {
        question: 'How do you ensure payment security?',
        answer: (
          <div>
            <p>
              We use a service called Stripe, an internationally trusted payment provider certified
              at the most stringent level available in the payments industry. We don’t store your
              credit card details on our servers, meaning their security can’t be compromised by
              either our company, or anyone who might access your brain.fm account.
            </p>
          </div>
        ),
      },
      {
        question: 'What if I need to cancel?',
        answer: (
          <div>
            <p>
              No problem! None of our subscriptions tie you to a contract. See below for the steps
              to cancel, based on where you purchased the subscription:
            </p>
            <p>
              <i>(Purchased on brain.fm website)</i> You can cancel at any time from your{' '}
              <ExternalLink
                analyticsEvent={{ category: 'FAQ Page', action: 'Accound Overview Link Click' }}
                href="https://my.brain.fm/user/account"
              >
                Account Overview
              </ExternalLink>
              , or by contacting us at support@brain.fm.
            </p>
            <p>
              <i>(Purchased on iTunes)</i> Please see the following Apple support article:{' '}
              <ExternalLink
                analyticsEvent={{
                  category: 'FAQ Page',
                  action: 'Apple App Store Manage Subscription Link Click',
                }}
                href="https://support.apple.com/en-ca/HT202039"
              >
                View, change, or cancel your subscriptions
              </ExternalLink>
              .
            </p>
            <p>
              <i>(Purchased on Google Play)</i> Please see the following Google support article:{' '}
              <ExternalLink
                analyticsEvent={{
                  category: 'FAQ Page',
                  action: 'Google Play Store Manage Subscription Link Click',
                }}
                href="https://support.google.com/googleplay/answer/2476088?hl=en&visit_id=1-636570844213510521-2895553368&rd=1"
              >
                Subscriptions on Google Play
              </ExternalLink>
            </p>
          </div>
        ),
      },
    ],
  },
  [FAQTopics.GettingBestResults]: {
    title: 'Getting Best Results',

    questions: [
      {
        question: 'Any gear needed or recommended?',
        answer: (
          <div>
            <p>
              We suggest using high quality earphones or headphones. For Sleep sessions, it’s ideal
              to use headband-style earphones for added comfort and stability during the night.
            </p>
          </div>
        ),
      },
      {
        question: 'When should I listen?',
        answer: (
          <div>
            <p>
              Listen anytime you want to focus, relax, sleep, nap or meditate. We suggest listening
              during the activity, (i.e. use the audio while you’re studying, instead of before) and
              listening as often as you’d like.
            </p>
          </div>
        ),
      },
      {
        question: 'Why shouldn’t I listen without headphones?',
        answer: (
          <div>
            <p>
              Without headphones you won’t hear the 3D audio enhancements, which are significant to
              the impact that the sessions are capable of.
            </p>
            <p>
              With stereo headphones, we can place a sound anywhere in 3D audio space. Behind you,
              in front of you, below you, around you, etc.
            </p>
            <p>
              For Focus sessions, we spatially locate all of the sound in front of you - as if the
              sound were coming from a book you are reading or a central point on the monitor in
              front of you. It keeps your mind attentive to what it’s looking at. It also places
              other sounds around you, which helps to drown out background noise.
            </p>
            <p>
              For Sleep and Relax, it mimics the rocking of a cradle or hammock. This is one of the
              theories behind why our sleep sessions work so well. The ears have a lot to do with
              balance.
            </p>
          </div>
        ),
      },
      {
        question: 'Can I download the audio and listen offline?',
        answer: (
          <div>
            <p>
              Yes! Via our mobile apps for iOS and Android, you can download any track for offline
              playback (note: this is a PRO feature).
            </p>
          </div>
        ),
      },
      {
        question: "Can I use sound cards or DSP's for added effects?",
        answer: (
          <div>
            <p>
              To get optimal effects of the music and desired mental state, we suggest disabling any
              DSP or EQ settings your device and/or headphones have which may be adding reverb,
              echo, or other FX to your audio.
            </p>
          </div>
        ),
      },
    ],
  },
  [FAQTopics.ProjectAndTeam]: {
    title: 'Project and Team',

    questions: [
      {
        question: 'Who’s the team behind brain.fm?',
        answer: (
          <div>
            <p>
              Learn more about us here -{' '}
              <ExternalLink
                analyticsEvent={{ category: 'FAQ Page', action: 'About Link Click' }}
                href="https://try.brain.fm/about"
              >
                www.brain.fm/about
              </ExternalLink>
            </p>
          </div>
        ),
      },
      {
        question:
          'I have another question / spotted a bug / have a suggestion - where do I reach you?',
        answer: (
          <div>
            <p>
              You can contact us at support@brain.fm about any or all of the above- we’d love to
              hear from you!
            </p>
          </div>
        ),
      },
    ],
  },
};
