import { Text, Button, ButtonVariants, FormInput } from '@Cortex';
import { NOOP } from '@Globals';
import React, { ChangeEvent, forwardRef, useState } from 'react';
import { TransitionStatus } from 'react-transition-group';

import { FormContainer } from '../../../../../shared/FormContainer';
import * as S from './Cancellation.styles';
import { RadioButton } from './components/RadioButton';

const OTHER_REASON = 'Other';

interface Props {
  onClose?: () => void;
  onContinue?: (value: string) => void;
  animationState: TransitionStatus;
}

export const Cancellation = forwardRef<HTMLDivElement, Props>(
  ({ onClose = NOOP, onContinue = NOOP, animationState }, ref) => {
    const [cancellationReason, setCancellationReason] = useState<string | null>(null);
    const [customReason, setCustomReason] = useState<string | null>(null);

    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
      setCancellationReason(evt.target.value);
    };
    const handleChangeCustomReason = (evt: ChangeEvent<HTMLInputElement>) => {
      setCustomReason(evt.target.value);
    };

    return (
      <S.Container ref={ref} animationState={animationState}>
        <FormContainer keepTextCase maxWidth="32rem" title="Feedback" onClose={onClose}>
          <form
            onSubmit={evt => {
              if (cancellationReason) {
                evt.preventDefault();

                if (cancellationReason === OTHER_REASON) {
                  onContinue(customReason || cancellationReason);
                  return;
                }

                onContinue(cancellationReason);
              }
            }}
          >
            <S.TextWrapper>
              <Text>
                We're sorry to see you had to cancel your brain.fm subscription. Could you share the
                reason with us? I would not cancel if:
              </Text>
            </S.TextWrapper>
            <S.Body>
              <RadioButton
                data-testid="cancelReason1"
                id="reason1"
                text="The price was lower"
                onChange={handleChange}
              />
              <RadioButton
                data-testid="cancelReason2"
                id="reason2"
                text="New music was added more frequently"
                onChange={handleChange}
              />
              <RadioButton
                data-testid="cancelReason3"
                id="reason3"
                text="There was more variety in the music"
                onChange={handleChange}
              />
              <RadioButton
                data-testid="cancelReason4"
                id="reason4"
                text="The music was more effective"
                onChange={handleChange}
              />
              <RadioButton
                data-testid="cancelReason5"
                id="reason5"
                text="I didn't encounter technical issues/bugs"
                onChange={handleChange}
              />
              <RadioButton id="reason6" text={OTHER_REASON} onChange={handleChange} />
            </S.Body>
            {cancellationReason === OTHER_REASON ? (
              <S.CancellationReason>
                <FormInput label="Tell us more" onChange={handleChangeCustomReason} />
              </S.CancellationReason>
            ) : null}
            <S.Footer>
              <S.ButtonWrapper>
                <Button
                  data-testid="confirmCancelSubscriptionButton"
                  disabled={cancellationReason === null}
                  keepTextCase
                  type="submit"
                  variant={ButtonVariants.Base}
                >
                  Cancel My Subscription
                </Button>
              </S.ButtonWrapper>

              <S.ButtonWrapper>
                <Button
                  keepTextCase
                  type="button"
                  variant={ButtonVariants.Primary}
                  onClick={onClose}
                >
                  Try it again
                </Button>
              </S.ButtonWrapper>
            </S.Footer>
          </form>
        </FormContainer>
      </S.Container>
    );
  },
);
