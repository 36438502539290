import { Loading, Icon, LoadingText, ButtonVariantStates, Button } from '@Cortex';
import { TEAMS_APP_URL } from '@Globals';
import { UserTeamType, UserTeamRoles } from '@Teams';
import React from 'react';

import TeamIcon from '../../../../assets/images/deep_work_icon.svg';
import { Variants } from '../../../Cortex/components/Button/Button';
import { MembershipPanelAura } from '../../../Memberships/components/MembershipPanel/components/MembershipPanelAura';
import * as S from './TeamInfo.styles';

export interface Props {
  list: UserTeamType[];
  isLoading: boolean;
}

export const TeamInfoDisplay = ({ list, isLoading }: Props) => {
  return (
    <S.ContentContainer>
      {list.map(({ id, title, hasPlatformAccess, role }) => (
        <S.Container key={id}>
          <S.Header>
            <S.HeaderIcon>
              {isLoading ? (
                <Loading height="44px" uniqueId="membershipPanelLoading" />
              ) : (
                <Icon size={53} src={TeamIcon} />
              )}
              <S.HeaderIconAura>
                <MembershipPanelAura
                  innerBackground={hasPlatformAccess ? S.normalAura.inner : S.redAura.inner}
                  outerBackground={hasPlatformAccess ? S.normalAura.outer : S.redAura.outer}
                />
              </S.HeaderIconAura>
            </S.HeaderIcon>
            <S.HeaderText width={isLoading ? '100%' : 'auto'}>
              <S.HeaderTextSubtitle>
                {isLoading ? <LoadingText width="25%" /> : `You are the ${role} of the team:`}
              </S.HeaderTextSubtitle>
              <S.HeaderTextTitle>{isLoading ? <LoadingText /> : title}</S.HeaderTextTitle>
            </S.HeaderText>
            {!hasPlatformAccess ? (
              <div>
                <S.Tag size="0.75rem">Inactive</S.Tag>
              </div>
            ) : null}
          </S.Header>
          <S.Children>
            {isLoading ? (
              <LoadingText />
            ) : (
              <S.SubscriptionInfoText>
                {hasPlatformAccess
                  ? "Enjoy using Brain.FM with the Team's subscription with no limits!"
                  : "Your team doesn't have an active subscription. Contact your team's admin or get an individual subscription instead."}
              </S.SubscriptionInfoText>
            )}
          </S.Children>
          {[UserTeamRoles.Admin, UserTeamRoles.Owner].includes(role) && (
            <S.ManageButtonWrapper>
              <S.StyledLink href={TEAMS_APP_URL} target="_blank">
                <Button variant={Variants.Outline} variantState={ButtonVariantStates.Idle}>
                  Manage Team
                </Button>
              </S.StyledLink>
            </S.ManageButtonWrapper>
          )}
        </S.Container>
      ))}
    </S.ContentContainer>
  );
};
