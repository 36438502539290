import { EffectLevels } from '@Model';
import { NeuralEffectLevelIcon } from '@Music';
import { useFeatureFlag, FeatureFlags } from '@Utils';
import { SyntheticEvent } from 'react';

import { MultiNelIcon } from '../MultiNelIcon';
import * as S from './TrackInformationCard.styles';
import { HeartIcon } from './components/HeartIcon';
import { ShareIcon } from './components/ShareIcon';
import { ThumbsDownIcon } from './components/ThumbsDownIcon/ThumbsDownIcon';
import { FALLBACK_TRACK_IMAGE } from '@Globals';

export type Props = {
  addToFavoritesButtonTestId?: string;
  shareButtonTestId?: string;
  dislikeButtonTestId?: string;
  imageUrl: string;
  hasMultipleNELs?: boolean;
  isDisliked?: boolean;
  isFavoritingAvailable?: boolean;
  isDislikingAvailable?: boolean;
  isFavorited?: boolean;
  isNewlyCreated?: boolean;
  neuralEffectLevel: EffectLevels;
  onAddToDislikes?: () => void;
  onAddToFavorites?: () => void;
  onClick?: () => void;
  onShareTrack?: () => void;
  onRemoveFromDislikes?: () => void;
  onRemoveFromFavorites?: () => void;
  subtitle: string;
  title: string;
};

export function TrackInformationCard(props: Props) {
  const isDeepLinksEnabled = useFeatureFlag(FeatureFlags.DeepLinks);

  return (
    <S.Container data-testid="trackInformationCard">
      <S.Image
        isClickable={Boolean(props.onClick)}
        src={props.imageUrl}
        onClick={createHandleClick(props.onClick)}
        onError={evt => {
          evt.currentTarget.src = FALLBACK_TRACK_IMAGE;
        }}
      />
      <S.InformationContainer>
        <S.Title
          data-testid="currentTrackTitle"
          isClickable={Boolean(props.onClick)}
          onClick={createHandleClick(props.onClick)}
        >
          <S.NeuralEffectLevelIcon>
            {props.hasMultipleNELs ? (
              <MultiNelIcon />
            ) : (
              <NeuralEffectLevelIcon effectLevel={props.neuralEffectLevel} />
            )}
          </S.NeuralEffectLevelIcon>

          {props.title}
          {props.isNewlyCreated && <S.TrackLabel data-testid="newLabel">new</S.TrackLabel>}
        </S.Title>
        <S.Subtitle data-testid="trackSubtitle" onClick={createHandleClick(props.onClick)}>
          {props.subtitle}
        </S.Subtitle>
        <S.TrackActions>
          {props.isFavoritingAvailable && (
            <S.TrackActionButton
              data-cy="addToFavoritesButton"
              data-testid={props.addToFavoritesButtonTestId ?? 'addToFavoritesButton'}
              onClick={createHandleClick(
                props.isFavorited ? props.onRemoveFromFavorites : props.onAddToFavorites,
              )}
            >
              <HeartIcon fillOpacity={props.isFavorited ? 1 : 0} />
            </S.TrackActionButton>
          )}
          {props.isDislikingAvailable && (
            <S.TrackActionButton
              data-cy="dislikeCurrentButton"
              data-testid={props.dislikeButtonTestId ?? 'dislikeButton'}
              onClick={createHandleClick(
                props.isDisliked ? props.onRemoveFromDislikes : props.onAddToDislikes,
              )}
            >
              <ThumbsDownIcon fillOpacity={props.isDisliked ? 1 : 0} />
            </S.TrackActionButton>
          )}
          {props.onShareTrack && isDeepLinksEnabled && (
            <S.TrackActionButton
              data-testid={props.shareButtonTestId ?? 'shareButton'}
              onClick={createHandleClick(props.onShareTrack)}
            >
              <ShareIcon />
            </S.TrackActionButton>
          )}
        </S.TrackActions>
      </S.InformationContainer>
    </S.Container>
  );
}

function createHandleClick(onClick?: () => void): (event: SyntheticEvent) => void {
  return function handleClick(event: SyntheticEvent): void {
    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }
  };
}
