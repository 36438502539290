import { FeatureFlags, useFeatureFlag, useIsFeatureFlagsReceived } from '@Utils';

import { DEFAULT_ONBOARDING } from '../constants';
import { OnboardingSpecification } from '../types';

export const useOnboardingProcessedData = (): {
  isLoading: boolean;
  onboardingSpecification: OnboardingSpecification;
} => {
  const isFeatureFlagsReceived = useIsFeatureFlagsReceived();
  const {
    cards: onboardingCardsLD,
    quizSpecification: onboardingQuizSpecificationLD,
    id: variationIdLD,
  } = useFeatureFlag<OnboardingSpecification | undefined>(FeatureFlags.Onboarding) || {};

  // LD is initialized and cards received, use them.
  if (isFeatureFlagsReceived && variationIdLD) {
    return {
      isLoading: false,
      onboardingSpecification: {
        cards: onboardingCardsLD ?? [],
        quizSpecification: onboardingQuizSpecificationLD,
        id: variationIdLD,
      },
    };
  }

  // LD is initialized and no cards were received, use hardcoded web fallback.
  if (isFeatureFlagsReceived && !onboardingCardsLD?.length) {
    return {
      isLoading: false,
      onboardingSpecification: {
        cards: DEFAULT_ONBOARDING.cards,
        quizSpecification: DEFAULT_ONBOARDING.quizSpecification,
        id: DEFAULT_ONBOARDING.id,
      },
    };
  }

  // cards are loading
  return {
    isLoading: true,
    onboardingSpecification: {
      cards: [],
      id: '',
    },
  };
};
