import { SessionType } from '../sessionManager';

export enum MentalStates {
  Focus = 'focus',
  Relax = 'relax',
  Sleep = 'sleep',
}

export interface MentalStateType {
  _id: string;
  type: string;
  slug: string;
  display: string;
  activities: string[];
  createdAt: string;
  updatedAt: string | null;
}

export interface MentalStatesNormalizedType {
  ids: string[];
  results: {
    [key: string]: MentalStateType;
  };
}

interface MentalStateData {
  _id: string;
  activities: string[];
  type: SessionType;
  display: string;
  color: string;
}

export interface MentalStatesDataType {
  types: SessionType[];
  byId: {
    [key: string]: MentalStateData | undefined;
  };
  byType: {
    [key in SessionType]: MentalStateData;
  };
}
