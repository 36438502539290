import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setModal } from '../actions/ui';

/**
 * @description
 * Closes opened modal if the user hits back button on the browser.
 */
export const useModalCloseOnNavigation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const closeModal = (event: Event) => {
      event.preventDefault();
      dispatch(setModal(null));
    };

    window.addEventListener('popstate', closeModal);

    return () => {
      window.removeEventListener('popstate', closeModal);
    };
  }, [dispatch]);
};
