import { Icon } from '@Cortex';

import ChevronIcon from '../../../../assets/images/chevron_up_icon.svg';
import { ActivityDataSingle } from '../../../../types';
import * as S from './ActivitySelector.styles';

export interface Props {
  isOpen: boolean;
  selectedActivity: ActivityDataSingle['display'];
  onClick: () => void;
}

export const ActivitySelectorDisplay = ({ isOpen, selectedActivity, onClick }: Props) => {
  return (
    <S.Container>
      <S.Selection onClick={onClick}>
        <S.Header>Activity</S.Header>
        <S.TextContainer>
          <S.Text data-testid="selectedActivity">{selectedActivity}</S.Text>
          <S.IconContainer isDown={!isOpen}>
            <Icon size={20} src={ChevronIcon} />
          </S.IconContainer>
        </S.TextContainer>
      </S.Selection>
    </S.Container>
  );
};
