import * as S from './CloseButton.styles';

type Props = {
  'data-testid'?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export function CloseButton(props: Props) {
  return (
    <div data-testid={props['data-testid']} onClick={props.onClick}>
      <S.CloseIcon />
    </div>
  );
}
