import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import * as Yup from 'yup';

import { get, post } from '../../api/client/client';
import { useQuery } from '../../hooks';
import { RootReducerType } from '../../reducers';
import { getGAEvent } from '../../utils/analytics/ga';
import { PrimaryButton } from '../buttons';
import { FormContainer } from './FormContainer';
import { FormHeader } from './FormHeader';
import { FormInput } from './FormInput';

const Form = styled.form`
  margin: 1rem 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
`;

const Text = styled.span`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

const validationSchema = Yup.object({
  password: Yup.string().required('Type in your new password'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Does not match the new password')
    .required('Confirm your new password'),
});

type ResetPasswordFormState = 'loading' | 'error' | 'success' | 'idle';

export const ResetPasswordForm: React.FC = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const { token } = useSelector((state: RootReducerType) => state.auth);
  const [error, setError] = useState('');
  const [status, setStatus] = useState<ResetPasswordFormState>();
  const queryToken = query.get('token');

  useEffect(() => {
    (async () => {
      const { status, messages } = await get<{ status: number; messages: string[] }>({
        path: `/auth/reset-password?token=${queryToken}`,
        token,
      });

      if (status !== 200) {
        setError(messages.join(' '));
        setStatus('error');
      } else {
        setError('');
        setStatus('idle');
      }
    })();
  }, [queryToken, token]);

  const handleFormSubmit = async (password: string) => {
    setStatus('loading');

    const { status, messages } = await post<
      { password: string },
      { status: number; messages: string[] }
    >({
      path: `/auth/reset-password?token=${query.get('token')}`,
      body: { password },
      token,
    });

    if (status !== 200) {
      setError(messages.join(' '));
      setStatus('error');
    } else {
      setStatus('success');
    }
  };

  if (status === 'success') {
    return (
      <FormContainer>
        <FormHeader title="Password has been reset" />
        <Text>Proceed to sign in screen to log in.</Text>
        <ButtonWrapper>
          <PrimaryButton
            analyticsEvent={getGAEvent('Session', 'reset_password', 'success')}
            onClick={() => navigate('/signin', { replace: true })}
          >
            Sign in
          </PrimaryButton>
        </ButtonWrapper>
      </FormContainer>
    );
  }

  return (
    <FormContainer onError={error} onErrorClose={() => setError('')}>
      <FormHeader title="Reset password" />
      <Formik
        initialValues={{ password: '', repeatPassword: '' }}
        validationSchema={validationSchema}
        onSubmit={values => handleFormSubmit(values.password)}
      >
        {({ handleChange, handleSubmit, handleBlur, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <FormInput
                key="password"
                label="New Password"
                name="password"
                placeholder="New Password"
                type="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <FormInput
                key="repeat-password"
                label="Repeat New Password"
                name="repeatPassword"
                placeholder="Repeat New Password"
                type="password"
                value={values.repeatPassword}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <ButtonWrapper>
                <PrimaryButton
                  analyticsEvent={getGAEvent('Signin', 'reset_password', 'set-new-password')}
                  disabled={status === 'error'}
                  loading={status === 'loading'}
                  type="submit"
                >
                  Submit
                </PrimaryButton>
              </ButtonWrapper>
            </Form>
          );
        }}
      </Formik>
    </FormContainer>
  );
};
