// These are in rem values ~ 419px and 150px
import { CDN_URL } from '@Globals';

export const RING_SIZE = 26.25;
export const RING_RADIUS = RING_SIZE / 2;
export const RING_SIZE_DIFFERENCE = 9.375;
export const RING_PULSE_DURATION = 4;
export const RING_STROKE_WIDTH_REM = 0.6;

export const MENTAL_STATE_AURA_GRADIENTS = {
  focus: `${CDN_URL}/images/session/focus_aura_sm.png`,
  relax: `${CDN_URL}/images/session/relax_aura_sm.png`,
  sleep: `${CDN_URL}/images/session/sleep_aura_sm.png`,
};
