import { createAction } from '@reduxjs/toolkit';
import { UserPreferenceDisplayTypes, UserPreferencesType } from 'src/domains/User/reducer';

export const getSessionPreferences = createAction('GET_SESSION_PREFERENCES');
export const getUserPreferences = createAction('GET_USER_PREFERENCES');
export const addSessionPreference = createAction<{
  mentalState: string;
  genreName?: string;
  neuralEffectLevel?: string;
}>('ADD_SESSION_PREFERENCE');
export const removeSessionPreference = createAction<{
  mentalState: string;
  genreName?: string;
  neuralEffectLevel?: string;
}>('REMOVE_SESSION_PREFERENCE');
export const toggleSessionPreference = createAction<{
  genreNames?: string[];
  neuralEffectLevels?: string[];
}>('TOGGLE_SESSION_PREFERENCE');
export const setDefaultDisplayType = createAction<{ type: UserPreferenceDisplayTypes }>(
  'SET_DEFAULT_DISPLAY_TYPE',
);
export const updateUserPreferences =
  createAction<Partial<UserPreferencesType>>('UPDATE_USER_PREFERENCES');
