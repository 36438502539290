import { trackSignUp, CoreAnalytics } from '@Analytics';
import { SELF_URL } from '@Globals';
import { membershipActions } from '@Memberships';
import { musicActions } from '@Music';
import { userSliceActions } from '@User';
import { Action } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import Amplitude from 'amplitude-js';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as analyticsActions from '../actions/analytics';
import * as authActions from '../actions/authentication';
import { RequestMethods } from '../api/client/types';
import { STORAGE_REDIRECT_PATH_KEY } from '../constants';
import { RootReducerType } from '../reducers';
import { authSliceActions } from '../reducers/authentication';
import { currentSessionSliceActions } from '../reducers/currentSession';
import { sessionManagerSliceActions } from '../reducers/sessionManager';
import { uiSliceActions } from '../reducers/uiReducer';
import { userSliceActions as legacyUserSliceActions } from '../reducers/user';
import { getErrorStatusCode } from '../utils/getErrorStatusCode';
import { getUiErrorMessage } from '../utils/getUiErrorMessage';
import { Logger } from '../utils/logger';
import { requestSaga } from './httpRequest';

function* emailLoginSaga(action: Action<authActions.EmailLoginPayload>) {
  try {
    // https://redux-toolkit.js.org/api/createAction#actioncreatormatch
    if (authActions.emailLoginRequest.match(action)) {
      yield put(authSliceActions.emailLogin());
      yield put(analyticsActions.signinLogEvent('sign_in_email'));

      const { result } = yield requestSaga(
        RequestMethods.POST,
        `/auth/email-login`,
        action.payload.values,
      );
      yield put(analyticsActions.fetchUserForSigninAnalytics({ token: result, authType: 'email' }));

      yield _saveTokenInCookie(result);

      yield put(authSliceActions.loginSuccess({ token: result, type: 'email' }));
      yield put(analyticsActions.signinLogEvent('sign_in_email_success'));
      CoreAnalytics.trackSignInSuccess({ method: 'email' });
      yield action.payload.navigate(action.payload.successRedirectUrl, {
        replace: true,
        state: { origin: 'emailSignin' },
      });
      yield clearRedirectPath();
    }
  } catch (error) {
    const errorMessage = getUiErrorMessage(error);
    yield put(authSliceActions.loginFailed(errorMessage));
    if (
      [
        'Please add a valid email address.',
        'Incorrect Email or Password.',
        'Please log in using the third party method you signed up with.',
      ].includes(errorMessage)
    ) {
      Logger.info(errorMessage);
    } else {
      Logger.error('AuthenticationSagas#emailLoginSaga failed.', {
        reason: error,
        status: getErrorStatusCode(error),
        online: navigator.onLine,
        action,
      });
    }
    CoreAnalytics.trackSignInError({ method: 'email', error });
    yield put(analyticsActions.signinLogEvent('sign_in_email_error'));
  }
}

function* emailSignupSaga(action: Action<authActions.EmailSignupPayload>) {
  try {
    if (authActions.emailSignUpRequest.match(action)) {
      yield put(authSliceActions.emailSignup());
      yield put(analyticsActions.signinLogEvent('sign_up_email'));

      const { result } = yield requestSaga(
        RequestMethods.POST,
        '/auth/email-signup',
        action.payload.values,
      );
      yield put(analyticsActions.fetchUserForSignupAnalytics({ token: result, authType: 'email' }));

      yield _saveTokenInCookie(result);
      yield put(authSliceActions.signupSuccess({ token: result, type: 'email' }));
      yield put(analyticsActions.signinLogEvent('sign_up_email_success'));
      trackSignUp();

      CoreAnalytics.trackSignUpSuccess({ method: 'email' });
      yield action.payload.navigate(action.payload.successRedirectUrl, {
        replace: true,
        state: { origin: 'emailSignup' },
      });
      yield clearRedirectPath();
    }
  } catch (error) {
    const errorMessage = getUiErrorMessage(error);
    yield put(authSliceActions.signupFailed(errorMessage));
    if (['Please add a valid email address.', 'Email already in use.'].includes(errorMessage)) {
      Logger.info('AuthenticationSagas#emailSignupSaga.', {
        reason: error,
        payload: authActions.emailSignUpRequest.match(action) ? action.payload.values : null,
      });
    } else {
      Logger.error('AuthenticationSagas#emailSignupSaga failed.', { reason: error });
    }
    CoreAnalytics.trackSignUpError({ method: 'email', error });
    yield put(analyticsActions.signinLogEvent('sign_up_email_error'));
  }
}

function* appleLoginSaga(action: Action) {
  try {
    if (authActions.appleLoginRequest.match(action)) {
      yield put(authSliceActions.appleLogin());
      yield put(analyticsActions.signinLogEvent('sign_in_apple'));

      const { authorization } = yield AppleID.auth.signIn();

      const payload = { accessToken: authorization.id_token };

      const { result } = yield requestSaga(RequestMethods.POST, '/auth/apple-login', payload);
      yield put(analyticsActions.fetchUserForSigninAnalytics({ token: result, authType: 'apple' }));

      yield _saveTokenInCookie(result);

      yield put(
        authSliceActions.loginSuccess({
          token: result,
          type: 'apple',
          thirdPartyToken: authorization.id_token,
        }),
      );

      yield put(analyticsActions.signinLogEvent('sign_in_apple_success'));
      CoreAnalytics.trackSignInSuccess({ method: 'apple' });
      yield action.payload.navigate(action.payload.successRedirectUrl, {
        replace: true,
        state: { origin: 'appleSignin' },
      });
      yield clearRedirectPath();
    }
  } catch (error) {
    const errorMessage = getUiErrorMessage(error);
    yield put(authSliceActions.loginFailed(errorMessage));
    const appleError = (error as any)?.error;
    if (
      (typeof appleError === 'string' && ['popup_closed_by_user'].includes(appleError)) ||
      ["Couldn't find any account with your Apple credentials"].includes(errorMessage)
    ) {
      Logger.info('AuthenticationSagas#appleLoginSaga.', {
        reason: error,
      });
    } else {
      Logger.error('AuthenticationSagas#appleLoginSaga failed.', { reason: error });
    }
    CoreAnalytics.trackSignInError({ method: 'apple', error });
    yield put(analyticsActions.signinLogEvent('sign_in_apple_error'));
  }
}

function* appleSignupSaga(action: Action<authActions.AppleSignupPayload>) {
  try {
    if (authActions.appleSignupRequest.match(action)) {
      yield put(authSliceActions.appleSignup());
      yield put(analyticsActions.signinLogEvent('sign_up_apple'));

      const { authorization } = yield AppleID.auth.signIn();
      const payload = { accessToken: authorization.id_token };

      const { result } = yield requestSaga(RequestMethods.POST, '/auth/apple-signup', payload);
      yield put(analyticsActions.fetchUserForSignupAnalytics({ token: result, authType: 'apple' }));

      yield _saveTokenInCookie(result);

      yield put(
        authSliceActions.signupSuccess({
          token: result,
          type: 'apple',
          thirdPartyToken: authorization.id_token,
        }),
      );

      yield put(analyticsActions.signinLogEvent('sign_up_apple_success'));
      trackSignUp();

      CoreAnalytics.trackSignUpSuccess({ method: 'apple' });
      yield action.payload.navigate(action.payload.successRedirectUrl, {
        replace: true,
        state: { origin: 'appleSignup' },
      });
      yield clearRedirectPath();
    }
  } catch (error) {
    yield put(authSliceActions.signupFailed(getUiErrorMessage(error)));
    const appleError = (error as any)?.error;
    if (typeof appleError === 'string' && ['popup_closed_by_user'].includes(appleError)) {
      Logger.info('AuthenticationSagas#appleSignupSaga.', {
        reason: error,
      });
    } else {
      Logger.error('AuthenticationSagas#appleSignupSaga failed.', { reason: error });
    }

    CoreAnalytics.trackSignUpError({ method: 'apple', error });
    yield put(analyticsActions.signinLogEvent('sign_up_apple_error'));
  }
}

function* facebookLoginSaga(action: Action<authActions.FacebookLoginPayload>) {
  try {
    if (authActions.facebookLoginRequest.match(action)) {
      yield put(authSliceActions.facebookLogin());
      yield put(analyticsActions.signinLogEvent('sign_in_facebook'));

      const { result } = yield requestSaga(RequestMethods.POST, '/auth/facebook-login', {
        userID: action.payload.userID,
        accessToken: action.payload.accessToken,
      });
      yield put(
        analyticsActions.fetchUserForSigninAnalytics({ token: result, authType: 'facebook' }),
      );

      yield _saveTokenInCookie(result);

      yield put(
        authSliceActions.loginSuccess({
          token: result,
          type: 'facebook',
          thirdPartyToken: action.payload.accessToken,
          profileId: action.payload.userID,
        }),
      );

      CoreAnalytics.trackSignInSuccess({ method: 'facebook' });
      yield put(analyticsActions.signinLogEvent('sign_in_facebook_success'));
      yield action.payload.navigate(action.payload.successRedirectUrl, {
        replace: true,
        state: { origin: 'facebookSignin' },
      });
      yield clearRedirectPath();
    }
  } catch (error) {
    const errorMessage = getUiErrorMessage(error);
    yield put(authSliceActions.loginFailed(errorMessage));
    if (["Couldn't find any account with your Facebook credentials"].includes(errorMessage)) {
      Logger.info('AuthenticationSagas#facebookLoginSaga.', { reason: error });
    } else {
      Logger.error('AuthenticationSagas#facebookLoginSaga failed.', { reason: error });
    }

    CoreAnalytics.trackSignInError({ method: 'facebook', error });
    yield put(analyticsActions.signinLogEvent('sign_in_facebook_error'));
  }
}

function* facebookSignupSaga(action: Action<authActions.FacebookLoginPayload>) {
  try {
    if (authActions.facebookSignupRequest.match(action)) {
      yield put(authSliceActions.facebookSignup());
      yield put(analyticsActions.signinLogEvent('sign_up_facebook'));

      const { result } = yield requestSaga(RequestMethods.POST, '/auth/facebook-signup', {
        userID: action.payload.userID,
        accessToken: action.payload.accessToken,
      });

      yield put(
        analyticsActions.fetchUserForSignupAnalytics({ token: result, authType: 'facebook' }),
      );
      yield _saveTokenInCookie(result);

      yield put(
        authSliceActions.signupSuccess({
          type: 'facebook',
          thirdPartyToken: action.payload.accessToken,
          profileId: action.payload.userID,
        }),
      );
      yield put(analyticsActions.signinLogEvent('sign_up_facebook_success'));
      trackSignUp();

      CoreAnalytics.trackSignUpSuccess({ method: 'facebook' });

      yield action.payload.navigate(action.payload.successRedirectUrl, {
        replace: true,
        state: { origin: 'facebookSignup' },
      });
      yield clearRedirectPath();
    }
  } catch (error) {
    const errorMessage = getUiErrorMessage(error);
    yield put(authSliceActions.signupFailed(errorMessage));
    if (['Account already exists. Please try signing in'].includes(errorMessage)) {
      Logger.info('AuthenticationSagas#facebookSignupSaga.', { reason: error });
    } else {
      Logger.error('AuthenticationSagas#facebookSignupSaga failed.', { reason: error });
    }

    CoreAnalytics.trackSignUpError({ method: 'facebook', error });
    yield put(analyticsActions.signinLogEvent('sign_up_facebook_error'));
  }
}

function* googleLoginSaga(action: Action<authActions.GoogleLoginPayload>) {
  try {
    if (authActions.googleLoginRequest.match(action)) {
      yield put(authSliceActions.googleLogin());
      yield put(analyticsActions.signinLogEvent('sign_in_google'));

      const { result } = yield requestSaga(RequestMethods.POST, '/auth/google-login', {
        accessToken: action.payload.accessToken,
      });
      yield put(
        analyticsActions.fetchUserForSigninAnalytics({ token: result, authType: 'google' }),
      );

      yield _saveTokenInCookie(result);

      yield put(
        authSliceActions.loginSuccess({
          token: result,
          type: 'google',
          thirdPartyToken: action.payload.accessToken,
        }),
      );

      CoreAnalytics.trackSignInSuccess({ method: 'google' });
      yield put(analyticsActions.signinLogEvent('sign_in_google_success'));
      yield action.payload.navigate(action.payload.successRedirectUrl, {
        replace: true,
        state: { origin: 'googleSignin' },
      });
      yield clearRedirectPath();
    }
  } catch (error) {
    const errorMessage = getUiErrorMessage(error);
    yield put(authSliceActions.loginFailed(errorMessage));
    if (["Couldn't find any account with your Google credentials"].includes(errorMessage)) {
      Logger.info('AuthenticationSagas#googleLoginSaga.', { reason: error });
    } else {
      Logger.error('AuthenticationSagas#googleLoginSaga failed.', { reason: error });
    }

    CoreAnalytics.trackSignInError({ method: 'google', error });
    yield put(analyticsActions.signinLogEvent('sign_in_google_error'));
  }
}
function* googleSignupSaga(action: Action<authActions.GoogleLoginPayload>) {
  try {
    if (authActions.googleSignupRequest.match(action)) {
      yield put(authSliceActions.googleSignup());
      yield put(analyticsActions.signinLogEvent('sign_up_google'));

      const { result } = yield requestSaga(RequestMethods.POST, '/auth/google-signup', {
        accessToken: action.payload.accessToken,
      });

      yield put(
        analyticsActions.fetchUserForSignupAnalytics({ token: result, authType: 'google' }),
      );
      yield _saveTokenInCookie(result);

      yield put(
        authSliceActions.signupSuccess({
          token: result,
          type: 'google',
          thirdPartyToken: action.payload.accessToken,
        }),
      );
      yield put(analyticsActions.signinLogEvent('sign_up_google_success'));
      trackSignUp();

      CoreAnalytics.trackSignUpSuccess({ method: 'google' });

      yield action.payload.navigate(action.payload.successRedirectUrl, {
        replace: true,
        state: { origin: 'googleSignup' },
      });
      yield clearRedirectPath();
    }
  } catch (error) {
    const errorMessage = getUiErrorMessage(error);
    yield put(authSliceActions.signupFailed(errorMessage));
    if (['Account already exists. Please try signing in'].includes(errorMessage)) {
      Logger.info('AuthenticationSagas#googleSignupSaga.', { reason: error });
    } else {
      Logger.error('AuthenticationSagas#googleSignupSaga failed.', { reason: error });
    }
    CoreAnalytics.trackSignUpError({ method: 'google', error });
    yield put(analyticsActions.signinLogEvent('sign_up_google_error'));
  }
}

function* signOutSaga(action: Action<typeof authActions.signOut>) {
  if (!authActions.signOut.match(action)) return;

  if (!action.payload?.shouldSkipConfirmation) {
    const isConfirmed = window.confirm('You are about to log out. Are you sure?');
    if (!isConfirmed) return;
  }

  const { type }: RootReducerType['auth'] = yield select((state: RootReducerType) => state.auth);

  if (type === 'facebook') {
    FB &&
      FB.getLoginStatus((response: any) => {
        if (response && response.status === 'connected') {
          FB.logout();
        }
      });
  }

  // TODO: we shouldn't have more than one of these.
  // clear out token cookie created by my.brain.fm
  // Delete this line anytime after 6.2.2022.
  document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  // clear out legacy redirect token
  const domain = getHostedDomain();
  document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${domain}`;

  // TODO - lets make everythign clear by default, except for a select few reducers so that
  // when we forget, we don't create problems. (opt out by default)
  yield put(authSliceActions.clearAuthState());
  yield put(currentSessionSliceActions.clearState());
  yield put(membershipActions.resetState());
  yield put(sessionManagerSliceActions.clearState());
  yield put(legacyUserSliceActions.clearUserState());
  yield put(musicActions.resetState());
  yield put(userSliceActions.resetState());

  // the ui reducer is blacklisted in react-persist but if you log
  // out then sign in with out signing out/refreshing then the
  // profile modal will still be up.
  yield put(uiSliceActions.setModalType(null));

  try {
    Amplitude.getInstance().regenerateDeviceId();
    Amplitude.getInstance().setUserId(null);
  } catch (e) {
    Logger.error(new Error('Failed to reset amplitude data on logout.'), { reason: e });
  }

  try {
    Sentry.setUser(null);
  } catch (e) {
    Logger.error(new Error('Failed to reset sentry data on logout.'), { reason: e });
  }
  yield clearRedirectPath();
}

function* clearErrorsSaga() {
  yield put(authSliceActions.clearErrors());
}

function _saveTokenInCookie(token: string): void {
  const domain = getHostedDomain();
  const expiration = new Date();
  expiration.setFullYear(2030);
  document.cookie = `token=${token}; expires=${expiration.toUTCString()}; domain=${domain}`;
}

function clearRedirectPath() {
  sessionStorage.removeItem(STORAGE_REDIRECT_PATH_KEY);
}

function* setAuthTokenSaga(action: Action) {
  if (authActions.setToken.match(action)) {
    yield put(authSliceActions.setToken(action.payload));
  }
}

export default function* watchAuthenticationSaga() {
  yield takeLatest(authActions.setToken.type, setAuthTokenSaga);

  yield takeLatest(authActions.emailLoginRequest.type, emailLoginSaga);
  yield takeLatest(authActions.appleLoginRequest.type, appleLoginSaga);
  yield takeLatest(authActions.facebookLoginRequest.type, facebookLoginSaga);
  yield takeLatest(authActions.googleLoginRequest.type, googleLoginSaga);

  yield takeLatest(authActions.emailSignUpRequest.type, emailSignupSaga);
  yield takeLatest(authActions.appleSignupRequest.type, appleSignupSaga);
  yield takeLatest(authActions.facebookSignupRequest.type, facebookSignupSaga);
  yield takeLatest(authActions.googleSignupRequest.type, googleSignupSaga);

  yield takeLatest(authActions.signOut.type, signOutSaga);
  yield takeLatest(authActions.clearErrors.type, clearErrorsSaga);
}

function getHostedDomain(): string {
  return getDomainFromURL(SELF_URL || 'brain.fm');
}

export function getDomainFromURL(URL: string) {
  const [topLevelDomain, domain] = URL.split('.').reverse();
  return `${domain}.${topLevelDomain}`;
}
