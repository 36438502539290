import { CallToActionWidget, MenuItemIcons } from '@Cortex';
import { FeatureFlags, useFeatureFlag } from '@Utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { RootReducerType } from '../../../../reducers';
import { uiSliceActions } from '../../../../reducers/uiReducer';
import { Analytics } from '../../../../utils/analytics';
import { useIsTrialUser } from '../../lenses/isTrialUser';

export function ReferAFriendWidget() {
  useTrackImpressions();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shouldRender = useShouldRenderReferAFriend();

  function handleClickIcon() {
    Analytics.logEvent('player_refer_a_friend_banner_dismiss');
    dispatch(uiSliceActions.setIsReferAFriendDismissed(true));
  }

  function handleClickRefer() {
    navigate('/user/refer');
    Analytics.logEvent('player_refer_a_friend_banner_click');
  }

  return shouldRender ? (
    <CallToActionWidget
      body="friends join for only $1"
      buttonText="refer a friend."
      icon={MenuItemIcons.Close}
      onClickButton={handleClickRefer}
      onClickIcon={handleClickIcon}
    />
  ) : null;
}

function useShouldRenderReferAFriend() {
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);
  const isTrial = useIsTrialUser();

  const expirationDate = new Date(legacyMembership?.expirationDate || '');
  const timeLeftInMilliseconds = expirationDate.getTime() - Date.now();
  const isExpired = timeLeftInMilliseconds <= 0;

  const hasActiveSubscription = !isTrial && !isExpired;
  const isInFeatureFlag = useFeatureFlag(FeatureFlags.ReferAFriendBanner);
  const isDismissed = useSelector((state: RootReducerType) => state.ui.isReferAFriendDismissed);

  return !isDismissed && isInFeatureFlag && hasActiveSubscription;
}

function useTrackImpressions() {
  const shouldRender = useShouldRenderReferAFriend();
  useEffect(() => {
    if (shouldRender) {
      Analytics.logEvent('player_refer_a_friend_banner_impression');
    }
  }, []);
}
