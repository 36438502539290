import styled from 'styled-components';

export const Container = styled.button`
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 1rem 1rem 1.25rem;
  background: #191736;
  border-radius: 0.75rem;
  width: 100%;
  text-transform: lowercase;
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin-right: 0.5rem;
  width: 2.25rem;
  height: 2.25rem;

  background: linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%);
  box-shadow: 0px 2.54251px 11.4413px rgba(147, 35, 102, 0.4);
  border-radius: 100%;
`;

export const Image = styled.img`
  width: calc(2.25rem - 0.5rem - 0.5rem);
  height: calc(2.25rem - 0.5rem - 0.5rem);
  object-fit: contain;
`;
