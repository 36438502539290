import { SuccessModalDisplay } from '@Cortex';
import ReactModal from 'react-modal';

import BrandLogoWithExclamation from '../../../../assets/images/brand_logo_exclamation.svg';
import { baseStyles, CLOSE_TIMEOUT_MS } from '../../../../modals/modalStyles';
import { SuccessModalTypeAction } from '../../../../types';
import * as S from './VerifyEmailModal.styles';

ReactModal.setAppElement('#root');

export type Props = {
  description: string;
  isOpen: boolean;
  primaryButtonAction: SuccessModalTypeAction['action'];
  primaryButtonHref: string;
  primaryButtonText: string;
  title: string;
};

export function VerifyEmailModalDisplay(props: Props) {
  return (
    <ReactModal closeTimeoutMS={CLOSE_TIMEOUT_MS} isOpen={props.isOpen} style={baseStyles}>
      <S.Wrapper>
        <S.ContainerWithIcon>
          <SuccessModalDisplay
            actions={[
              {
                action: props.primaryButtonAction,
                navigateTo: props.primaryButtonHref,
                navigateState: { from: 'Verify Email Modal' },
                text: props.primaryButtonText,
                type: 'primary',
              },
            ]}
            description={props.description}
            iconSrc={BrandLogoWithExclamation}
            isOpen={true}
            title={props.title}
          />
        </S.ContainerWithIcon>
      </S.Wrapper>
    </ReactModal>
  );
}
