import { createAction } from '@reduxjs/toolkit';

import {
  AnalyticsEvents,
  AnalyticsEventsProperties,
  AnalyticsFetchUserPayload,
  AnalyticsHomeScreenEvents,
  AnalyticsPlayerEvents,
  AnalyticsPostSessionEvents,
  AnalyticsProfileEvents,
  AnalyticsSessionEvents,
  AnalyticsSigninEvents,
  AnalyticsSigninPayload,
  AnalyticsSignupPayload,
} from '../types/utils/analytics';
import { ExploreEvents } from '../utils/analytics/events';

export const logEvent = createAction<AnalyticsEvents>('ANALYTICS_LOG_EVENT');
export const logEventWithProperties = createAction<{
  event: AnalyticsEvents;
  props?: Partial<AnalyticsEventsProperties>;
}>('ANALYTICS_LOG_EVENT_WITH_PROPS');

export const setUser = createAction<AnalyticsSigninPayload>('ANALYTICS_SET_USER');
export const createUser = createAction<AnalyticsSignupPayload>('ANALYTICS_CREATE_USER');
export const fetchUserForSignupAnalytics = createAction<AnalyticsFetchUserPayload>(
  'ANALYTICS_SIGNUP_FETCH_USER',
);
export const fetchUserForSigninAnalytics = createAction<AnalyticsFetchUserPayload>(
  'ANALYTICS_SIGNIN_FETCH_USER',
);

// Player related actions
export const playerResume = createAction<number>('ANALYTICS_PLAYER_RESUME');

export const playerLogEventWithProps = createAction<{ event: AnalyticsPlayerEvents; value: any }>(
  'ANALYTICS_PLAYER_LOG_EVENT_PROPS',
);

export const sessionLogEvent = createAction<AnalyticsSessionEvents>('ANALYTICS_SESSION_LOG_EVENT');

export const signinLogEvent = createAction<AnalyticsSigninEvents>('ANALYTICS_SIGNIN_LOG_EVENT');

export const exploreMusicLogEvent = createAction<ExploreEvents>(
  'ANALYTICS_EXPLORE_MUSIC_LOG_EVENT',
);
export const exploreMusicLogEventWithProps = createAction<{ event: ExploreEvents; value: any }>(
  'ANALYTICS_EXPLORE_MUSIC_LOG_EVENT_PROPS',
);

export const postSessionLogEvent = createAction<AnalyticsPostSessionEvents>(
  'ANALYTICS_POST_SESSION_LOG_EVENT',
);
export const postSessionLogEventWithProps = createAction<{
  event: AnalyticsPostSessionEvents;
  value: any;
}>('ANALYTICS_POST_SESSION_LOG_EVENT_PROPS');

export const homeScreenLogEvent = createAction<AnalyticsHomeScreenEvents>(
  'ANALYTICS_HOMESCREEN_LOG_EVENT',
);
export const profileScreenLogEvent = createAction<AnalyticsProfileEvents>(
  'ANALYTICS_PROFILESCREEN_LOG_EVENT',
);
export const playerLogEvent = createAction<AnalyticsPlayerEvents>('ANALYTICS_PLAYER_LOG_EVENT');

export const logTrackStartEvent = createAction<{ trackId: string; trackVariationId: string }>(
  'ANALYTICS_LOG_TRACK_START_EVENT',
);
