import styled from 'styled-components';

export const PaymentInfo = styled.div`
  font-family: TTNormsPro-Regular;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const PaymentInfoBold = styled.span`
  font-family: TTNormsPro-Bold;
  font-size: 1rem;
`;
