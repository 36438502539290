import * as S from './StreakInfo.styles';

type Props = {
  title: string;
  subTitle: string;
  value: number;
  description: string;
};

export const StreakInfo = ({ title, subTitle, value, description }: Props) => {
  return (
    <S.Streak>
      <S.LeftPart>
        <S.Title>{title}</S.Title>
        <S.Description>{subTitle}</S.Description>
      </S.LeftPart>
      <S.RightPart>
        <S.Title>{value}</S.Title>
        <S.Description>{description}</S.Description>
      </S.RightPart>
    </S.Streak>
  );
};
