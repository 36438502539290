import styled from 'styled-components';

export const Container = styled.div`
  margin: 0.25rem;
  padding: 0.125rem 0;
`;

export const Radio = styled.input.attrs({ type: 'radio' })`
  width: 2rem;
`;

export const RadioButtonLabel = styled.label``;
