import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import * as uiActions from '../../../../actions/ui';
import { post } from '../../../../api/client/client';
import { RootReducerType } from '../../../../reducers';
import { getGAEvent } from '../../../../utils/analytics/ga';
import { getErrorMessage } from '../../../../utils/getErrorMessage';
import { ForgotPasswordScreenDisplay } from './ForgotPasswordScreen.display';

export type ForgotPasswordFormValues = { email: string };

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
});

interface ForgotPasswordFormState {
  status: 'loading' | 'error' | 'success' | 'idle';
  message: string;
  email: string;
}

export const ForgotPasswordScreen: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state: RootReducerType) => state.auth);
  const [state, setState] = useState<ForgotPasswordFormState>({
    status: 'idle',
    message: '',
    email: '',
  });

  const handleFormSubmit = async (email: string) => {
    try {
      setState({ ...state, email, status: 'loading' });

      const { result } = await post<
        { email: string },
        { result: ForgotPasswordFormState['status'] }
      >({
        path: '/auth/forgot-password',
        body: { email },
        token,
      });

      setState({ ...state, status: result });

      if (result === 'success') {
        navigate('/signin', { replace: true });
        dispatch(
          uiActions.successModalOpen({
            actions: [{ type: 'primary', text: 'Close', action: uiActions.successModalClose() }],
            description: `Please check ${email} for a link to reset your password`,
            title: 'Email sent!',
          }),
        );
      }
    } catch (error) {
      setState({ ...state, status: 'error', message: getErrorMessage(error) });
    }
  };

  return (
    <ForgotPasswordScreenDisplay<ForgotPasswordFormValues>
      formError={state.message}
      formInitialValues={{ email: '' }}
      formInputs={[
        {
          label: 'Email',
          name: 'email',
          placeholder: 'Please enter the email associated with your account',
          type: 'email',
        },
      ]}
      formValidationSchema={validationSchema}
      isLoading={state.status === 'loading'}
      onClickSignIn={() => navigate('/signin', { replace: true })}
      onClickSignInAnalyticsEvent={getGAEvent('Session', 'reset_password', 'sign-in')}
      onFormChange={() => setState({ ...state, message: '' })}
      onFormSubmit={values => handleFormSubmit(values.email)}
      onFormSubmitAnalyticsEvent={getGAEvent('Signin', 'forgot_password', 'form')}
    />
  );
};
