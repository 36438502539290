import UserImage from '../../../../../../assets/images/user_icon.svg';
import * as S from './UserIcon.styles';

export const UserIcon = () => {
  return (
    <S.Container>
      <S.Image src={UserImage} />
    </S.Container>
  );
};
