import { ReactNode } from 'react';

import CloseIcon from '../../../assets/images/close_icon.svg';
import * as S from './FormContainer.styles';

interface Props {
  title: string;
  onClose: () => void;
  errorMessage?: string | null;
  keepTextCase?: boolean;
  maxWidth?: string;
  children: ReactNode | undefined;
}

export const FormContainer: React.FC<Props> = ({
  title,
  onClose,
  children,
  errorMessage = null,
  keepTextCase,
  maxWidth,
}) => {
  return (
    <S.Container maxWidth={maxWidth}>
      <S.ImageButton type="button" onClick={onClose}>
        <S.Image src={CloseIcon} />
      </S.ImageButton>

      <S.Title keepTextCase={keepTextCase}>{title}</S.Title>
      {children}
      <S.ErrorBox>
        <S.ErrorText>{errorMessage}</S.ErrorText>
      </S.ErrorBox>
    </S.Container>
  );
};
