import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Assets } from '../../../utils/assets';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const ContainerWithIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 29.125rem;
  max-width: 49.125rem;
  margin: auto;
  padding: 1rem;
`;

const Container = styled.div<{ type: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 1.25rem;
  box-shadow: 0px 4px 24px rgba(10, 19, 51, 0.19);
  overflow: hidden;
  background: radial-gradient(circle at left, #4915f8 -200%, transparent),
    radial-gradient(circle at right, #ff496b -100%, transparent), #232e65;
  ${({ type }) =>
    type === 'relax' &&
    css`
      background: radial-gradient(circle at left, #3ad5e6 -200%, transparent),
        radial-gradient(circle at right, #6223ee -100%, transparent), #232e65;
    `}
  ${({ type }) =>
    type === 'sleep' &&
    css`
      background: radial-gradient(circle at left, #823599 -200%, transparent),
        radial-gradient(circle at right, #c340d9 -100%, transparent), #3c2464;
    `}
`;

const CloseIcon = styled.img`
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  right: 0.75rem;
  top: -1.75rem;

  &:hover {
    cursor: pointer;
  }
`;

interface ModalBackgroundProps {
  onClose?: () => void;
  type?: string;
  children: ReactNode | undefined;
}

export const ModalBackground: React.FC<ModalBackgroundProps> = ({
  children,
  onClose,
  type = 'focus',
}) => {
  return (
    <Wrapper onClick={onClose}>
      <ContainerWithIcon>
        {onClose && (
          <CloseIcon
            alt={Assets.icons.close_white.alt}
            src={Assets.icons.close_white.url}
            onClick={onClose}
          />
        )}
        <Container type={type} onClick={e => e.stopPropagation()}>
          {children}
        </Container>
      </ContainerWithIcon>
    </Wrapper>
  );
};
