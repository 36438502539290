import { useToken } from '@Authentication';
import { NOOP } from '@Globals';
import { MembershipSources, MembershipStates } from '@Memberships';
import { useDispatch, useSelector } from 'react-redux';

import * as authActions from '../../../../actions/authentication';
import * as uiActions from '../../../../actions/ui';
import { deleteCall } from '../../../../api/client/client';
import { RootReducerType } from '../../../../reducers';
import * as S from './DeleteAccount.styles';
import { Confirmation } from './components/Confirmation';

interface Props {
  onClose?: () => void;
}

export const DeleteAccount = ({ onClose = NOOP }) => {
  const handleDeleteAccountExperience = useHandleDeleteAccountExperience();
  const membership = useSelector((state: RootReducerType) => state.membership);

  const isMembershipActive = membership.state === MembershipStates.Active;
  const isTrial = membership.source === MembershipSources.Trial;
  const isDeletionDisabled = isMembershipActive && !isTrial;

  return (
    <S.Container>
      <Confirmation
        bodyText={
          isDeletionDisabled
            ? 'You must cancel your subscriptions before deleting your accounts! See the membership panel for more information.'
            : 'You will immediately lose access to brain.fm, and your information will be deleted within 30 days.'
        }
        isDisabled={isDeletionDisabled}
        onClose={onClose}
        onContinue={handleDeleteAccountExperience}
      />
    </S.Container>
  );
};

function useHandleDeleteAccountExperience() {
  const dispatch = useDispatch();
  const token = useToken();
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);

  return async function handleDeleteAccountExperience() {
    if (!userId || !token) return;

    try {
      await deleteAccountForUserId({ token, userId });
      dispatch(authActions.signOut({ shouldSkipConfirmation: true }));
    } catch (error) {
      dispatch(createOpenErrorModalAction());
    }
  };
}

async function deleteAccountForUserId(params: { token: string; userId: string }) {
  const result = await deleteCall<{}, { status: number }>({
    path: `/users/${params.userId}`,
    body: {},
    token: params.token,
  });
  if (result.status !== 200) throw 'unable to delete account';
}

function createOpenErrorModalAction() {
  return uiActions.successModalOpen({
    iconSrc: null,
    title: 'Something went wrong!',
    description:
      'Please make sure you have cancelled your subscription! If you continue to receive this message use the "Contact Support" option for assistance!',
    actions: [{ type: 'primary', text: 'Close', action: uiActions.successModalClose() }],
  });
}
