import { EffectLevels } from '@Model';
import { useSession } from '@Session';

import { Activity } from '../../../Session/components/ActivitySettings/components/Activity';
import { useDynamicSession } from '../../../Session/lenses/useDynamicSession';
import * as S from './NeuralEffectLevelSelection.styles';

const EFFECT_LEVEL_TEXT = {
  [EffectLevels.Low]:
    'Use this effect level if you are generally sensitive to sounds, or if the higher effect levels feel too intense.',
  [EffectLevels.Medium]:
    'Our standard level of neural phase locking is a great place to start. Be sure to try the other levels to find what works best for you!',
  [EffectLevels.High]:
    'Try the strongest level of our neural phase locking technology if you need extra stimulation, or have attentional challenges (ADHD or similar)',
};

type Props = {
  selected: string[];
  onSelect: (value: string) => void;
};

export const NeuralEffectLevelSelection = ({ selected, onSelect }: Props) => {
  const session = useSession();
  const dynamicSession = useDynamicSession();

  const handleClick = (neuralEffectLevel: EffectLevels) => {
    onSelect(neuralEffectLevel);
  };

  return (
    <S.DropdownContainer>
      <S.TimerInfoPresetsContainer>
        {Object.values(EffectLevels).map((neuralEffectLevel, index) => {
          const isActive = Boolean(
            (session || dynamicSession) && selected.includes(neuralEffectLevel),
          );

          return (
            <Activity
              key={index}
              data-testid={`nel_${neuralEffectLevel.toLowerCase()}`}
              description={EFFECT_LEVEL_TEXT[neuralEffectLevel]}
              icon={neuralEffectLevel}
              isSelected={isActive}
              title={`${neuralEffectLevel} effect`.toLowerCase()}
              onClick={() => handleClick(neuralEffectLevel)}
            />
          );
        })}
      </S.TimerInfoPresetsContainer>
    </S.DropdownContainer>
  );
};
